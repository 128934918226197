<template>
  <b-container class="p-0" fluid>
    <b-navbar
      v-if="!search"
      fixed="bottom"
      class="d-md-none"
      style="justify-content: center"
    >
      <b-navbar-nav
        small
        align="center"
        justified
        class="d-md-none"
        style="align-items: center; width: 100%"
      >
        <b-nav-item
          v-for="(list, i) in supportList"
          :key="i"
          :to="`/help-center/${list.id}`"
          :class="list.link === list.name ? 'active' : ''"
        >
          {{ list.name }}
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>

    <b-navbar
      v-else
      fixed="bottom"
      class="d-md-none"
      style="justify-content: center"
    >
      <b-navbar-nav
        small
        align="center"
        justified
        class="d-md-none"
        style="align-items: center; width: 100%"
      >
        <b-nav-item
          v-for="(list, i) in supportList"
          :key="i"
          :to="`/help-center/${list.supportcategory.id}`"
          :class="'active'"
        >
          {{ list.supportcategory.name }}
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>

    <b-row>
      <b-col cols="12" class="dark-bg" style="height: 150px">
        <b-row align-v="end" style="height: 100%" class="pl-5 pb-3">
          <b-col cols="12" md="6">
            <h1 class="mb-3 heading" style="font-size: 46px; line-height: 100%">
              {{ title }}
            </h1>
          </b-col>
          <b-col cols="12" md="6">
            <b-input-group>
              <b-form-input
                v-model="keyword"
                type="search"
                placeholder="Some words here"
                @keyup.enter.native="searchSupport(keyword)"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text>
                  <b-icon icon="search" @click="searchSupport(keyword)" />
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="pl-5 pr-5">
      <b-col v-if="!search" cols="12" md="3">
        <affix
          class="sidemenu d-none d-sm-none d-md-flex"
          relative-element-selector="#tofixsidebar"
          :offset="{ top: 190, bottom: 40 }"
        >
          <b-nav vertical>
            <b-nav-item
              v-for="(list, i) in supportList"
              :key="i"
              :to="`/help-center/${list.id}`"
              @click="loadDetails(list.name, list.id)"
              :class="list.link === list.name ? 'active' : ''"
            >
              {{ list.name }}
            </b-nav-item>
          </b-nav>
        </affix>
      </b-col>
      <b-col v-else cols="12" md="3">
        <affix
          class="sidemenu d-none d-sm-none d-md-flex"
          relative-element-selector="#tofixsidebar"
          :offset="{ top: 190, bottom: 40 }"
        >
          <b-nav vertical>
            <b-nav-item
              v-for="(list, i) in objSupportCategory"
              :key="i"
              :to="`/help-center/${list}`"
              :class="'active'"
            >
              {{ i }}
            </b-nav-item>
          </b-nav>
        </affix>
      </b-col>
      <b-col
        cols="12"
        md="9"
        id="tofixsidebar"
        class="pb-7"
        style="min-height: calc(100vh - 230px)"
      >
        <!-- <router-view /> -->

        <b-row>
          <b-col v-if="!search" cols="12" class="mt-5">
            <div class="accordion" role="tablist">
              <b-card
                no-body
                class="mb-0 list-of-question"
                v-for="(list, i) in resultQuery"
                :key="i"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div
                    class="toggle-accordion bold d-letter-spacing"
                    v-b-toggle="'accordion-' + i"
                  >
                    {{ list.title }}
                    <span class="when-opened">
                      <b-icon icon="chevron-up" />
                    </span>
                    <span class="when-closed">
                      <b-icon icon="chevron-down" />
                    </span>
                  </div>
                </b-card-header>
                <b-collapse
                  :id="'accordion-' + i"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-text class="pt-4 pb-4 d-letter-spacing">{{
                    list.content
                  }}</b-card-text>
                </b-collapse>
              </b-card>
            </div>
          </b-col>
          <b-col v-else cols="12" class="mt-5">
            <div class="accordion" role="tablist">
              <b-card
                no-body
                class="mb-0 list-of-question"
                v-for="(list, i) in resultQuery"
                :key="i"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div
                    class="toggle-accordion bold d-letter-spacing"
                    v-b-toggle="'accordion-' + i"
                  >
                    {{ list.title }}
                    <span class="when-opened">
                      <b-icon icon="chevron-up" />
                    </span>
                    <span class="when-closed">
                      <b-icon icon="chevron-down" />
                    </span>
                  </div>
                </b-card-header>
                <b-collapse
                  :id="'accordion-' + i"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-text class="pt-4 pb-4 d-letter-spacing">{{
                    list.content
                  }}</b-card-text>
                </b-collapse>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { SupportService } from "@/services/support.service";
export default {
  name: "help-center",
  data() {
    return {
      supportList: [],
      objSupportCategory: {},
      resultQuery: [],
      title: "",
      keyword: "",
      id: "",
      search: false,
      sidenav: [
        {
          name: "How It Works",
          link: "help-center/how-it-works",
        },
        {
          name: "My Account",
          link: "help-center/my-account",
        },
        {
          name: "Orders & Shipping",
          link: "help-center/order-and-shipping",
        },
        {
          name: "Winona Products",
          link: "help-center/winona-products",
        },
        {
          name: "Privacy & Security",
          link: "help-center/privacy-and-security",
        },
      ],
    };
  },
  methods: {
    async loadDetails(name, id) {
      this.search = false;
      this.title = name;
      this.id = id;
      await this.getSupportId(id);
    },
    async getSupportId(id) {
      try {
        let res = await SupportService.getSupportCategoryId(id);
        if (res && res.data) {
          this.resultQuery = res.data;
        }
      } catch (error) {
        if (error.message) {
          console.log("Error", error.message);
        }
      }
    },
    async searchSupport(keyword) {
      try {
        this.search = true;
        this.supportList = [];
        this.objSupportCategory = {};
        this.resultQuery = [];
        let res = await SupportService.searchSupport(keyword);
        if (keyword) {
          if (res && res.data) {
            this.title = `Search results for "${keyword}"`;
            let data = res.data;

            for (let i = 0; i < data.length; i++) {
              this.objSupportCategory[data[i].supportcategory.name] =
                data[i].supportcategory.id;
            }
            this.supportList = data;
            this.resultQuery = data;
          } else {
            this.title = `No results for "${keyword}"`;
          }
        } else {
          this.search = false;
          this.getSupportId(this.$route.params.id);
          this.getSupport();
        }
      } catch (error) {
        if (error.message) {
          console.log("Error", error.message);
        }
      }
    },
    async searchSupportCategory(keyword) {
      try {
        let supportcategoryid = this.id;
        let res = await SupportService.searchSupportCategory(
          keyword,
          supportcategoryid
        );

        if (res && res.data) {
          let data = res.data;
          //console.log("Search ", res.data);

          this.supportList = res.data;
        }
      } catch (error) {
        if (error.message) {
          console.log("Error", error.message);
        }
      }
    },
    async getSupport() {
      this.search = false;
      try {
        let res = await SupportService.getSupportCategory();
        if (res && res.data) {
          let data = res.data;
          this.supportList = res.data;
          let obj = data.filter((item) => item.id === this.$route.params.id);
          this.title = obj[0].name;
        }
      } catch (error) {
        if (error.message) {
          console.log("Error", error.message);
        }
      }
    },
  },

  async mounted() {
    window.analytics.page();
    this.search = false;
    this.id = this.$route.params.id;
    this.getSupportId(this.id);
    this.getSupport();
  },
};
</script>
<style lang="scss" scoped>
.nav-item a {
  color: currentColor;
  opacity: 0.5;
  letter-spacing: 0;

  &.router-link-active {
    opacity: 1;
  }
}
</style>
