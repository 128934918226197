var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.prescription
    ? _c("div", { staticClass: "card-holder mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "\n      card-image-container\n      img-holder\n      d-flex\n      justofy-content-center\n      align-items-center\n    ",
            staticStyle: { height: "auto !important" }
          },
          [
            _c("img", {
              staticStyle: { "object-fit": "cover", height: "100%" },
              attrs: { src: _vm.prescription.image }
            })
          ]
        ),
        _c("div", { staticClass: "card-content" }, [
          _c(
            "h3",
            {
              staticClass: "text-default bold-font",
              staticStyle: { "letter-spacing": "0.01em" }
            },
            [_vm._v(" " + _vm._s(_vm.prescription.name) + " ")]
          ),
          _c("p", { staticClass: "mb-4" }, [
            _vm._v(" " + _vm._s(_vm.prescription.description) + " ")
          ]),
          _c("p", { staticClass: "mb-0 bold-font" }, [
            _vm._v(
              " Available monthly (starting at " +
                _vm._s(_vm.prescription.regularprice) +
                ") and quarterly (starting at " +
                _vm._s(_vm.prescription.discountedprice) +
                ") "
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }