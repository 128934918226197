var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eligibilitySurgery" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "mb-4" }, [
              _c("h2", { staticClass: "mb-3 heading text-primary" }, [
                _vm._v(
                  " Have you undergone a surgery that removed your ovaries, and put you into menopause? "
                )
              ]),
              _c(
                "div",
                { staticClass: "single-answer" },
                [
                  _c("w-checkbox", {
                    attrs: {
                      type: "radio",
                      id: "overianSurgeryYes",
                      value: "Yes",
                      label: "Yes"
                    },
                    on: { change: _vm.submitAnswer },
                    model: {
                      value: _vm.surgery,
                      callback: function($$v) {
                        _vm.surgery = $$v
                      },
                      expression: "surgery"
                    }
                  }),
                  _c("w-checkbox", {
                    attrs: {
                      type: "radio",
                      id: "ovarianSurgeryNo",
                      value: "No",
                      label: "No"
                    },
                    on: { change: _vm.submitAnswer },
                    model: {
                      value: _vm.surgery,
                      callback: function($$v) {
                        _vm.surgery = $$v
                      },
                      expression: "surgery"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }