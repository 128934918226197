<template>
  <vue-page-transition>
    <router-view />
  </vue-page-transition>
</template>

<script>
export default {
  name: 'loading'
}
</script>

<style>

</style>