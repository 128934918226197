var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("h2", { staticClass: "heading text-primary text-center" }, [
          _vm._v("WINONA TERMS OF SERVICE")
        ]),
        _c("p", [
          _vm._v(
            " These Terms of Service (“Terms”) govern your access to and use of the Winona, Inc. (“Winona”) website, located at "
          ),
          _c("a", { attrs: { href: "https://bywinona.com/contact" } }, [
            _vm._v("https://bywinona.com/")
          ]),
          _vm._v(
            " (the “Website”) and the Winona mobile applications and services that facilitate direct access to certain women’s health services provided by physicians who are contracted with or employed by affiliated physician practices (the “Physician(s)”), for the ordering of certain prescription medications and other products from Winona’s affiliated pharmacies and pharmacy partners, and for the payment of these services (collectively, the “Services”). Please note that supplemental terms applicable to Physicians may be found in Section 13 of these Terms. "
          )
        ]),
        _c("p", [
          _vm._v(
            " You agree that by visiting the Website, or by registering or using the Services, you, the individual (“you” or “your”) are entering into a legally binding agreement with Winona, Inc., located at 1501 Barton Springs Road, Austin, Texas 78704 based on these Terms as well as Winona’s Privacy Policy and Winona’s Informed Consent, each of which are hereby incorporated by reference in these Terms (collectively, the “Agreement”). Your access to and your use of the Services are conditioned on your acceptance of and compliance with the terms of this Agreement. By accessing or using the Services, you agree to be bound by the Agreement. "
          )
        ]),
        _c("ol", [
          _c("li", [
            _c("h3", [_vm._v("Basic Terms")]),
            _c("p", [
              _vm._v(
                " Winona acts as a technology platform to connect registered users of the Website with Physicians and pharmacies for medical consultations and dispensing of medications prescribed by the Physicians. Winona is not a health care provider or pharmacy and does not offer or provide any medical advice, treatment recommendations, or pharmacy services. You acknowledge that you are not entering into a physician-patient relationship with Winona, and that Winona is not liable for any advice, medical care, or treatment you receive by the Physicians or pharmacies affiliated with Winona (“Pharmacies”) or for any consequences, injury or harm that may result therefrom. Physicians employed or under contract with Winona Medical of California, P.C. (the “Medical Practice”) provide specific medical consultations through the Website. Winona does not own the Medical Practice, nor does Winona control the practice of medicine by the Medical Practice or its Physicians. The content of the Website and the Services, including without limitation, text, copy, audio, video, photographs, illustrations, graphics, and other visuals, is for informational purposes only and does not constitute professional medical advice, diagnosis, treatment, or recommendations of any kind by Winona. "
              )
            ]),
            _c("p", [
              _vm._v(
                " You are responsible for verifying that any information you provide to Winona or a treating Physician is accurate and complete. Winona will not be liable for any damages or losses that occur as a result of untruthful, inaccurate or otherwise incomplete information. The Services are not a substitute for in-person treatment or advice of your primary care physician or other health care providers. The Website facilitates medical consultations limited to diagnosis and treatment of certain women’s health issues, and not for any other medical or health issue. If you are not feeling well, contact your primary care physician. The Website is not to be used for medical emergencies. "
              ),
              _c("b", [
                _vm._v(
                  "IF YOU ARE HAVING A MEDICAL EMERGENCY, PLEASE DIAL 911 OR GO IMMEDIATELY TO THE EMERGENCY ROOM."
                )
              ])
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Eligibility to Use the Services")]),
            _c("p", [
              _vm._v(
                " The Services currently are available only in the States of Arizona, California, Colorado, Florida, Georgia, Maine, Massachusetts, Michigan, Pennsylvania, Texas, Illinois, Iowa, Ohio, New Hampshire, North Carolina, South Carolina, Washington, Wisconsin and Nevada. You must be located in Arizona, Colorado, California, Florida, Georgia, Maine, Massachusetts, Michigan, Pennsylvania, Texas, Illinois, Iowa, Ohio, New Hampshire, North Carolina, South Carolina, Washington, Wisconsin or Nevada in order to use the Services. "
              )
            ]),
            _c("p", [
              _vm._v(
                " You are responsible for your use of the Services and for any consequences thereof. You may use the Services only if you can form a binding contract with Winona and are not a person barred from accessing the Services under the laws of the United States or other applicable jurisdiction. The Services are not available to individuals under the age of eighteen (18). You may use the Services only in compliance with these Terms and all applicable local, state, national, and international laws, rules, and regulations. "
              )
            ]),
            _c("p", [
              _vm._v(
                " The form and nature of the Services are always evolving, and may change from time to time without prior notice to you. In addition, Winona may decide to temporarily or permanently stop providing the Services, or any features within the Services, to you or to users generally, and may not be able to provide you with prior notice when doing so. Winona retains the right to create limits on use of the Services, and/or storage your information related to the Services, at our sole discretion at any time and without prior notice to you. "
              )
            ]),
            _c("p", [
              _vm._v(
                " The Services may include advertisements, which may be targeted to the content or information on the Services, queries made through the Services, or other information. The types and extent of advertising on the Services are subject to change at any time and without prior notice to you. In consideration for Winona granting you access to and use of the Services, you agree that Winona, its partners, and any third party providers may place such advertising on the Services or in connection with the display of content or information from the Services whether submitted by you or others. "
              )
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Registering for an Account")]),
            _c("p", [
              _vm._v(
                " You must create a user account in order to use the Services. You may create such an account by registering through the Website and providing us with accurate and complete information as requested, including but not limited to completing an adaptive interview that asks about your health and medical conditions and symptoms (the “Registration”). "
              )
            ]),
            _c("p", [
              _vm._v(
                " You must update your Registration, as needed, in order to keep it accurate and complete. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Upon completion of your Registration, you may utilize the Website to consult with a Physician and, if medically indicated, to place your order for products. Some of the products available for purchase on the Website require a prescription, while others do not. We will assign you a Physician from the Medical Practice who is licensed to practice in the state where you are located to review your Registration, make a diagnosis, determine the medical appropriateness of any products you would like to order, and, if appropriate, issue you a prescription for such products as required. The issuance of any prescription is not guaranteed. You may request that we assign you to a different Physician. Your order for any products will not be accepted until a Physician provides you with a medical consultation, evaluates the clinical appropriateness of the desired product, and issues a prescription for the product you ordered, if a prescription is needed. The Physicians will not prescribe any medications that are DEA controlled substances. If, following a medical consultation, your Physician does not issue a prescription (but a prescription is otherwise required), Winona will decline your order, even if it was previously accepted, and will return your payment in the same method as it was accepted. If you prefer to fill your prescription at a different pharmacy, we will send the prescription to your selected pharmacy. In this instance, you will be responsible for picking up and paying for the prescription. Some of the products available on the Website might not require a prescription, in which case, you may purchase the products following your Registration without a medical consultation. Prescriptions filled by the Pharmacy will be shipped to you, and the associated shipping costs will be included in your Subscription Fee. We cannot accept returns of pharmaceutical products. Winona does not endorse any specific medications, pharmacies, or physicians. "
              )
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Payment for the Services")]),
            _c("p", [
              _vm._v(
                " Some products available on the Website require that you purchase the product on a subscription basis for a monthly subscription fee that is automatically charged against the form of payment you provide to Winona (the “Subscription Fee”). The Subscription Fee covers the cost of Physician medical consultations, prescriptions filled by the Pharmacy, shipping and handling costs of products distributed by the Pharmacy, a Website platform fee, and sales or similar taxes that may be imposed on your payments. Certain, other products are available for purchase on the Website that do not require a prescription. These products may be purchased by registered users at the prices indicated on the Website and without a subscription. "
              )
            ]),
            _c("p", [
              _vm._v(
                " By entering your payment information and submitting your request, you authorize us and our third party payment processors to charge the amount(s) due. Any health care services or pharmacy services not made available through the Website are not included in the payments collected by Winona, and are separately chargeable by the Physicians and/or the Pharmacies, as applicable. Your Subscription Fee may be increased or decreased if your prescription changes, such as by a dosage increase or frequency of refills. You may cancel your subscription at any time by logging into your account page at "
              ),
              _c("a", { attrs: { href: "https://bywinona.com/contact/" } }, [
                _vm._v("https://bywinona.com/")
              ]),
              _vm._v(
                ". Cancellation requests must be received two (2) full calendar days prior to your next shipping date. Cancellation requests received after that date will be processed before the following shipping date. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Federal and state health care programs, such as Medicare and Medicaid, do not cover or pay for the Services, including your medical consultations and pharmacy products. Neither Winona, the Medical Practice, the Physicians, nor the Pharmacies, submit any claims for payments to third parties for Services provided to you. You acknowledge and agree that you are solely responsible for the cost of any services or products provided to you, that no claims for reimbursement will be submitted on your behalf, and that you will not submit a claim for reimbursement to any third party for the Services. "
              )
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Privacy")]),
            _c("p", [
              _vm._v(
                " Any information that you provide to Winona is subject to our Privacy Policy, which governs the transfer of this information to or within the United States and/or to other countries for storage, processing and use by Winona. As part of providing the Services, we may need to provide you with certain communications, such as collection and use of your information. You understand that through your use of the Services you consent to the collection and use (as set forth in the Privacy Policy) of this information, including announcements and administrative messages. These communications are considered part of the Services and your Winona account, which you may not be able to opt-out from receiving. "
              )
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Passwords")]),
            _c("p", [
              _vm._v(
                " You are solely responsible for safeguarding the password or other credentials you use to access your Winona account and the Services. We encourage you to use “strong” passwords, commonly described as passwords that use a combination of upper and lower case letters, numbers, and symbols, with your account and with other accounts that you may connect to your Winona account. Winona cannot, and will not, be liable for any loss or damage arising from your failure to comply with the above requirements. You agree to notify us immediately upon becoming aware of any breach of security or unauthorized use of your Winona account. "
              )
            ]),
            _c("p", [
              _vm._v(
                " You may not use as a username the name of another person or entity or a name or trademark that is subject to any rights of another person without appropriate authorization. You may not use as a username any word or combination of words that is offensive, vulgar, or obscene. "
              )
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Content Regarding the Services")]),
            _c("p", [
              _vm._v(
                " Please be aware that, except as expressly provided or as otherwise required under applicable law, Winona does not verify the accuracy of any information made available on the Services by third parties. Information about prescription medications that may be purchased on the Website has been provided directly by our affiliated pharmacies and has not been reviewed or approved by Winona. Any use or reliance on any information you obtain through the Services is at your own risk. "
              )
            ]),
            _c("p", [
              _vm._v(
                " You understand that by using the Services, you may be exposed to content that might be offensive, harmful, inaccurate, or otherwise inappropriate. Under no circumstances will Winona be liable in any way for any content posted, emailed, transmitted, or otherwise made available via the Services or broadcast elsewhere. "
              )
            ]),
            _c("p", [
              _vm._v(
                "You agree not to transmit any content on the Services that:"
              )
            ]),
            _c("p", [
              _c("ul", [
                _c("li", [
                  _vm._v(
                    " Is harassing, impersonates another person or entity in a manner that does or is intended to mislead, confuse, or deceive others; "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " Violates the rights of a third party, including copyright, trademark, privacy, and publicity rights; "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    "Is a direct and specific threat of violence to others;"
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " Violates any contractual obligation to refrain from photographing, filming or streaming any performance, event, film, concert, sporting event or other happening; "
                  )
                ]),
                _c("li", [_vm._v("Is furtherance of illegal activities; or")]),
                _c("li", [_vm._v("Is abusive or constitutes spam.")])
              ])
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Winona’s Rights")]),
            _c("p", [
              _vm._v(
                " Subject to the terms of this Agreement, Winona grants you a limited and non-exclusive license (without the right to sublicense) to access and use the Services as provided herein. Winona is the sole and exclusive owner of the Website, including any and all copyright, patent, trademark, trade secret and other ownership and intellectual property rights, in and to the Website and any related materials and documentation. No title or ownership of the Website or any portion thereof is transferred to you hereunder. Winona reserves all rights not expressly granted hereunder. You agree not to change or delete any copyright or proprietary notice related to materials downloaded from the Website. "
              )
            ]),
            _c("p", [
              _vm._v(
                " All rights, title, and interest in and to the Services and any content available on the Services are and will remain the exclusive property of Winona and its licensors. The Services and its content are protected by copyright, trademark, and other laws of the United States. Winona reserves all rights not expressly granted in these Terms. You acknowledge and agree that any feedback, comments, or suggestions you may provide regarding Winona, or the Services, are entirely voluntary and we will be free to use such feedback, comments, or suggestions as we see fit and without any obligation to you. Notwithstanding the foregoing, any content, such as postings, messages, or photos that you upload to the Website, including any content that you submit using the Services in connection with any medical consultation, belongs to you and you authorize Winona to use and disclose such content in connection with providing the Services. "
              )
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Restrictions on Use of the Services")]),
            _c("p", [
              _vm._v(
                " Winona reserves the right, at all times, but will not have an obligation, to suspend and or terminate users or reclaim usernames without liability to you. Winona May, in its sole discretion, terminate your access to the Website and Services, or any portion thereof, for any reason whatsoever without prior notice. "
              )
            ]),
            _c("p", [
              _vm._v(
                " You may not do any of the following while accessing or using the Services: (i) access, tamper with, or use non-public areas of the Services, Winona’s computer systems, or the technical delivery systems of Winona providers; (ii) probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures; (iii) access or search or attempt to access or search the Services by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by Winona (and only pursuant to those terms and conditions), unless you have been specifically allowed to do so in a separate agreement with Winona "
              ),
              _c("b", [
                _vm._v(
                  "(NOTE: scraping the Services without the prior consent of Winona is expressly prohibited);"
                )
              ]),
              _vm._v(
                " (iv) forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services to send altered, deceptive or false source-identifying information; or (v) interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of content in such a manner as to interfere with or create an undue burden on the Services. "
              )
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Ending These Terms")]),
            _c("p", [
              _vm._v(
                " These Terms will continue to apply until terminated by either you or Winona as follows: "
              )
            ]),
            _c("p", [
              _vm._v(
                " You may end your Agreement with Winona at any time, for any reason, by deactivating your Winona account and discontinuing your use of the Services. In order to deactivate your account, please contact us at "
              ),
              _c("a", { attrs: { href: "https://bywinona.com/contact/" } }, [
                _vm._v("https://bywinona.com/")
              ]),
              _vm._v(". ")
            ]),
            _c("p", [
              _vm._v(
                " We may suspend or terminate your accounts or cease providing you with all or part of the Services at any time for any reason, including, but not limited to, if we reasonably believe: (i) you have violated these Terms, (ii) you create risk or possible legal exposure for us; (iii) our provision of the Services to you is no longer commercially viable; or (iv) our relationship with the affiliated Medical Practice or Pharmacy ends. We will make reasonable efforts to notify you by the email address associated with your account or through the Services the next time you attempt to access your Winona account. "
              )
            ]),
            _c("p", [
              _vm._v(
                " In all such cases, these Terms shall terminate, including, without limitation, your license to use the Services, except those Sections you would expect to survive termination, such as indemnification and limitations on liability. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Nothing in this section shall affect Winona’s rights to change, limit, or stop the provision of the Services without prior notice, as provided above. "
              )
            ]),
            _c("p", [
              _vm._v(
                " The Medical Practice will retain your medical records for a period of five (5) years, unless a longer period is required by state or federal law, after which they may be destroyed. Individual Physicians, or the Pharmacies, may retain your medical records for a longer or shorter period of time as required by law. "
              )
            ])
          ]),
          _c("li", [
            _c("h3", [
              _vm._v("Disclaimers, Limitation of Liability and Indemnity")
            ]),
            _c("p", [
              _vm._v(
                " Please read this section carefully since it limits the liability of Winona and its stockholders, subsidiaries, affiliates, related companies, officers, directors, employees, agents, representatives, partners, and licensors (collectively, the “Winona Entities”). Each of the subsections below only applies up to the maximum extent permitted under applicable law. Some jurisdictions do not allow the disclaimer of implied warranties or the limitation of liability in contracts, and as a result the contents of this Section 11 may not apply to you. Nothing in this Section 11 is intended to limit any rights you may have which may not be lawfully limited. "
              )
            ]),
            _c("ol", { staticClass: "upper-alpha" }, [
              _c("li", [
                _c("h3", [_vm._v("The Services are Available “As-Is”")]),
                _c("p", [
                  _vm._v(
                    " Your access to and use of the Services is done at your own risk. You understand and agree that the Services are provided to you on an “AS IS” and “AS AVAILABLE” basis. Without limiting the foregoing, THE WINONA ENTITIES DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " The Winona Entities make no warranty and disclaim all responsibility and liability for: (i) any actions of Winona users, including without limitation, any advice, recommendations, treatment plans or other information provided by the affiliated Physicians and Pharmacies; (ii) the completeness, accuracy, availability, timeliness, security or reliability of the Services or any information provided on the Services; (iii) any harm to your computer system, loss of data, or other harm that results from your access to or use of the Services, or any content; (iv) the deletion of, or the failure to store or to transmit, any content and other communications maintained by the Services; (v) whether the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis; (vi) any advice or information you receive through the Services; and (vii) any injuries or harm you or a third party incurs as a result of information or advice received through the Services. No advice or information, whether oral or written, obtained from the Winona Entities or through the Services, will create any warranty not expressly made herein. TO THE EXTENT LEGALLY PERMITTED, YOU EXPRESSLY RELEASE THE WINONA ENTITIES FROM ANY AND ALL LIABILITY FOR ANY DAMAGES, SUITS, CLAIMS, PERSONAL INJURIES, DEATH, AND PROPERTY DAMAGE THAT MAY ARISE FROM OR RELATE TO ANY ACTS OR OMISSIONS OF THE MEDICAL PRACTICE, PHYSICIANS, OR PHARMACIES IN CONNECTION WITH THE SERVICES, INCLUDING WITHOUT LIMITATION, THE PROVISION OF MEDICAL SERVICES, CONSULTATION, OR TREATMENT BY PHYSICIANS. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.” "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " Some of Winona’s shareholders, directors, officers, contractors, or employees may have a financial interest in the Medical Practice, the Pharmacies, or any other third party supplier of goods or services, and may profit from your use of such entities and/or the sale of such goods and services to you. Dr. Michael Green, President of the Medical Practice, is Winona’s Chief Medical Officer and receives compensation from Winona, including but not limited to an equity interest in Winona, for the performance of his non-clinical services to Winona. "
                  )
                ])
              ]),
              _c("li", [
                _c("h3", [_vm._v("Links")]),
                _c("p", [
                  _vm._v(
                    " The Services may contain links to third party services or resources. You acknowledge and agree that we are not responsible or liable for: (i) the availability or accuracy of such services or resources; or (ii) the content, products, or services on or available from such services or resources. Links to such services or resources do not imply any endorsement by Winona or the Winona Entities regarding such services or resources or the content, products, or services available from such services or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such services or resources. "
                  )
                ])
              ]),
              _c("li", [
                _c("h3", [_vm._v("Limitation of Liability")]),
                _c("p", [
                  _vm._v(
                    " TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE WINONA ENTITIES, THE MEDICAL PRACTICE, THE PHYSICIANS, AND THE PHARMACIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " EXCEPT AS REQUIRED BY LAW, IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE WINONA ENTITIES, THE MEDICAL PRACTICE, THE PHYSICIANS, OR THE PHARMACIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE AMOUNT YOU PAID WINONA, IF ANY, IN THE PAST SIX MONTHS FOR ACCESS TO THE SERVICES GIVING RISE TO THE CLAIM. "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " TO THE EXTENT PERMITTED BY LAW, THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE WINONA ENTITIES, THE MEDICAL PRACTICE, THE PHYSICIANS, OR THE PHARMACIES HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE, EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. "
                  )
                ])
              ]),
              _c("li", [
                _c("h3", [_vm._v("Indemnification")]),
                _c("p", [
                  _vm._v(
                    " To the extent not prohibited by law, you expressly agree to indemnify and hold harmless Winona and the Winona Entities, from and against any and all liabilities, expenses, damages, and costs, including, but not limited to, reasonable attorneys’ fees and costs, related to all third party claims, charges and investigations related to: (i) any information, advice, treatment recommendations, or other content you transmit via the Services; (ii) your failure to comply with the Terms, including without limitation, your obligations and representations provided in Section 1; (iii) your interactions with any other Winona user; (iv) your use or disclosure of information, including without limitation, personal information or medical information you learned about on or through the Services; and (v) any activity in which you engage on or through the Services. "
                  )
                ])
              ])
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Miscellaneous Terms")]),
            _c("ol", { staticClass: "upper-alpha" }, [
              _c("li", [
                _c("h3", [_vm._v("Waiver and Severability")]),
                _c("p", [
                  _vm._v(
                    " The failure of Winona to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. In the event that any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect. "
                  )
                ])
              ]),
              _c("li", [
                _c("h3", [_vm._v("Controlling Law and Jurisdiction")]),
                _c("p", [
                  _vm._v(
                    " These Terms and any action related thereto will be governed by the laws of the state of California without regard to or application of its conflict of law provisions or your state or country of residence. All claims, legal proceedings, or litigation arising in connection with the Services will be brought solely in the federal or state courts located in Los Angeles County, California, United States, and you consent to the jurisdiction of and venue in such courts and waive any objection as to inconvenient forum. You agree that you may only bring claims against Winona related to your use of the Services on an individual basis and not as a plaintiff or class member in any purported class or representative action or proceeding. "
                  )
                ])
              ]),
              _c("li", [
                _c("h3", [_vm._v("Entire Agreement")]),
                _c("p", [
                  _vm._v(
                    " These Terms, our Privacy Policy, and your Informed Consent are the entire and exclusive agreement between Winona and you regarding the Services (excluding any service for which you have a separate agreement with Winona that is explicitly in addition or in place of these Terms). These Terms supersede and replace any prior agreements between Winona and you regarding the Services. "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " We may revise these Terms from time to time, the most current version will always be at "
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "https://bywinona.com/terms-conditions/" }
                    },
                    [_vm._v("https://bywinona.com/terms-conditions/")]
                  ),
                  _vm._v(
                    ". If we, in our sole discretion, determine that the revision is material, we will notify you via email to the email address associated with your account. If you do not wish to be bound by any such revisions to the Terms, your sole and exclusive remedy is to discontinue your use of the Website and the Services. By continuing to access or use the Services after those revisions become effective, you agree to be bound by the revised Terms. "
                  )
                ])
              ]),
              _c("li", [
                _c("h3", [_vm._v("Assignment")]),
                _c("p", [
                  _vm._v(
                    " These Terms are personal to you, and are not assignable, transferable, or sub-licensable by you except with Winona’s prior written consent. Winona may assign, transfer, or delegate any of its rights and obligations hereunder without your consent. "
                  )
                ])
              ])
            ])
          ]),
          _c("li", [
            _c("h3", [_vm._v("Supplemental Terms Applicable to Physicians")]),
            _c("p", [
              _vm._v(
                " Certain supplemental terms apply to the Physicians who utilize the Winona platform. In the event of a conflict between the supplemental terms in this Section 13, and any other terms herein, these supplemental terms shall prevail. "
              )
            ]),
            _c("p", [
              _vm._v(
                " To be a Physician using the Website (for purposes of this Section 13, “you” or “your”) you must be a licensed physician contracted with or employed by the Medical Practice, and you must agree to comply with all medical and professional board laws, rules, regulations, and other guidance applicable to you as a Physician in the state(s) in which you provide the Services. Your relationship with Winona users (including, but not limited to, your Winona patients) is directly between you and these individuals. Winona users never will have a provider-patient relationship with Winona. Winona does not practice medicine and offers no medical services. As set forth more fully below, Physician is solely responsible for all agreements, consents, notices, and other interactions with patients and other consumers. Without limiting the generality of the foregoing, Winona shall have no liability whatsoever to Physician with respect to any amounts owed by any patient or other consumer to Physician. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Winona does not provide any medical advice, legal advice, or representations in any way regarding any legal or medical issues associated with Physician, goods, or services offered by Physician, including but not limited to any compliance obligations or steps necessary to comply with any state or federal laws and regulations. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Physician will use the Website and provide the Services only in accordance with applicable standards of good medical and professional practice. While software products such as the Website can facilitate and improve the quality of the services that Physician can offer to his/her patients, many factors, including but not limited to the provider-patient relationship, can affect a patient’s outcome, and with intricate and interdependent technologies and complex decision-making it is often difficult or impossible to accurately determine what the factors were and in what proportion an outcome may have been affected. Physician shall be solely responsible for his/her use of the Website and provision of the Services. "
              )
            ]),
            _c("p", [
              _vm._v(
                " As a result of the complexities and uncertainties inherent in the patient care process, Physician agrees to defend, indemnify, and hold Winona harmless from any claim by or on behalf of any patient of Physician, or by or on behalf of any other third party or person claiming damage by virtue of a familial or financial relationship with such a patient, which is brought against Winona, regardless of the cause if such claim arises for any reason whatsoever, out of Physician’s use or operation of the Website and Services. To the extent applicable, Physician will obtain Winona’s prior written consent to any settlement or judgment in which Physician agrees to any finding of fault of Winona or defect in the Website or Services. Winona will promptly notify Physician in writing of any claim subject to this indemnification, promptly provide Physician with the information reasonably required for the defense of the same, and grant to Physician exclusive control over its defense and settlement. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Finally, as a Physician using the Services, you are expressly representing that: (i) you are fully licensed and otherwise qualified to provide the requested services; (ii) your provision of advice or other services is in accordance with all applicable federal and state laws, including without limitation, laws related to patient privacy or confidentiality such as HIPAA; (iii) you are fully responsible for the consequences of any advice or information you offer through providing the Services to the same extent as if you provided the advice or information directly to the client in person or in any other setting; and (iv) your provision of advice or information will be professional, competent and in line with the standards of care and best practices in your industry. "
              )
            ]),
            _c("p", [
              _vm._v(
                " If you have any questions about these Terms, please contact us. "
              )
            ])
          ])
        ]),
        _c("hr"),
        _c("h2", { staticClass: "heading text-primary text-center mt-4" }, [
          _vm._v(" WINONA, INC. PRIVACY POLICY ")
        ]),
        _c("p", { staticClass: "text-center" }, [_vm._v("(for Users)")]),
        _c("h4", [_vm._v("Effective Date:")]),
        _c("br"),
        _c("p", [
          _vm._v(
            " Thank you for visiting the Winona, Inc. (“Winona”) website "
          ),
          _c("a", { attrs: { href: "https://bywinona.com/" } }, [
            _vm._v("https://bywinona.com/")
          ]),
          _vm._v(
            " (the “Site”), contacting Winona, and/or using any Winona mobile applications or services (the “Services”). This Privacy Policy is intended to describe how Winona handles information that you provide, or that we learn about the individuals who: visit our website, use our Services, contact us by mail, email or telephone or in person, or who provide us with information through any other means. We recommend that you carefully review this notice before providing us with any information. By accessing and using this Site or the Services, you agree to this Privacy Policy and our Terms of Service. "
          )
        ]),
        _c("p", [
          _vm._v(
            " BY VISITING OR USING THE SERVICES, YOU EXPRESSLY CONSENT TO THE PROCESSING OF YOUR PERSONAL INFORMATION ACCORDING TO THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH OUR POLICIES AND PRACTICES, YOUR CHOICE IS TO NOT USE THE SERVICES. THE SERVICES ARE INTENDED FOR USERS LOCATED IN THE UNITED STATES AND YOUR INFORMATION WILL BE PROCESSED AND STORED IN THE UNITED STATES. WE MAKE NO REPRESENTATION THAT THE SERVICES ARE APPROPRIATE OR AVAILABLE FOR USE OUTSIDE THE UNITED STATES. ACCESS TO THE SERVICES FROM COUNTRIES OR TERRITORIES OR BY INDIVIDUALS WHERE SUCH ACCESS IS ILLEGAL IS PROHIBITED. THE SERVICES MAY ONLY BE USED WITHIN CERTAIN STATES WITHIN THE UNITED STATES AS DESCRIBED IN OUR TERMS OF SERVICE. "
          )
        ]),
        _c("p", [
          _vm._v(
            " WINONA IS NOT A MEDICAL PROVIDER. WINONA CONNECTS USERS WITH MEDICAL PROVIDERS WHO PROVIDE MEDICAL CONSULTATIONS AND PHARMACIES THAT FILL PRESCRIPTIONS ISSUED BY THE MEDICAL PROVIDERS. THE MEDICAL PROVIDERS INCLUDE WINONA MEDICAL OF CALIFORNIA, P.C., AN INDEPENDENT MEDICAL GROUP WITH A NETWORK OF MEDICAL PROVIDERS (EACH, A “PHYSICIAN”). WINONA IS NOT RESPONSIBLE FOR THE USE OR DISCLOSURE OF YOUR INFORMATION BY PHYSICIANS. "
          )
        ]),
        _c("p", [
          _c("b", [_vm._v("We Do Not Sell Your Personal Information")])
        ]),
        _c("p", [
          _vm._v(
            " Winona does not, to the best of our knowledge, sell or rent personal information that we have collected or retain about you to any other third-party for any purpose. Accordingly, we do not offer individuals the ability to “opt-out” of the selling or renting of personal information because we do not engage in those practices. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("Children’s Privacy")])]),
        _c("p", [
          _vm._v(
            " The Winona website and Services are not intended for children under the age of 18 and Winona does not knowingly collect any information from children under 18 years old through its website. If the parent or guardian of a child under 18 believes that the child has provided us with any information, the parent or guardian of that child should contact us if they want this information deleted from our files. If Winona obtains knowledge from any source that it has information about a child under 18 in retrievable form in its files, we will delete that information from our existing files. "
          )
        ]),
        _c("p", [
          _c("b", [
            _vm._v("What Information does Winona Collect and How is it Used?")
          ])
        ]),
        _c("p", [_c("i", [_vm._v("Registration Information")])]),
        _c("p", [
          _vm._v(
            " Winona will only collect personal information that you voluntarily provide to us or our service providers. When you register to use the services, we may collect information from you that includes, among other things, your name, mailing address, birthdate, e-mail address, telephone number, image of your photo ID and the information contained thereon, and your interest in specific types of products and/or services. You may also voluntarily provide us with personal information when you contact us with a question or comment or otherwise interact with the Services. "
          )
        ]),
        _c("p", [_c("i", [_vm._v("Health Information ")])]),
        _c("p", [
          _vm._v(
            " When you use the Services to request access to products offered for sale on the Services, you may provide us with additional information related to your general health, medical history, symptoms, preexisting conditions or other information that you may consider sensitive (“Health Information”). We collect this information to connect you with a healthcare professional for consultation and treatment, and to coordinate the sale and dispensing of prescription medications by our affiliated pharmacy. You must truthfully, accurately and thoroughly answer all the health questions presented to you in order for the physicians reviewing your case to make an accurate assessment of the suitability of treatment options. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Winona is not a “covered entity” under the Health Insurance Portability and Accountability Act of 1996, and its related regulations (“HIPAA”). The medical professionals and pharmacies who provide services to you may or may not be covered entities under HIPAA. Click here for the Notice of Privacy Practices adopted by the Medical Practice that describes how the Medical Practice uses and discloses your PHI. Winona is not responsible for the privacy, information or practices of third parties, including the Physicians. Winona may be considered a “business associate” of Medical Practice and our affiliated pharmacies. If we are considered a “business associate,” then we will protect your PHI and disclose it only in accordance with HIPAA. However, we may use and disclose any information that does not constitute PHI in any manner permitted under this Privacy Policy. This includes, for example, any information you provide to Winona in order to register and set up an account. In addition, PHI does not include information that has been de-identified in accordance with applicable laws. "
          )
        ]),
        _c("p", [
          _vm._v(
            " We may collect your medical information on behalf of the Medical Practice and Pharmacy which may include: "
          )
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              " Health and medical information that you submit for diagnosis and treatment purposes, including any information in a questionnaire "
            )
          ]),
          _c("li", [
            _vm._v(
              " Images or videos that you share for diagnosis or treatment purposes "
            )
          ]),
          _c("li", [_vm._v("Communications with the Physicians.")])
        ]),
        _c("p", [
          _vm._v(
            " In addition to information that we collect directly from you, we may also collect information from the Medical Practice and/or Pharmacy who provide treatment and other services to you in connection with the Services and enter your medical information in the electronic medical record provided to them on the Website. This information may include diagnoses and treatment plans (including prescription details). "
          )
        ]),
        _c("p", [
          _vm._v(
            " Winona and/or Medical Practice may de-identify your information so that it is no longer considered protected health information. Winona may disclose, aggregate, sell, or otherwise disclose such de-identified information to third parties for analytics, research, or other purposes. "
          )
        ]),
        _c("p", [_c("i", [_vm._v("Purchase Information")])]),
        _c("p", [
          _vm._v(
            " Winona uses Stripe, a secure payment provider, to process your payments if you use the Services to make a purchase. Your payment information is subject to the privacy policy and terms of use of Stripe. We recommend you review these policies before uploading any credit card information. Stripe does not have access to your credit card information and does not store this information on our servers. "
          )
        ]),
        _c("p", [_c("i", [_vm._v("Non-Personal Information")])]),
        _c("p", [
          _vm._v(
            " Like most website operators, Winona collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request when visitors use the Services. Winona’s purpose in collecting non-personally identifying information is to better understand how Winona’s visitors use the Services. From time to time, Winona may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its Services. Winona also collects potentially personally-identifying information like Internet Protocol (IP) addresses and may collect statistics about the behavior of visitors to the Services. For instance, Winona may monitor the Services to help identify spam. We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). "
          )
        ]),
        _c("p", [
          _vm._v(
            " In addition to log data, we may also collect information about the device you’re using to access the Services, including the type of device, the operating system, device settings, device identifiers and crash data. Whether we collect some or all of this information often depends on what type of device you’re using and its settings. To learn more about the information your device makes available to us, please review the policies of your device manufacturer or software provider. "
          )
        ]),
        _c("p", [
          _vm._v(
            " We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us. "
          )
        ]),
        _c("p", [
          _vm._v(
            " In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this type of information in order to increase the Services’ functionality. These third-party service providers have their own privacy policies addressing how they use such information. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("How we use your information")])]),
        _c("p", [
          _vm._v(
            " We use the information we collect to provide you full access and functionality of the Services. Accordingly, your information may be used for the following purposes: (i) to provide and improve our services, features and content; (ii) to administer your use of our services and accounts; (iii) to enable users to enjoy and easily navigate the Services; (iv) to better understand your needs and interests; (v) to fulfill requests or to respond to questions or comments you may make; (vi) to personalize your experience; (vii) to provide you with announcements, notifications and advertisements related to your interests and use of the Services and other communications such as electronic newsletters, promotional e-mails or similar messaging; (viii) to provide service announcements; (ix) to protect against users seeking to hack into the Services; (x) to assess the level of general interest in the Services and (xi) for any other purpose with your consent. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Unless the information is fully anonymized and cannot be used to identify you as an individual, we will only use Health Information as reasonably necessary to provide you with services you directly request. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("With Whom Do We Share Your Information?")])]),
        _c("p", [_c("i", [_vm._v("Personal Information ")])]),
        _c("p", [
          _vm._v(
            " In order to provide you with access to the Services, certain third parties that perform services for Winona, such as hosting, analytical, data management or other similar services or are otherwise acting on our behalf (“Service Providers”) may have access to your personal information, including your Health Information. Service Providers are authorized to use your information only to perform the service for which they are hired and may not share your information with any third parties. They are required to abide by the terms of our Privacy Policy including taking reasonable measures to ensure your personal information is secure. On occasion, we contract with trusted third-party providers who would receive your personal information or Health Information and conduct anonymized aggregate analyses of the data. Through our contractual arrangements, we require our contracting partners to maintain adequate security of personal information provided to them. We do not permit such third parties to sell your personal information to other third parties. "
          )
        ]),
        _c("p", [
          _c("i", [_vm._v("Other Ways We May Share your Personal Information")])
        ]),
        _c("p", [
          _vm._v(
            " We may share the information you provide Winona, including your personal information and Health Information with the medical professionals and pharmacies that provide services to you to enable them to continue providing services to you via the Services. "
          )
        ]),
        _c("p", [
          _vm._v(
            " We may disclose non-personally identifiable aggregated information about our users without restriction. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Other than to our employees, contractors and affiliated organizations or as described above, we disclose personally-identifying only when required to do so by law, or when we believe in good faith that disclosure is reasonably necessary to protect the property or rights of Winona, third parties or the public at large. If you send us a request (for example via chat, a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. "
          )
        ]),
        _c("p", [
          _vm._v(
            " In addition, in some cases we may choose to buy or sell assets. In these types of transactions, user information is typically one of the business assets that is transferred. Moreover, if Winona or substantially all of its assets were acquired, or in the unlikely event that Winona goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Winona may continue to use your personal and non-personal information only as set forth in this policy. Otherwise, we will not rent or sell potentially personally-identifying and personally-identifying information to anyone. "
          )
        ]),
        _c("p", [
          _vm._v(
            " We may also disclose your personally-identifying information if you expressly consent to the disclosure. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("Security")])]),
        _c("p", [
          _vm._v(
            " We are committed to protecting your Personal Information and Health Information. We regularly test our facilities and use a variety of security technologies and procedures to help protect your personal information from unauthorized access, use or disclosure. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("Our Data Retention Criteria")])]),
        _c("p", [
          _vm._v(
            " The period during which we store your personal information varies depending on the purpose for the processing. For example, we store personal information needed to provide you with products and services, or to facilitate transactions you have requested, for so long as you are a customer of Winona. We store your personal information for our marketing purposes until you have opted-out of receiving further direct marketing communications in accordance with applicable law. In all other cases, we store your personal information for as long as is needed to fulfill the purposes outlined in this Privacy Policy, following which time it is either anonymized (where permitted by applicable law), deleted or destroyed. The Providers may dispose of or delete any such information except as set forth in any other agreement or document executed by the Providers or as required by law. "
          )
        ]),
        _c("p", [
          _c("b", [_vm._v("Our Use of Cookies and Analytical Tools ")])
        ]),
        _c("p", [
          _vm._v(
            " Cookies are text files containing small amounts of information which are downloaded to your hard disk or to your browser’s memory when you visit one of our Sites. Cookies are useful because they help arrange the content and layout of our Sites and allow us to recognize those computers or other devices that have been to our Sites before. Winona does not permit third parties or third-party cookies to access any communications that you have with Providers or medical information that you submit to Provider for diagnosis and treatment purposes. Cookies do many different jobs, such as allowing our Sites to remember your preference settings and helping us to enhance the usability and performance of our Sites and your experience using them. Our Sites also may contain electronic images known as web beacons – sometimes called single-pixel gifs – that allow us to count the number of users who have visited specific pages. We may also include web beacons in promotional e-mail messages or newsletters in order to determine whether messages have been opened and acted upon. The type of cookie or similar technology that may be used on our Sites can be categorized as follows: Strictly Necessary, Performance, Functionality & Profile and Advertising. "
          )
        ]),
        _c("ul", [
          _c("li", [
            _c("p", [
              _c("b", [_c("i", [_vm._v("Strictly Necessary Cookies.")])]),
              _vm._v(
                " These cookies are essential for basic functionalities of the Site, and they enable you to move around our Sites and use their features, particularly in connection with information searches and order placement. Without these cookies, services you have asked for cannot be provided. These cookies do not gather information about you that could be used for marketing or remembering where you have been on the internet. Examples of strictly necessary cookies are a shopping basket cookie, which is used to remember the products that you wish to purchase when you add products to your shopping basket or proceed to checkout, a login/ authentication cookie which allows and manages your login to the Site and identifies you after logging in for a single session, a session cookie which is required to carry out the data transmission and provide the Site to you, a security cookie that detects repeat failed login attempts or similar abuses of the Sites. These types of cookies are regularly stored only as long as required for their purpose. "
              )
            ])
          ]),
          _c("li", [
            _c("p", [
              _c("b", [_c("i", [_vm._v("Performance Cookies.")])]),
              _vm._v(
                " These cookies collect information about how you use our Sites, for example which pages you go to most often and if you get any error messages from certain pages. These cookies collect information that is used to improve how our Sites work. Without these cookies we cannot learn how our Sites are performing and make relevant improvements that could better your browsing experience. Examples of performance cookies that our Sites use include cookies from Google and Adobe Analytics (see further discussion below). "
              )
            ])
          ]),
          _c("li", [
            _c("p", [
              _c("b", [_c("i", [_vm._v("Functionality & Profile Cookies.")])]),
              _vm._v(
                " These cookies allow our Sites to store information that you provide, such as preferences, and to store technical information useful for your interactions with our Sites. For instance, they remember your user ID and elements of your user profile. They also ensure that your experience using the Sites is relevant to you. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. These cookies will not be used to track your browsing activity on other websites. Without these cookies, a website cannot remember choices you have previously made or personalize your browsing experience. For example, we use a cookie to store your language preferences, which allows us to present you with product search results in the correct language, and we use a cookie to store your choice about the appearance of the cookie information banner that we display on our Sites. This cookie will help us remember your choice about the appearance of the cookie information banner when you subsequently visit the same site where you made your choice about the banner and any other Winona sites with the same domain or the same top-level domain. "
              )
            ])
          ]),
          _c("li", [
            _c("p", [
              _c("b", [
                _c("i", [
                  _vm._v("Advertising Cookies and Similar Technologies. ")
                ])
              ]),
              _vm._v(
                " These cookies or similar technologies may be used to deliver advertisements that are more relevant to you and your interests. They may also be used to limit the times you see an advertisement as well as help to measure the effectiveness of the advertising campaign. These cookies may track your visits to other websites. Without these cookies or other technologies, online advertisements you encounter will be less relevant to you and your interests. "
              )
            ])
          ])
        ]),
        _c("p", [_vm._v("Setting your cookie preference")]),
        _c("p", [
          _vm._v(
            " You can usually modify your browser settings to decline cookies and you can withdraw your consent at any time by modifying the settings of your browser to reject or disable cookies or by opting out of specific cookies through the opt-out options shared below. If you choose to decline cookies altogether, you may not be able to fully experience the features of the Sites that you visit. "
          )
        ]),
        _c("p", [_vm._v("Our use of web analytics")]),
        _c("p", [
          _vm._v(
            " We use different analytic tools which serve the purpose of measuring, analyzing and optimizing our marketing measures and provide you with customized advertisements that could be of particular interest to you. In particular, we use the following tools: "
          )
        ]),
        _c("ul", [
          _c("li", [
            _c("p", [
              _c("b", [_c("i", [_vm._v("Google Analytics")])]),
              _vm._v(
                " uses cookies which enable an analysis of your use of the Sites. The information collected (IP address, browsing activities and other data linked to your usage of the Sites) is usually transferred to a Google server in the USA and stored there. You can prevent Google Analytics from recognizing you on return visits to the Sites by disabling cookies on your browser. To see how you can opt-out of certain Google features, visit: "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://tools.google.com/dlpage/gaoptout",
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [_vm._v("Google Analytics Opt Out Link")]
              ),
              _vm._v(". ")
            ])
          ]),
          _c("li", [
            _c("p", [
              _c("b", [_c("i", [_vm._v("Facebook Pixels")])]),
              _vm._v(
                " allow user behavior to be tracked after they have been redirected to our website by clicking on a Facebook ad. This enables us to measure the effectiveness of Facebook ads for statistical and market research purposes. Facebook may link his information in your Facebook account and use it for its own promotional purposes. "
              )
            ])
          ]),
          _c("li", [
            _c("p", [
              _c("b", [_c("i", [_vm._v("Mixpanel ")])]),
              _vm._v(
                " analyzes customer-driven event data and creates funnel reports so we can better understand how our users interact with the Services. "
              )
            ])
          ]),
          _c("li", [
            _c("p", [
              _c("b", [_c("i", [_vm._v("Segment ")])]),
              _vm._v(
                " collects user events from the Services and provides a data toolkit to allow us to use the data more efficiently. "
              )
            ])
          ])
        ]),
        _c("p", [_c("b", [_vm._v("Your Rights and Responsibilities ")])]),
        _c("p", [
          _vm._v(
            " You are permitted, and hereby agree, to only provide personal information to Winona if such personal information is accurate, reliable, and relevant to our relationship and only to the extent such disclosure will not violate any applicable data protection law, statute, or regulation. "
          )
        ]),
        _c("p", [
          _vm._v(
            " You may have certain rights under applicable data protection law with respect to personal information about you that is collected through the Sites or when you contact or otherwise engage with us. To exercise any of these data privacy rights, please contact us, or have your authorized agent contact us, in accordance with the “Contact Us” section listed below. In the event you submit, or your authorized agent submits on your behalf, a data request, you (and your authorized agent) hereby acknowledge and agree, under penalty of perjury, that you are (or the authorized agent of) the consumer whose personal information is the subject of the request. We will respond to any data requests within the timeframes required by law, and we may charge a fee to facilitate your request where permitted by law. "
          )
        ]),
        _c("p", [
          _c("b", [_c("i", [_vm._v("Marketing. ")])]),
          _vm._v(
            " You have the right to opt-out of receiving electronic direct marketing communications from us. All electronic direct marketing communications that you may receive from us, such as e-mail messages, will give you an option of not receiving such communications from us in the future. "
          )
        ]),
        _c("p", [
          _c("b", [_c("i", [_vm._v("California Privacy Rights. ")])]),
          _vm._v(
            " California Civil Code Section § 1798.83 permits users of the Sites that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. Pursuant to the California Consumer Privacy Act of 2018, as amended (“CCPA”), California residents may have certain data privacy rights, such as the right to be notified about what personal information categories are collected about you, and our intended use and purpose for collecting your personal information. You may have the right to request access to your personal information and, to the extent feasible, request that it be transmitted in certain forms and formats. You may have the right to request that we (and any applicable service provider) delete your personal information. You have the right not to be subject to discrimination for asserting your rights under the CCPA. If you make, or an authorized agent on your behalf makes, any request related to your personal information, Winona will ascertain your identity to the degree of certainty required under the CCPA before addressing your request. Winona may require you to match at least three pieces of personal information we have previously collected from you before granting you access or otherwise responding to your request. "
          )
        ]),
        _c("p", [
          _c("b", [_c("i", [_vm._v("Do Not Track. ")])]),
          _vm._v(
            " Some web browsers may transmit “do-not-track” signals to the Sites with which the user communicates. Because of differences in how web browsers incorporate and activate this feature, it is not always clear whether users intend for these signals to be transmitted, or whether they even are aware of them. We currently do not take action in response to these signals. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("European Union (EU) Privacy Disclaimer")])]),
        _c("p", [
          _vm._v(
            " Winona processes personal information in accordance with the legal bases set forth in the EU General Data Protection Regulation (GDPR) or EU Member State law. For example, our processing of Personal Data on individuals (as described above) is justified based on statutory provisions that (1) processing is based on the consent; (2) processing is necessary for Winona’s legitimate interests as set out herein; and (3) processing is necessary for the performance of a contract to which you are a party. If you are in the EU or afforded protection under the GDPR, you may have certain rights with respect to the Personal Data. To the extent permitted by applicable data protection laws, you may access the Personal Data we hold about you; request that inaccurate, outdated, or no longer necessary information be corrected, erased, or restricted; and, request that we provide your Personal Data in a format that allows you to transfer it to another service provider. You also may withdraw your consent at any time where we are relying on your consent for the processing of your Personal Data. You may object to our processing of your Personal Data where that processing is based on our legitimate interest. You have the right to lodge a complaint with your competent data protection authority. If you wish to exercise any of these rights, please contact us in accordance with the instructions provided below. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("Nevada Privacy Disclaimer ")])]),
        _c("p", [
          _vm._v(
            " Pursuant to Nevada law, a Nevada “consumer” (as the term is defined therein), may, at any time, submit a verified request through a designated request address to an “operator” directing the operator not to make any sale of his or her personal information that the operator has collected or will collect about the consumer. For clarity purposes, Winona does not sell or exchange your personal information for monetary consideration to a third party for the third party to license or sell the information to additional persons or parties. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("Links to Third-Party Websites")])]),
        _c("p", [
          _vm._v(
            " The Services may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit. "
          )
        ]),
        _c("p", [
          _vm._v(
            " We have no control over, and assume no responsibility for the content, privacy policies or practices of any third-party sites or services. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("Changes to This Privacy Policy")])]),
        _c("p", [
          _vm._v(
            " We may occasionally update this Privacy Policy. When we do, we will revise the “last updated” date at the top of the Privacy Policy and take such additional steps as may be required by law. We recommend that you periodically review our Privacy policy for updates. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("Contact Us")])]),
        _c("p", [
          _vm._v(
            " If you have questions regarding this Privacy Policy, our handling of your personal information, or would like to request more information or exercise a data right, please contact us using on the webpage at "
          ),
          _c("a", { attrs: { href: "https://bywinona.com/contact/" } }, [
            _vm._v("https://bywinona.com/contact/")
          ]),
          _vm._v(" or by email at following: "),
          _c("a", { attrs: { href: "mailto:help@bywinona.com" } }, [
            _vm._v("help@bywinona.com")
          ]),
          _vm._v(" or by telephone at "),
          _c("a", { attrs: { href: "tel:+(844) 929-1586" } }, [
            _vm._v("+(844) 929-1586")
          ]),
          _vm._v(". ")
        ]),
        _c("hr"),
        _c("h2", { staticClass: "mt-4 heading text-primary text-center" }, [
          _vm._v("INFORMED CONSENT")
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " Winona, Inc. is a website that connects users to medical professionals for medical consultations relating to certain women’s health issues. Consultations between patients and medical professionals through technology where the patient and the medical professional are not at the same location are known as telehealth. They can occur in real-time through video consultations, or through asynchronous means such as submission of questionnaires and online communications. The purpose of this form is to explain some of the benefits and risks of telehealth and obtain your consent to receiving medical services through telehealth. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Consultations between patients and medical professionals through the Winona Website occur primarily through submission of online questionnaires and online communications. Consultations will not occur in person or by live video. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Your medical professional will determine, through the information that you share and telehealth, what course of treatment is appropriate for you. "
          )
        ]),
        _c("p", [
          _vm._v(" Telehealth with Winona has many benefits, including: ")
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "You do not have to leave the privacy of your home to receive a medical consultation or to pick up a prescription. This can save you time and money and can protect your privacy."
            )
          ]),
          _c("li", [
            _vm._v(
              "It is easier and more efficient to access health care providers online when you need them, rather than waiting weeks and possibly months for an appointment."
            )
          ]),
          _c("li", [
            _vm._v(
              "You can easily receive follow-up consultations from your Winona medical professional from the convenience of your PC or phone."
            )
          ])
        ]),
        _c("p", [
          _vm._v(
            " There are possible risks to using telehealth, though. There can be disruption in communication between you and your medical professional if the online connection is interrupted for some reason, or you or your medical professional experience technical difficulties. "
          )
        ]),
        _c("p", [
          _vm._v(
            " While our Website includes security features to protect your information, there is a risk that unauthorized access to your information still can occur. "
          )
        ]),
        _c("p", [
          _vm._v(
            " It may be more difficult for medical professionals to diagnose you without seeing you in person. For example, it may be more difficult to perform a physical assessment, to check vitals, and to perform diagnostic tests. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Your medical provider will only treat specific conditions, so you will need to access treatment for other medical conditions using a provider outside of the Winona Website. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Your medical provider does not have access to medical records prepared by your other healthcare providers. As a result, they do not have access to all of your medical information. They only have access to the information that you provided through the online questionnaire, through direct communication with the Winona provider, or through the Website. If all of your medical information is not accurately provided, this could lead to, for example, adverse interactions with other medications that you might be taking. "
          )
        ]),
        _c("p", [
          _vm._v(
            " If you decide that receiving medical treatment through telehealth is not the best fit for you, simply deactivate your Winona account. "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.flhealthsource.gov/telehealth/",
                target: "_blank"
              }
            },
            [_vm._v("Florida law")]
          ),
          _vm._v(
            " authorizes out-of-state health care practitioners to perform telehealth services for patients in Florida. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Michael Green, M.D. is Winona’s Chief Medical Officer and has an ownership interest in Winona, Inc. You may choose any pharmacy service to fill prescriptions ordered or requested by Dr. Green. "
          )
        ]),
        _c("p", [_vm._v(" By accepting below, you confirm that: ")]),
        _c("ol", [
          _c("li", [
            _vm._v(
              "You have read, understand, and accept this Informed Consent."
            )
          ]),
          _c("li", [
            _vm._v(
              "You understand the risks and benefits of the telehealth services offered through the Website, and that you have determined that telehealth is a good choice for you. You also understand that none of the benefits are guaranteed and that the treatment provided will not necessarily improve any of your medical conditions."
            )
          ]),
          _c("li", [
            _vm._v(
              "It is your responsibility to provide accurate medical information to your medical professional, and that your medical professional may decide that you need to seek treatment for your medical condition from a different provider."
            )
          ]),
          _c("li", [
            _vm._v(
              "Your treatment and care provided through the Website is limited to specific women’s health services offered through the Website, and treatment for other conditions is not available."
            )
          ]),
          _c("li", [
            _vm._v(
              "You give your informed consent to telehealth as an acceptable form of delivering healthcare services to you and that this consent will cover any and all of your telehealth services through the Website."
            )
          ]),
          _c("li", [
            _vm._v(
              "You may withdraw your informed consent by discontinuing Services through the Website and deactivating your account."
            )
          ]),
          _c("li", [
            _vm._v(
              "Winona, Inc. and its affiliates are third party beneficiaries of this Informed Consent and have the right to enforce it against you."
            )
          ])
        ]),
        _c("p", [
          _c("b", [
            _vm._v(
              " ______ I HAVE READ THIS DOCUMENT CAREFULLY, UNDERSTAND THE RISKS AND BENEFITS OF THE TELEHEALTH CONSULTATION, AND HAVE HAD MY QUESTIONS REGARDING THE TELEHEALTH SERVICES EXPLAINED SUFFICIENTLY. I HEREBY CONSENT TO RECEIVING TELEHEALTH SERVICES AS DESCRIBED HEREIN. "
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }