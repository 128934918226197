var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-block text-center" },
    [
      _c(
        "b-button",
        {
          staticClass: "mt-4 mb-3",
          attrs: { variant: "info", pill: "", block: "" },
          on: {
            click: function($event) {
              return _vm.$emit("next")
            }
          }
        },
        [_c("b-icon-lock"), _vm._v("Continue")],
        1
      ),
      _c(
        "a",
        {
          staticClass: "mx-auto",
          attrs: { href: "javascript:;" },
          on: { click: _vm.toggleText }
        },
        [_c("u", [_vm._v("Why we need this information")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }