var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modalUpsell",
      attrs: {
        id: "modalUpsell",
        size: "xl",
        centered: "",
        "hide-header": "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "modal-class": "root-modal-upsell",
        "content-class": "content-modal-upsell",
        "dialog-class": "dialog-modal-upsell"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-header",
          staticStyle: { padding: "0", border: "0" }
        },
        [
          _c(
            "b-button",
            {
              staticStyle: {
                position: "absolute",
                top: "25px",
                right: "25px",
                padding: "2px 0px"
              },
              attrs: { size: "sm", variant: "link" },
              on: { click: _vm.onDenyUpsell }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("circle", {
                    attrs: { cx: "12", cy: "12", r: "11.5", stroke: "#1A6A72" }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M14.903 14.189L12.7638 12.0499L14.903 9.91077L14.1899 9.19773L12.0508 11.3369L9.91162 9.19773L9.19857 9.91077L11.3377 12.0499L9.19857 14.189L9.91162 14.9021L12.0508 12.763L14.1899 14.9021L14.903 14.189Z",
                      fill: "#1A6A72"
                    }
                  })
                ]
              )
            ]
          )
        ],
        1
      ),
      _vm.upsellProductList.length === 1
        ? _c(
            "b-row",
            { staticClass: "upsell-container-single mt-4" },
            [
              _c("b-col", { staticClass: "text-center", attrs: { lg: "" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "align-items-center flex-column d-none d-lg-flex"
                  },
                  [
                    _c("img", {
                      staticClass: "my-5",
                      staticStyle: { width: "70%" },
                      attrs: {
                        src: _vm.upsellProductList[0].images[0],
                        alt: ""
                      }
                    }),
                    _c("p", { staticClass: "text-center" }, [
                      _c("span", { staticClass: "upsell__remark" }, [
                        _vm._v(
                          ' "' +
                            _vm._s(_vm.upsellProductList[0].metadata.remark) +
                            '" '
                        )
                      ]),
                      _c("br"),
                      _c("span", { staticClass: "upsell__remarkby" }, [
                        _vm._v(
                          " -" +
                            _vm._s(_vm.upsellProductList[0].metadata.remarkby) +
                            " "
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _vm.isSuccessSectionVisible
                ? _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex flex-column justify-content-center pr-4",
                      attrs: { lg: "" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "align-items-center flex-column d-flex d-lg-none mb-5"
                        },
                        [
                          _c("img", {
                            staticClass: "my-5",
                            staticStyle: { width: "60%" },
                            attrs: {
                              src: _vm.upsellProductList[0].images[0],
                              alt: ""
                            }
                          }),
                          _c("p", { staticClass: "text-center" }, [
                            _c("span", { staticClass: "upsell__remark" }, [
                              _vm._v(
                                ' "' +
                                  _vm._s(
                                    _vm.upsellProductList[0].metadata.remark
                                  ) +
                                  '" '
                              )
                            ]),
                            _c("br"),
                            _c("span", { staticClass: "upsell__remarkby" }, [
                              _vm._v(
                                " -" +
                                  _vm._s(
                                    _vm.upsellProductList[0].metadata.remarkby
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ]
                      ),
                      _c("h2", { staticClass: "h1 upsell__header mb-2" }, [
                        _vm._v("Success!")
                      ]),
                      _c("p", { staticClass: "upsell__product-success" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.upsellProductList[0].name) +
                            " has been added to your treatment plan for your doctor to review. "
                        )
                      ]),
                      _c(
                        "b-button",
                        {
                          attrs: { pill: "", variant: "primary mb-4" },
                          on: { click: _vm.closeUpsellModal }
                        },
                        [_vm._v(" Close Window ")]
                      )
                    ],
                    1
                  )
                : _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex flex-column justify-content-center pr-4",
                      attrs: { lg: "" }
                    },
                    [
                      _c("div", { staticClass: "text-center text-lg-left" }, [
                        _c("h2", { staticClass: "h1 upsell__header mb-2" }, [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.upsellProductList[0].metadata.header
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "mb-5",
                            staticStyle: { "font-size": "18px" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.upsellProductList[0].metadata
                                    .headerdescription
                                ) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "align-items-center flex-column d-flex d-lg-none mb-5"
                        },
                        [
                          _c("img", {
                            staticClass: "my-5",
                            staticStyle: { width: "60%" },
                            attrs: {
                              src: _vm.upsellProductList[0].images[0],
                              alt: ""
                            }
                          }),
                          _c("p", { staticClass: "text-center" }, [
                            _c("span", { staticClass: "upsell__remark" }, [
                              _vm._v(
                                ' "' +
                                  _vm._s(
                                    _vm.upsellProductList[0].metadata.remark
                                  ) +
                                  '" '
                              )
                            ]),
                            _c("br"),
                            _c("span", { staticClass: "upsell__remarkby" }, [
                              _vm._v(
                                " -" +
                                  _vm._s(
                                    _vm.upsellProductList[0].metadata.remarkby
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ]
                      ),
                      _c("h3", { staticClass: "upsell__product-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.upsellProductList[0].name) + " "
                        )
                      ]),
                      _vm.upsellProductList[0].pricing.length <= 1
                        ? _c(
                            "h4",
                            { staticClass: "mb-4 upsell__product-price" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2",
                                  staticStyle: {
                                    "text-decoration": "line-through"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.upsellProductList[0].metadata
                                          .strikethroughprice
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("span", [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.getPrice(
                                        _vm.upsellProductList[0].pricing[0]
                                          .unit_amount * 0.01
                                      )
                                    )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "upsell__product-description" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex upsell__description-nav" },
                            [
                              _c(
                                "a",
                                {
                                  style:
                                    _vm.selectedUpsellNavigation ===
                                    "description"
                                      ? {
                                          color: "#19191c",
                                          "text-decoration": "none"
                                        }
                                      : "",
                                  on: {
                                    click: function($event) {
                                      _vm.selectedUpsellNavigation =
                                        "description"
                                    }
                                  }
                                },
                                [_vm._v(" Description ")]
                              ),
                              _c("span", { staticClass: "mx-2" }, [
                                _vm._v("|")
                              ]),
                              _c(
                                "a",
                                {
                                  style:
                                    _vm.selectedUpsellNavigation === "howtouse"
                                      ? {
                                          color: "#19191c",
                                          "text-decoration": "none"
                                        }
                                      : "",
                                  on: {
                                    click: function($event) {
                                      _vm.selectedUpsellNavigation = "howtouse"
                                    }
                                  }
                                },
                                [_vm._v(" How It Works ")]
                              ),
                              _c("span", { staticClass: "mx-2" }, [
                                _vm._v("|")
                              ]),
                              _c(
                                "a",
                                {
                                  style:
                                    _vm.selectedUpsellNavigation ===
                                    "ingredients"
                                      ? {
                                          color: "#19191c",
                                          "text-decoration": "none"
                                        }
                                      : "",
                                  on: {
                                    click: function($event) {
                                      _vm.selectedUpsellNavigation =
                                        "ingredients"
                                    }
                                  }
                                },
                                [_vm._v(" Ingredients ")]
                              )
                            ]
                          ),
                          _c("p", {
                            staticClass: "mb-0 upsell__description-text",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.upsellProductList[0][
                                  _vm.selectedUpsellNavigation
                                ].html
                              )
                            }
                          }),
                          _c(
                            "a",
                            {
                              staticClass:
                                "upsell__learn-more mb-4 d-inline-block",
                              attrs: {
                                href:
                                  "https://help.bywinona.com/en/collections/3079719-blossom-by-winona",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                " Learn more about " +
                                  _vm._s(_vm.upsellProductList[0].name) +
                                  " by Winona "
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "ul",
                        { staticClass: "list-unstyled list-inline mt-4" },
                        _vm._l(_vm.upsellProductList[0].pricing, function(
                          price
                        ) {
                          return _c("li", { key: price.id }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedUpsellPricingId,
                                  expression: "selectedUpsellPricingId"
                                }
                              ],
                              attrs: {
                                id: price.id,
                                type: "radio",
                                name: price.id
                              },
                              domProps: {
                                value: price.id,
                                checked: _vm._q(
                                  _vm.selectedUpsellPricingId,
                                  price.id
                                )
                              },
                              on: {
                                change: function($event) {
                                  _vm.selectedUpsellPricingId = price.id
                                }
                              }
                            }),
                            price.type === "one_time"
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "subscription-label mb-0",
                                    attrs: { for: price.id }
                                  },
                                  [
                                    _vm._v(
                                      " $" +
                                        _vm._s(
                                          _vm.getPrice(
                                            (price.unit_amount * 0.01).toFixed(
                                              2
                                            )
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _c(
                                  "label",
                                  {
                                    staticClass: "subscription-label mb-0",
                                    attrs: { for: price.id }
                                  },
                                  [
                                    _vm._v(
                                      " $" +
                                        _vm._s(
                                          _vm.getPrice(
                                            (price.unit_amount * 0.01).toFixed(
                                              2
                                            )
                                          )
                                        ) +
                                        "/month "
                                    )
                                  ]
                                )
                          ])
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mb-4 d-flex justify-content-between flex-wrap",
                          class: {
                            "mt-4": _vm.upsellProductList[0].pricing.length <= 1
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center py-2" },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: require("@/assets/images/upsell/prescription.svg"),
                                  alt: "",
                                  height: "30",
                                  width: "30"
                                }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-uppercase upsell__icon-info mb-0"
                                },
                                [
                                  _vm._v(" Prescribed by "),
                                  _c("br"),
                                  _vm._v(" licensed doctors ")
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center py-2" },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: require("@/assets/images/upsell/hand-heart.svg"),
                                  alt: "",
                                  height: "30",
                                  width: "30"
                                }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-uppercase upsell__icon-info mb-0"
                                },
                                [
                                  _vm._v(" Made custom "),
                                  _c("br"),
                                  _vm._v(" for your body ")
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center py-2" },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: require("@/assets/images/dashboard/heart.svg"),
                                  alt: "",
                                  height: "30",
                                  width: "30"
                                }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-uppercase upsell__icon-info mb-0"
                                },
                                [
                                  _vm._v(" Free shipping, "),
                                  _c("br"),
                                  _vm._v(" Cancel anytime ")
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { pill: "", variant: "primary mb-4" },
                          on: { click: _vm.onAcceptUpsell }
                        },
                        [
                          _vm._v(" Add to treatment plan "),
                          _vm.upsellProductList[0].pricing.length <= 1
                            ? _c("span", [
                                _vm._v(
                                  " | " +
                                    _vm._s(
                                      _vm.parsePrice(
                                        _vm.getPrice(
                                          _vm.upsellProductList[0].pricing[0]
                                            .unit_amount * 0.01
                                        )
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "text-underline text-center",
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.onDenyUpsell }
                        },
                        [
                          _vm._v(
                            " No thanks, I would not like to add this to my order. "
                          )
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _vm.upsellProductList.length > 1
        ? _c(
            "b-row",
            {
              staticClass:
                "\n      upsell-container-multiple\n      d-flex\n      flex-column\n      align-items-center\n      mt-4\n    "
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-center text-center mb-4"
                },
                [
                  _c("h2", { staticClass: "h1 mb-3 upsell__header" }, [
                    _c("span", { staticClass: "d-none d-md-block" }, [
                      _vm._v(" Would you like to try our "),
                      _c("br"),
                      _vm._v(" new products? ")
                    ]),
                    _c("span", { staticClass: "d-block d-md-none" }, [
                      _vm._v(" Would you like to try our new products? ")
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                { staticStyle: { "max-width": "800px" } },
                _vm._l(_vm.upsellProductList, function(product, i) {
                  return _c("SelectProductUpsell", {
                    key: product.id,
                    attrs: { product: product, idx: i },
                    on: {
                      "upsell-change": _vm.onMultipleUpsellChange,
                      "upsell-remove": _vm.onMultipleUpsellRemove
                    }
                  })
                }),
                1
              ),
              _c(
                "b-button",
                {
                  staticStyle: { width: "250px" },
                  attrs: { pill: "", variant: "primary mb-4" },
                  on: { click: _vm.onAcceptUpsell }
                },
                [_vm._v(" Request Prescription ")]
              ),
              _c(
                "p",
                {
                  staticClass: "text-underline text-center",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.onDenyUpsell }
                },
                [
                  _vm._v(
                    " No thanks, I would not like to add this to my order. "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }