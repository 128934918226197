var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      _vm.prescription == "rx"
        ? [
            _c(
              "b-row",
              [
                _vm.coupon.show
                  ? _c(
                      "b-col",
                      { staticClass: "bg-info text-center py-2 text-light" },
                      [
                        _vm._v(" " + _vm._s(_vm.coupon.message) + " "),
                        _vm.coupon.showBlossomLink
                          ? _c(
                              "a",
                              {
                                staticClass: "text-light",
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function($event) {
                                    return _vm.showBlossomModal("BLOSSOM")
                                  }
                                }
                              },
                              [_c("u", [_vm._v("Claim now")])]
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm.couponerror.show
                  ? _c(
                      "b-col",
                      { staticClass: "bg-danger text-center py-2 text-light" },
                      [_vm._v(" " + _vm._s(_vm.couponerror.message) + " ")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "b-row",
              { attrs: { "align-h": "center" } },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: _vm.show ? 6 : 9 } },
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-5 hero-banner" },
                      [
                        _vm.id
                          ? _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      "alert alert-danger mb-3 text-center verify-status",
                                      !_vm.verificationstatus
                                        ? "not-verify"
                                        : ""
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      " Action Required. You have something that needs updated or confirmed in your account. "
                                    ),
                                    _c("router-link", {
                                      attrs: {
                                        to: "/account-settings",
                                        custom: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var navigate = ref.navigate
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "color-inherit text-underline",
                                                    attrs: {
                                                      href: "javascript:void(0)"
                                                    },
                                                    on: { click: navigate }
                                                  },
                                                  [_vm._v("Visit here")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3381679774
                                      )
                                    }),
                                    _vm._v(" . ")
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "alert alert-danger mb-3 text-center verify-status",
                                    class: _vm.showSkipAlert ? "not-verify" : ""
                                  },
                                  [
                                    _vm._v(
                                      " For our doctor to accept you, please upload your " +
                                        _vm._s(
                                          _vm.iduploaded == "SKIPPED"
                                            ? "ID"
                                            : ""
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.iduploaded == "SKIPPED" &&
                                            _vm.photouploaded == "SKIPPED"
                                            ? "and"
                                            : ""
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.photouploaded == "SKIPPED"
                                            ? "Personal Photo"
                                            : ""
                                        ) +
                                        " by clicking "
                                    ),
                                    _c("router-link", {
                                      attrs: {
                                        to: "/account-settings",
                                        custom: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var navigate = ref.navigate
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "color-inherit text-underline",
                                                    attrs: {
                                                      href: "javascript:void(0)"
                                                    },
                                                    on: { click: navigate }
                                                  },
                                                  [_vm._v("here")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        187700079
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _vm.followups.length > 0 &&
                                _vm.isTwoWeeksBeforeFollowup
                                  ? _c(
                                      "b-alert",
                                      {
                                        staticClass: "mb-5 h3",
                                        staticStyle: {
                                          background: "#1a6a72",
                                          color: "#cdfcb1",
                                          "border-color": "#1a6a72"
                                        },
                                        attrs: { show: "" }
                                      },
                                      [
                                        _vm.followups[0].type === "1YEAR"
                                          ? _c("span", [
                                              _vm._v(" 1 Year Follow Up: ")
                                            ])
                                          : _c("span", [
                                              _vm._v("10 Week Follow Up: ")
                                            ]),
                                        _vm._v(
                                          " Your next check-in is scheduled for " +
                                            _vm._s(
                                              _vm.parseDate(
                                                _vm.followups[0]
                                                  .followupdatestamp
                                              )
                                            ) +
                                            ". "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          {
                            class: _vm.show ? "text-center" : "pr-md-5",
                            attrs: { cols: "12", lg: _vm.show ? 12 : 6 }
                          },
                          [
                            _c(
                              "b-row",
                              {
                                staticStyle: { height: "100%" },
                                attrs: { "align-v": "stretch" }
                              },
                              [
                                _c("b-col", { attrs: { cols: "12" } }, [
                                  _c("div", { staticClass: "mt-5" }, [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "text-primary display-3 mb-3 med-font",
                                        staticStyle: {
                                          "line-height": "1.1",
                                          "font-weight": "initial"
                                        }
                                      },
                                      [
                                        _vm._v(" Hi "),
                                        !_vm.name
                                          ? [_vm._v("...")]
                                          : [_vm._v(_vm._s(_vm.name))],
                                        _vm._v(", we’re so glad you’re here. ")
                                      ],
                                      2
                                    ),
                                    _c("p", { staticClass: "sub-text mt-5" }, [
                                      _vm._v(
                                        " Here’s what to expect in the next few days. If have any questions, you can always start a consultation with your provider. "
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._l(_vm.availableDashboardUpsell, function(
                                  item,
                                  i
                                ) {
                                  return _c(
                                    "b-col",
                                    {
                                      key: i,
                                      staticClass: "mb-4",
                                      attrs: { cols: "12", "align-self": "end" }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "p-4 mx-1 mx-md-0 banner-box"
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pl-0 text-center",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c("b-img", {
                                                attrs: {
                                                  fluid: "",
                                                  src: item.images[0],
                                                  alt: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _c(
                                                "h2",
                                                { staticClass: "mb-3" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.metadata.header)
                                                  )
                                                ]
                                              ),
                                              _c("p", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.description.html
                                                  )
                                                }
                                              }),
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "info",
                                                    size: "lg",
                                                    pill: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.showBlossomModal(
                                                        item.upsellcode
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.reorder
                                                          ? "Reorder now"
                                                          : "Order now"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", lg: _vm.show ? 12 : 6 } },
                          [
                            _c(
                              "div",
                              { staticClass: "timeline-box mt-5" },
                              [
                                _c(
                                  "h2",
                                  {
                                    staticClass: "bold-font h1",
                                    staticStyle: { "margin-bottom": "2.5rem" }
                                  },
                                  [_vm._v(" What’s next? ")]
                                ),
                                _c(
                                  "ul",
                                  {
                                    class: [
                                      "list-unstyled timeline",
                                      _vm.show ? "show-all" : "hide-some"
                                    ]
                                  },
                                  _vm._l(_vm.timeline, function(list, i) {
                                    return _c(
                                      "li",
                                      {
                                        key: i,
                                        class: list.done ? "done" : ""
                                      },
                                      [
                                        list.done
                                          ? _c("b-icon", {
                                              attrs: {
                                                icon: "check-circle-fill"
                                              }
                                            })
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "list-content" },
                                          [
                                            _c(
                                              "h2",
                                              { staticClass: "med-font mb-2" },
                                              [_vm._v(_vm._s(list.title))]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "sub-text mb-1" },
                                              [_vm._v(_vm._s(list.desc))]
                                            ),
                                            list.after
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-muted small-text"
                                                  },
                                                  [_vm._v(_vm._s(list.after))]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "ml-5 mb-4",
                                    attrs: { variant: "link" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showMore()
                                      }
                                    }
                                  },
                                  [
                                    _vm.show
                                      ? [_vm._v(" Show Less ")]
                                      : [_vm._v(" Show More ")]
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.prescription == "non-rx"
        ? [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("banner", {
                      attrs: {
                        name: _vm.name,
                        "button-text": "view your order",
                        "background-image": require("@/assets/images/login/bg.png"),
                        height: "calc(100vh - 150px)"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "p-4" },
        [
          _c(
            "b-col",
            { staticClass: "px-4 mb-4", attrs: { cols: "12", lg: "4" } },
            [
              _c("d-card", {
                attrs: {
                  "bg-color": "#E8F2EC",
                  "border-color": "#cdfcb1",
                  image: require("@/assets/images/dashboard/msg-bg.png"),
                  link: "/message",
                  text: "Message Provider"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "px-4 mb-4", attrs: { cols: "12", lg: "4" } },
            [
              _c("d-card", {
                attrs: {
                  "bg-color": "#E8F2EC",
                  "border-color": "#cdfcb1",
                  image: require("@/assets/images/dashboard/support-bg.png"),
                  url: "https://help.bywinona.com/en/",
                  text: "Support Center"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "px-4", attrs: { cols: "12", lg: "4" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "default-bg",
                  staticStyle: {
                    height: "435px",
                    "background-color": "#94d9c3"
                  }
                },
                [
                  _c(
                    "b-row",
                    {
                      staticStyle: { height: "100%" },
                      attrs: { "align-v": "stretch" }
                    },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("img", {
                          staticClass: "mb-4",
                          attrs: {
                            src: require("@/assets/images/dashboard/heart.svg"),
                            alt: ""
                          }
                        }),
                        _c("h2", { staticClass: "mb-2 heading text-default" }, [
                          _vm._v("How can we help you?")
                        ]),
                        _c("p", { staticClass: "mb-0 sub-text" }, [
                          _vm._v(
                            " Have a question about your membership, or trouble accessing your account? Your friends at the Winona are here to help! "
                          )
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", "align-self": "end" } },
                        [
                          _c(
                            "h2",
                            { staticClass: "mb-0 mt-5 pt-4" },
                            [
                              _c("router-link", {
                                attrs: { to: "/contact-us", custom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var navigate = ref.navigate
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-default",
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: { click: navigate }
                                          },
                                          [
                                            _vm._v(" Contact Care Team "),
                                            _c("span", {
                                              staticClass: "next-arrow"
                                            })
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "p-4" },
        [
          _c(
            "b-col",
            { staticClass: "px-4 mb-4", attrs: { cols: "12", lg: "4" } },
            [
              _c("d-card", {
                attrs: {
                  "bg-color": "#E8F2EC",
                  "border-color": "#C7BDFF",
                  image: require("@/assets/images/for-facebook-community.png"),
                  url: "https://www.facebook.com/groups/141206008222366",
                  text: "Join Winona Womens’ Facebook Community"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "px-4 mb-4", attrs: { cols: "12", lg: "4" } },
            [
              _c("d-card", {
                attrs: {
                  "bg-color": "#E8F2EC",
                  "border-color": "#C7BDFF",
                  image: require("@/assets/images/webinar.png"),
                  url: "https://app.livestorm.co/winona",
                  text: "Sign Up for Our Monthly Live Q&A"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "px-4", attrs: { cols: "12", lg: "4" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "default-bg",
                  staticStyle: {
                    height: "435px",
                    "background-color": "rgba(97, 211, 226, 0.5)"
                  }
                },
                [
                  _c(
                    "b-row",
                    {
                      staticStyle: { height: "100%" },
                      attrs: { "align-v": "stretch" }
                    },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("img", {
                          staticClass: "mb-4",
                          attrs: {
                            src: require("@/assets/images/dashboard/physician.svg"),
                            alt: "physician"
                          }
                        }),
                        _c("h2", { staticClass: "mb-2 heading text-default" }, [
                          _vm._v("Winona Physician")
                        ]),
                        _c("p", { staticClass: "mb-0 sub-text" }, [
                          _vm._v(
                            " For questions regarding your health, treatment, and symptoms "
                          )
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", "align-self": "end" } },
                        [
                          _c(
                            "h2",
                            { staticClass: "mb-0 mt-5 pt-4" },
                            [
                              _c("router-link", {
                                attrs: { to: "/message", custom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var navigate = ref.navigate
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-default",
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: { click: navigate }
                                          },
                                          [
                                            _vm._v(
                                              " Contact Winona Physician "
                                            ),
                                            _c("span", {
                                              staticClass: "next-arrow"
                                            })
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            id: "modalNotif",
            size: "md",
            centered: "",
            "hide-header": "",
            "hide-footer": ""
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-header intake-header",
              staticStyle: { padding: "0", border: "0" }
            },
            [
              _c(
                "b-container",
                { staticClass: "pl-0 pr-0", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "link" },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.hide("modalNotif")
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "x-circle-fill",
                                  variant: "primary"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center p-3" },
            [
              _c("h2", { staticClass: "h1 heading text-primary mb-3" }, [
                _vm._v(" Your treatment has been changed ")
              ]),
              _c("p", { staticClass: "sub-text" }, [
                _vm._v(
                  " The price of Progesterone Capsule treatment has increased from $89 to $199. "
                )
              ]),
              _c(
                "b-button",
                {
                  staticStyle: { "min-width": "200px" },
                  attrs: { pill: "", variant: "info" }
                },
                [_vm._v("View Message")]
              )
            ],
            1
          )
        ]
      ),
      _vm.isUpsellModalVisible
        ? _c("OneClickUpsell", {
            attrs: {
              isUpsellModalVisible: _vm.isUpsellModalVisible,
              "upsell-from": "Patient Portal Upsell",
              couponid: _vm.$cookies.get("promocode")
            },
            on: {
              "update:isUpsellModalVisible": function($event) {
                _vm.isUpsellModalVisible = $event
              },
              "update:is-upsell-modal-visible": function($event) {
                _vm.isUpsellModalVisible = $event
              },
              "remove-banner": function($event) {
                _vm.coupon.show = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }