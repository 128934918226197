<template>
  <div class="eligibilityState">
    <h2 class="question-title">
      Let’s make sure we’re available in your state
    </h2>
    <b-row>
      <b-col>
        <div role="group" class="group-mb">
          <label for="state" class="label-text"
            >What state do you live in?</label
          >
          <v-select
            class="custom-input-style fs-mask"
            append-to-body
            :calculate-position="withPopper"
            v-model="state"
            label="text"
            :options="states"
            :reduce="(states) => states.value"
            :searchable="false"
            placeholder="Select state"
          >
            <template #open-indicator="{ attributes }">
              <b-icon v-bind="attributes" icon="chevron-down"></b-icon>
            </template>
            <span v-if="states.length == 0" slot="no-options">Loading...</span>
          </v-select>
        </div>
        <!-- Terms and conditions -->
        <b-form-checkbox
          id="terms"
          class="checkbox-label"
          v-model="terms"
          name="checkbox-1"
          value="accepted"
          unchecked-value="not_accepted"
        >
          <span>I have read and agree to these </span>
          <a href="#" @click.prevent="termsModal = true">
            Terms &amp; Conditions,
          </a>
          &nbsp;
          <a href="#" @click.prevent="termsModal = true">Privacy Policy,</a>
          and
          <a href="#" @click.prevent="termsModal = true">Telehealth Consent</a>
        </b-form-checkbox>
        <b-button
          pill
          variant="info"
          @click="submitState"
          class="mb-3"
          :disabled="disabled"
          >Continue</b-button
        >
        <small class="d-block"
          >Already have an account?
          <router-link to="/login">Sign In</router-link></small
        >
      </b-col>
    </b-row>

    <!-- Modals -->
    <TermsModal
      :show="termsModal"
      @exit="termsModal = false"
      header-class="terms-title"
      body-class="terms-body"
    >
      <template #header>
        <h1 class="h2 mb-0 heading text-primary pr-4">
          Terms &amp; Conditions, Privacy Policy and Telehealth Consent
        </h1>
      </template>
      <template #body>
        <terms />
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button
              pill
              variant="info"
              @click="agreeTerms()"
              style="min-width: 250px"
            >
              I Agree
            </b-button>
          </b-col>
        </b-row>
      </template>
    </TermsModal>
    <div class="bg-overlay" @click="termsModal = false"></div>

    <modal
      v-model="showModal"
      id="agreement"
      modal-class="agreement"
      size="sm"
      centered
      hide-header
      hide-footer
    >
      <h2 class="mb-0 text-center link">
        Winona’s treatments require our customers to agree to the terms
      </h2>
      <!-- <b-button @click="$bvModal.hide('agreement')">Hide Modal</b-button> -->
    </modal>
  </div>
</template>

<script>
import popper from "@/assets/js/popper.js";
import formValidation from "@/components/form/mixins";

import { LookUpService } from "@/services/lookup.service";
import Terms from "@/components/partials/Terms";
import TermsModal from "@/components/partials/SideModal";

export default {
  mixins: [formValidation, popper],
  components: {
    Terms,
    TermsModal,
  },
  props: {
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    preAnswer: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      state: "",
      states: [],
      stateData: [],
      showModal: false,
      termsModal: false,
      terms: "",
      disabled: false,
    };
  },
  methods: {
    async getStates() {
      const { data } = await LookUpService.getLookUp("state");
      // Raw state data
      this.stateData = data;
      // State data for options
      data.forEach((data) => {
        this.states.push({ value: data.id, text: data.name });
      });
    },
    submitState() {
      if (this.terms == "" || this.terms == "not_accepted") {
        this.toggleModal();
      } else if (this.state == "") {
        this.$emit("showAlert", "Please choose a state.");
      } else {
        let selectedState = this.stateData.filter(
          (state) => state.id == this.state
        )[0];
        this.$emit("setParams", "state", selectedState.id);
        this.$emit("setStateName", selectedState.name);
        if (selectedState.hasOwnProperty("supported")) {
          // this.$emit('showLoader', 3)

          // WIN-15169
          window.analytics.track("State Selected", {
            state: this.state,
          });

          this.$emit("setStep", "email");
        } else {
          // WIN-15590
          window.analytics.track("Onboarding Reject", {
            question: "What state do you live in?",
            answer: this.state,
            onboardingflow: this.onboardingflow,
          });

          this.$emit("setStep", "waitlist");
        }
      }
    },
    toggleModal() {
      this.$root.$emit("bv::show::modal", "agreement");
    },
    agreeTerms() {
      // this.$root.$emit("bv::hide::modal", "terms");
      this.termsModal = false;
      this.terms = "accepted";
    },
  },
  async mounted() {
    if (this.preAnswer) {
      this.state = this.preAnswer;
    }

    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        // customerId: <Customer ID.>,
        screen: "1.1-A",
        onboardingflow: this.onboardingflow,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
      },
      {
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );
    this.getStates();
  },
};
</script>

<style lang="scss" scoped>
.vs__dropdown-toggle {
  height: 52px;
}

.checkbox-label a {
  cursor: pointer;
  color: #a783ff;
  text-decoration: none;

  &:hover {
    color: #1a6a72 !important;
  }
}
</style>
