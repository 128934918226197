import Vue from "vue";
import VueRouter from "vue-router";
import VueBodyClass from "vue-body-class";
import VueCookies from "vue-cookies";

// this is to test page for the new UI added
// import Test from '@/views/Test.vue'

import Cart from "@/views/Cart.vue";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";

// import Signup from '@/views/Signup.vue'
// import Profile from '@/views/Profile.vue'
// import Contact from '@/views/ContactUs.vue'
import NotFound from "@/views/PageNotFound.vue";
import HelpCenter from "@/views/HelpCenter.vue";
import Redirect from "@/views/Redirect.vue";
// import Onboarding from '@/views/Onboardingv1.vue'
import Onboarding from "@/views/Onboarding.vue";
import LoaderSwitcher from "@/views/LoaderSwitcher.vue";
import NewPassword from "@/views/NewPassword.vue";
import ResetPassword from "@/views/ForgotPassword.vue";
// import GettingStarted from '@/views/GettingStarted.vue'
import Complete from "@/components/onboarding/v1/Complete.vue";
import Quiz from "@/components/partials/Quiz";

import config from "../../config.js";

import onboardingRouter from "./onboarding.js";

Vue.use(VueRouter);

import { AuthService } from "@/services/auth.service";
import { OnboardingService } from "@/services/onboarding.service";
const routes = [
  {
    path: "*",
    redirect: "/404",
    meta: {
      transition: "overlay-up-down",
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "Page not Found",
    },
  },
  {
    // id act as user credentials for example
    // /redirect/myidtoken, /redirect/myidnumber,
    path: "/redirect/",
    name: "redirect",
    component: Redirect,
    meta: {
      transition: "fade",
      title: "Redirection in process...",
    },
  },
  {
    path: "/website/",
    beforeEnter(to, from, next) {
      location.href = `${process.env.VUE_APP_WEBSITE_URL}`;
      next(false);
    },
    meta: {
      title: "Redirection in progress...",
    },
  },
  {
    path: "/website/contact",
    beforeEnter(to, from, next) {
      location.href = `${process.env.VUE_APP_WEBSITE_URL}/contact`;
      next(false);
    },
    meta: {
      title: "Redirection in progress...",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    // beforeEnter (to, from, next) {
    //   // Delegates the proper login redirections
    //   window.location.href = `${process.env.VUE_APP_WEBSITE_URL}?redirectURL=${process.env.VUE_APP_SITE_URL}${from.fullPath}`;
    // },
    meta: {
      transition: "fade-in-left",
      title: "Login",
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
    meta: {
      transition: "fade",
      title: "Cart",
      bodyClass: "cart",
    },
  },
  {
    path: "/quiz",
    name: "quiz",
    component: Quiz,
    meta: {
      transition: "fade",
    },
  },
  // { // deprecated
  //   path: '/signup',
  //   name: 'signup',
  //   component: Signup,
  //   meta: {
  //     transition: 'fade-in-left',
  //     title: 'Sign Up'
  //   }
  // },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ResetPassword,
    meta: {
      transition: "fade-in-left",
      title: "Forgot Password",
    },
  },
  {
    path: "/new-password",
    name: "new-password",
    component: NewPassword,
    meta: {
      transition: "fade-in-left",
      title: "New Pasword",
    },
  },
  // {
  //   path: '/checkout-completed',
  //   name: 'checkout-complete',
  //   component: Complete,
  //   meta: {
  //     transition: 'fade',
  //     title: 'Checkout Completed'
  //   },
  // },
  // Onboarding v2
  {
    path: "/",
    name: "onboarding",
    component: Onboarding,
    meta: {
      bodyClass: "getting-started",
      title: "Onboarding",
    },
    beforeEnter: onboardingRouter,
    beforeUpdate(to, from, next) {
      if (to == "/") {
        next(false);
      }
    },
    children: [
      {
        path: "/eligibility",
        component: () => import("@/components/onboarding/Eligibility.vue"),
        name: "eligibility",
        meta: {
          transition: "fade",
          title: "Account Creation",
        },
      },
      {
        path: "/health-questions",
        component: () => import("@/components/onboarding/HealthQuestions.vue"),
        name: "health-questions",
        meta: {
          transition: "fade",
          title: "Health Questions",
        },
        // beforeEnter(to, from, next) {
        //   try {
        //     // Optinmonster
        //     if (to.path != "/treatment-options") {
        //       let head = document.head;
        //       let optin = documentgetElementById("optinmonster");
        //       if (optin) {
        //         head.removeChild(optin);
        //       }
        //     }
        //   } catch (err) {
        //   } finally {
        //     next();
        //   }
        // },
      },
      {
        path: "/treatment-options",
        component: () => import("@/components/onboarding/TreatmentOptions.vue"),
        name: "treatment-options",
        // beforeEnter(to, from, next) {
        //   // Optinmonster
        //   try {
        //     // <!-- This site is converting visitors into subscribers and customers with OptinMonster - https://optinmonster.com -->
        //     let optin = document.getElementById("optinmonster");
        //     if (!optin) {
        //       (function (d, u, ac) {
        //         var s = d.createElement("script");
        //         s.id = "optinmonster";
        //         s.type = "text/javascript";
        //         s.src = "https://a.omappapi.com/app/js/api.min.js";
        //         s.async = true;
        //         s.dataset.user = u;
        //         s.dataset.account = ac;
        //         d.getElementsByTagName("head")[0].appendChild(s);
        //       })(document, 112892, 124794);
        //     }
        //   } catch (err) {
        //   } finally {
        //     next();
        //   }
        // <!-- / https://optinmonster.com -->
        // },
        meta: {
          transition: "fade",
          title: "Treatment Options",
        },
      },
      {
        path: "/treatment-addons",
        component: () => import("@/components/onboarding/TreatmentAddons.vue"),
        name: "treatment-addons",
        meta: {
          transition: "fade",
          title: "Treatment Add-ons",
        },
      },
      {
        path: "/identity-verification",
        name: "identity-verification",
        component: () =>
          import("@/components/onboarding/IdentityVerification.vue"),
        meta: {
          transition: "fade",
          title: "Identity Verification",
        },
        // beforeEnter(to, from, next) {
        //   try {
        //     // Optinmonster
        //     if (to.path != "/treatment-options") {
        //       let head = document.head;
        //       let optin = documentgetElementById("optinmonster");
        //       if (optin) {
        //         head.removeChild(optin);
        //       }
        //     }
        //   } catch (err) {
        //   } finally {
        //     next();
        //   }
        // },
      },
      {
        path: "/checkout",
        name: "checkout",
        component: () => import("@/components/onboarding/CheckOut.vue"),
        meta: {
          transition: "fade",
          title: "Check Out",
        },
      },
    ],
  },
  {
    path: "/home",
    name: "index",
    component: Home,
    beforeEnter(to, from, next) {
      let head = document.head;
      let sas = document.getElementById("shareasale");
      if (sas) {
        head.removeChild(sas);
      }
      next();
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/components/dashboard/Dashboard.vue"),
        meta: {
          transition: "fade",
          title: "Home",
        },
      },
      {
        // id act as user credentials
        path: "/treatment",
        name: "treatment",
        component: () => import("@/components/dashboard/Treatment.vue"),
        meta: {
          transition: "fade",
          title: "Treatment",
        },
      },
      {
        path: "/message",
        name: "messages",
        component: () => import("@/components/dashboard/Message.vue"),
        meta: {
          transition: "fade",
          title: "Message",
          bodyClass: "message-page",
        },
      },
      {
        path: "/support",
        name: "support",
        component: () => import("@/components/dashboard/Support.vue"),
        meta: {
          transition: "fade",
          title: "Help Center",
          bodyClass: "support",
        },
      },
      {
        path: "/subscription/:id",
        name: "subscription",
        component: () => import("@/components/partials/Subscription.vue"),
        meta: {
          transition: "fade",
          title: "Subscription",
        },
      },
      {
        path: "/order/:id",
        name: "order",
        component: () => import("@/components/partials/Order.vue"),
        meta: {
          transition: "fade",
          title: "My Order",
        },
      },
      // {
      //   path: '/story/:id',
      //   name: 'story',
      //   component: () => import('@/components/partials/Story.vue'),
      //   meta: {
      //     transition: 'fade',
      //     story: (route) => route.params.id.replace(/(?: | |\b)(\w)/g, (firstLetter) => { return firstLetter.toUpperCase()}).replace(/-/g, ' ')
      //   },
      // },
      {
        path: "/your-account",
        name: "your-account",
        component: () => import("@/components/account/Account.vue"),
        meta: {
          transition: "fade",
          title: "Your Account",
        },
      },
      {
        path: "/account-settings",
        name: "account-settings",
        component: () => import("@/components/account/Profile.vue"),
        meta: {
          transition: "fade",
          title: "Account Settings",
        },
        children: [
          {
            name: "billing-details",
            path: "/account-settings/billing-details",
            meta: {
              title: "Account Settings",
            },
          },
          {
            name: "account-identity-verification",
            path: "/account-settings/identity-verification",
            meta: {
              title: "Account Settings",
            },
          },
        ],
      },
      {
        path: "/medical-information",
        name: "medical-information",
        component: () => import("@/components/account/MedicalInformation.vue"),
        meta: {
          transition: "fade",
          title: "Medical Information",
        },
      },
      {
        path: "/insurance-documents",
        name: "insurance-documents",
        component: () => import("@/components/account/InsuranceDocuments.vue"),
        meta: {
          transition: "fade",
          title: "Insurance Documents",
        },
      },
      {
        path: "/contact-us",
        name: "contact-us",
        component: () => import("@/components/partials/ContactUs.vue"),
        meta: {
          transition: "fade",
          title: "Contact Care Team",
        },
      },
      {
        path: "/cancel-subscription",
        name: "cancel-subscription",
        component: () => import("@/components/partials/CancelSubscription.vue"),
        meta: {
          transition: "fade",
          title: "Cancel Subscription",
        },
      },
      {
        path: "/referral/",
        component: () => import("@/components/dashboard/Referrals"),
        meta: {
          transition: "fade",
          title: "Refer a Friend",
        },
        beforeEnter(to, from, next) {
          if (to.query.refcode) {
            if (localStorage.getItem("accessToken")) {
              next();
            } else {
              Vue.$cookies.set("referralToken", to.query.refcode);
              // if (process.env.VUE_APP_SERVER == "local") {
              //   next({ name: "eligibility" });
              // } else {
              //   window.location.href = process.env.VUE_APP_QUIZ_URL;
              // }
              next({ name: "eligibility" });
            }
          }
          next();
        },
      },
      // {
      //   path: "/help-center/:id",
      //   component: HelpCenter,
      //   name: "help-center",
      //   meta: {
      //     bodyClass: "help-center",
      //   },
      //   children: [
      //     {
      //       path: "/help-center/:id",
      //       name: "how-it-works",
      //       component: () => import("@/components/helpcenter/HowItWorks.vue"),
      //       meta: {
      //         transition: "fade",
      //         title: "Help Center - How It Works",
      //       },
      //     },
      //     {
      //       path: "/help-center/my-account",
      //       name: "my-account",
      //       component: () => import("@/components/helpcenter/MyAccount.vue"),
      //       meta: {
      //         transition: "fade",
      //         title: "Help Center - My Account",
      //       },
      //     },
      //     {
      //       path: "/help-center/order-and-shipping",
      //       name: "order-and-shipping",
      //       component: () =>
      //         import("@/components/helpcenter/OrderAndShipping.vue"),
      //       meta: {
      //         transition: "fade",
      //         title: "Help Center - Orders & Shipping",
      //       },
      //     },
      //     {
      //       path: "/help-center/winona-products",
      //       name: "winona-products",
      //       component: () =>
      //         import("@/components/helpcenter/WinonaProducts.vue"),
      //       meta: {
      //         transition: "fade",
      //         title: "Help Center - Winona Products",
      //       },
      //     },
      //     {
      //       path: "/help-center/privacy-and-security",
      //       name: "privacy-and-security",
      //       component: () =>
      //         import("@/components/helpcenter/PrivacyAndSecurity.vue"),
      //       meta: {
      //         transition: "fade",
      //         title: "Help Center - Privacy & Security",
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: '/help-center/how-it-works',
      //   component: HelpCenter,
      //   children: [
      //     {
      //       path: '/',
      //       name: 'how-it-works',
      //       component: () => import('@/components/helpcenter/HowItWorks.vue'),
      //       meta: {
      //         transition: 'fade',
      //         title: 'Help Center - How It Works'
      //       },
      //     },
      //     {
      //       path: '/help-center/my-account',
      //       name: 'my-account',
      //       component: () => import('@/components/helpcenter/MyAccount.vue'),
      //       meta: {
      //         transition: 'fade',
      //         title: 'Help Center - My Account'
      //       },
      //     },
      //     {
      //       path: '/help-center/order-and-shipping',
      //       name: 'order-and-shipping',
      //       component: () => import('@/components/helpcenter/OrderAndShipping.vue'),
      //       meta: {
      //         transition: 'fade',
      //         title: 'Help Center - Orders & Shipping'
      //       },
      //     },
      //     {
      //       path: '/help-center/winona-products',
      //       name: 'winona-products',
      //       component: () => import('@/components/helpcenter/WinonaProducts.vue'),
      //       meta: {
      //         transition: 'fade',
      //         title: 'Help Center - Winona Products'
      //       },
      //     },
      //     {
      //       path: '/help-center/privacy-and-security',
      //       name: 'privacy-and-security',
      //       component: () => import('@/components/helpcenter/PrivacyAndSecurity.vue'),
      //       meta: {
      //         transition: 'fade',
      //         title: 'Help Center - Privacy & Security'
      //       },
      //     },
      //   ]
      // },
    ],
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

const vueBodyClass = new VueBodyClass(routes);
const DEAFULT_TITLE = "Winona";

// router.afterEach((to, from) => {
//   if (to.name != "messages") {
//     // Page
//     window.analytics.page();
//   }
// });

router.beforeEach(async (to, from, next) => {
  vueBodyClass.guard(to, next);

  Vue.prototype.$ninetailed.page();

  if (to.query.token) {
    localStorage.setItem("accessToken", `${to.query.token}`);
    localStorage.setItem("token", `Bearer ${to.query.token}`);
    const { data } = await AuthService.getTokenFromOTT();
    if (data) {
      localStorage.setItem("accessToken", `${data.token}`);
      localStorage.setItem("token", `Bearer ${data.token}`);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("refreshToken", true);
    }
  }

  if (to.query.promocode || $cookies.get("promocode")) {
    if (!to.query.ec_id && !$cookies.get("promocodeExistingID")) {
      let promocode = to.query.promocode || $cookies.get("promocode");
      try {
        const res = await OnboardingService.checkCoupon(promocode);
        // For those who are already logged in
        if (window.localStorage.getItem("accessToken")) {
          // Skips cookie removal if code applies to Blossom
          // if (res.data.hasOwnProperty("applies_to")) {
          //   if (
          //     res.data.applies_to.products[0] != "prod_Jxqgr2Hh9YGwjZ" &&
          //     res.data.applies_to.products[0] != "prod_K0rnRpVxfgGP4y"
          //   ) {
          //     const applyCoupon = await OnboardingService.applyCoupon({
          //       couponid: promocode,
          //     });
          //     this.$cookies.remove("promocode");
          //   }
          // }

          // * Temporarily opt for hardcoded values
          const blossom_promocodes = [
            "BFBLOSSOM40",
            "BFBLOSSOM40FIXED",
            "BLACKFRIDAY2022",
            "CYBERMONDAY2022",
            "BFBLOSSOM10",
          ];
          if (blossom_promocodes.includes(promocode)) {
            // Don't apply and remove
          } else {
            const { data } = await OnboardingService.getPatientOnboarding();
            if (data.onboardingstatus !== "DONE") {
              await OnboardingService.applyCoupon({
                couponid: promocode,
              });
              this.$cookies.remove("promocode");
            }
          }
        }
        if (to.query.promocode && !window.localStorage.getItem("accessToken")) {
          $cookies.set("promocode", to.query.promocode);
        }
      } catch (err) {
        if (err == "Error: Coupon does not exist.") {
          alert(
            "The coupon you entered does not exist, please check the code you entered."
          );
        } else if (err == "Error: Unauthorized request") {
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("accessToken");
        } else if (err == "Error: You've already completed this step") {
          var newRouteQuery = _.omit(to.query, "promocode");
          router.replace({ query: newRouteQuery });
          $cookies.remove("promocode");
        } else {
          alert(err);
        }
      }
    } else {
      if (!$cookies.get("promocode")) {
        $cookies.set("promocode", to.query.promocode);
      }
      if (!$cookies.get("promocodeExistingID")) {
        $cookies.set("promocodeExistingID", to.query.ec_id);
      }
    }
  }
  // if (to.query.token) {
  //   localStorage.setItem('accessToken', `${to.query.token}`)
  //   localStorage.setItem('token', `Bearer ${to.query.token}`)
  //   localStorage.setItem('isAuthenticated', true)
  //   localStorage.setItem('refreshToken', true)
  // }

  // if (to.meta.story) document.title = DEAFULT_TITLE + ' | ' + to.meta.story(to)
  // else document.title = DEAFULT_TITLE + ' | ' + to.meta.title
  document.title = DEAFULT_TITLE + " | " + to.meta.title;
  if (to.path == "/contact-us") next();
  else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !JSON.parse(localStorage.getItem("accessToken"))
  )
    next({ name: "login" });
  else next();
});

export default router;
