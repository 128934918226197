<template>
  <div class="billing-address">
    <h3 class="med-font">Billing Address</h3>
    <b-row class="mt-4">
      <b-col>
        <label for="address1" class="input-label">Street Address</label>
        <vue-google-autocomplete
          id="map"
          ref="streetAddress"
          classname="form-control"
          placeholder="Street address"
          v-on:placechanged="getAddressData"
          country="us"
          class="fs-mask onboarding-input"
          @inputChange="onStreetAddressInputChange"
          @change="onStreetAddressChange"
        >
        </vue-google-autocomplete>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <label for="address2" class="input-label">Apartment/Suite</label>
        <b-form-input
          @input="fromAutocomplete = false"
          class="fs-mask onboarding-input"
          v-model="billingaddress.address2"
          name="address2"
          placeholder="Apartment/Suite"
        />
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <label for="city" class="input-label">City</label>
        <b-form-input
          @input="fromAutocomplete = false"
          class="fs-mask onboarding-input"
          v-model="billingaddress.city"
          name="city"
          placeholder="City"
        />
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12" lg="6">
        <label for="state" class="input-label">State</label>
        <b-form-select
          @input="fromAutocomplete = false"
          class="fs-mask onboarding-input"
          v-model="billingaddress.state"
          :options="stateNames"
          name="state"
          placeholder="State"
        />
      </b-col>
      <b-col cols="12" lg="6">
        <label for="zipcode" class="input-label">Zip Code</label>
        <b-form-input
          @input="fromAutocomplete = false"
          class="fs-mask onboarding-input"
          v-model="billingaddress.zipcode"
          name="zipcode"
          placeholder="Zip Code"
        />
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <label for="phoneno" class="input-label">Phone</label>
        <b-form-input
          @input="fromAutocomplete = false"
          class="fs-mask onboarding-input"
          v-model="billingaddress.phoneno"
          name="phoneno"
          placeholder="Phone No."
          :formatter="formatPhoneNumber"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { LookUpService } from "@/services/lookup.service";
import mixins from "@/components/form/mixins";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  mixins: [mixins],
  data() {
    return {
      selectedState: {},
      states: [],
      stateNames: [],
      billingaddress: {
        address1: "",
        address2: null,
        city: "",
        country: "US",
        state: "",
        zipcode: null,
        phoneno: null,
      },
      fromAutocomplete: false,
    };
  },
  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    async getAddressData(addressData, placeResultData, id) {
      this.fromAutocomplete = true;
      if (addressData.street_number) {
        this.billingaddress.address1 = `${addressData.street_number} ${addressData.route}`;
      } else {
        this.billingaddress.address1 = addressData.route;
      }
      this.billingaddress.city = addressData.locality
        ? addressData.locality
        : "";
      this.billingaddress.zipcode = addressData.postal_code
        ? addressData.postal_code
        : "";

      this.selectedState = this.states.find(
        (state) => state.id == addressData.administrative_area_level_1
      );
      this.billingaddress.state = this.selectedState.name;
    },
    onStreetAddressChange(val) {
      this.$refs.streetAddress.update(this.billingaddress.address1);
    },
    onStreetAddressInputChange(val) {
      this.billingaddress.address1 = this.$refs.streetAddress.autocompleteText;
    },
  },
  async mounted() {
    const { data } = await LookUpService.getLookUp("state");
    this.states = data;
    for (let state of data) {
      this.stateNames.push(state.name);
    }
  },
};
</script>

<style></style>
