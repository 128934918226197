var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "multiple-answer" },
    [
      _c("h2", { staticClass: "question-title" }, [
        _vm._v(" " + _vm._s(_vm.body.question) + " ")
      ]),
      _vm.body.header ? _c("p", [_vm._v(_vm._s(_vm.body.header))]) : _vm._e(),
      _vm.body.allowempty
        ? _c("w-checkbox", {
            attrs: {
              type: "checkbox",
              id: "none",
              value: "true",
              label: "None"
            },
            model: {
              value: _vm.none,
              callback: function($$v) {
                _vm.none = $$v
              },
              expression: "none"
            }
          })
        : _vm._e(),
      _vm._l(_vm.body.answerlist, function(list, i) {
        return _c("w-checkbox", {
          key: list.name + i,
          attrs: {
            type: "checkbox",
            id: list.id,
            value: list.id,
            label: list.name,
            description: list.description
          },
          scopedSlots: _vm._u(
            [
              list.image
                ? {
                    key: "image",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "img-holder" }, [
                          _c("img", {
                            attrs: { src: list.image, alt: list.name }
                          })
                        ])
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          ),
          model: {
            value: _vm.answers,
            callback: function($$v) {
              _vm.answers = $$v
            },
            expression: "answers"
          }
        })
      }),
      _vm.body.hasothersarray
        ? _c("w-checkbox", {
            attrs: {
              type: "checkbox",
              id: "others",
              value: "true",
              label: "Other"
            },
            model: {
              value: _vm.activateOthers,
              callback: function($$v) {
                _vm.activateOthers = $$v
              },
              expression: "activateOthers"
            }
          })
        : _vm._e(),
      _vm.activateOthers
        ? _c("Array", {
            attrs: { body: _vm.arrayBody, othersField: _vm.others },
            on: { submitAnswer: _vm.checkAnswer, alert: _vm.alert }
          })
        : _vm._e(),
      !_vm.activateOthers
        ? _c(
            "b-button",
            {
              attrs: {
                variant: "info",
                block: "",
                pill: "",
                disabled: _vm.disabled
              },
              on: { click: _vm.checkAnswer }
            },
            [_vm._v("Continue")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }