<template>
  <div
    :style="{
      backgroundColor:
        step === 2 ? '#d5f4ec' : step === 4 ? '#96B5AD' : 'white',
    }"
  >
    <div
      class="mx-5 pt-5 position-relative"
      style="z-index: 2"
      v-if="step !== 4"
    >
      <b-button variant="link" class="mt-3" to="/account-settings">
        <span
          class="next-arrow-purple"
          style="transform: rotate(180deg)"
        ></span>
        Back to profile
      </b-button>
    </div>
    <b-container
      v-if="step === 1"
      class="referral-main my-5 my-md-6 step-container"
    >
      <div class="step-one-content">
        <b-row>
          <b-col class="col-auto">
            <h3
              class="display-4 text-primary heading"
              style="line-height: 2rem"
            >
              Sure you don’t want to just take a break?
            </h3>
            <h2 class="mt-4">Sometimes you just need a break.</h2>
          </b-col>
        </b-row>
        <b-form-group class="my-5" v-slot="{ takeABreak }">
          <b-form-radio
            class="shipping-radio"
            v-model="breakTime"
            :aria-describedby="takeABreak"
            name="take-a-break"
            value="30"
          >
            30 days
          </b-form-radio>
          <b-form-radio
            class="shipping-radio"
            v-model="breakTime"
            :aria-describedby="takeABreak"
            name="take-a-break"
            value="60"
          >
            60 days
          </b-form-radio>
          <b-form-radio
            class="shipping-radio"
            v-model="breakTime"
            :aria-describedby="takeABreak"
            name="take-a-break"
            value="90"
          >
            90 days
          </b-form-radio>
        </b-form-group>
        <b-row class="text-center" align-h="center" no-gutters>
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              @click="$router.push('/account-settings')"
              variant="info"
              style="max-width: 300px"
            >
              keep my subscription
            </b-button>
          </b-col>
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              @click="stopSubscription"
              variant="outline-info"
              style="max-width: 300px"
              :disabled="isProcessing"
            >
              Pause subscription
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              v-if="data.availedstopcancel"
              pill
              block
              variant="link"
              style="max-width: 300px"
              :disabled="isProcessing"
              @click="removeSubscription"
            >
              continue to cancel
            </b-button>
            <b-button
              v-else
              pill
              block
              variant="link"
              style="max-width: 300px"
              @click="step = 2"
            >
              continue to cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <div v-if="step === 2" class="upsell-container pt-6">
      <div class="position-relative dhea-img-container">
        <img src="@/assets/images/cancel-subscription/dhea.png" alt="" />
      </div>
      <div class="step-two-content">
        <b-row>
          <b-col>
            <b-alert variant="warning" :show="alert.show">{{
              alert.message
            }}</b-alert>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col class="col-auto">
            <h3
              class="display-4 text-primary heading"
              style="line-height: 2.5rem"
            >
              Hang on, keep your subscription and get 20% off your next
              shipment!
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              @click="preventSubscription"
              variant="info"
              style="max-width: 300px"
              :disabled="isProcessing"
            >
              get 20% off
            </b-button>
          </b-col>
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              variant="outline-info"
              style="max-width: 300px"
              @click="step = 1"
              :disabled="isProcessing"
            >
              Pause subscription
            </b-button>
          </b-col>
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              variant="link"
              style="max-width: 300px"
              @click="step = 3"
            >
              continue to cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-container
      v-if="step === 3"
      class="referral-main my-5 my-md-6 step-container"
    >
      <div class="step-three-content">
        <b-row>
          <b-col class="col-auto">
            <h3
              class="display-4 text-primary heading"
              style="line-height: 2rem"
            >
              We're sorry to see you go...
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-alert variant="warning" :show="alert.show">{{
              alert.message
            }}</b-alert>
          </b-col>
        </b-row>
        <b-row align-h="center" align-v="center" class="mt-4">
          <b-col class="mb-5 mx-4 mx-md-0 mb-md-0">
            <div role="group" class="mb-4">
              <label for="cancellationreason" class="d-letter-spacing">
                Why are you cancelling?
              </label>
              <v-select
                append-to-body
                :calculate-position="withPopper"
                v-model="selectedReason"
                label="text"
                :options="cancellationReasons"
              >
                <template #open-indicator="{ attributes }">
                  <b-icon v-bind="attributes" icon="chevron-down"></b-icon>
                </template>
              </v-select>
            </div>
            <div role="group" class="mb-4">
              <label for="furtherexplanation" class="d-letter-spacing">
                Further explanation
              </label>
              <b-form-textarea
                id="textarea"
                v-model="furtherExplanation"
                rows="6"
                max-rows="6"
              ></b-form-textarea>
            </div>
            <div class="mt-5">
              <b-col cols="12" class="mb-3">
                <b-button
                  pill
                  block
                  variant="outline-info"
                  style="max-width: 300px"
                  :disabled="isProcessing"
                  @click="removeSubscription"
                >
                  Submit cancellation
                </b-button>
              </b-col>
              <b-col cols="12" class="mb-3">
                <b-button
                  pill
                  block
                  variant="info"
                  style="max-width: 300px"
                  @click="preventSubscription"
                  :disabled="isProcessing"
                >
                  get 20% off
                </b-button>
              </b-col>
              <b-col cols="12" class="mb-3">
                <b-button
                  pill
                  block
                  variant="link"
                  style="max-width: 300px"
                  @click="step = 1"
                  :disabled="isProcessing"
                >
                  Pause subscription
                </b-button>
              </b-col>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <div v-if="step === 4" class="cancelled-container pt-6">
      <div class="position-relative ep-img-container">
        <img
          src="@/assets/images/cancel-subscription/ep-body-cream.jpeg"
          alt=""
        />
      </div>
      <div class="step-four-content" style="">
        <b-row class="mb-4">
          <b-col class="col-auto">
            <h3
              class="display-4 heading"
              style="line-height: 2.5rem; color: #0c3523"
            >
              We have successfully canceled your account. We are sorry that
              Winona wasn't the right fit for you.
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              variant="info"
              style="
                max-width: 300px;
                background: #0c3523;
                border-color: #0c3523;
              "
              @click="$router.push('/account-settings')"
            >
              Back to profile
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { UsersService } from "@/services/users.service";
import { TransactionService } from "@/services/transaction.service";
import popper from "@/assets/js/popper.js";

export default {
  mixins: [popper],
  data() {
    return {
      analyticsId: "",
      step: 1,
      alert: {
        show: false,
        message: "",
      },
      data: {},
      cancellationReasons: [
        "I haven't found my desired results",
        "It is too expensive",
        "I am using a different prescription service",
        "Bad reaction to medication",
        "Can't get in touch with the doctor",
        "Bad customer service",
        "Not customized enough",
        "I keep forgetting to take it",
        "Too many emails and sms",
      ],
      selectedReason: "I haven't found my desired results",
      furtherExplanation: "",
      breakTime: "30",
      isProcessing: false,
    };
  },
  watch: {
    step() {
      this.alert = {
        show: false,
        message: "",
      };
    },
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      try {
        this.isProcessing = true;
        let res = await UsersService.getCurrent();
        if (res && res.data) {
          this.data = res.data;
          this.analyticsId = res.data.usercustomid
            ? res.data.usercustomid
            : res.data.id;
        }
        this.isProcessing = false;
      } catch (error) {
        if (error.message) {
          console.log("Error", error.message);
        }
      }
    },
    async stopSubscription() {
      this.alert.show = false;
      let newDate = this.moment().add(this.breakTime, "days");
      let resumeDate = this.moment(newDate, "MM/DD/YYYY HH:mm").unix();
      let param = {
        resumecollection: resumeDate,
      };

      try {
        this.isProcessing = true;
        let res = await TransactionService.pauseSubscription(
          this.data.id,
          param
        );
        this.$router.push("/account-settings");
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }
      } finally {
        this.isProcessing = false;
      }
    },
    async preventSubscription() {
      console.log("attempt to 20% discount subscription");
      try {
        this.isProcessing = true;
        let res = await TransactionService.preventCancelSubscription(
          this.data.id
        );
        this.$router.push("/account-settings?discount=true");
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }
      } finally {
        this.isProcessing = false;
      }
    },
    async removeSubscription() {
      console.log("attempt to cancel subscription");

      window.analytics.track(
        "Cancelled Subscription",
        {
          customerId: this.analyticsId,
          ...(await this.$store.dispatch("getDeviceDetailsForContext")),
        },
        {
          context: await this.$store.dispatch("geDeviceDetails"),
        }
      );

      try {
        this.isProcessing = true;
        let res = await TransactionService.cancelSubscription(this.data.id, {
          reason: this.selectedReason,
          notes: this.furtherExplanation,
        });
        this.step = 4;
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }
      } finally {
        this.isProcessing = false;
      }
    },
    showAlert(message, type = null) {
      this.alert = {
        show: true,
        type: "warning",
        message: message,
      };
    },
  },
};
</script>

<style scoped>
/deep/.form-control,
/deep/.vs__dropdown-toggle {
  padding: 0;
}

/deep/.vs__selected {
  margin: 0 !important;
}
</style>

<style lang="scss" scoped>
.step-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-one-content {
  max-width: 500px;
}

.step-two-content,
.step-four-content {
  max-width: 650px;
  width: 50%;
}

.step-four-content {
  align-self: center;
}

.step-three-content {
  max-width: 700px;
  width: 100%;
}

.dhea-img-container {
  width: 50%;
  height: calc(100vh - (67px + 89px));
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ep-img-container {
  width: 50%;
  height: calc(100vh - (67px));
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.dhea-img-container img,
.ep-img-container img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.cancelled-container {
  display: flex;
  height: calc(100vh - 67px);
}

.upsell-container {
  display: flex;
  height: calc(100vh - (67px + 89px));
}

@media (max-width: 1200px) {
  .dhea-img-container,
  .ep-img-container {
    width: 100%;
    height: auto;
    display: block;

    img {
      width: 50%;
    }
  }

  .step-two-content,
  .step-four-content {
    width: 100%;
    max-width: initial;
    padding: 0 1rem;
  }

  .upsell-container,
  .cancelled-container {
    flex-direction: column-reverse;
    height: auto;
  }
}
</style>
