var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h3", { staticClass: "bold-font" }, [
        _vm._v(" Estrogen pills, patches and body creams: ")
      ]),
      _c("p", [
        _vm._v(
          " Primarily used for the relief of menopause and perimenopause symptoms. Estrogen replacement can reduce hot flashes and night sweats, treat vulvar and vaginal atrophy (thinning and drying of the vaginal tissues), prevent and treat osteoporosis and osteopenia (weakening of the bones), treat hypoestrogenism (low estrogen) due to menopause, surgical removal of the ovaries, and primary ovarian failure. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The most common side effects for women who take estrogen are headaches, breast tenderness, nausea, vaginal spotting, and bloating. For most women these side effects will go away after a few weeks of use. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Serious side effects are rare, much less than 1%, they include cancer of the uterus, breast, and ovaries, stroke, and blood clots, and gallbladder disease. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Women with a uterus should use progesterone while using estrogen. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Women that should not use estrogen include women that are pregnant, breast feeding, have cancer of the breast, uterus, ovaries, or cervix, had a stroke or heart attack in the last year, have or have had blood clots, have active liver disease, or are allergic to estrogen. "
        )
      ]),
      _c("br"),
      _c("h3", { staticClass: "bold-font" }, [_vm._v("Progesterone pills:")]),
      _c("p", [
        _vm._v(
          " Primarily used to prevent endometrial hyperplasia (overgrowth of the uterine lining) and uterine cancer in women receiving estrogen that still have a uterus. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The most common side effects for women who take progesterone are headaches, breast tenderness, nausea, vaginal spotting, and bloating. For most women these side effects will go away after a few weeks of use. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Serious side effects are rare, much less than 1%, they include cancer of the uterus, breast, and ovaries, stroke, and blood clots, and gallbladder disease. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Women that should not use progesterone include women that are pregnant, breast feeding, have cancer of the breast, uterus, ovaries, or cervix, had a stroke or heart attack in the last year, have or have had blood clots, have active liver disease, or are allergic to progesterone. This product contains peanut oil and should not be used by women allergic to peanuts. "
        ),
        _c("br")
      ]),
      _c("h3", { staticClass: "bold-font" }, [
        _vm._v("Progesterone body cream:")
      ]),
      _c("p", [
        _vm._v(
          " Primarily used to prevent endometrial hyperplasia (overgrowth of the uterine lining) and uterine cancer in women receiving estrogen that still have a uterus. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " The most common side effects for women who take progesterone are headaches, breast tenderness, nausea, vaginal spotting, and bloating. For most women these side effects will go away after a few weeks of use. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Serious side effects are rare, much less than 1%, they include cancer of the uterus, breast, and ovaries, stroke, and blood clots, and gallbladder disease. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          " Women that should not use progesterone include women that are pregnant, breast feeding, have cancer of the breast, uterus, ovaries, or cervix, had a stroke or heart attack in the last year, have or have had blood clots, have active liver disease, or are allergic to progesterone. "
        ),
        _c("br")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }