<template>
  <div class="eligibilityWaitlist">
    <h2 class="mb-3 question-title">
      At the moment, we don't offer treatment in your state
    </h2>
    <p>
      Our goal is to open up in your state soon. In the meantime, would you be
      interested in joining our waitlist?
    </p>
    <w-checkbox
      type="radio"
      label="Yes"
      id="yes"
      value="true"
      v-model="waitlist"
      @change="textbox = true"
    />
    <w-checkbox
      type="radio"
      label="No"
      id="no"
      value="false"
      v-model="waitlist"
      @change="submitAnswer"
    />
    <div v-if="textbox">
      <label for="email">What is your email address?</label>
      <b-form-input
        class="fs-mask"
        v-model="email"
        name="email"
        placeholder="Email"
      />
      <b-button pill variant="info" class="px-5 mt-4" @click="submitAnswer">
        Continue
      </b-button>
    </div>
  </div>
</template>

<script>
import { OnboardingService } from "@/services/onboarding.service";

export default {
  props: {
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    state: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      waitlist: null,
      textbox: false,
      email: "",
    };
  },
  methods: {
    async submitAnswer() {
      if (this.waitlist == "true" && this.email == "") {
        this.$emit("showAlert", "Please enter in your email.");
        return;
      }

      if (this.waitlist == "true") {
        // send email data
        const body = { email: this.email, state: this.state };
        console.log(body);
        const { data } = await OnboardingService.collectEmail(body);

        this.$emit("setStep", "thankyou");

        window.analytics.track(
          "Onboarding Screen Viewed",
          {
            screen: "1.1.1.1-A",
            ...(await this.$store.dispatch("getDeviceDetailsForContext")),
          },
          {
            context: await this.$store.dispatch("geDeviceDetails"),
          }
        );
      } else {
        window.location.href = "https://bywinona.com";
      }
    },
  },
  async mounted() {
    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        // customerId: <Customer ID.>,
        screen: "1.1.1-A",
        onboardingflow: this.onboardingflow,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
      },
      {
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );
  },
};
</script>
