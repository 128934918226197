var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticStyle: { border: "0" }, attrs: { "no-body": "" } },
    [
      _c(
        "b-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "b-col",
            { staticClass: "mr-1", attrs: { cols: "auto" } },
            [
              _c("b-form-checkbox", {
                staticClass: "product-reveal-checkbox",
                attrs: {
                  id: "checkbox-" + _vm.product.id,
                  name: "product-id",
                  value: true,
                  "unchecked-value": false
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-row",
                {
                  staticStyle: {
                    position: "relative",
                    "background-color": "#f8f8f8"
                  },
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("b-col", { attrs: { cols: "12", lg: "4" } }, [
                    _c("div", { staticClass: "img-holder" }, [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.product.image == null
                              ? require("@/assets/images/dashboard/articlebg.png")
                              : _vm.product.image
                        }
                      })
                    ])
                  ]),
                  _c(
                    "b-col",
                    {
                      staticClass: "px-4 py-4",
                      attrs: { cols: "12", lg: "8" }
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "text-default bold-font",
                          staticStyle: { "letter-spacing": "0.01em" }
                        },
                        [_vm._v(" " + _vm._s(_vm.product.name) + " ")]
                      ),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(_vm._s(_vm.descriptionText))
                      ]),
                      _c("small", { staticClass: "d-block mb-3" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.showDescription = !_vm.showDescription
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.readMoreText))]
                        )
                      ]),
                      _c(
                        "ul",
                        { staticClass: "list-unstyled list-inline" },
                        _vm._l(_vm.product.availablepricing, function(pricing) {
                          return _c("li", { key: pricing.pricingid }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedpricing,
                                  expression: "selectedpricing"
                                }
                              ],
                              attrs: {
                                id: pricing.pricingid,
                                type: "radio",
                                name: _vm.product.id
                              },
                              domProps: {
                                value: pricing.pricingid,
                                checked: _vm._q(
                                  _vm.selectedpricing,
                                  pricing.pricingid
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setPricing(pricing.pricingid)
                                },
                                change: function($event) {
                                  _vm.selectedpricing = pricing.pricingid
                                }
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "subscription-label",
                                attrs: { for: pricing.pricingid }
                              },
                              [
                                _vm._v(
                                  " $" +
                                    _vm._s(pricing.price) +
                                    " - " +
                                    _vm._s(pricing.name) +
                                    " "
                                )
                              ]
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }