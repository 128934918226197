<template>
  <div class="checkout-summary">
    <OnboardingModal
      size="lg"
      v-if="showModal"
      :body="modalBody"
      @submitAnswer="openPharmSelect"
    />

    <SideModal
      :show="showEditModal"
      :showClose="true"
      @exit="showEditModal = false"
      body-class="terms-body"
    >
      <template #body>
        <h2 class="question-title with-subtext">
          Edit your prescribed treatments
        </h2>
        <h3 class="sub-text mb-6">
          Change of mind? Here are the recommended treatments:
        </h3>
        <EditCheckout
          :desired-plan="desiredPlan"
          :product-reveal="productreveal"
          :treatment-form="treatmentform"
          @close-edit="showEditModal = false"
          @update-checkout="
            $emit('reload-summary');
            showEditModal = false;
          "
          @get-product-reveal="getProductReveal"
        />
      </template>
    </SideModal>
    <div class="bg-overlay" @click="showEditModal = false"></div>

    <SideModal
      :show="riskModal"
      @exit="hideRiskModal()"
      header-class="terms-title"
      body-class="terms-body"
    >
      <template #header>
        <h1 class="h2 mb-0 heading text-primary">Safety Information</h1>
      </template>
      <template #body>
        <Risk />
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button
              pill
              variant="info"
              @click="agreeTerms()"
              style="min-width: 250px"
            >
              I Agree
            </b-button>
          </b-col>
        </b-row>
      </template>
    </SideModal>
    <div class="bg-overlay" @click="hideRiskModal()"></div>

    <div
      v-if="
        !nonrx && body.hasOwnProperty('desiredplan') && desiredPlan.length > 0
      "
    >
      <h2 class="question-title with-subtext">Your visit summary</h2>
      <h3 class="sub-text mb-5">
        Almost done! Here’s your personalized treatment plan for the doctor to
        review. Our board certified doctors are here to determine the right
        treatment plan for you. In the next step, you’ll add billing information
        to your account and then a Winona doctor will review your medical
        history and address any questions.
      </h3>

      <div class="d-flex justify-content-between">
        <h3 class="sub-text mb-4" style="margin-top: 10px">
          Prescription Treatment
        </h3>
        <span class="sub-text mb-4" style="margin-top: 10px">
          <a
            href="javascript:void(0)"
            :disabled="loading"
            style="color: #1a6a72"
            @click="showEditModal = true"
          >
            <u>EDIT</u>
          </a>
        </span>
      </div>
      <b-card
        no-body
        v-for="med in desiredPlan.filter(
          (x) =>
            x.type === undefined || (x.type != undefined && x.type === 'RX')
        )"
        :key="med.id"
        style="background-color: rgba(242, 242, 242, 0.5)"
        class="py-2"
      >
        <b-row no-gutters align-v="stretch">
          <b-col
            :cols="med.image ? '8' : '12'"
            class="d-flex flex-column justify-content-between pl-4 pr-2 h-100"
            align-self="center"
          >
            <div class="mb-4">
              <h3 class="mb-2 med-font">{{ med.name }}</h3>
              <p>{{ med.pricingdetail.nickname }}</p>
            </div>
            <p class="med-font mb-0">
              <!-- med.pricingdetail.hidebillingfrequency -->
              <span
                v-if="med.pricingdetail.hasOwnProperty('hidebillingfrequency')"
              >
                ${{ med.price }}
              </span>
              <span v-else>
                {{
                  formatPricing(
                    med.pricingdetail.type,
                    med.price,
                    med.pricingdetail.recurring
                  )
                }}
              </span>
            </p>
          </b-col>
          <!-- this is for image placeholder  -->
          <b-col cols="4" class="p-0" v-if="med.image">
            <div class="img-holder">
              <img :src="med.image" :alt="med.name" />
            </div>
          </b-col>
        </b-row>
      </b-card>
      <p class="mb-5 pb-3" style="margin-top: -10px">
        You won't be charged for or shipped this prescription product unless a
        doctor prescribes it to you.
      </p>
    </div>
    <!-- TODO: Hide add-ons for now -->

    <!-- 
    <h3><b>{{ !nonrx ? 'Add-ons' : 'Your Order' }}</b></h3>
    <div v-if="body.hasOwnProperty('desiredaddon') && desiredAddon.length > 0">
      <b-card no-body v-for="med in desiredAddon" :key="med.id" style="background-color: rgba(242, 242, 242, 0.5)">
        <b-row no-gutters align-v="stretch" style="min-height: 100%">
          <b-col :cols="med.image ? '8' : '12'" class="p-4" align-self="center">
            <h3 class="mb-4"><b>{{ med.medicinename }}</b></h3>
            <b class="mb-0">{{ formatPricing(med.type, med.price, med.recurring) }}</b>
          </b-col>
          <b-col cols="4" class="p-0" v-if="med.image">
            <div class="img-holder" >
              <img :src="med.image" :alt="med.name">
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <p v-if="body.hasOwnProperty('desiredaddon') && desiredAddon.length == 0">You have not selected any {{ !nonrx ? 'add-ons' : 'non-rx products' }}.</p> 
    -->

    <!-- <b-card no-body class="p-3">
      <b-row class="pt-3 mb-4" v-for="med in body.desiredaddon" :key="med.id">
        <b-col>
          <h3><b>{{ med.medicinename }}</b></h3>
          <h3>{{ med.nickname }}</h3>
          <span>${{ med.price }} per {{ med.shippingmethod }}s</span>
        </b-col>
      </b-row>
    </b-card> -->
    <!-- <div v-if="!nonrx">
      <h3><b>Visit Fee</b></h3>
      <b-row>
        <b-col>
          There is an initial $20 provider fee. This fee is credited back to you and added directly to the cost of any prescription medications you have fulfilled through the Winona Pharmacy.
        </b-col>
      </b-row>
    </div> -->
    <!-- <div class="mt-5">
      <h3 class="sub-text"><b>Pharmacy</b></h3>
      <b-row>
        <b-col v-if="selectedPharmacy && body.pharmacyid != ''">
          <span>{{ pharmacy.StoreName }} - {{ pharmacy.City }}</span> <br>
          <span>{{ pharmacy.Address1 }},<span v-if="pharmacy.Address2">{{ pharmacy.Address2 }},</span>{{ pharmacy.ZipCode }}</span> <br>
          <a class="changePharmacy" href="#" @click="clearPharmacy">Choose Winona Pharmacy</a>
        </b-col>
        <b-col v-else>
          <span>When you fill a prescription with Winona you will be receiving the highest quality treatments from our Winona Pharmacy Network delivered right to your door for free!</span> 
          <a class="selectPharmacy" @click="toggleModal" v-if="!nonrx">Select Different Pharmacy</a>
        </b-col>
      </b-row>
    </div> -->
    <h3 class="mt-5 sub-text med-font">Added Benefits</h3>
    <b-row>
      <b-col cols="3" md="auto">
        <img src="@/assets/images/onboarding/shipping.svg" />
      </b-col>
      <b-col cols="9" class="pt-3 pr-0">
        <h4 class="med-font">Free Shipping</h4>
        <p class="mb-0">
          We offer fast shipping so you have your medication when you need it.
        </p>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="3" md="auto">
        <img src="@/assets/images/onboarding/support.svg" />
      </b-col>
      <b-col cols="9" class="pt-3 pr-0">
        <h4 class="med-font">Continued Support</h4>
        <p class="mb-0">
          As a Winona patient, you will receive continuous, personalized care
          from our healthcare team who are specially trained to treat your
          symptoms.
        </p>
      </b-col>
    </b-row>

    <b-form-checkbox
      id="risk"
      class="checkbox-label mt-4"
      v-model="risk"
      name="checkbox-2"
    >
      <span>I have read and understand the </span>
      <a
        href="javascript:void(0)"
        class="text-underline"
        @click="showRiskModal()"
        >Safety Information</a
      >
      associated with these treatments.
    </b-form-checkbox>

    <b-button
      pill
      block
      variant="info"
      class="mt-4"
      @click="confirmContinue"
      :disabled="buttonDisabled"
    >
      {{ loading ? "Loading" : !saving ? "Continue" : "Saving" }}</b-button
    >
  </div>
</template>

<script>
import { OnboardingService } from "@/services/onboarding.service";
import OnboardingModal from "@/components/onboarding/questions/Modal";
import Risk from "@/components/partials/Risk";
import SideModal from "@/components/partials/SideModal";
import EditCheckout from "@/components/onboarding/checkout/EditCheckout";

export default {
  props: {
    body: Object,
    nonrx: {
      type: Boolean,
      default: false,
    },
    selectedPharmacy: {
      type: Boolean,
      default: false,
    },
    pharmacy: Object,
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    analyticsId: {
      type: String,
      default: "",
    },
  },
  components: {
    OnboardingModal,
    Risk,
    SideModal,
    EditCheckout,
  },
  data() {
    return {
      desiredAddon: [],
      desiredPlan: [],
      productreveal: [],
      loading: false,
      showModal: false,
      riskModal: false,
      treatmentform: "",
      risk: this.pharmacy.Risk,
      showEditModal: false,
      modalBody: {
        title: "Need an alternate pharmacy?",
        description:
          "If you pick a pharmacy outside of the Winona Pharmacy Network and qualify for a prescription, you will be charged $50 for the platform fee and we will not be able to guarantee the price of the medication and provide free monthly delivery.",
        answerlist: [
          {
            id: "winonaPharm",
            name: "No, pick Winona Pharmacy",
          },
          {
            id: "other",
            name: "Yes, I'm sure",
          },
        ],
      },
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    confirmContinue() {
      if (this.risk) {
        this.$emit("nextStep");
      } else {
        this.elementTag("hidden", true);
      }
    },
    getInterval(pricingdetails) {
      // let appendedArray = pricingdetails.onetimepayment.concat(pricingdetails.subscription)
      // const details = appendedArray.filter((pricing) => pricing.price == lowestPrice)
      // console.log(details)
      // return `${details[0].recurring.interval_count} ${details[0].recurring.interval}s`;
      if (pricingdetails.type == "recurring") {
        return `${pricingdetails.recurring.interval_count} ${pricingdetails.recurring.interval}s`;
      }
    },
    async getProductReveal() {
      const getReveal = await OnboardingService.getProductReveal();
      this.productreveal = getReveal.data;
    },
    clearPharmacy() {
      this.$emit("clearPharmacy");
    },
    openPharmSelect(questionid = null, answer = null) {
      if (answer == "winonaPharm") {
        this.$emit("clearPharmacy");
        return;
      }
      this.$emit("goToStep", "pharmacy-select");
    },
    formatPricing(type, price, recurring) {
      if (type == "one_time") {
        return `One time payment of $${price}`;
      } else if (recurring.interval == "day") {
        let isMoreThanOneMonth = recurring.interval_count / 30 > 1;
        let plural = isMoreThanOneMonth ? "s" : "";
        let interval = isMoreThanOneMonth
          ? Math.ceil(recurring.interval_count / 30) + " "
          : "";
        return `$${price}/${interval}month${plural}`;
      } else {
        return `$${price}/${recurring.interval_count}${recurring.interval}(s)`;
      }
    },
    agreeTerms() {
      this.risk = true;
      this.elementTag(null, false);
    },
    showRiskModal() {
      this.elementTag("hidden", true);
    },
    hideRiskModal() {
      this.elementTag(null, false);
    },
    elementTag(style, Boolean) {
      this.riskModal = Boolean;
      const htmlTag = document.documentElement;
      htmlTag.style.overflow = style;
      htmlTag.scrollTop = htmlTag.scrollHeight;
    },
    closeModal(e) {
      if (e.trigger == "backdrop") {
        e.preventDefault();
        return;
      }
    },
  },
  watch: {
    body() {
      this.desiredPlan = [];
      this.loading = true;
      this.desiredAddon = this.body.desiredaddon;
      this.risk = this.body.agreedtorisks;
      // console.log(this.risk)
      // Desired Plan
      for (let med of this.body.desiredplan) {
        //console.log("med", med)
        let found = this.body.desiredaddon.filter(
          (item) => item.productid === med.productid
        );
        // console.log('found', found)
        if (found && found.length > 0) {
        } else {
          this.desiredPlan.push(med);
        }
      }
      this.loading = false;
    },
    showEditModal() {
      const htmlTag = document.documentElement;
      if (this.showEditModal) {
        htmlTag.style.overflow = "hidden";
      } else {
        htmlTag.style.overflow = null;
      }
    },
  },
  async mounted() {
    try {
      window.analytics.track(
        "Onboarding Screen Viewed",
        {
          customerId: this.analyticsId,
          screen: "5.1-A",
          firstname: this.body.firstname,
          lastname: this.body.lastname,
          onboardingflow: this.onboardingflow,
          ...(await this.$store.dispatch("getDeviceDetailsForContext")),
        },
        {
          context: await this.$store.dispatch("geDeviceDetails"),
        }
      );
      this.loading = true;
      this.desiredAddon = this.body.desiredaddon;
      this.risk = this.body.agreedtorisks;
      // console.log(this.risk)
      // Desired Plan
      for (let med of this.body.desiredplan) {
        //console.log("med", med)
        let found = this.body.desiredaddon.filter(
          (item) => item.productid === med.productid
        );
        // console.log('found', found)
        if (found && found.length > 0) {
        } else {
          this.desiredPlan.push(med);
        }
      }

      this.getProductReveal();
      this.treatmentform = this.body.treatmentform;

      this.loading = false;
    } catch {
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
a.disabled {
  pointer-events: none;
}

/deep/.modal-header {
  border-bottom: 0;
  align-items: center;
}

/deep/.modal-footer {
  border-top: 0;
  align-items: center;
}

/deep/.terms-modal .modal-header {
  padding-left: 10px;
  padding-right: 10px;
  font-family: "hattonmedium";
  letter-spacing: initial;
}
</style>

<style lang="scss" scoped>
.changePharmacy {
  color: #828282;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $info;
  }
}
.selectPharmacy {
  color: #19191c;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: lighten(#19191c, 25%);
  }
}

.img-holder {
  width: 100% !important;
  height: 140px !important;
  text-align: right;
  // padding-right: 2rem;
  margin-right: 0 !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
