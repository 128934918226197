<template>
  <div id="app" v-if="hydrated" :class="formControl ? 'login-page' : ''">
    <!-- Login and Sign Up -->
    <template v-if="formControl">
      <b-container
        fluid
        class="login-cont cover-image"
        :style="{
          backgroundImage:
            this.$route.name == 'login' || this.$route.name == 'new-password'
              ? 'url(' + require('@/assets/images/login/bg.png') + ')'
              : this.$route.name == 'forgot-password'
              ? 'url(' + require('@/assets/images/login/fp.png') + ')'
              : '',
        }"
      >
        <b-row align-v="stretch" style="height: 100vh">
          <b-col cols="12" lg="5" xxl="4">
            <b-row style="height: 100%" align-v="stretch">
              <b-col cols="12" align-self="start">
                <b-navbar-brand to="/website" class="c-logo">
                  <img src="@/assets/images/logo/logo_w_black.svg" alt="" />
                </b-navbar-brand>
              </b-col>
              <b-col cols="12" align-self="start">
                <vue-page-transition>
                  <router-view />
                </vue-page-transition>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" align-self="end" class="pb-4">
            <b-row align-v="center">
              <b-col cols="6" lg="6">
                <p class="mb-0">©WINONA {{ getYear }}</p>
              </b-col>
              <b-col cols="6" lg="6" class="text-right d-none d-md-block">
                <b-row align-h="end">
                  <b-col cols="6" lg="4" class="text-right">
                    <p class="d-none d-lg-block mb-0">
                      <router-link to="/website" custom v-slot="{ navigate }">
                        <a
                          href="javascript:void(0)"
                          role="link"
                          @click="navigate"
                          class="text-white"
                        >
                          BYWINONA.COM</a
                        >
                      </router-link>
                    </p>
                    <p class="d-block d-lg-none mb-0">
                      <router-link to="/website" custom v-slot="{ navigate }">
                        <a
                          href="javascript:void(0)"
                          role="link"
                          @click="navigate"
                          class="text-primary"
                        >
                          BYWINONA.COM
                        </a>
                      </router-link>
                    </p>
                  </b-col>
                  <b-col cols="6" lg="4">
                    <p class="d-none d-lg-block mb-0">
                      <router-link
                        to="/website/contact"
                        custom
                        v-slot="{ navigate }"
                      >
                        <a
                          href="javascript:void(0)"
                          role="link"
                          @click="navigate"
                          class="text-white"
                        >
                          CONTACT US
                        </a>
                      </router-link>
                    </p>
                    <p class="d-block d-lg-none mb-0">
                      <router-link
                        to="/website/contact"
                        custom
                        v-slot="{ navigate }"
                      >
                        <a
                          href="javascript:void(0)"
                          role="link"
                          @click="navigate"
                          class="text-primary"
                        >
                          CONTACT US
                        </a>
                      </router-link>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-else>
      <vue-page-transition>
        <router-view />
      </vue-page-transition>
    </template>

    <vue-cookie-accept-decline
      v-show="this.$route.name !== 'cart'"
      :ref="'myPanel1'"
      :elementId="'myPanel1'"
      :debug="false"
      :position="'bottom-center'"
      :type="'floating'"
      :disableDecline="false"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
    >
      <!-- Optional -->
      <!-- <div slot="message">
          By clicking "Accept All Cookies", you accept our Cookie Policy, including the storing of cookies on your device to enhance site navigation, analyze site usage and assist in Tailor's marketing efforts. You can manage your cookie preferences <a href="https://cookiesandyou.com/" target="_blank">here</a>
      </div> -->

      <div slot="declineContent">DECLINE</div>

      <!-- Optional -->
      <div slot="acceptContent">ACCEPT</div>
    </vue-cookie-accept-decline>
  </div>
</template>

<script>
const year = new Date().getFullYear();
export default {
  name: "Winona-Patient",
  data() {
    return {
      hydrated: false,
      status: null,
    };
  },
  methods: {
    cookieStatus(status) {
      this.status = status;
    },
    cookieClickedAccept() {
      //console.log('here in accept')
      this.status = "accept";
    },
    cookieClickedDecline() {
      //console.log('here in decline')
      this.status = "decline";
    },
    cookieRemovedCookie() {
      //console.log('here in cookieRemoved')
      this.status = null;
      this.$refs.myPanel1.init();
    },
    removeCookie() {
      //console.log('Cookie removed')
      this.$refs.myPanel1.removeCookie();
    },
  },
  computed: {
    getYear() {
      return year;
    },
    statusText() {
      return this.status || "No cookie set";
    },
    formControl: function () {
      return (
        this.$route.name == "login" ||
        this.$route.name == "signup" ||
        this.$route.name == "new-password" ||
        this.$route.name == "forgot-password" ||
        this.$route.name == "invitation-code"
      );
    },
  },
  async mounted() {
    await this.$apollo.provider.defaultClient.hydrated();
    this.hydrated = true;
  },
};
</script>

<style lang="scss">
:root {
  color-scheme: light only;
}
// .cookie__floating--top-left,
// .cookie__floating--bottom-left {
//   left: 50% !important;
//   right: 0;
//   max-width: 100% !important;
//   width: 95%;
//   transform: translateX(-50%);
// }

@media (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

$svg-icons: "home", "profile", "orders", "message", "settings", "file", "rx",
  "prescription", "bell", "briefcase", "rx-request", "grid", "list";
$svg-active-icons: "home", "profile", "orders", "message", "settings";

// default bg for article-card component
.article {
  background-image: url("./assets/images/dashboard/articlebg.png");
}

// icon logo
.icon-logo {
  width: 30px;
  height: 30px;
  background-image: url("./assets/images/icon-logo.svg");
  background-size: contain;
  background-repeat: no-repeat;

  &.dark {
    background-image: url("./assets/images/dark-icon-logo.svg");
  }
}

.win-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-left: 1.5em;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  outline: 0;
}

@each $icon in $svg-icons {
  .win-icon.is-#{$icon} {
    background-image: url("#{$svg}/#{$icon}.svg");
  }
}

@each $icon in $svg-active-icons {
  .nav-link.active .win-icon.is-#{$icon} {
    background-image: url("#{$svg}/active/#{$icon}.svg");
  }
}

.next-arrow-cyan,
.next-arrow-purple,
.next-arrow-white,
.next-arrow-aqua,
.next-arrow {
  display: inline-block;
  width: 34px;
  height: 16px;
  margin-left: 10px;
  background-image: url($next-arrow);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.next-arrow-white {
  background-image: url($next-arrow-white);
}

.next-arrow-aqua {
  background-image: url($next-arrow-aqua);
}

.next-arrow-purple {
  background-image: url($next-arrow-purple);
}

.next-arrow-cyan {
  background-image: url($next-arrow-cyan);
}

// onboarding page
.getting-started {
  letter-spacing: 0;
}

.overlay-top,
.overlay-left,
.overlay-right,
.overlay-bottom {
  z-index: 99999;
  background: $primary !important;
  transition-duration: 0.2s !important;
}

body {
  // @include media-breakpoint-up(xxl) {
  //   font-size: ($font-size-base * 2) !important;
  // }

  &.message-page {
    overflow: hidden;
  }
}

p {
  line-height: 1.3;
}
</style>

<style lang="scss" scoped>
#app {
  position: relative;

  &.scrollthis {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.c-logo {
  position: relative;
  top: 0;
  left: 0;
  margin-top: 2rem;

  @include media-breakpoint-down(sm) {
    margin-top: 2rem;
  }
}

.login-page {
  background-color: #e9f5f3;
}

.login-cont {
  padding: 0 5rem;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding: 0 1.5rem;
  }
}

.cover-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%;
  overflow: hidden;
  transition: background-image 1s cubic-bezier(0.215, 0.61, 0.355, 1);

  @include media-breakpoint-down(md) {
    background-image: none !important;
  }
}
</style>
