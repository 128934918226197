import axios from 'axios'
// import LocalStorageService from '@/localStorage'

// const localStorageService = LocalStorageService.getService()

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

instance.interceptors.request.use(
  async request => {
    NProgress.start() 
    const token = window.localStorage.getItem('accessToken')
    if (token != null) {
      request.headers = { 'Authorization': `Bearer ${token}` }
    }
    return request;
  },
  error => {
    Promise.reject(error)
    NProgress.done() 
  }
)

// Response interceptor for API calls
// Refresh Token when expired
instance.interceptors.response.use((response) => {
  NProgress.done()
  return response
}, async error => {
  const originalRequest = error.config
  if (error.response.status === 401 && error.response.data.message === "The incoming token has expired" && !originalRequest._retry) {
    originalRequest._retry = true
    const options = {
      method: 'post', url: 'refreshtoken',
    }
    try {
      const response = await instance(options)
      window.localStorage.setItem('accessToken', response.data.body.idToken)
      instance.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('accessToken')
      return instance(originalRequest)
    }
    catch (error) {
      return reject(error)
    }
  }
  return Promise.reject(error)
})

export default instance


// instance.interceptors.request.use(
//   async config => {
//     const token = await localStorageService.getAccessToken()
//     config.headers = { 
//       'Authorization': `Bearer ${token}`,
//     }
//     console.log(token)
//     NProgress.start()
//     return config;
//   },
//   error => {
//     Promise.reject(error)
// });

// // Response interceptor for API calls
// // Refresh Token when expired
// instance.interceptors.response.use((response) => {
//   NProgress.done()
//   return response
// }, async function (error) {
//   const originalRequest = error.config;
//   if (error.response.status === 401 && error.response.data.message === "The incoming token has expired" && !originalRequest._retry) {
//     originalRequest._retry = true
//     const options = {
//       method: 'post', url: 'refreshtoken'
//     }
//     return instance(options)
//     .then(function (response) {
//       console.log(response.data)
//       localStorageService.setToken(response.data.body.idToken)
//       instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken()
//       return instance(originalRequest)
//     })
//     .catch(function (error) {
//       console.log(error)
//       return reject(error)
//     });
//   }
//   return Promise.reject(error);
// })

// export default instance