var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      !_vm.search
        ? _c(
            "b-navbar",
            {
              staticClass: "d-md-none",
              staticStyle: { "justify-content": "center" },
              attrs: { fixed: "bottom" }
            },
            [
              _c(
                "b-navbar-nav",
                {
                  staticClass: "d-md-none",
                  staticStyle: { "align-items": "center", width: "100%" },
                  attrs: { small: "", align: "center", justified: "" }
                },
                _vm._l(_vm.supportList, function(list, i) {
                  return _c(
                    "b-nav-item",
                    {
                      key: i,
                      class: list.link === list.name ? "active" : "",
                      attrs: { to: "/help-center/" + list.id }
                    },
                    [_vm._v(" " + _vm._s(list.name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          )
        : _c(
            "b-navbar",
            {
              staticClass: "d-md-none",
              staticStyle: { "justify-content": "center" },
              attrs: { fixed: "bottom" }
            },
            [
              _c(
                "b-navbar-nav",
                {
                  staticClass: "d-md-none",
                  staticStyle: { "align-items": "center", width: "100%" },
                  attrs: { small: "", align: "center", justified: "" }
                },
                _vm._l(_vm.supportList, function(list, i) {
                  return _c(
                    "b-nav-item",
                    {
                      key: i,
                      class: "active",
                      attrs: { to: "/help-center/" + list.supportcategory.id }
                    },
                    [_vm._v(" " + _vm._s(list.supportcategory.name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "dark-bg",
              staticStyle: { height: "150px" },
              attrs: { cols: "12" }
            },
            [
              _c(
                "b-row",
                {
                  staticClass: "pl-5 pb-3",
                  staticStyle: { height: "100%" },
                  attrs: { "align-v": "end" }
                },
                [
                  _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                    _c(
                      "h1",
                      {
                        staticClass: "mb-3 heading",
                        staticStyle: {
                          "font-size": "46px",
                          "line-height": "100%"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.title) + " ")]
                    )
                  ]),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "search",
                              placeholder: "Some words here"
                            },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchSupport(_vm.keyword)
                              }
                            },
                            model: {
                              value: _vm.keyword,
                              callback: function($$v) {
                                _vm.keyword = $$v
                              },
                              expression: "keyword"
                            }
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "search" },
                                    on: {
                                      click: function($event) {
                                        return _vm.searchSupport(_vm.keyword)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-5 pr-5" },
        [
          !_vm.search
            ? _c(
                "b-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c(
                    "affix",
                    {
                      staticClass: "sidemenu d-none d-sm-none d-md-flex",
                      attrs: {
                        "relative-element-selector": "#tofixsidebar",
                        offset: { top: 190, bottom: 40 }
                      }
                    },
                    [
                      _c(
                        "b-nav",
                        { attrs: { vertical: "" } },
                        _vm._l(_vm.supportList, function(list, i) {
                          return _c(
                            "b-nav-item",
                            {
                              key: i,
                              class: list.link === list.name ? "active" : "",
                              attrs: { to: "/help-center/" + list.id },
                              on: {
                                click: function($event) {
                                  return _vm.loadDetails(list.name, list.id)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(list.name) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "b-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c(
                    "affix",
                    {
                      staticClass: "sidemenu d-none d-sm-none d-md-flex",
                      attrs: {
                        "relative-element-selector": "#tofixsidebar",
                        offset: { top: 190, bottom: 40 }
                      }
                    },
                    [
                      _c(
                        "b-nav",
                        { attrs: { vertical: "" } },
                        _vm._l(_vm.objSupportCategory, function(list, i) {
                          return _c(
                            "b-nav-item",
                            {
                              key: i,
                              class: "active",
                              attrs: { to: "/help-center/" + list }
                            },
                            [_vm._v(" " + _vm._s(i) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
          _c(
            "b-col",
            {
              staticClass: "pb-7",
              staticStyle: { "min-height": "calc(100vh - 230px)" },
              attrs: { cols: "12", md: "9", id: "tofixsidebar" }
            },
            [
              _c(
                "b-row",
                [
                  !_vm.search
                    ? _c(
                        "b-col",
                        { staticClass: "mt-5", attrs: { cols: "12" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "accordion",
                              attrs: { role: "tablist" }
                            },
                            _vm._l(_vm.resultQuery, function(list, i) {
                              return _c(
                                "b-card",
                                {
                                  key: i,
                                  staticClass: "mb-0 list-of-question",
                                  attrs: { "no-body": "" }
                                },
                                [
                                  _c(
                                    "b-card-header",
                                    {
                                      staticClass: "p-1",
                                      attrs: {
                                        "header-tag": "header",
                                        role: "tab"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "b-toggle",
                                              rawName: "v-b-toggle",
                                              value: "accordion-" + i,
                                              expression: "'accordion-' + i"
                                            }
                                          ],
                                          staticClass:
                                            "toggle-accordion bold d-letter-spacing"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(list.title) + " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "when-opened" },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "chevron-up" }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "when-closed" },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "chevron-down" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: {
                                        id: "accordion-" + i,
                                        accordion: "my-accordion",
                                        role: "tabpanel"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-card-text",
                                        {
                                          staticClass:
                                            "pt-4 pb-4 d-letter-spacing"
                                        },
                                        [_vm._v(_vm._s(list.content))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ]
                      )
                    : _c(
                        "b-col",
                        { staticClass: "mt-5", attrs: { cols: "12" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "accordion",
                              attrs: { role: "tablist" }
                            },
                            _vm._l(_vm.resultQuery, function(list, i) {
                              return _c(
                                "b-card",
                                {
                                  key: i,
                                  staticClass: "mb-0 list-of-question",
                                  attrs: { "no-body": "" }
                                },
                                [
                                  _c(
                                    "b-card-header",
                                    {
                                      staticClass: "p-1",
                                      attrs: {
                                        "header-tag": "header",
                                        role: "tab"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "b-toggle",
                                              rawName: "v-b-toggle",
                                              value: "accordion-" + i,
                                              expression: "'accordion-' + i"
                                            }
                                          ],
                                          staticClass:
                                            "toggle-accordion bold d-letter-spacing"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(list.title) + " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "when-opened" },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "chevron-up" }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "when-closed" },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "chevron-down" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: {
                                        id: "accordion-" + i,
                                        accordion: "my-accordion",
                                        role: "tabpanel"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-card-text",
                                        {
                                          staticClass:
                                            "pt-4 pb-4 d-letter-spacing"
                                        },
                                        [_vm._v(_vm._s(list.content))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }