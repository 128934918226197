<template>
  <div class="verification-info">
    <h2 class="question-title with-subtext mt-2">
      Ok great! The Winona physician is ready to review your information.
    </h2>
    <p class="sub-text mb-3">
      You're almost done. In the next step, we'll verify your identity so your
      doctor can legally write you a prescription.
    </p>
    <p class="sub-text mb-2" style="color: #a783ff">2 minute task</p>
    <div class="mt-5">
      <h2>Here's what's needed:</h2>
      <h2 class="med-font mb-4 row align-items-center">
        <b-col cols="auto">
          <b-avatar variant="primary" text="1" size="1.5rem"></b-avatar>
        </b-col>
        <b-col>
          A photo of your ID (required by your state to verify identity)
        </b-col>
      </h2>
      <h2 class="med-font row align-items-center">
        <b-col cols="auto">
          <b-avatar variant="primary" text="2" size="1.5rem"></b-avatar>
        </b-col>
        <b-col> A photo of your face (used to confirm the ID is yours) </b-col>
      </h2>
    </div>
    <Personalize
      :baseline="{ slug: 'identity-verification' }"
      :component="getRenderingComponent(false)"
      :loadingComponent="getRenderingComponent(true)"
      :holdout="0.5"
      @next="next"
      @show-why-need-dialog="showWhyNeedDialog"
    />

    <!-- :variants="[
        {
          id: 'variant',
          audience: { id: 'CADKX4xBYMzH55uArnO7K' },
          slug: 'identity-verification-2',
        },
      ]" -->

    <!-- Why we need this dialog -->
    <SideModal
      :show="showWhyDialog"
      @exit="hideWhyDialog()"
      body-class="why-body"
    >
      <template #header>
        <h1 class="h2 mt-6 mb-0 heading text-primary">Onboarding</h1>
      </template>
      <template #body>
        <b-row>
          <b-col>
            <b-row class="mt-5">
              <b-col><h3>Identity Verification</h3></b-col>
            </b-row>
            <b-row>
              <b-col
                cols="9"
                class="d-flex flex-column justify-content-between"
              >
                <span class="text-small text-muted"
                  >Why we need this information?</span
                >
                <p>
                  Government issued ID is required when purchasing any
                  prescription medication, as the medical provider will need to
                  verify your identity before they can determine if prescription
                  medication is right for you. Your personal identifying
                  information, medical history or ID will never be shared
                  outside of our secure system.
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="align-self-center text-right">
            <b-button
              pill
              variant="info"
              @click="hideWhyDialog()"
              style="min-width: 250px"
              >OK</b-button
            >
          </b-col>
        </b-row>
      </template>
    </SideModal>
    <div class="bg-overlay"></div>
  </div>
</template>

<script>
import { Personalize } from "@ninetailed/experience.js-vue";
import postscribe from "postscribe";
import { OnboardingService } from "@/services/onboarding.service";

// Split testing
import VerificationWrapper from "@/components/onboarding/verification/split/VerificationWrapper";
import Loading from "@/components/onboarding/verification/split/Loading";

import SideModal from "@/components/partials/SideModal";

export default {
  components: {
    Personalize,
    SideModal,
  },
  props: {
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    analyticsId: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
    phoneno: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    firstname: {
      type: String,
      default: "",
    },
    lastname: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showWhyDialog: false,
    };
  },
  methods: {
    showWhyNeedDialog() {
      this.elementTag("hidden", true);
    },
    hideWhyDialog() {
      this.elementTag(null, false);
    },
    elementTag(style, Boolean) {
      this.showWhyDialog = Boolean;
      const htmlTag = document.documentElement;
      htmlTag.style.overflow = style;
      htmlTag.scrollTop = htmlTag.scrollHeight;
    },
    next() {
      this.$emit("setStep", "idphoto");
    },
    async fbqTrack(data, eventID) {
      // FB Pixel Tracking
      try {
        fbq("init", "348219289912097", {
          em: data.email,
          fn: data.firstname,
          ln: data.lastname,
          ph: data.phoneno,
          external_id: data.id,
          extern_id: data.id,
        });
        // fbq("track", "PageView");
        await OnboardingService.onboardingEvent("PageView");
        fbq(
          "trackCustom",
          "VerificationStarted",
          {
            external_id: data.id,
            extern_id: data.id,
            state: this.state,
            ...(await this.$store.dispatch("getFBQParams")),
          },
          {
            eventID: eventID,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    getRenderingComponent(loading) {
      if (!loading) return VerificationWrapper;
      else return Loading;
    },
  },
  async mounted() {
    console.log(Personalize);
    // window.analytics.page();
    let event_checkoutstarted = await OnboardingService.triggerOnboardingEvents(
      {
        eventname: "VerificationStarted",
        path: window.location.pathname,
      }
    );
    let onboarding = await OnboardingService.getPatientOnboarding();

    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        customerId: this.analyticsId,
        screen: "4.1-A",
        onboardingflow: this.onboardingflow,
        firstname: this.firstname,
        lastname: this.lastname,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
      },
      {
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );

    if (event_checkoutstarted.data.status === "new") {
      window.analytics.track(
        "Identity Verification Started",
        {
          customerId: this.analyticsId,
          onboardingflow: this.onboardingflow,
          state: this.state,
          phoneno: this.phoneno,
          email: this.email,
          messageID: event_checkoutstarted.data.id,
          firstname: this.firstname,
          lastname: this.lastname,
          ...(await this.$store.dispatch("getDeviceDetailsForContext")),
          // ...(await this.$store.dispatch("getFBQParams")),
        },
        {
          context: await this.$store.dispatch("geDeviceDetails"),
        }
      );

      // WIN-14971
      window.Northbeam.fireCustomGoal("Verification Started", {});
      console.log("Northbeam: Verification Started");

      // GTM: Mariwin
      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   event: "Identity Verification Started",
      //   customerID: onboarding.data.id,
      //   firstName: onboarding.data.firstname,
      //   lastName: onboarding.data.lastname,
      //   phone: onboarding.data.phoneno,
      // });

      if (event_checkoutstarted.data.id) {
        await this.fbqTrack(onboarding.data, event_checkoutstarted.data.id);
      } else if (
        event_checkoutstarted.data.id &&
        process.env.VUE_APP_SERVER != "production"
      ) {
        // alert('FB TRACKING VerificationStarted: ' + event_checkoutstarted.data.id)
        console.log("FB TRACKING VerificationStarted");
        console.log(
          "FB TRACKING VerificationStarted: " + event_checkoutstarted.data.id
        );
      }
    }
    if (document.getElementById("certified-logo").childNodes.length === 0) {
      postscribe(
        "#certified-logo",
        `<script  src="https://static.legitscript.com/seals/8131176.js"><\/script>`
      );
    }
  },
};
</script>
