<template>
  <div class="eligibilityBirthday">
    <h2 class="question-title with-subtext">When is your birthday?</h2>
    <p class="sub-text group-mb">
      Age helps us to determine if you are eligible for treatment.
    </p>
    <b-row>
      <b-col>
        <div role="group" class="mb-3 pb-1">
          <label for="birthday" class="input-label">Date of birth*</label>
          <b-form-input
            id="birthday"
            placeholder="MM/DD/YYYY"
            class="fs-exclude onboarding-input birthdate"
            v-model="birthday"
            type="text"
            pattern="[0-9]*"
            inputmode="numeric"
            v-mask="'##/##/####'"
            autocomplete="off"
          />
          <!-- v-if="!isMobile" -->
          <!-- <BirthdayInput
            v-model="birthday"
            class="fs-exclude"
            :checkForMobile="checkForMobile"
          /> -->
        </div>

        <b-button
          class="mb-3"
          pill
          variant="info"
          @click="submitAnswer"
          :disabled="buttonDisabled"
          >Continue</b-button
        >
        <!-- <small class="d-block"
          >Already have an account?
          <router-link to="/login" custom v-slot="{ navigate }"
            ><a href="javascript:void(0)" @click="navigate"
              >Sign In</a
            ></router-link
          >
        </small> -->
      </b-col>
    </b-row>
    <Modal v-if="showModal" :body="ageModal" @submitAnswer="exit" />
  </div>
</template>

<script>
// import BirthdayInput from "@/components/partials/BirthdayInput";
import Modal from "@/components/onboarding/questions/Modal";
import mixins from "@/components/form/mixins";
import { OnboardingService } from "@/services/onboarding.service";

export default {
  components: {
    // BirthdayInput,
    Modal,
  },
  props: {
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    preAnswer: {
      type: String,
      default: "",
    },
    externalid: {
      type: String,
      default: "",
    },
  },
  mixins: [mixins],
  data() {
    return {
      dateFormat: "MM/DD/YYYY",
      pickerConfig: {
        altInput: true,
        altFormat: "m-d-Y",
        allowInput: true,
        position: "above left",
        minDate: this.moment().subtract(100, "year").format("MM-DD-YYYY"),
        maxDate: this.moment().subtract(35, "year").format("MM-DD-YYYY"),
      },
      birthday: "",
      checkForMobile: true,
      buttonDisabled: false,
      showModal: false,
      ageModal: {
        id: "ageModal",
        title: "You must be 35 years of age or older to proceed",
        answerlist: [
          {
            name: "continue",
          },
        ],
      },
    };
  },
  watch: {
    async showModal(show) {
      if (show) {
        const rejectBody = {
          externalid: this.externalid,
          question: "How old are you?",
          answer: await this.calculateAge(),
          onboardingflow: this.onboardingflow,
        };
        // OnboardingService.rejectOnboardingEvent(rejectBody);

        // WIN-15590
        window.analytics.track("Onboarding Reject", {
          ...rejectBody,
        });

        window.Northbeam.fireCustomGoal("Onboarding Rejected", {});
        console.log("Northbeam: Onboarding Rejected");
      }
    },
  },
  methods: {
    async calculateAge() {
      // TODO Make sure age is precise to the day
      // birthday is a date
      let dateArray = this.getDateArray(this.birthday);
      let newDate = new Date(dateArray[2], dateArray[0], dateArray[1]);
      let ageDifMs = Date.now() - newDate.getTime();
      if (ageDifMs <= 0) {
        this.$emit("showAlert", "Please input a valid birthday.");
        return false;
      }
      let ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    getDateArray(date) {
      // converts MM/DD/YYYY string to numbers
      let dateArray = date.split("/").map((x) => +x);
      // Month is 0 indexed
      dateArray[0] = dateArray[0] - 1;
      return dateArray;
    },
    formatBirthdayForDisplay(date) {
      const dateString = date.split("-");
      return `${dateString[1]}/${dateString[2]}/${dateString[0]}`;
    },
    formatBirthday(date) {
      // let conDate = new Date(date);
      // const yyyy = conDate.getFullYear();
      // const mm = String(conDate.getMonth() + 1).padStart(2, '0');
      // const dd = String(conDate.getDate()).padStart(2, '0');
      const dateString = date.split("/");
      const yyyy = dateString[2];
      const mm = dateString[0];
      const dd = dateString[1];
      return `${yyyy}-${mm}-${dd}`;
    },
    async submitAnswer() {
      const dateString = this.birthday.split("/");
      if (dateString.length == 3) {
        dateString[2] = dateString[2].replace(/^0+/, "");
      } else {
        this.buttonDisabled = false;
        this.$emit("showAlert", "Please input a valid birthday.");
        return;
      }

      this.birthday = `${dateString[0]}/${dateString[1]}/${dateString[2]}`;
      if (!this.moment(this.birthday, this.dateFormat, true).isValid()) {
        this.$emit("showAlert", "Please input a valid birthday.");
        return;
      }

      // Checks if age is within the limits -- false is returned if a future date is entered
      let age = await this.calculateAge();
      if (age < 0 || age > 100 || age == false) {
        this.buttonDisabled = false;
        this.$emit("showAlert", "Please input a valid birthday.");
        return;
      } else if (age < 35) {
        this.buttonDisabled = false;
        this.showModal = true;
        return;
      }
      this.$emit("setParams", "birthday", this.formatBirthday(this.birthday));

      // Checks if empty
      if (this.birthday === "") {
        this.buttonDisabled = false;
        this.$emit("showAlert", "Enter your birthday");
        return;
      } else if (age >= 60) {
        this.buttonDisabled = false;
        const rejectBody = {
          externalid: this.externalid,
          question: "How old are you?",
          answer: age,
          onboardingflow: this.onboardingflow,
        };
        // OnboardingService.rejectOnboardingEvent(rejectBody);

        // WIN-15590
        window.analytics.track("Onboarding Reject", {
          ...rejectBody,
        });

        window.Northbeam.fireCustomGoal("Onboarding Rejected", {});
        console.log("Northbeam: Onboarding Rejected");

        this.$emit("setStep", "over60");
      } else {
        this.buttonDisabled = false;
        // this.$emit('showLoader' ,1);
        this.$emit("setStep", "sex");
      }
    },
    exit() {
      window.location.href = process.env.VUE_APP_WEBSITE_URL;
    },
  },
  async mounted() {
    if (this.preAnswer) {
      this.birthday = this.formatBirthdayForDisplay(this.preAnswer);
    }

    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        screen: "1.2-A",
        onboardingflow: this.onboardingflow,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
      },
      {
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );
  },
};
</script>

<style lang="scss" scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

// @media (max-width: 767px) {
//    #birthday {
//      pointer-events: none;
//    }
// }
</style>
