var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "personalPhoto" },
    [
      _c("h2", { staticClass: "question-title with-subtext" }, [
        _vm._v(
          " " +
            _vm._s(_vm.isAndroid11Webview ? "Send us" : "Upload") +
            " a photo of your face "
        )
      ]),
      _c("p", { staticClass: "sub-text" }, [
        _vm._v(
          " Your medical provider is legally required to verify your identity by comparing your photo" +
            _vm._s(
              _vm.isAndroid11Webview
                ? "."
                : " with the ID you uploaded on the previous step."
            ) +
            " "
        )
      ]),
      !_vm.isMobileOrTablet
        ? _c(
            "div",
            [
              _c("div", { staticClass: "photo-holder fs-exclude" }, [
                (_vm.picture || _vm.imagePreview) &&
                _vm.uploadFileType !== "application/pdf"
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-crop",
                        on: { click: _vm.openCropModal }
                      },
                      [_c("b-icon", { attrs: { icon: "crop" } })],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "photo",
                    style: {
                      backgroundImage:
                        _vm.isPhotoTaken || _vm.showPhoto || _vm.photoUrl
                          ? ""
                          : "url(" + require("@/assets/images/photo.jpg") + ")"
                    }
                  },
                  [
                    _vm.isPhotoTaken
                      ? _c("img", {
                          staticClass: "fs-exclude",
                          attrs: { src: _vm.picture, alt: "" }
                        })
                      : _vm.showPhoto
                      ? _c("img", {
                          staticClass: "fs-exclude",
                          attrs: { src: _vm.imagePreview }
                        })
                      : _vm.photoUrl
                      ? _c("img", {
                          staticClass: "fs-exclude",
                          attrs: { src: _vm.photoUrl }
                        })
                      : _vm._e()
                  ]
                )
              ]),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "photo-btn",
                          attrs: {
                            pill: "",
                            block: "",
                            variant: "info",
                            disabled: _vm.saving
                          }
                        },
                        [
                          _c("input", {
                            ref: "photo",
                            staticClass: "hide-file-input",
                            attrs: {
                              type: "file",
                              id: "photo",
                              accept: "image/*, application/pdf"
                            },
                            on: {
                              change: function($event) {
                                return _vm.photoUpload()
                              }
                            }
                          }),
                          _c(
                            "label",
                            { staticClass: "mb-0", attrs: { for: "photo" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.isPhotoTaken &&
                                      !_vm.showPhoto &&
                                      !_vm.photoUrl
                                      ? "Select Photo"
                                      : "REUPLOAD"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-info",
                            disabled: _vm.saving,
                            pill: "",
                            block: ""
                          },
                          on: { click: _vm.showCamera }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isPhotoTaken ? "Retake" : "TAKE PHOTO"
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "modal",
                {
                  attrs: {
                    id: "takePhoto",
                    size: "md",
                    centered: "",
                    "hide-header": "",
                    "hide-footer": "",
                    "no-close-on-esc": "",
                    "no-close-on-backdrop": ""
                  }
                },
                [
                  _c("video", {
                    ref: "camera",
                    staticStyle: { "background-color": "#000" },
                    attrs: { width: "100%", height: "325", autoplay: "" }
                  }),
                  _c("canvas", {
                    ref: "canvas",
                    staticClass: "photo-taken",
                    attrs: { width: 325, height: 250 }
                  }),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { pill: "", block: "", variant: "info" },
                              on: { click: _vm.takePhoto }
                            },
                            [_vm._v(" Capture ")]
                          ),
                          _c("div", { staticClass: "mb-3 d-md-none" })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-info"
                              },
                              on: { click: _vm.hideCameraModal }
                            },
                            [_vm._v(" Cancel ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isPhotoTaken || _vm.showPhoto || _vm.photoUrl
                ? _c(
                    "b-button",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        disabled: _vm.saving,
                        variant: "info",
                        block: "",
                        pill: ""
                      },
                      on: { click: _vm.next }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.saving ? "Saving..." : "Confirm") + " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "b-modal",
                {
                  staticClass: "fs-exclude",
                  attrs: {
                    id: "crop",
                    size: "md",
                    centered: "",
                    "hide-header": "",
                    "hide-footer": "",
                    "no-close-on-esc": "",
                    "no-close-on-backdrop": ""
                  }
                },
                [
                  _c("vue-croppie", {
                    ref: "croppieRef",
                    attrs: {
                      enableOrientation: false,
                      enableResize: false,
                      boundary: { width: 300, height: 300 },
                      viewport: { width: 300, height: 300 }
                    }
                  }),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { pill: "", block: "", variant: "info" },
                              on: { click: _vm.cropImage }
                            },
                            [_vm._v(" Crop ")]
                          ),
                          _c("div", { staticClass: "mb-3 d-md-none" })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-info"
                              },
                              on: { click: _vm.closeCropModal }
                            },
                            [_vm._v(" Cancel ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c("MobileUpload", {
                staticClass: "fs-exclude",
                attrs: {
                  isAndroid11Webview: _vm.isAndroid11Webview,
                  existingPhoto: _vm.photoUrl,
                  backgroundType: "personal"
                },
                on: {
                  submitAnswer: _vm.setMobilePhoto,
                  showAlert: _vm.showAlert,
                  hideAlert: _vm.hideAlert,
                  "update:isAndroid11Webview": function($event) {
                    _vm.isAndroid11Webview = $event
                  },
                  "update:is-android11-webview": function($event) {
                    _vm.isAndroid11Webview = $event
                  },
                  skip: function($event) {
                    return _vm.next({ isSkipped: true })
                  }
                }
              })
            ],
            1
          ),
      _c("div", { staticClass: "text-center" }, [
        _vm.photoUploadStatus !== "UPLOADED" && !_vm.isAndroid11Webview
          ? _c(
              "a",
              {
                staticClass:
                  "text-default text-underline d-inline-block mt-4 py-3 mx-auto",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.showSkipModal = true
                  }
                }
              },
              [_vm._v(" Having Trouble Uploading Photo? ")]
            )
          : _vm._e()
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            id: "trouble",
            "hide-header": "",
            "hide-footer": "",
            centered: "",
            "content-class": "dark-bg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-stacking": ""
          },
          model: {
            value: _vm.showSkipModal,
            callback: function($$v) {
              _vm.showSkipModal = $$v
            },
            expression: "showSkipModal"
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo dark" })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c(
                "p",
                { staticClass: "sub-text", staticStyle: { color: "inherit" } },
                [
                  _vm._v(
                    " You can skip uploading your photo now but you will need to upload it later to verify your identity. "
                  )
                ]
              ),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", lg: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: { "min-width": "100px", width: "100%" },
                          attrs: { variant: "info", pill: "" },
                          on: { click: _vm.closeModal }
                        },
                        [_vm._v(" Upload Now ")]
                      ),
                      _c("div", { staticClass: "separator" })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", lg: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "modal-cancel-btn",
                          staticStyle: { "min-width": "100px", width: "100%" },
                          attrs: { variant: "outline-info", pill: "" },
                          on: {
                            click: function($event) {
                              return _vm.next({ isSkipped: true })
                            }
                          }
                        },
                        [_vm._v(" Skip Uploading ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }