var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c("div", { staticClass: "photo-holder fs-exclude" }, [
          _vm.photo &&
          !_vm.existing &&
          _vm.photoFile.type !== "application/pdf" &&
          !_vm.isAndroidFirefoxRestriction
            ? _c(
                "div",
                { staticClass: "btn-crop", on: { click: _vm.openCropModal } },
                [_c("b-icon", { attrs: { icon: "crop" } })],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              class: [
                "photo",
                _vm.idSize &&
                _vm.isImageFileFormat &&
                !_vm.isAndroidFirefoxRestriction
                  ? "id-size"
                  : ""
              ],
              style: {
                backgroundImage: _vm.backgroundImage
              }
            },
            [
              _vm.photo != ""
                ? _c("img", {
                    staticClass: "fs-mask",
                    style: {
                      width:
                        _vm.existingPhotoFileType === "application/pdf"
                          ? "auto"
                          : "100%"
                    },
                    attrs: { src: _vm.photo }
                  })
                : _vm._e()
            ]
          )
        ]),
        _vm.isAndroid11WebView
          ? _c(
              "div",
              [
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " Please send your " +
                      _vm._s(
                        _vm.backgroundType === "id" ? "ID document" : "photo"
                      ) +
                      " to our "
                  ),
                  _c("a", { attrs: { href: "mailto:help@bywinona.com" } }, [
                    _vm._v("email address")
                  ]),
                  _vm._v(" or via Intercom. ")
                ]),
                !_vm.dashboard
                  ? _c(
                      "b-button",
                      {
                        attrs: { variant: "info", pill: "", block: "" },
                        on: { click: _vm.skipUpload }
                      },
                      [_vm._v(" Continue ")]
                    )
                  : _vm._e()
              ],
              1
            )
          : _c(
              "div",
              [
                _vm.photo == "" || _vm.isAndroidWebView
                  ? _c(
                      "label",
                      {
                        staticClass:
                          "btn btn-outline-info btn-pill mobile-upload-label",
                        attrs: { for: _vm.componentID + "-upload" }
                      },
                      [_vm._v(" Select Photo ")]
                    )
                  : _vm._e(),
                this.captureText === "RETAKE" && !_vm.isAndroidWebView
                  ? _c(
                      "b-button",
                      {
                        staticClass: "mb-2",
                        attrs: {
                          variant: "outline-info",
                          pill: "",
                          block: "",
                          disabled: _vm.isImageUploadProcessing
                        },
                        on: {
                          click: function($event) {
                            _vm.photo = ""
                            _vm.photoFile = null
                          }
                        }
                      },
                      [_vm._v(" RETAKE ")]
                    )
                  : this.captureText !== "RETAKE" && !_vm.isAndroidWebView
                  ? _c(
                      "label",
                      {
                        staticClass: "btn btn-pill mobile-upload-label",
                        class: {
                          "btn-info": _vm.captureText == "TAKE PHOTO",
                          "btn-outline-info": _vm.captureText == "RETAKE"
                        },
                        attrs: {
                          for: _vm.componentID + "-capture",
                          disabled: _vm.isImageUploadProcessing
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.captureText) + " ")]
                    )
                  : _vm._e(),
                _c("input", {
                  staticClass: "mobile-upload",
                  attrs: {
                    type: "file",
                    id: _vm.componentID + "-capture",
                    accept: "image/*",
                    name: "mobile-upload",
                    capture: ""
                  },
                  on: {
                    change: function(e) {
                      return _vm.setPictureMobile(e, { isPhotoTaken: true })
                    }
                  }
                }),
                _c("input", {
                  staticClass: "mobile-upload",
                  attrs: {
                    type: "file",
                    id: _vm.componentID + "-upload",
                    accept: "image/*, application/pdf",
                    name: "mobile-upload"
                  },
                  on: {
                    change: function(e) {
                      return _vm.setPictureMobile(e, { isPhotoTaken: false })
                    }
                  }
                }),
                !_vm.dashboard && this.captureText == "RETAKE"
                  ? _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "info",
                          pill: "",
                          block: "",
                          disabled: _vm.isImageUploadProcessing
                        },
                        on: { click: _vm.submitAnswer }
                      },
                      [
                        _vm.isImageUploadProcessing
                          ? _c("b-spinner", { attrs: { small: "" } })
                          : _c("span", [_vm._v(_vm._s(_vm.submitText))])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
      ]),
      _c(
        "b-modal",
        {
          staticClass: "fs-exclude",
          attrs: {
            id: "crop",
            size: "md",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          }
        },
        [
          _c("vue-croppie", {
            ref: "croppieRefMobile",
            attrs: {
              enableOrientation: false,
              enableResize: false,
              boundary: {
                width: 300,
                height: _vm.backgroundType === "id" ? 200 : 300
              },
              viewport: {
                width: 300,
                height: _vm.backgroundType === "id" ? 200 : 300
              }
            }
          }),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { pill: "", block: "", variant: "info" },
                      on: { click: _vm.cropImage }
                    },
                    [_vm._v(" Crop ")]
                  ),
                  _c("div", { staticClass: "mb-3 d-md-none" })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { pill: "", block: "", variant: "outline-info" },
                      on: { click: _vm.closeCropModal }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }