<template>
  <b-container fluid v-show="showContent">
    <!-- for Desktop Only Progress -->
    <OnboardingProgress
      @back="prev"
      :hide="hideBackButton"
      :progress="progress"
    />
    <b-row class="onboarding-wrapper">
      <OnboardingHeader
        @back="prev"
        :hide="hideBackButton"
        step="1"
        :section="step == 'email'"
        :title="title"
      />
      <b-col
        cols="12"
        lg="5"
        xl="4"
        xxl="4"
        class="mt-6 pt-3 onboarding-content"
      >
        <DiscountBanner :show="coupon.show" :couponid="coupon.couponid" />

        <b-alert :show="alert.show" :variant="alert.type">
          {{ alert.message }}
          <span v-if="alert.showEmail">
            <br />
            <router-link to="/login" class="text-primary"
              ><u>Click here to sign in.</u></router-link
            >
          </span>
        </b-alert>
        <!-- <h6 class="d-lg-none d-letter-spacing mt-3 mb-2 step">Step 1 of 4</h6> -->
        <!-- mobile progress -->
        <b-row class="d-lg-none">
          <b-col>
            <h3 class="bold d-letter-spacing onboarding-title">
              Account Creation
              <!-- {{ title }} -->
            </h3>
          </b-col>
          <b-col>
            <h3
              class="
                bold
                d-letter-spacing
                onboarding-title
                text-muted text-right
              "
            >
              Intake
            </h3>
          </b-col>
        </b-row>
        <b-progress
          :value="progress[0].value"
          :max="progress[0].max"
          class="step-progress d-lg-none mb-5"
          variant="purple"
          height="5px"
        ></b-progress>
        <!-- Set event handlers as necessary -->

        <State
          v-if="step == 'state'"
          :preAnswer="eligibilityParams.state"
          @setParams="setParams"
          @setStateName="setStateName"
          @setStep="setStep"
          @showLoader="showLoader"
          @showAlert="showAlert"
          :onboardingflow="onboardingflow"
        />
        <!-- This is for the new email flow -->
        <!-- remove style to display to check the UI  -->
        <Email
          v-if="step == 'email'"
          @setStep="setStep"
          @showAlert="showAlert"
          @showEmailAlert="showEmailAlert"
          @setId="setId"
          @setEmail="setEmail"
          :onboardingflow="onboardingflow"
          :eligibilityParams="eligibilityParams"
        />

        <Waitlist
          v-if="step == 'waitlist'"
          :state="eligibilityParams.state"
          @setStep="setStep"
          @showAlert="showAlert"
          :onboardingflow="onboardingflow"
        />
        <Nothing
          v-if="step == 'thankyou'"
          :body="thankyouBody"
          @submitAnswer="exit"
        />
        <Birthday
          v-if="step == 'birthday'"
          @setParams="setParams"
          @setStep="setStep"
          @showLoader="showLoader"
          @showAlert="showAlert"
          @exit="exit"
          :preAnswer="eligibilityParams.birthday"
          :onboardingflow="onboardingflow"
          :externalid="userId"
        />

        <Nothing
          v-if="step == 'over60'"
          :body="over60Body"
          @submitAnswer="exit"
        />

        <Sex
          v-if="step == 'sex' && !registered"
          :onboardingflow="onboardingflow"
          :preAnswer="eligibilityParams.gender"
          :age="age"
          @setParams="setParams"
          @setStep="setStep"
          @showLoader="showLoader"
          @checkToken="checkAuthToken"
          @showAlert="showAlert"
        />

        <Sex
          v-if="step == 'sex' && registered"
          :preAnswer="eligibilityParams.gender"
          :onboardingflow="onboardingflow"
          :age="age"
          @setParams="setParams"
          @finish="finish"
          v-bind:eligibilityParams="eligibilityParams"
          @setStep="setStep"
          @showLoader="showLoader"
          @checkToken="checkAuthToken"
          @showAlert="showAlert"
        />

        <Surgery
          v-if="step == 'surgery'"
          @setParams="setParams"
          @setStep="setStep"
          @showLoader="showLoader"
          @checkToken="checkAuthToken"
          @showAlert="showAlert"
        />

        <!-- If you want to switch between exit and non rx, just change the setStep to exit and vice versa. -->
        <Nothing
          v-if="step == 'under35'"
          :body="under35Body"
          @submitAnswer="exit"
        />

        <Nothing v-if="step == 'male'" :body="maleBody" @submitAnswer="exit" />

        <!-- <Registration
          v-show="step == 'registration'"
          ref="Registration"
          @showAlert="showAlert"
          @finish="finish"
          v-bind:eligibilityParams="eligibilityParams"
          :stateName="stateName"
        /> -->
        <Registration
          v-if="step == 'registration'"
          ref="Registration"
          @showAlert="showAlert"
          @finish="finish"
          @update-registration="updateRegistration"
          :registrationParams="registrationParams"
          :eligibilityParams="eligibilityParams"
          :stateName="stateName"
          :id="userId"
          :email="email"
        />
      </b-col>
      <b-col cols="12" lg="3" xl="4" xxl="4">
        <b-row align-v="stretch" style="height: 100%">
          <b-col align-self="start" cols="12">
            <div class="d-none d-lg-block mt-6"></div>
            <div class="d-block d-lg-none mt-4"></div>
            <div
              v-show="step == 'registration'"
              class="certified-logo text-center"
              id="certified-logo"
            ></div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-show="step == 'email'">
        <Personalize
          :baseline="{
            id: 'Account Creation TP Widget',
            audience: { id: 'CADKX4xBYMzH55uArnO7K' },
          }"
          :component="getRenderingComponent(false)"
          :loadingComponent="getRenderingComponent(true)"
          :holdout="0.5"
        />
        <!-- :baseline="{
            id: 'Account Creation',
            audience: { id: 'CADKX4xBYMzH55uArnO7K' },
          }"
          :variants="[
            {
              id: 'Account Creation TP Widget',
              audience: { id: 'CADKX4xBYMzH55uArnO7K' },
            },
          ]" -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import crypto from "crypto";
import moment from "moment";
// Services
import { AuthService } from "@/services/auth.service";
import { OnboardingService } from "@/services/onboarding.service";
import { UsersService } from "@/services/users.service";

// Components
import State from "@/components/onboarding/eligibility/State";
import Waitlist from "@/components/onboarding/eligibility/Waitlist";
import Email from "@/components/onboarding/eligibility/Email";
import Nothing from "@/components/onboarding/questions/Nothing";
import Birthday from "@/components/onboarding/eligibility/Birthday";
import Sex from "@/components/onboarding/eligibility/Sex";
import Surgery from "@/components/onboarding/eligibility/Surgery";
import Registration from "@/components/onboarding/eligibility/Registration";
import OnboardingHeader from "@/components/partials/OnboardingHeader";
import OnboardingProgress from "@/components/partials/OnboardingProgress";
import DiscountBanner from "@/components/partials/DiscountBanner.vue";

// Split testing
import { Personalize } from "@ninetailed/experience.js-vue";
import EligibilitySplitWrapper from "@/components/onboarding/eligibility/split/EligibilitySplitWrapper";
import Loading from "@/components/onboarding/eligibility/split/Loading";

let tyParams = {
  id: "thankyou",
  title:
    "Thank you for visiting Winona. We will reach out to you as soon as we are in your state.",
};

let under35Params = {
  id: "under35",
  title:
    "Women who are younger than 35 years old and have their ovaries do not qualify for the treatment.",
  description:
    "We invite you to check out some of our non-prescription products.",
};

let maleParams = {
  id: "male",
  title:
    "We can only provide Bioidentical Treatments to individuals whose gender assignment at birth was female.",
  description:
    "We invite you to check out some of our non-prescription products.",
};

let over60Params = {
  id: "over60",
  title:
    "Thank you for your interest in Winona. Unfortunately, women of age 60 and up are not eligible for treatment on this platform.",
  description:
    "We recommend you to consult with your doctor for treatment opportunities. In the meantime, we invite you to check out some of our non-prescription products.",
};

export default {
  props: ["title"],
  components: {
    State,
    Email,
    Waitlist,
    Nothing,
    Birthday,
    Sex,
    Surgery,
    Registration,
    OnboardingHeader,
    OnboardingProgress,
    Personalize,
    DiscountBanner,
  },
  data() {
    return {
      alert: {
        show: false,
        message: "",
        showEmail: false,
        email: "",
      },
      step: "state",
      thankyouBody: tyParams,
      maleBody: maleParams,
      under35Body: under35Params,
      over60Body: over60Params,
      eligibilityParams: {
        state: "",
        birthday: "",
        ovariansurgery: null,
        gender: null,
      },
      coupon: {
        show: false,
        id: "",
      },
      stateName: "",
      showContent: true,
      buttonDisabled: false,
      age: null,
      onboardingstep: null,
      userId: null,
      email: "",
      registered: false,
      referralToken: "",
      discountcode: "",
      registrationParams: {
        email: "",
        firstname: "",
        lastname: "",
        phoneno: "",
      },

      // for new progress UI update on the header
      progress: [
        {
          title: "Account Creation",
          value: 0,
          max: 5,
        },
        {
          title: "Intake",
          value: 0,
          max: 5,
        },
        {
          title: "Onboarding Complete",
          value: 0,
        },
      ],
      manaJs: "",
    };
  },
  watch: {
    async step() {
      if (this.step == "registration") {
        window.analytics.track(
          "Onboarding Screen Viewed",
          {
            screen: "1.4-A",
            onboardingflow: this.onboardingflow,
            ...(await this.$store.dispatch("getDeviceDetailsForContext")),
          },
          {
            context: await this.$store.dispatch("geDeviceDetails"),
          }
        );
      }

      if (this.step == "male") {
        const rejectBody = {
          externalid: this.userId,
          question: "What was the sex assigned to you at birth?",
          answer: "Male",
          onboardingflow: this.onboardingflow,
        };
        // OnboardingService.rejectOnboardingEvent(rejectBody);

        // WIN-15590
        window.analytics.track("Onboarding Reject", {
          ...rejectBody,
        });

        window.Northbeam.fireCustomGoal("Onboarding Rejected", {});
        console.log("Northbeam: Onboarding Rejected");
      }

      switch (this.step) {
        case "state":
          this.$set(this.progress[0], "value", 1);
          break;
        case "email":
          this.$set(this.progress[0], "value", 2);
          break;
        case "birthday":
          this.$set(this.progress[0], "value", 3);
          break;
        case "sex":
          this.$set(this.progress[0], "value", 4);
          break;
        case "registration":
          this.$set(this.progress[0], "value", 5);
          break;
      }
    },
    eligibilityParams: {
      handler() {
        let newDate = new Date(this.eligibilityParams.birthday);
        let ageDifMs = Date.now() - newDate.getTime();
        let ageDate = new Date(ageDifMs); // miliseconds from epoch
        this.age = Math.abs(ageDate.getUTCFullYear() - 1970);
      },
      deep: true,
    },
  },
  computed: {
    onboardingflow() {
      const flow = $cookies.get("onboardingflow") || $cookies.get("flow");
      if (flow && (flow == "flowB" || flow == "b")) {
        return "flowB";
      } else {
        return "flowA";
      }
    },
    hideBackButton() {
      if (this.step != "state" && this.step != "birthday") {
        return false;
      }
      return true;
    },
  },
  methods: {
    getRenderingComponent(loading) {
      if (!loading) return EligibilitySplitWrapper;
      else return Loading;
    },
    setId(id) {
      this.userId = id;
    },
    setEmail(email) {
      this.email = email;
    },
    setStateName(name) {
      this.stateName = name;
    },
    async checkAuthToken() {
      const auth = await AuthService.getBearer();
      if (auth) {
        const { data } = await OnboardingService.getPatientOnboarding();
        if (data.onboardingstep >= 1.5) {
          this.finish();
        }
      }
    },
    showLoader(type) {
      this.showContent = false;
      this.$emit("showLoader", type);
      window.setTimeout(() => {
        this.showContent = true;
      }, 1500);
    },
    showAlert(message, type = null) {
      this.alert.show = false;
      if (!type) type = "warning";
      this.alert = {
        show: true,
        type: type,
        message: message,
        showEmail: false,
        email: "",
      };
      window.scrollTo(0, 0);
    },
    showEmailAlert(message, email) {
      this.alert.show = false;
      this.alert = {
        show: true,
        type: "warning",
        message: message,
        showEmail: true,
        email: email,
      };
      window.scrollTo(0, 0);
    },
    prev() {
      this.alert.show = false;
      switch (this.step) {
        case "waitlist":
          this.step = "state";
          break;
        case "thankyou":
          this.step = "waitlist";
          break;
        case "email":
          this.step = "state";
          break;
        // case "birthday":
        //   this.step = "state";
        //   break;
        case "sex":
          this.step = "birthday";
          break;
        case "over60":
          this.step = "birthday";
          break;
        case "male":
          this.step = "sex";
          break;
        case "surgery":
          this.step = "sex";
          break;
        case "under35":
          this.step = "surgery";
          break;
        case "registration":
          if (this.age < 35 && this.sex == "Female") {
            this.step == "surgery";
          } else {
            this.step = "sex";
          }
          break;
      }
    },
    setStep(step) {
      this.alert.show = false;
      if (this.coupon.show) {
        this.coupon.show = false;
      }
      this.step = step;
    },
    updateRegistration(key, value) {
      this.$set(this.registrationParams, key, value);
    },
    setParams(key, value) {
      this.$set(this.eligibilityParams, key, value);
    },
    exit() {
      window.location.href = process.env.VUE_APP_WEBSITE_URL;
    },
    addTrustpilot() {
      const pilot = document.createElement("script");
      pilot.type = "text/javascript";
      pilot.src =
        "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
      pilot.async = "true";
      document.head.appendChild(pilot);

      pilot.onload = function () {
        const trustbox = document.getElementById("trustbox");
        window.Trustpilot.loadFromElement(trustbox);
      };
    },
    mana() {
      const manajs = document.createElement("script");
      manajs.type = "text/javascript";
      manajs.src = "https://jsx.s3.us-west-2.amazonaws.com/mana.js";
      manajs.async = "true";
      manajs.setAttribute("data-tag", "onboarding");
      manajs.setAttribute("id", "manaJs");
      // store it to data
      this.manaJs = document.head.appendChild(manajs);
    },
    async loadOnboarding() {
      try {
        if (window.localStorage.getItem("accessToken")) {
          const { data } = await OnboardingService.getPatientOnboarding();
          this.onboardingstep = data.onboardingstep;
          if (this.onboardingstep == 1) {
            this.step = "birthday";
            this.setParams("state", data.state);
            this.stateName = data.statename;
            this.email = data.email;
          }
          this.userId = data.id;
          //console.log('eligibility-user', data)
          if (data.fromsite || data.email) {
            this.registered = true;
          }
        } else {
          throw "Not logged in";
        }
      } catch (err) {
        return;
      }
    },
    async finish(eligibilitydata) {
      this.step = "registration";
      // Wait for registration to finish loading
      await this.$nextTick();

      this.$refs.Registration.buttonDisabled = true;
      // Attach quiz results if there are any
      let healthmarker = $cookies.get("winona-quiz-healthmarker");
      let feellikeage = $cookies.get("winona-quiz-feellikeage");
      if (healthmarker) {
        this.setParams("healthmarker", healthmarker);
      }
      if (feellikeage) {
        this.setParams("feellikeage", feellikeage);
      }

      if (!this.eligibilityParams.ovariansurgery) {
        this.eligibilityParams.ovariansurgery = false;
      }

      try {
        const { data } = await OnboardingService.updatePatientOnboarding(
          1,
          eligibilitydata
        );
        let userid = this.userId;
        const signature = crypto
          .createHmac("sha256", process.env.VUE_APP_INTERCOM_SECRETKEY)
          .update(userid)
          .digest("hex");
        if (userid != undefined) {
          window.analytics.identify(
            userid,
            {
              customerId: userid,
              age: this.age,
            },
            {
              integrations: {
                Intercom: {
                  user_hash: signature,
                },
              },
            }
          );
        }

        FS.event("Visit Started");

        // SEGMENT
        // const analyticsId = auth.usercustomid ? auth.usercustomid : auth.userid;
        const analyticsId = this.userId;

        // GTM: Mariwin
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //   event: "Visit Started",
        //   customerID: analytics,
        //   birthDate: this.eligibilityParams.birthday,
        //   state: this.eligibilityParams.state,
        //   gender: this.eligibilityParams.gender,
        // });

        if (data.eventid) {
          window.analytics.track("Visit Started", {
            customerId: data.userid,
            messageID: data.eventid,
            firstname: eligibilitydata.firstname,
            lastname: eligibilitydata.lastname,
            ...(await this.$store.dispatch("getDeviceDetailsForContext")),
            state: this.eligibilityParams.state,
            email: this.email,
            phoneno: this.registrationParams.phoneno,
          });

          // WIN-14971
          window.Northbeam.fireCustomGoal("Visit Started", {});
          console.log("Northbeam: Visit Started");

          await this.fbqTrack(data);
        } else if (data.eventid && process.env.VUE_APP_SERVER != "production") {
          // alert('FB TRACKING AccountCreated: ' + auth.eventid);
          console.log({ ...(await this.$store.dispatch("getFBQParams")) });
          console.log("FB TRACKING VisitStarted");
          console.log("FB TRACKING VisitStarted: " + data.eventid);
        }

        if (
          data.hasOwnProperty("nonrxmedicationonly") &&
          data.nonrxmedicationonly
        ) {
          window.location.href = process.env.VUE_APP_WEBSITE_URL;
        } else {
          //this.$router.push('/health-questions')
          this.$emit("setExiting", true);
          this.$router.push("/health-questions");
        }
      } catch (err) {
        this.$refs.Registration.buttonDisabled = false;
        this.showAlert(err);
        //this.$refs.Registration.buttonDisabled = false;
      }
    },
    async fbqTrack(auth) {
      // FB Pixel Tracking
      try {
        fbq("init", "348219289912097", {
          em: this.email,
          fn: this.firstname,
          ln: this.lastname,
          ph: this.phoneno,
          external_id: auth.userid,
          extern_id: auth.userid,
        });
        // fbq("track", "PageView");
        await OnboardingService.onboardingEvent("PageView");
        fbq(
          "trackCustom",
          "VisitStarted",
          {
            external_id: auth.userid,
            extern_id: auth.userid,
            state: this.eligibilityParams.state,
            ...(await this.$store.dispatch("getFBQParams")),
          },
          {
            eventID: auth.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
  },
  beforeDestroy() {
    const manaJs = document.getElementById("manaJs");
    document.head.removeChild(manaJs);
  },
  async mounted() {
    // if(window.localStorage.getItem('token')) {
    //   this.$router.push('/getting-started')
    // }

    this.mana();
    this.loadOnboarding();
    this.addTrustpilot();

    // this.$store.dispatch("sendErrorLog", {
    //   step: "Eligibility",
    //   cus_id: "testuser_1hblk412h4k12h31k2jgof65kjh",
    //   email: "kenshin@outliant.com",
    //   error_log: {
    //     logid: "test_id",
    //     message: "This is a test error from the production branch",
    //   },
    // });

    // Referral code
    if ($cookies.get("referralToken") && !$cookies.get("promocodeExistingID")) {
      try {
        const checkToken = await UsersService.checkReferralToken({
          referralcode: $cookies.get("referralToken"),
        });
        if (checkToken.message == "success") {
          this.referralToken = $cookies.get("referralToken");
          this.showAlert(
            "Referral code detected! Finish your registration to apply your referral code.",
            "success"
          );
        } else {
          this.showAlert(checkToken.message);
        }
      } catch (err) {
        if (err.message == "Not a valid referral");
        this.showAlert("The referral code you provided is not valid.");
      }
    }

    // Promo Code
    if (
      ($cookies.get("promocode") || this.$route.query.promocode) &&
      !$cookies.get("promocodeExistingID")
    ) {
      try {
        const promocode =
          $cookies.get("promocode") || this.$route.query.promocode;

        const checkDiscount = await OnboardingService.checkCoupon(promocode);

        this.coupon.couponid = promocode;
        if (promocode != "BFBLOSSOM40" && promocode != "BFBLOSSOM40FIXED") {
          this.coupon.show = true;
        }

        // this.showAlert(
        //   "Promo code detected! Finish your registration to apply your promo code.",
        //   "success"
        // );
      } catch (err) {
        this.showAlert(err.message);
      }
    }

    // Flow B
    if (this.$route.query.flow) {
      const flow =
        this.$route.query.flow == "b" || this.$route.query.flow == "flowB"
          ? "flowB"
          : "";
      $cookies.set("onboardingflow", flow);
    }

    // SEGMENT
    window.analytics.page();

    // Full Story
    console.log("FS Started Onboarding");
    window.analytics.track(
      "Onboarding Started",
      {
        // customerId: auth.userid
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
        onboardingflow: this.onboardingflow,
      },
      {
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );

    window.Northbeam.fireCustomGoal("Onboarding Started", {});
    console.log("Northbeam: Visit Started");

    //INTEGROMAT
    let onboardingStartedBody = {
      referralcode: $cookies.get("referralToken")
        ? $cookies.get("referralToken")
        : null,
    };
    await OnboardingService.onboardingEvent(
      "ONBOARDING_STARTED",
      onboardingStartedBody
    );

    // WIN-15171
    // Save timestamp as cookie
    const now = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    $cookies.set("onboardingStartedTimestamp", now);

    // this.showLoader(2);
  },
  beforeRouteLeave(to, from, next) {
    // Check if there's a modal open
    if (this.$store.state.global.isModalOpen) {
      next(false);
      return;
    }
    next();
  },
};
</script>

<style scoped>
/* /deep/.vs__dropdown-toggle:not(.custom-select) {
  padding-right: 0.75em;
} */

/deep/.custom-input-style .vs__dropdown-toggle {
  margin-top: 10px;
  /* padding-left: 0.75em; */
  border: 0;
  background-color: rgba(242, 242, 242, 0.5);
  border-radius: 30px;
}
</style>

<style lang="scss" scoped>
.custom-control-label {
  line-height: 1.1;
}

.certified-logo {
  @include media-breakpoint-up(xxl) {
    margin-top: 130px;
  }
}
</style>
