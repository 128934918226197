import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class PrescriptionService extends BaseService {
  static async getPrescriptionorder(patientId, prescriptionId) {
    try {
      const response = await this.request({ auth: true }).get(
        `/prescriptionorder/${patientId}/${prescriptionId}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getAllPrescriptionOrders() {
    try {
      const response = await this.request({ auth: true }, "v2").get(
        `/prescriptionOrder/full`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async cancelProcessingOrder(patientId, payload) {
    try {
      const response = await this.request({ auth: true }).post(
        `/transact/patient/${patientId}/cancelorder`,
        payload
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
