var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eligibilityState" },
    [
      _c("h2", { staticClass: "question-title" }, [
        _vm._v(" Let’s make sure we’re available in your state ")
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "div",
                { staticClass: "group-mb", attrs: { role: "group" } },
                [
                  _c(
                    "label",
                    { staticClass: "label-text", attrs: { for: "state" } },
                    [_vm._v("What state do you live in?")]
                  ),
                  _c(
                    "v-select",
                    {
                      staticClass: "custom-input-style fs-mask",
                      attrs: {
                        "append-to-body": "",
                        "calculate-position": _vm.withPopper,
                        label: "text",
                        options: _vm.states,
                        reduce: function(states) {
                          return states.value
                        },
                        searchable: false,
                        placeholder: "Select state"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "open-indicator",
                          fn: function(ref) {
                            var attributes = ref.attributes
                            return [
                              _c(
                                "b-icon",
                                _vm._b(
                                  { attrs: { icon: "chevron-down" } },
                                  "b-icon",
                                  attributes,
                                  false
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.state,
                        callback: function($$v) {
                          _vm.state = $$v
                        },
                        expression: "state"
                      }
                    },
                    [
                      _vm.states.length == 0
                        ? _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options"
                            },
                            [_vm._v("Loading...")]
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "checkbox-label",
                  attrs: {
                    id: "terms",
                    name: "checkbox-1",
                    value: "accepted",
                    "unchecked-value": "not_accepted"
                  },
                  model: {
                    value: _vm.terms,
                    callback: function($$v) {
                      _vm.terms = $$v
                    },
                    expression: "terms"
                  }
                },
                [
                  _c("span", [_vm._v("I have read and agree to these ")]),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.termsModal = true
                        }
                      }
                    },
                    [_vm._v(" Terms & Conditions, ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.termsModal = true
                        }
                      }
                    },
                    [_vm._v("Privacy Policy,")]
                  ),
                  _vm._v(" and "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.termsModal = true
                        }
                      }
                    },
                    [_vm._v("Telehealth Consent")]
                  )
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-3",
                  attrs: { pill: "", variant: "info", disabled: _vm.disabled },
                  on: { click: _vm.submitState }
                },
                [_vm._v("Continue")]
              ),
              _c(
                "small",
                { staticClass: "d-block" },
                [
                  _vm._v("Already have an account? "),
                  _c("router-link", { attrs: { to: "/login" } }, [
                    _vm._v("Sign In")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("TermsModal", {
        attrs: {
          show: _vm.termsModal,
          "header-class": "terms-title",
          "body-class": "terms-body"
        },
        on: {
          exit: function($event) {
            _vm.termsModal = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c("h1", { staticClass: "h2 mb-0 heading text-primary pr-4" }, [
                  _vm._v(
                    " Terms & Conditions, Privacy Policy and Telehealth Consent "
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _c("terms"),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "text-right", attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "min-width": "250px" },
                            attrs: { pill: "", variant: "info" },
                            on: {
                              click: function($event) {
                                return _vm.agreeTerms()
                              }
                            }
                          },
                          [_vm._v(" I Agree ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("div", {
        staticClass: "bg-overlay",
        on: {
          click: function($event) {
            _vm.termsModal = false
          }
        }
      }),
      _c(
        "modal",
        {
          attrs: {
            id: "agreement",
            "modal-class": "agreement",
            size: "sm",
            centered: "",
            "hide-header": "",
            "hide-footer": ""
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("h2", { staticClass: "mb-0 text-center link" }, [
            _vm._v(
              " Winona’s treatments require our customers to agree to the terms "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }