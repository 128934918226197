var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-checkout" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: "warning" } },
                [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            [
              _c("label", { staticClass: "sub-text" }, [
                _vm._v(" Preferred Treatment Type ")
              ]),
              _c("v-select", {
                staticClass: "custom-input-style",
                attrs: {
                  options: _vm.options,
                  label: "text",
                  reduce: function(options) {
                    return options.value
                  },
                  clearable: false,
                  searchable: false,
                  placeholder: "Select treatment type"
                },
                scopedSlots: _vm._u([
                  {
                    key: "open-indicator",
                    fn: function(ref) {
                      var attributes = ref.attributes
                      return [
                        _c(
                          "b-icon",
                          _vm._b(
                            { attrs: { icon: "chevron-down" } },
                            "b-icon",
                            attributes,
                            false
                          )
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedTreatment,
                  callback: function($$v) {
                    _vm.selectedTreatment = $$v
                  },
                  expression: "selectedTreatment"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _vm.editedPlan.length > 0
            ? _c(
                "b-col",
                _vm._l(_vm.editedPlan, function(product) {
                  return _c("ProductRevealCheckbox", {
                    key: product.id,
                    ref: "ProductRevealCheckbox",
                    refInFor: true,
                    attrs: { product: product },
                    on: {
                      "set-selection": _vm.updateSelection,
                      "update-shipping": _vm.updateShipping
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-header": "",
            "hide-footer": "",
            centered: "",
            "content-class": "dark-bg",
            "no-close-on-backdrop": "",
            "backdrop.preventDefault": "",
            "no-close-on-esc": ""
          },
          model: {
            value: _vm.progesteroneModal,
            callback: function($$v) {
              _vm.progesteroneModal = $$v
            },
            expression: "progesteroneModal"
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo dark" })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c(
                "p",
                { staticClass: "sub-text", staticStyle: { color: "inherit" } },
                [
                  _vm._v(
                    " Because of your medical history, it would not be safe to take Estrogen without Progesterone. "
                  )
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "info", pill: "", block: "" },
                  on: {
                    click: function($event) {
                      _vm.progesteroneModal = false
                    }
                  }
                },
                [_vm._v("Continue")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "d-flex jutify-content-end" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    variant: "outline-info",
                    pill: "",
                    size: "lg",
                    disabled: _vm.buttonDisabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close-edit")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-2",
                  attrs: {
                    variant: "info",
                    pill: "",
                    size: "lg",
                    disabled: _vm.buttonDisabled
                  },
                  on: { click: _vm.submitAnswer }
                },
                [_vm._v(" Update ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }