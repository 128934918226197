var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("w-form", {
    attrs: {
      name: this.$route.name,
      title: "Recover Password",
      "sub-title": "Don’t worry, happens to the best of us."
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }