var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "week-nothing", attrs: { "align-v": "stretch" } },
    [
      _vm.question.type == "exitmodal"
        ? _c(
            "b-col",
            { attrs: { "align-self": "center" } },
            [
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "h1",
                        { staticClass: "text-center question-title-v2 mb-4" },
                        [_vm._v(" " + _vm._s(_vm.question.title) + " ")]
                      ),
                      _c(
                        "b-row",
                        {
                          staticClass: "mt-5 mb-4 mx-auto",
                          attrs: { "align-v": "center", "align-h": "center" }
                        },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("b-avatar", {
                                attrs: {
                                  variant: "primary",
                                  src: _vm.question.doctor.profileUrl,
                                  size: "7rem"
                                }
                              })
                            ],
                            1
                          ),
                          _c("b-col", { attrs: { cols: "auto" } }, [
                            _c(
                              "h2",
                              { staticClass: "mb-0 bold d-letter-spacing" },
                              [
                                _vm._v(
                                  " Dr. " +
                                    _vm._s(_vm.question.doctor.firstname) +
                                    " " +
                                    _vm._s(_vm.question.doctor.lastname) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "px-6 mt-4 mx-auto d-block",
                          attrs: {
                            pill: "",
                            variant: "info",
                            size: "lg",
                            disabled: _vm.disabled
                          },
                          on: { click: _vm.submitAnswer }
                        },
                        [_vm._v("Continue")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "b-col",
            {
              staticClass: "d-flex flex-column",
              attrs: { "align-self": "center" }
            },
            [
              _c("h2", { staticClass: "text-center question-title-v2 mb-2" }, [
                _vm._v(_vm._s(_vm.question.title) + ",")
              ]),
              _vm.question.description
                ? _c(
                    "p",
                    { staticClass: "sub-text group-mb text-center mb-4" },
                    [_vm._v(" " + _vm._s(_vm.question.description) + " ")]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "align-self-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "px-6",
                      attrs: {
                        pill: "",
                        variant: "info",
                        size: "lg",
                        disabled: _vm.disabled
                      },
                      on: { click: _vm.submitAnswer }
                    },
                    [_vm._v("Continue")]
                  )
                ],
                1
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }