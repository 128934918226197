var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { staticClass: "cart-registration", attrs: { cols: "12" } },
    [
      _c(
        "b-row",
        {
          staticClass: "py-3",
          staticStyle: { height: "100%" },
          attrs: { "align-v": "stretch" }
        },
        [
          _c(
            "b-col",
            { attrs: { "align-self": "start" } },
            [
              _c(
                "h3",
                {
                  staticClass: "bold-font text-right mt-3",
                  staticStyle: { "letter-spacing": "0.16em" }
                },
                [_vm._v(" ACCOUNT ")]
              ),
              _vm.cartPath
                ? _c("cartIcon", {
                    staticClass: "mt-5",
                    attrs: { number: "2" }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "mt-4",
              attrs: { cols: "12", "align-self": "start" }
            },
            [
              _c(
                "h1",
                { staticClass: "mb-3 heading text-center" },
                [
                  _vm.cartPath
                    ? [_vm._v(" Welcome to Winona! ")]
                    : [_vm._v(" Hello, beautiful! ")]
                ],
                2
              ),
              _c(
                "p",
                { staticClass: "label-text text-center" },
                [
                  _vm.cartPath
                    ? [_vm._v(" We're here to help you feel your best. ")]
                    : [_vm._v(" Please sign up to continue ")]
                ],
                2
              )
            ]
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", "align-self": "center" } },
            [
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: "warning" } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c("b-col", { attrs: { cols: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: { for: "firstName" }
                          },
                          [_vm._v("First Name*")]
                        ),
                        _c("b-form-input", {
                          staticClass: "pl-0 fs-mask",
                          attrs: {
                            id: "firstName",
                            type: "text",
                            autocomplete: "off",
                            required: ""
                          },
                          model: {
                            value: _vm.firstName,
                            callback: function($$v) {
                              _vm.firstName = $$v
                            },
                            expression: "firstName"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("b-col", { attrs: { cols: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: { for: "lastName" }
                          },
                          [_vm._v("Last Name*")]
                        ),
                        _c("b-form-input", {
                          staticClass: "pl-0 fs-mask",
                          attrs: {
                            id: "lastName",
                            type: "text",
                            autocomplete: "off",
                            required: ""
                          },
                          model: {
                            value: _vm.lastName,
                            callback: function($$v) {
                              _vm.lastName = $$v
                            },
                            expression: "lastName"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4", attrs: { role: "group" } },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "email" } },
                    [_vm._v("Email Address*")]
                  ),
                  _c("b-form-input", {
                    staticClass: "pl-0 fs-mask",
                    attrs: {
                      id: "email",
                      type: "email",
                      state: _vm.emailState,
                      autocomplete: "off",
                      required: ""
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "input-live-feedback" } },
                    [_vm._v(" The email must have '@email.com' ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4", attrs: { role: "group" } },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "password" } },
                    [_vm._v("Password*")]
                  ),
                  _c("b-form-input", {
                    staticClass: "pl-0 fs-mask",
                    attrs: {
                      id: "password",
                      type: "password",
                      state: _vm.passwordState,
                      required: "",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "input-live-feedback" } },
                    [_vm._v(" Min. 8 characters ")]
                  )
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "checkbox-label",
                  attrs: { id: "terms", name: "terms" },
                  model: {
                    value: _vm.iAgree,
                    callback: function($$v) {
                      _vm.iAgree = $$v
                    },
                    expression: "iAgree"
                  }
                },
                [
                  _vm._v(" I have read and agree to these "),
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.termLink },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showDialog(_vm.termLink)
                        }
                      }
                    },
                    [_vm._v("Terms & Conditions")]
                  ),
                  _vm._v(", "),
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.privacyLink },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showDialog(_vm.privacyLink)
                        }
                      }
                    },
                    [_vm._v("Privacy Policy")]
                  ),
                  _vm._v(", and "),
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.teleLink },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showDialog(_vm.teleLink)
                        }
                      }
                    },
                    [_vm._v("Telehealth Consent")]
                  )
                ]
              ),
              _c(
                "small",
                { staticClass: "my-5 pt-2 d-block text-center small-text" },
                [
                  _vm._v(" Already have an account? "),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)", tabindex: "-1" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("set-screen", "login")
                        }
                      }
                    },
                    [_vm._v("Log In")]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", "align-self": "end" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: _vm.buttonDisabled,
                    pill: "",
                    block: "",
                    variant: "primary"
                  },
                  on: { click: _vm.register }
                },
                [_vm._v(" Register ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }