var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "billing-display" },
    [
      _c("CreditModal", {
        attrs: {
          show: _vm.showModal,
          "header-class": "id-modal",
          "body-class": "id-body-modal"
        },
        on: {
          exit: function($event) {
            return _vm.hideModal()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c("img", {
                  attrs: { src: require("@/assets/images/credit.svg"), alt: "" }
                }),
                _c(
                  "b-row",
                  { staticClass: "security-row mt-5 pt-3" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("b-icon", { attrs: { icon: "lock-fill" } }),
                        _vm._v(" 256-BIT TLS SECURITY ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _c(
                  "h3",
                  {
                    staticClass: "sub-text text-uppercase text-primary",
                    staticStyle: { "letter-spacing": "0.16em" }
                  },
                  [_vm._v(" BILLING INFORMATION ")]
                ),
                _c(
                  "h2",
                  {
                    staticClass:
                      "heading h1 text-default mt-5 mb-2 initial-line-height"
                  },
                  [_vm._v(" Why do you need my credit card information? ")]
                ),
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " Winona’s physicians are on hand to review your medical information quickly in order to provide you with safe, accessible care so you can feel better right away. "
                  )
                ]),
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " We ask that you share your credit card information so that if the medication is approved by one of our doctors we can get it to you right away and to ensure that our patients are serious about the desire to receive treatment. "
                  )
                ]),
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " Your card will never be charged until treatment is prescribed. "
                  )
                ]),
                _c(
                  "h2",
                  {
                    staticClass:
                      "heading h1 text-default mt-4 mb-2 initial-line-height"
                  },
                  [
                    _vm._v(
                      " Shouldn’t I talk to a Winona doctor before purchasing treatment? "
                    )
                  ]
                ),
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " Your Winona physician will never prescribe a treatment without first reviewing your medical history, and addressing any questions or concerns either of you may have. "
                  )
                ]),
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " Once you enter your credit card information you will be directed to your secure patient portal where you can directly message your doctor. If you have any additional questions, you can ask them at this step. "
                  )
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "mt-5",
                    attrs: { pill: "", block: "", variant: "info" },
                    on: {
                      click: function($event) {
                        return _vm.hideModal()
                      }
                    }
                  },
                  [_vm._v("I understand")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("div", {
        staticClass: "bg-overlay",
        on: {
          click: function($event) {
            return _vm.hideModal()
          }
        }
      }),
      _c("h2", { staticClass: "question-title with-subtext" }, [
        _vm._v("Your doctor is waiting")
      ]),
      _c("p", { staticClass: "sub-text" }, [
        _vm._v(
          " Add a payment method to be used if treatment is prescribed (you will not be charged now). "
        ),
        _c("br"),
        _c(
          "span",
          {
            staticClass: "text-underline",
            staticStyle: { color: "#828282", cursor: "pointer" },
            on: {
              click: function($event) {
                return _vm.openModal()
              }
            }
          },
          [_vm._v("Why do you need my credit card information?")]
        )
      ]),
      !_vm.nonrx && _vm.body.desiredplan
        ? _c(
            "b-card",
            { staticStyle: { "background-color": "rgba(242, 242, 242, 0.5)" } },
            [
              _c("h3", { staticClass: "label-text mb-5" }, [
                _vm._v("Your Treatment if Prescribed")
              ]),
              _vm._l(
                _vm.body.desiredplan.filter(function(x) {
                  return (
                    x.type === undefined ||
                    (x.type != undefined && x.type === "RX")
                  )
                }),
                function(med) {
                  return _c(
                    "b-row",
                    {
                      key: med.productid,
                      staticStyle: { "margin-bottom": "1rem" },
                      attrs: { "align-v": "center" }
                    },
                    [
                      _c("b-col", { attrs: { cols: "8" } }, [
                        _c("h4", { staticClass: "label-text mb-1" }, [
                          _vm._v(_vm._s(med.name))
                        ]),
                        _c("span", { staticClass: "small-text" }, [
                          _vm._v(_vm._s(med.pricingdetail.nickname))
                        ])
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "4" } },
                        [
                          _c("h3", { staticClass: "label-text" }, [
                            _vm._v("$" + _vm._s(med.price))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              ),
              _c("div", { staticClass: "mt-4" }),
              _vm.body.hasOwnProperty("referraldiscountamount") ||
              _vm.discountDisplay
                ? _c("hr")
                : _vm._e(),
              _vm.body.referraldiscountamount
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "8" } }, [
                        _c("span", { staticClass: "small-text" }, [
                          _vm._v("First Time Referral Discount")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "4" } },
                        [
                          _c("span", { staticClass: "small-text" }, [
                            _vm._v(
                              " -$" +
                                _vm._s(_vm.body.referraldiscountamount) +
                                " "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.discount > 0
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "8" } }, [
                        _c("h4", { staticClass: "label-text mb-1" }, [
                          _vm._v(_vm._s(this.body.couponid))
                        ]),
                        _vm.couponData.percent_off
                          ? _c("span", { staticClass: "small-text" }, [
                              _vm._v(
                                _vm._s(_vm.couponData.percent_off) + "% off"
                              ),
                              _vm.couponData.duration == "once"
                                ? _c("span", [
                                    _vm._v(" of your first purchase")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "4" } },
                        [
                          _c("span", { staticClass: "small-text text-info" }, [
                            _vm._v(_vm._s(_vm.couponData.percent_off) + "% off")
                          ]),
                          _c("span", { staticClass: "small-text" }, [
                            _vm._v(" -$" + _vm._s(_vm.discountDisplay) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("hr", {
                staticClass: "my-3",
                staticStyle: { "border-color": "#19191c" }
              }),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "8" } }, [
                    _c("span", { staticClass: "small-text" }, [
                      _vm._v("Online doctor visit & shipping")
                    ])
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "4" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "small-text",
                          staticStyle: { "text-decoration": "line-through" }
                        },
                        [_vm._v("$50.00")]
                      ),
                      _c("span", { staticClass: "small-text" }, [
                        _vm._v(" Free")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e(),
      _vm.body.pharmacyid != ""
        ? _c(
            "b-card",
            { staticStyle: { "background-color": "rgba(242, 242, 242, 0.5)" } },
            [
              _c("h3", { staticClass: "mb-5 label-text" }, [
                _vm._v("Platform Fee")
              ]),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "8" } }, [
                    _c("span", { staticClass: "small-text" }, [
                      _vm._v("Outside Winona Pharmacy Network")
                    ])
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "4" } },
                    [
                      _c("span", { staticClass: "small-text" }, [
                        _vm._v("$" + _vm._s(_vm.platformfee.toFixed(2)))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.desiredAddon.length > 0
        ? _c("h3", { staticClass: "label-text mt-5 pt-2" }, [_vm._v("To Pay")])
        : _vm._e(),
      _vm.desiredAddon.length > 0
        ? _c(
            "b-card",
            { staticStyle: { "background-color": "rgba(242, 242, 242, 0.5)" } },
            [
              _c("h3", { staticClass: "mb-5 label-text" }, [
                _vm._v("Your Order")
              ]),
              _c("hr", { staticClass: "my-3" }),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "8" } }, [
                    _vm._v(" Order Sub-total ")
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "4" } },
                    [_vm._v(" $" + _vm._s(_vm.subtotal.toFixed(2)) + " ")]
                  )
                ],
                1
              ),
              _vm.body.pharmacyid != ""
                ? _c("hr", { staticClass: "my-3" })
                : _vm._e(),
              _vm.body.pharmacyid != ""
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "8" } }, [
                        _vm._v(" Platform fee ")
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "4" } },
                        [
                          _vm._v(
                            " $" + _vm._s(_vm.platformfee.toFixed(2)) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("hr", { staticClass: "my-3" }),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "8" } }, [_vm._v(" Tax ")]),
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "4" } },
                    [_vm._v(" $" + _vm._s(_vm.taxamount.toFixed(2)) + " ")]
                  )
                ],
                1
              ),
              _c("hr", {
                staticClass: "my-3",
                staticStyle: {
                  "border-color": "#19191c",
                  "border-width": "3px"
                }
              }),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "6" } }, [
                    _c("b", [_vm._v("Grand Total")])
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "6" } },
                    [
                      _c("b", [
                        _vm._v("$" + _vm._s(_vm.totalamount.toFixed(2)))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }