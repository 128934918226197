export default {
  namespaced: true,
  state: {
    isImageUploadProcessing: false,
  },
  mutations: {
    SET_IS_IMAGE_UPLOAD_PROCESSING(state, payload) {
      state.isImageUploadProcessing = payload;
    },
  },
  getters: {},
  actions: {},
};
