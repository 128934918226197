var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "breadcrumb" },
    _vm._l(_vm.icon, function(n, i) {
      return _c("li", { key: i, class: { active: i + 1 == _vm.number } }, [
        _c(
          "div",
          { staticClass: "icon-box" },
          [
            _c("v-icon", {
              attrs: {
                name: n.name,
                color: i + 1 == _vm.number ? "#19191C" : "#BDBDBD",
                size: "20"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "spacer" },
          [_c("b-icon", { attrs: { icon: "chevron-right" } })],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }