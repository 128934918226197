var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-card" },
    [
      _c("div", {
        staticClass: "image-holder",
        style: {
          backgroundImage: "url(" + _vm.image + ")",
          height: _vm.imageHeight,
          backgroundColor: _vm.bgColor,
          "--borderColor": _vm.borderColor
        }
      }),
      _vm.link
        ? _c("router-link", {
            attrs: { to: _vm.link, custom: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var navigate = ref.navigate
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "text-default",
                          attrs: { href: "javascript:void(0)" },
                          on: { click: navigate }
                        },
                        [
                          _c("h3", { staticClass: "mb-0 heading" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "70%",
                                  display: "inline-block"
                                }
                              },
                              [_vm._v(_vm._s(_vm.text))]
                            ),
                            _c("span", { staticClass: "next-arrow" })
                          ])
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3602734744
            )
          })
        : _vm._e(),
      _vm.url
        ? _c(
            "a",
            {
              staticClass: "text-default",
              attrs: { href: _vm.url, target: "_blank" }
            },
            [
              _c("h3", { staticClass: "mb-0 heading" }, [
                _c(
                  "span",
                  { staticStyle: { width: "70%", display: "inline-block" } },
                  [_vm._v(" " + _vm._s(_vm.text) + " ")]
                ),
                _c("span", { staticClass: "next-arrow" })
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }