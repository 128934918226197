var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { staticClass: "cart-shipping", attrs: { cols: "12" } },
    [
      _c(
        "b-row",
        { staticStyle: { height: "100%" }, attrs: { "align-v": "stretch" } },
        [
          _c(
            "b-col",
            { attrs: { "align-self": "start" } },
            [
              _c(
                "h3",
                {
                  staticClass: "bold-font text-right mt-3",
                  staticStyle: { "letter-spacing": "0.16em" }
                },
                [_vm._v(" SHIPPING ")]
              ),
              _c("cartIcon", { staticClass: "my-3", attrs: { number: "3" } })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", "align-self": "center" } },
            [
              _c("h2", { staticClass: "heading mb-1 text-center" }, [
                _vm._v("Shipping Information")
              ]),
              _c("p", { staticClass: "text-center" }, [
                _vm._v(" Where would you like us to ship this? ")
              ]),
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: "warning" } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
              _c(
                "div",
                { staticClass: "mb-3", attrs: { role: "group" } },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "address" } },
                    [_vm._v("Street Address*:")]
                  ),
                  _c("b-form-input", {
                    staticClass: "pl-0 fs-mask",
                    attrs: { id: "address1", type: "text" },
                    model: {
                      value: _vm.address1,
                      callback: function($$v) {
                        _vm.address1 = $$v
                      },
                      expression: "address1"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-3", attrs: { role: "group" } },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "address2" } },
                    [_vm._v("Apartment/Suite*:")]
                  ),
                  _c("b-form-input", {
                    staticClass: "pl-0 fs-mask",
                    attrs: { id: "address2", type: "text" },
                    model: {
                      value: _vm.address2,
                      callback: function($$v) {
                        _vm.address2 = $$v
                      },
                      expression: "address2"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-3", attrs: { role: "group" } },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "state" } },
                    [_vm._v("State*:")]
                  ),
                  _c("v-select", {
                    staticClass: "cart-state fs-mask",
                    attrs: {
                      "append-to-body": "",
                      "calculate-position": _vm.withPopper,
                      label: "text",
                      options: _vm.stateoptions,
                      reduce: function(stateoptions) {
                        return stateoptions.value
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "open-indicator",
                        fn: function(ref) {
                          var attributes = ref.attributes
                          return [
                            _c(
                              "b-icon",
                              _vm._b(
                                { attrs: { icon: "chevron-down" } },
                                "b-icon",
                                attributes,
                                false
                              )
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.state,
                      callback: function($$v) {
                        _vm.state = $$v
                      },
                      expression: "state"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-3", attrs: { role: "group" } },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: { for: "city" }
                          },
                          [_vm._v("City*:")]
                        ),
                        _c("b-form-input", {
                          staticClass: "pl-0 fs-mask",
                          attrs: { id: "city", type: "text" },
                          model: {
                            value: _vm.city,
                            callback: function($$v) {
                              _vm.city = $$v
                            },
                            expression: "city"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("b-col", { attrs: { cols: "6", md: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "mb-3", attrs: { role: "group" } },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: { for: "zipcode" }
                          },
                          [_vm._v("Zip Code*:")]
                        ),
                        _c("b-form-input", {
                          staticClass: "pl-0 fs-mask",
                          attrs: { id: "zipcode", type: "text" },
                          model: {
                            value: _vm.zipcode,
                            callback: function($$v) {
                              _vm.zipcode = $$v
                            },
                            expression: "zipcode"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", "align-self": "end" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "mt-3 mb-3",
                  attrs: { pill: "", block: "", variant: "link" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("set-screen", "cart")
                    }
                  }
                },
                [_vm._v("Back")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-3 mb-3",
                  attrs: { pill: "", block: "", variant: "primary" },
                  on: { click: _vm.submitShipping }
                },
                [_vm._v("Continue")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }