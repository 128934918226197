var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "billing-address" },
    [
      _c("h3", { staticClass: "med-font" }, [_vm._v("Billing Address")]),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "address1" } },
                [_vm._v("Street Address")]
              ),
              _c("vue-google-autocomplete", {
                ref: "streetAddress",
                staticClass: "fs-mask onboarding-input",
                attrs: {
                  id: "map",
                  classname: "form-control",
                  placeholder: "Street address",
                  country: "us"
                },
                on: {
                  placechanged: _vm.getAddressData,
                  inputChange: _vm.onStreetAddressInputChange,
                  change: _vm.onStreetAddressChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "address2" } },
                [_vm._v("Apartment/Suite")]
              ),
              _c("b-form-input", {
                staticClass: "fs-mask onboarding-input",
                attrs: { name: "address2", placeholder: "Apartment/Suite" },
                on: {
                  input: function($event) {
                    _vm.fromAutocomplete = false
                  }
                },
                model: {
                  value: _vm.billingaddress.address2,
                  callback: function($$v) {
                    _vm.$set(_vm.billingaddress, "address2", $$v)
                  },
                  expression: "billingaddress.address2"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "city" } },
                [_vm._v("City")]
              ),
              _c("b-form-input", {
                staticClass: "fs-mask onboarding-input",
                attrs: { name: "city", placeholder: "City" },
                on: {
                  input: function($event) {
                    _vm.fromAutocomplete = false
                  }
                },
                model: {
                  value: _vm.billingaddress.city,
                  callback: function($$v) {
                    _vm.$set(_vm.billingaddress, "city", $$v)
                  },
                  expression: "billingaddress.city"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "state" } },
                [_vm._v("State")]
              ),
              _c("b-form-select", {
                staticClass: "fs-mask onboarding-input",
                attrs: {
                  options: _vm.stateNames,
                  name: "state",
                  placeholder: "State"
                },
                on: {
                  input: function($event) {
                    _vm.fromAutocomplete = false
                  }
                },
                model: {
                  value: _vm.billingaddress.state,
                  callback: function($$v) {
                    _vm.$set(_vm.billingaddress, "state", $$v)
                  },
                  expression: "billingaddress.state"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "zipcode" } },
                [_vm._v("Zip Code")]
              ),
              _c("b-form-input", {
                staticClass: "fs-mask onboarding-input",
                attrs: { name: "zipcode", placeholder: "Zip Code" },
                on: {
                  input: function($event) {
                    _vm.fromAutocomplete = false
                  }
                },
                model: {
                  value: _vm.billingaddress.zipcode,
                  callback: function($$v) {
                    _vm.$set(_vm.billingaddress, "zipcode", $$v)
                  },
                  expression: "billingaddress.zipcode"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            [
              _c(
                "label",
                { staticClass: "input-label", attrs: { for: "phoneno" } },
                [_vm._v("Phone")]
              ),
              _c("b-form-input", {
                staticClass: "fs-mask onboarding-input",
                attrs: {
                  name: "phoneno",
                  placeholder: "Phone No.",
                  formatter: _vm.formatPhoneNumber
                },
                on: {
                  input: function($event) {
                    _vm.fromAutocomplete = false
                  }
                },
                model: {
                  value: _vm.billingaddress.phoneno,
                  callback: function($$v) {
                    _vm.$set(_vm.billingaddress, "phoneno", $$v)
                  },
                  expression: "billingaddress.phoneno"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }