var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "sm", fixed: "top" } },
        [
          _c(
            "b-navbar-brand",
            { staticClass: "c-logo relative mb-0", attrs: { to: "/" } },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/logo.svg"), alt: "" }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        {
          staticStyle: {
            "padding-top": "150px",
            "padding-bottom": "100px",
            "padding-left": "25px",
            "padding-right": "25px"
          },
          attrs: { "align-h": "center" }
        },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "6", xl: "6" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("h1", { staticClass: "heading" }, [
                        _vm._v("Success! Your visit has been sent to a doctor.")
                      ]),
                      _c("p", [
                        _vm._v(
                          "Thank your for your submission (order #0000000). Your visit is being reviewed by a doctor in the office of Dr. Lorem Ipsum."
                        )
                      ]),
                      _c(
                        "b-row",
                        {
                          staticClass: "mt-5 mb-6",
                          attrs: { "align-v": "center" }
                        },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("b-avatar", {
                                attrs: {
                                  src: "https://placekitten.com/300/300",
                                  size: "10rem"
                                }
                              })
                            ],
                            1
                          ),
                          _c("b-col", [
                            _c(
                              "h2",
                              { staticClass: "mb-0 bold d-letter-spacing" },
                              [_vm._v("Dr. Lorem Ipsum")]
                            ),
                            _c("p", [_vm._v("Primary Care Physician")])
                          ])
                        ],
                        1
                      ),
                      _c("h2", { staticClass: "bold d-letter-spacing" }, [
                        _vm._v("Next steps")
                      ]),
                      _c("p", [
                        _vm._v(
                          "Your doctor will review your medical history and preferences. If approved, you will be prescribed a treatment and received your medication in 0-0 days."
                        )
                      ]),
                      _c("ul", { staticClass: "list-unstyled timeline" }, [
                        _c(
                          "li",
                          { staticClass: "done" },
                          [
                            _c("b-icon", {
                              attrs: { icon: "check-circle-fill" }
                            }),
                            _vm._v(" submitted")
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "in-progress" },
                          [
                            _c("b-icon", {
                              attrs: { icon: "circle-fill", scale: ".4" }
                            }),
                            _vm._v(" Choosing Doctor")
                          ],
                          1
                        ),
                        _c("li", [_vm._v("Doctor Review")]),
                        _c("li", [_vm._v("Diagnosis and treatment")])
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass: "mb-4",
                          attrs: { pill: "", variant: "primary" },
                          on: { click: _vm.done }
                        },
                        [_vm._v("Go to Patient Dashboard")]
                      ),
                      _c("h2", { staticClass: "bold d-letter-spacing" }, [
                        _vm._v("Have a question?")
                      ]),
                      _c(
                        "p",
                        [
                          _vm._v("Please go to our "),
                          _c("router-link", {
                            attrs: { to: "/support", custom: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var navigate = ref.navigate
                                  return [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: { click: navigate }
                                      },
                                      [_vm._v("Help Center")]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(
                            " where you get answers to commonly asked reestions about billing, shipment status, etc."
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }