<template>
  <b-navbar toggleable="sm" fixed="top">
    <b-container fluid class="navigator">
      <b-navbar-brand to="/">
        <!-- <template v-if="this.$route.name == 'support'">
          <img src="@/assets/images/logo_lime.svg" alt="" />
        </template> -->
        <template>
          <img src="@/assets/images/logo_black.svg" alt="" />
        </template>
      </b-navbar-brand>

      <b-navbar-nav
        class="d-none d-sm-none d-md-flex"
        style="padding-left: 40px"
      >
        <b-nav-item
          v-for="(list, i) in navList"
          :key="i + '3'"
          :to="'/' + list.link"
          exact
          exact-active-class="active"
          class="px-3"
        >
          <span class="d-flex align-items-center">
            <span style="line-height: 14px">{{ list.name }}</span>
            <b-badge
              v-if="list.link === 'message' && unreadMessagesCount > 0"
              style="background: #ff8d40; color: white; line-height: 11px"
              pill
              class="ml-2"
            >
              {{ unreadMessagesCount }}
            </b-badge>
          </span>
        </b-nav-item>
      </b-navbar-nav>

      <div class="navbar-user ml-center">
        <notification />

        <b-dropdown variant="link" right no-caret :class="['user-dropdown']">
          <template #button-content>
            <span
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              class="dropdown-toggle"
              style="padding: 0 !important"
            >
              <b-avatar
                variant="primary"
                :text="username && username.charAt(0)"
                class="mr-3"
              ></b-avatar>
              <h4
                class="
                  mb-0
                  pr-2
                  text-uppercase
                  d-none d-sm-none d-md-none d-lg-flex
                  fs-mask
                "
              >
                {{ username }}
              </h4>
              <b-icon icon="caret-down-fill" font-scale="0.75"></b-icon>
            </span>
          </template>

          <b-dropdown-item
            class="d-md-none"
            v-for="(list, i) in navList"
            :key="i + '3'"
            :to="'/' + list.link"
            exact
            exact-active-class="active"
          >
            <span class="d-flex align-items-center">
              <span style="line-height: 14px">{{ list.name }}</span>
              <b-badge
                v-if="list.link === 'message' && unreadMessagesCount > 0"
                style="background: #ff8d40; color: white; line-height: 11px"
                pill
                class="ml-2"
              >
                {{ unreadMessagesCount }}
              </b-badge>
            </span>
          </b-dropdown-item>

          <b-dropdown-divider class="d-md-none"></b-dropdown-divider>

          <b-dropdown-item
            v-for="(a, i) in accountList"
            :key="i + '1'"
            :to="'/' + a.link"
            exact
            exact-active-class="active"
          >
            {{ a.name }}
          </b-dropdown-item>

          <b-dropdown-divider class="d-md-none"></b-dropdown-divider>

          <b-dropdown-item
            exact
            exact-active-class="active"
            :to="{ name: 'insurance-documents' }"
          >
            Insurance Documents
          </b-dropdown-item>

          <!-- WIN-8549 -->
          <!-- HSA Receipt Download -->
          <!-- Hide receipt download for WIN-10098 -->
          <!-- <b-dropdown-item
            exact
            exact-active-class="active"
            @click="$refs.HSAReceiptDialog.showDialog = true"
          >
            HSA Receipt
          </b-dropdown-item> -->

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item @click="logout"> Logout </b-dropdown-item>

          <HSAReceiptDialog ref="HSAReceiptDialog" :id="id" :name="username" />
        </b-dropdown>
      </div>
      <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

      <!-- <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav>

          <b-nav-item 
            v-for="(list, i) in navList" 
            :key="i + '3'" 
            :to="'/' + list.link"
            exact
            exact-active-class="active"
            class="px-3"
            >
            {{ list.name }}
          </b-nav-item>

        </b-navbar-nav>

      </b-collapse> -->
    </b-container>
  </b-navbar>
</template>

<script>
import { mapState } from "vuex";
import Notification from "@/components/partials/Notification";
import { AuthService } from "@/services/auth.service";
import HSAReceiptDialog from "@/components/dashboard/HSAReceiptDialog";
export default {
  props: {
    username: {
      type: String,
    },
    id: {
      type: String,
    },
    profilePic: {
      type: String,
    },
  },
  components: {
    Notification,
    HSAReceiptDialog,
  },
  data() {
    return {
      navList: [
        {
          name: "Home",
          link: "",
        },
        {
          name: "Treatment",
          link: "treatment",
        },
        {
          name: "Messages",
          link: "message",
        },
        // {
        //   name: "Contact",
        //   link: "contact-us",
        // },
        {
          name: "Support",
          link: "support",
        },
        {
          name: "Share",
          link: "referral",
        },
      ],
      accountList: [
        // {
        //   name: "Account Settings",
        //   link: "account-settings",
        // },
        {
          name: "Your Account",
          link: "your-account",
        },
        {
          name: "Medical Information",
          link: "medical-information",
        },
        // {
        //   name: "Contact Care Team",
        //   link: "contact-us",
        // },
      ],
    };
  },

  computed: {
    ...mapState("message", ["unreadMessagesCount"]),
  },
  methods: {
    async logout() {
      try {
        await AuthService.logout();
      } catch (error) {
        // this.error =
        //   error.status === 404
        //     ? "User with same email not found"
        //     : error.message;
        // this.alertMessage("warning", this.error);
        console.log("error", error.message);
      }
    },
  },
};
</script>

<style scoped>
/deep/.nav-link.dropdown-toggle,
/deep/.btn-link.dropdown-toggle {
  padding: 0;
}

/deep/.btn-link.dropdown-toggle {
  color: inherit;
  text-align: left;
}

/deep/.btn-link.dropdown-toggle .text-uppercase {
  font-weight: 700;
  letter-spacing: 1.5px;
  font-size: inherit;
}
</style>

<style lang="scss" scoped>
.navbar {
  @include media-breakpoint-up(xxl) {
    padding: 1.75rem 1rem !important;
  }
}

@media (max-width: 320px) {
  .navbar {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.navbar-nav .nav-link.active:before {
  @include media-breakpoint-up(xxl) {
    bottom: calc(-1.75rem - 1px) !important;
  }
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link {
  color: $text-color;
}

.dark-bg {
  box-shadow: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.dark-bg .navbar-nav .nav-link:hover {
  opacity: 0.85;
}

.dark-bg .navbar-nav .nav-link:hover,
.dark-bg .navbar-nav .nav-link.active,
.dark-bg .navbar-nav .nav-link {
  color: $secondary;
}

.dark-bg .navbar-nav .nav-link.active:before {
  border-color: $secondary;
}

.is-support {
  .badge-primary {
    background-color: $secondary;
    color: $primary;
  }
}

@media (min-width: 767px) {
  .navigator {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .navbar-expand-sm .navbar-collapse {
    flex: 1 0 0;
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  .navbar-user.ml-auto {
    margin-left: inherit !important;
  }

  .navigator {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.nav-link {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  font-size: 14px;
  text-decoration: none !important;

  @include media-breakpoint-up(xxl) {
    font-size: 14px * $scale-font;
  }
}

.dropdown-toggle {
  display: flex;
  align-items: center;

  &::after {
    margin-left: 15px;
  }
}
</style>
