<template>
  <div class="billing-display">
    <!-- Credit Card Modal -->
    <CreditModal
      :show="showModal"
      @exit="hideModal()"
      header-class="id-modal"
      body-class="id-body-modal"
    >
      <template #header>
        <img :src="require('@/assets/images/credit.svg')" alt="" />
        <b-row class="security-row mt-5 pt-3">
          <b-col cols="12">
            <b-icon icon="lock-fill"></b-icon> 256-BIT TLS SECURITY
          </b-col>
        </b-row>
      </template>
      <template #body>
        <h3
          class="sub-text text-uppercase text-primary"
          style="letter-spacing: 0.16em"
        >
          BILLING INFORMATION
        </h3>

        <h2 class="heading h1 text-default mt-5 mb-2 initial-line-height">
          Why do you need my credit card information?
        </h2>
        <p class="sub-text">
          Winona’s physicians are on hand to review your medical information
          quickly in order to provide you with safe, accessible care so you can
          feel better right away.
        </p>
        <p class="sub-text">
          We ask that you share your credit card information so that if the
          medication is approved by one of our doctors we can get it to you
          right away and to ensure that our patients are serious about the
          desire to receive treatment.
        </p>
        <p class="sub-text">
          Your card will never be charged until treatment is prescribed.
        </p>
        <h2 class="heading h1 text-default mt-4 mb-2 initial-line-height">
          Shouldn’t I talk to a Winona doctor before purchasing treatment?
        </h2>
        <p class="sub-text">
          Your Winona physician will never prescribe a treatment without first
          reviewing your medical history, and addressing any questions or
          concerns either of you may have.
        </p>
        <p class="sub-text">
          Once you enter your credit card information you will be directed to
          your secure patient portal where you can directly message your doctor.
          If you have any additional questions, you can ask them at this step.
        </p>

        <b-button pill block variant="info" @click="hideModal()" class="mt-5"
          >I understand</b-button
        >
      </template>
    </CreditModal>
    <div class="bg-overlay" @click="hideModal()"></div>

    <h2 class="question-title with-subtext">Your doctor is waiting</h2>
    <p class="sub-text">
      Add a payment method to be used if treatment is prescribed (you will not
      be charged now).
      <!-- TODO: hide modal for billing information for now -->
      <br />
      <span
        style="color: #828282; cursor: pointer"
        class="text-underline"
        @click="openModal()"
        >Why do you need my credit card information?</span
      >
    </p>
    <b-card
      v-if="!nonrx && body.desiredplan"
      style="background-color: rgba(242, 242, 242, 0.5)"
    >
      <h3 class="label-text mb-5">Your Treatment if Prescribed</h3>
      <b-row
        align-v="center"
        v-for="med in body.desiredplan.filter(
          (x) =>
            x.type === undefined || (x.type != undefined && x.type === 'RX')
        )"
        style="margin-bottom: 1rem"
        :key="med.productid"
      >
        <b-col cols="8">
          <h4 class="label-text mb-1">{{ med.name }}</h4>
          <span class="small-text">{{ med.pricingdetail.nickname }}</span>
        </b-col>
        <b-col cols="4" class="text-right">
          <h3 class="label-text">${{ med.price }}</h3>
        </b-col>
      </b-row>
      <div class="mt-4"></div>
      <hr
        v-if="body.hasOwnProperty('referraldiscountamount') || discountDisplay"
      />
      <b-row v-if="body.referraldiscountamount">
        <b-col cols="8">
          <span class="small-text">First Time Referral Discount</span>
        </b-col>
        <b-col cols="4" class="text-right">
          <span class="small-text"> -${{ body.referraldiscountamount }} </span>
        </b-col>
      </b-row>
      <b-row v-if="discount > 0">
        <b-col cols="8">
          <!-- WIN-8178 -->
          <h4 class="label-text mb-1">{{ this.body.couponid }}</h4>
          <span class="small-text" v-if="couponData.percent_off"
            >{{ couponData.percent_off }}% off<span
              v-if="couponData.duration == 'once'"
            >
              of your first purchase</span
            ></span
          >
        </b-col>
        <b-col cols="4" class="text-right">
          <span class="small-text text-info"
            >{{ couponData.percent_off }}% off</span
          >
          <span class="small-text"> -${{ discountDisplay }} </span>
        </b-col>
      </b-row>
      <hr class="my-3" style="border-color: #19191c" />
      <b-row>
        <b-col cols="8">
          <span class="small-text">Online doctor visit &amp; shipping</span>
        </b-col>
        <b-col cols="4" class="text-right">
          <span class="small-text" style="text-decoration: line-through"
            >$50.00</span
          >
          <span class="small-text"> Free</span>
        </b-col>
      </b-row>
    </b-card>
    <!-- 
      TODO: Remove add-ons for now
      <b-card style="background-color: rgba(242, 242, 242, 0.5);">
      <h3 class="mb-5 label-text">Add-ons</h3>
      <b-row v-for="addon in body.desiredaddon" style="margin-bottom: 1rem" :key="addon.productid">
        <b-col cols="8">
          <h4 class="label-text mb-4">{{ addon.medicinename }}</h4>
        </b-col>
        <b-col cols="4" class="text-right">
          <h3 class="label-text mb-0">${{ addon.price.toFixed(2) }}</h3>
        </b-col>
      </b-row>
      <hr class="my-3">
      <b-row>
        <b-col cols="8"><span class="small-text">Shipping</span></b-col>
        <b-col cols="4" class="text-right"><span class="small-text">Free</span></b-col>
      </b-row>
      <hr class="my-3">
      <b-row>
        <b-col cols="8"><span class="small-text">Due Now</span></b-col>
        <b-col cols="4" class="text-right"><span class="small-text">${{ total.toFixed(2) }}</span></b-col>
      </b-row>
    </b-card> 
    -->

    <b-card
      v-if="body.pharmacyid != ''"
      style="background-color: rgba(242, 242, 242, 0.5)"
    >
      <h3 class="mb-5 label-text">Platform Fee</h3>
      <!-- <b-row >
        <b-col cols="8">
          <h4 class="label-text mb-4">Outside Winona Pharmacy Network</h4>
        </b-col>
        <b-col cols="4" class="text-right">
          <h3 class="label-text mb-0">$50.00</h3>
        </b-col>
      </b-row>
      <hr class="my-3">
      <b-row>
        <b-col cols="8"><span class="small-text">Shipping</span></b-col>
        <b-col cols="4" class="text-right"><span class="small-text">Free</span></b-col>
      </b-row>
      <hr class="my-3"> -->
      <b-row>
        <b-col cols="8"
          ><span class="small-text"
            >Outside Winona Pharmacy Network</span
          ></b-col
        >
        <b-col cols="4" class="text-right"
          ><span class="small-text">${{ platformfee.toFixed(2) }}</span></b-col
        >
      </b-row>
    </b-card>

    <h3 v-if="desiredAddon.length > 0" class="label-text mt-5 pt-2">To Pay</h3>
    <b-card
      v-if="desiredAddon.length > 0"
      style="background-color: rgba(242, 242, 242, 0.5)"
    >
      <h3 class="mb-5 label-text">Your Order</h3>
      <!-- <hr v-if="!nonrx && body.desiredplan"  class="my-3"> -->

      <!-- <b-row v-if="!nonrx && body.desiredplan" >
        <b-col cols="8">
          Treatment if prescribed
        </b-col>
        <b-col cols="4" class="text-right">
          ${{ plantotal.toFixed(2) }}
        </b-col>
      </b-row>
      <hr class="my-3">
      <b-row>
        <b-col cols="8">
          Add-ons
        </b-col>
        <b-col cols="4" class="text-right">
          ${{ addontotal.toFixed(2) }}
        </b-col>
      </b-row> -->
      <hr class="my-3" />

      <b-row>
        <b-col cols="8"> Order Sub-total </b-col>
        <b-col cols="4" class="text-right"> ${{ subtotal.toFixed(2) }} </b-col>
      </b-row>
      <hr class="my-3" v-if="body.pharmacyid != ''" />
      <b-row v-if="body.pharmacyid != ''">
        <b-col cols="8"> Platform fee </b-col>
        <b-col cols="4" class="text-right">
          ${{ platformfee.toFixed(2) }}
        </b-col>
      </b-row>
      <hr class="my-3" />
      <b-row>
        <b-col cols="8"> Tax </b-col>
        <b-col cols="4" class="text-right"> ${{ taxamount.toFixed(2) }} </b-col>
      </b-row>
      <hr class="my-3" style="border-color: #19191c; border-width: 3px" />
      <b-row>
        <b-col cols="6">
          <b>Grand Total</b>
        </b-col>
        <b-col cols="6" class="text-right">
          <b>${{ totalamount.toFixed(2) }}</b>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { TransactionService } from "@/services/transaction.service";
import { OnboardingService } from "@/services/onboarding.service";
import CreditModal from "@/components/partials/SideModal";
export default {
  components: {
    CreditModal,
  },
  props: {
    body: Object,
    nonrx: {
      type: Boolean,
      default: false,
    },
    discount: {
      type: Number,
      default: 0,
    },
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    analyticsId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      total: 0,
      checkoutData: { checkout: [] },
      desiredAddon: [],
      desiredPlan: [],
      couponData: {},
      orderDetail: {},
      orderSummary: {},
      subtotal: 0,
      plantotal: 0,
      addontotal: 0,
      taxamount: 0,
      taxrate: 0,
      totalamount: 0,
      platformfee: 50.0,
      showModal: false,
    };
  },
  computed: {
    discountDisplay() {
      return (Math.round(this.discount * 100) / 100).toFixed(2);
    },
  },
  methods: {
    openModal() {
      this.elementTag("hidden", true);
    },
    hideModal() {
      this.elementTag(null, false);
    },
    elementTag(style, Boolean) {
      this.showModal = Boolean;
      const htmlTag = document.documentElement;
      htmlTag.style.overflow = style;
      // htmlTag.scrollTop = htmlTag.scrollHeight;
    },
    async computeTax() {
      try {
        this.checkoutData.creditdiscount = this.body.referraldiscountamount;
        this.checkoutData.couponid = this.body.couponid;

        if (this.body.couponid) {
          const couponGet = await OnboardingService.checkCoupon(
            this.body.couponid
          );
          this.couponData = couponGet.data;
        }

        let res = await TransactionService.computeTax(this.checkoutData);
        let addon = [];
        let desired = [];
        if (res && res.data) {
          const { orderdetail, ordersummary } = res.data;
          this.orderSummary = ordersummary;
          // this.subtotal = ordersummary.subtotal;
          this.taxamount = ordersummary.taxamount;
          this.totalamount = ordersummary.totalamount;
          if (ordersummary.discount > 0) {
            this.$emit("set-discount", ordersummary.discount);
          }
          this.$emit("set-total", this.totalamount);
        }
        this.desiredAddon = addon;
        this.desiredPlan = desired;

        if (
          !this.nonrx &&
          this.body.desiredplan &&
          this.body.desiredplan.length > 0
        ) {
          for (let record of this.body.desiredplan.filter(
            (x) =>
              x.type === undefined || (x.type != undefined && x.type === "RX")
          )) {
            this.plantotal += Number(record.price);
          }
        }

        if (this.body.desiredaddon && this.body.desiredaddon.length > 0) {
          for (let record of this.body.desiredaddon) {
            this.addontotal += Number(record.price);
          }
        }

        this.subtotal = this.addontotal + this.plantotal || 0;
        this.totalamount =
          this.subtotal + this.taxamount + this.platformfee || 0;
      } catch (error) {
        console.log("Error", error);
      }
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        customerId: this.analyticsId,
        screen: "5.3-A",
        onboardingflow: this.onboardingflow,
        firstname: this.body.firstname,
        lastname: this.body.lastname,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
      },
      {
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );
    if (this.body.pharmacyid === "") {
      this.platformfee = 0;
    } else {
      this.platformfee = 50.0;
    }
    for (let med of this.body.desiredaddon) {
      this.total = this.total + Math.floor(med.price);
      this.checkoutData.checkout.push({
        productid: med.productid,
        pricingid: med.pricingid,
        quantity: med.quantity,
      });
    }
    // Desired Plan
    if (!this.nonrx && this.body.desiredplan) {
      for (let med of this.body.desiredplan) {
        this.checkoutData.checkout.push({
          productid: med.productid,
          pricingid: med.pricingdetail.pricingid,
          quantity: med.quantity ? med.quantity : 1,
        });
      }
    }

    // if(!this.nonrx) {this.total += 20}
    this.computeTax();
  },
};
</script>

<style lang="scss">
.security-row {
  @include media-breakpoint-down(xs) {
    margin-top: 1.5rem !important;
    padding-top: 0 !important;
  }
}

.id-modal {
  background-color: #f6f6f6;
  height: 45vh;

  @include media-breakpoint-down(xs) {
    height: 30vh;
  }

  img {
    height: 50%;
  }
}

.id-body-modal {
  overflow: auto;
  // height: 55vh !important;
  overflow-x: hidden;

  @include media-breakpoint-down(xs) {
    // height: 70vh !important;
  }
}
</style>
