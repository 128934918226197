import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util';

export class SupportService extends BaseService{
  static async getSupportCategory() {
    try {
      const response = await this.request({ auth: true }).get(`/support/category`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getSupportCategoryId(supportcategoryid) {
    try {
      const response = await this.request({ auth: true }).get(`/support/category/${supportcategoryid}`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchSupport(keyword) {
    try {
      const response = await this.request({ auth: true }).get(`/support/search?contains=${keyword}`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchSupportCategory(keyword, supportcategoryid) {
    try {
      const response = await this.request({ auth: true }).get(`/support/category/${supportcategoryid}/search?contains=${keyword}`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}