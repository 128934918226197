<template>
  <div>
    <EligibilityTPA v-if="variant.id == 'Account Creation'" />
    <EligibilityTPB v-else />
  </div>
</template>

<script>
import EligibilityTPA from "@/components/onboarding/eligibility/split/EligibilityTPA";
import EligibilityTPB from "@/components/onboarding/eligibility/split/EligibilityTPB";

export default {
  props: ["variant"],
  mounted() {
    console.log(this.variant.id);
  },
  components: {
    EligibilityTPA,
    EligibilityTPB,
  },
};
</script>
