import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class UsersService extends BaseService {
  static async getCurrent() {
    try {
      const response = await this.request({ auth: true }).get("user");
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getHealthQuestions() {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/healthquestion`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPrescriptionorder() {
    try {
      const response = await this.request({ auth: true }).get(
        "user/prescriptionorder"
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async getShipment(prescriptionId) {
    try {
      const response = await this.request({ auth: true }).get(
        `user/prescriptionorder/${prescriptionId}/shipment`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async emailVerification(data) {
    try {
      const response = await this.request({ auth: true }).put(
        "user/emailverification"
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateUser(data) {
    try {
      const response = await this.request({ auth: true }).put("user", data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateUserEmail(data) {
    try {
      const response = await this.request({ auth: true }).put(
        "user/email",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async uploadAvatar(avatar) {
    try {
      const response = await this.request({ auth: true }).post(
        "user/avatar",
        avatar
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async uploadPhoto(photo) {
    try {
      const response = await this.request({ auth: true }).post(
        "user/photo",
        photo
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async uploadIdentification(identification) {
    try {
      const response = await this.request({ auth: true }).post(
        "user/identification",
        identification
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updatePharmacy(identification) {
    try {
      const response = await this.request({ auth: true }).put(
        "user/updatepharmacy",
        identification
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addBillingAddress(data) {
    try {
      const response = await this.request({ auth: true }).put(
        "user/billingaddress",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async forgotPassword(email) {
    try {
      const response = await this.request({ auth: false }).post(
        "password/forgot",
        { email }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async confirmPassword(data) {
    try {
      const response = await this.request({ auth: false }).post(
        "password/confirm",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async changePassword(data) {
    try {
      const response = await this.request({ auth: true }).put(
        "password/change",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async subscribe(data) {
    try {
      const response = await this.request({ auth: false }).post(
        "mailinglist",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getReferralToken() {
    try {
      const response = await this.request({ auth: true }).post("user/referral");
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async checkReferralToken(data) {
    try {
      const response = await this.request({ auth: true }).post(
        "/referral/check",
        data
      );
      // console.log(response);
      return new ResponseWrapper(
        response,
        response.data.body,
        response.data.message
      );
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
