var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Array", {
    ref: "arrayComponent",
    attrs: { body: _vm.questionBody, disabled: _vm.disabled },
    on: { submitAnswer: _vm.submitAnswer, alert: _vm.showAlert }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }