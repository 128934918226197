var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-right" }, [
      _c(
        "a",
        {
          attrs: { href: "javascript:;" },
          on: {
            click: function($event) {
              return _vm.$bvModal.hide("report-issue-form")
            }
          }
        },
        [
          _c("b-icon", {
            attrs: { icon: "x", variant: "link", "font-scale": "1.5" }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "px-2 pt-2 pb-4" }, [
      _vm.isFinished
        ? _c(
            "div",
            { staticClass: "mx-auto", staticStyle: { "max-width": "768px" } },
            [
              _c(
                "p",
                {
                  staticClass: "h2 text-uppercase my-2",
                  staticStyle: { "letter-spacing": "0.1rem" }
                },
                [_vm._v(" Thank You ")]
              ),
              _c("p", [
                _vm._v(
                  " Your request has been received and our Patient Care team will contact you via email in 24 hours. "
                )
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "info", pill: "", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("report-issue-form")
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "mx-auto", staticStyle: { "max-width": "768px" } },
            [
              _vm._t("title"),
              _c("p", [
                _vm._v(
                  " Please share details about your issue with our Patient Care team. We’ll get back to you via email within 24 hours. "
                )
              ]),
              _c(
                "b-alert",
                {
                  attrs: { variant: _vm.alert.type, dismissible: "" },
                  on: {
                    dismissed: function($event) {
                      _vm.alert.show = false
                    }
                  },
                  model: {
                    value: _vm.alert.show,
                    callback: function($$v) {
                      _vm.$set(_vm.alert, "show", $$v)
                    },
                    expression: "alert.show"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
              ),
              _c("b-form-textarea", {
                staticClass: "mx-auto",
                staticStyle: {
                  overflow: "auto",
                  border: "1px solid #19191c",
                  "border-radius": "0.25rem"
                },
                attrs: { id: "textarea", rows: "6", "max-rows": "6" },
                model: {
                  value: _vm.message,
                  callback: function($$v) {
                    _vm.message = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "message"
                }
              }),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    variant: "info",
                    pill: "",
                    block: "",
                    disabled: _vm.isProcessing
                  },
                  on: { click: _vm.onSendEmail }
                },
                [
                  _vm.isProcessing
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _c("span", [_vm._v("Continue")])
                ],
                1
              )
            ],
            2
          )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }