<template>
  <b-row align-h="center" class="pt-6 ml-4 mr-4">
    <b-col cols="12" md="9" lg="8" xl="8" xxl="6">
      <!-- <template v-if="!editInformation && !intakeForm">
        
      </template> -->

      <b-row align-h="center">
        <b-col cols="12" md="9" class="mb-5">
          <h1 class="mb-3 text-center heading text-primary">
            Medical Information
          </h1>
          <p class="sub-text text-center">
            If you would like to update your medical information please message
            your doctor <b-link to="/message">here</b-link>
            <!-- <b-link to="/message">help@bywinona.com</b-link> -->
          </p>
          <div class="d-block d-md-none pb-3"></div>
        </b-col>
        <b-col
          class="mb-3"
          cols="12"
          md="9"
          v-for="(healthQuestion, index) in healthQuestions"
          :key="index"
        >
          <div role="group" class="mb-3">
            <label
              class="sub-text mb-1 text-uppercase"
              :for="`healthQuestion${index}`"
              >{{ index + 1 }}. {{ healthQuestion.question }}</label
            >
            <p
              class="mb-2"
              :style="`color:${healthQuestion.highlightcolor}`"
              :id="`healthQuestion${index}`"
              v-if="healthQuestion.question != 'Stated allergies'"
            >
              {{ healthQuestion.answer }}
            </p>
            <p
              class="mb-2"
              v-else
              :style="`color:${healthQuestion.highlightcolor}`"
            >
              {{ healthQuestion.answer.replace(/(\r\n|\n|\r)/gm, "\r\n") }}
            </p>
            <hr class="my-2" />
            <!-- <b-form-input
              v-if="healthQuestion.question != 'Stated allergies'"
              :id="`healthQuestion${index}`"
              disabled
              class="pl-0"
              type="text"
              :value="healthQuestion.answer"
              :style="`color:${healthQuestion.highlightcolor}`"
            ></b-form-input>
            <b-form-textarea
              v-else
              disabled
              max-rows="15"
              class="pl-0"
              type="text"
              style="overflow: hidden"
              :value="
                healthQuestion.answer.replace(
                  /(\r\n|\n|\r)/gm,
                  '\r\n'
                )
              "
              :style="`color:${healthQuestion.highlightcolor}`"
            ></b-form-textarea> -->
          </div>
        </b-col>
      </b-row>

      <!-- <b-card
        class="default-bg"
        style="padding: 1rem"
        v-if="!editInformation && !intakeForm"
      >
        <b-row align-v="center" class="mb-4">
          <b-col>
            <h3 class="mb-0" style="font-size: 24px; font-weight: 500">
              Personal Information
            </h3>
          </b-col>
          <b-col md="auto">
            <a
              href="#"
              @click="editInformation = true"
              class="text-uppercase text-underline"
              >Edit</a
            >
          </b-col>
        </b-row>
        <div class="offset">
          <b-row>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">First Name:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span class="fs-mask">{{ user.firstname }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">Last Name:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span class="fs-mask">{{ user.lastname }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">Date of Birth:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span v-if="user.birthday" class="fs-mask">{{
                    moment(user.birthday).format("MMMM DD, YYYY")
                  }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">Gender:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span>{{ user.gender }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">Phone Number:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span class="fs-mask">{{ formatPhoneNumber(user.phoneno) }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">Email:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span class="fs-mask">{{ user.email }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">Street Address:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span>{{ shippingDetails.address1 }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">Apartment/Suite:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span>{{ shippingDetails.address2 || "N/A" }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">State:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span>{{ shippingDetails.state }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">City:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span>{{ shippingDetails.city }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="6" md="5">
                  <span class="text-primary mr-3">Zip Code:</span>
                </b-col>
                <b-col cols="6" md="7">
                  <span>{{ shippingDetails.zipcode }}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-card> -->

      <!-- <b-card
        class="dark-bg"
        style="padding: 1rem"
        v-if="!editInformation && !intakeForm"
      >
        <b-row align-v="center" class="mb-4">
          <b-col>
            <h3 class="mb-0" style="font-size: 24px; font-weight: 500">
              Medical History
            </h3>
          </b-col>
          <b-col md="auto">
            <a
              href="#"
              @click="intakeForm = true"
              style="color: inherit"
              class="text-uppercase"
              >View</a
            >
          </b-col>
        </b-row>
        <div class="offset">
          <p class="mb-4">
            We made sure that all of our product recommendations are fit to your
            needs, we based our prescription from your answers from the
            onboarding process.
          </p>
          <p>
            You can view your medical history and if wish to change please
            message your doctor.
          </p>
        </div>
      </b-card> -->

      <!-- edit medical information -->
      <template v-if="editInformation">
        <h2 class="mb-6 text-primary" style="font-size: 26px">
          Personal Information
        </h2>

        <b-alert :show="alert.show" :variant="alert.type">{{
          alert.message
        }}</b-alert>
        <b-row>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="firstName" class="d-letter-spacing"
                >First Name:</label
              >
              <b-form-input
                class="fs-mask"
                id="firstName"
                type="text"
                v-model="user.firstname"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="lastName" class="d-letter-spacing">Last Name:</label>
              <b-form-input
                class="fs-mask"
                id="lastName"
                type="text"
                v-model="user.lastname"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="birthday" class="d-letter-spacing"
                >Date of Birth:</label
              >
              <flat-pickr
                autocomplete="off"
                class="form-control pl-0 fs-mask"
                v-model="user.birthday"
                id="birthday"
                placeholder="MM/DD/YYYY"
                :config="config"
              ></flat-pickr>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="gender" class="d-letter-spacing">Gender:</label>
              <v-select
                append-to-body
                :calculate-position="withPopper"
                v-model="gender"
                label="text"
                :options="genderOption"
                :reduce="(genderOption) => genderOption.value"
                disabled
              >
                <template #open-indicator="{ attributes }">
                  <b-icon v-bind="attributes" icon="chevron-down"></b-icon>
                </template>
              </v-select>
              <!-- <b-form-input
                id="gender"
                type="text"
              ></b-form-input> -->
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="emailAddress" class="d-letter-spacing"
                >Email Address:</label
              >
              <b-form-input
                class="fs-mask"
                id="emailAddress"
                type="text"
                v-model="user.email"
                disabled
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-4">
              <label for="phone" class="d-letter-spacing">Phone Number:</label>
              <b-form-input
                class="fs-mask"
                id="phone"
                :formatter="formatPhoneNumber"
                v-model="user.phoneno"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="streetAddress" class="d-letter-spacing"
                >Street Address:</label
              >
              <b-form-input
                id="streetAddress"
                type="text"
                v-model="shippingDetails.address1"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="apartment" class="d-letter-spacing"
                >Apartment/Suite:</label
              >
              <b-form-input
                id="apartment"
                type="text"
                v-model="shippingDetails.address2"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="city" class="d-letter-spacing">City:</label>
              <b-form-input
                id="city"
                type="text"
                v-model="shippingDetails.city"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="state" class="d-letter-spacing">State:</label>
              <v-select
                id="state"
                type="text"
                v-model="selectedState"
                :options="stateOptions"
              ></v-select>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="country" class="d-letter-spacing">Country:</label>
              <b-form-input
                id="country"
                type="text"
                v-model="shippingDetails.country"
                readonly
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div role="group" class="mb-3">
              <label for="zip" class="d-letter-spacing">Zip Code:</label>
              <b-form-input
                id="zip"
                type="text"
                v-model="shippingDetails.zipcode"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-3">
          <b-col cols="12" md="6"></b-col>
          <b-col cols="6" md="3">
            <b-button
              pill
              block
              @click="editInformation = false"
              variant="outline-info"
              >Cancel</b-button
            >
          </b-col>
          <b-col cols="6" md="3">
            <b-button pill block @click="saveUpdate" variant="info"
              >Update</b-button
            >
          </b-col>
        </b-row>
      </template>

      <!-- intake form -->
      <template v-if="intakeForm">
        <h2 class="mb-6 text-primary" style="font-size: 26px">
          Medical History
        </h2>
        <b-row>
          <b-col cols="12" class="mb-3">
            <b-row align-v="center" class="mb-4">
              <b-col cols="12" class="mb-3">
                <h3 class="bold d-letter-spacing">Health Questions</h3>
              </b-col>

              <b-col cols="12">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    v-for="(healthQuestion, index) in healthQuestions"
                    :key="index"
                  >
                    <div role="group" class="mb-3">
                      <label
                        class="d-letter-spacing"
                        :for="`healthQuestion${index}`"
                        >{{ index + 1 }}. {{ healthQuestion.question }}</label
                      >
                      <b-form-input
                        v-if="healthQuestion.question != 'Stated allergies'"
                        :id="`healthQuestion${index}`"
                        disabled
                        class="pl-0"
                        type="text"
                        :value="healthQuestion.answer"
                        :style="`color:${healthQuestion.highlightcolor}`"
                      ></b-form-input>
                      <b-form-textarea
                        v-else
                        disabled
                        max-rows="15"
                        class="pl-0"
                        type="text"
                        style="overflow: hidden"
                        :value="
                          healthQuestion.answer.replace(
                            /(\r\n|\n|\r)/gm,
                            '\r\n'
                          )
                        "
                        :style="`color:${healthQuestion.highlightcolor}`"
                      ></b-form-textarea>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="12" md="6"></b-col>
          <b-col cols="6" md="3"> </b-col>
          <b-col cols="6" md="3">
            <b-button pill block @click="saveUpdate" variant="info"
              >Back</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

import { LookUpService } from "@/services/lookup.service";

import popper from "@/assets/js/popper.js";
import mixins from "@/components/form/mixins";

export default {
  name: "medical-information",
  mixins: [mixins, popper],
  data() {
    return {
      alert: {
        show: false,
        type: "warning",
        message: "",
      },
      editInformation: false,
      intakeForm: false,
      config: {
        altInput: true,
        altFormat: "m/d/Y",
        allowInput: true,
      },
      states: [],
      stateOptions: [],
      selectedState: "",
      gender: "Female",
      genderOption: [
        { value: "male", text: "Male" },
        { value: "female", text: "Female" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      shippingDetails: "user/getDefaultShipping",
      healthQuestions: "user/getHealthQuestions",
    }),
  },
  watch: {
    selectedState() {
      this.shippingDetails.state = this.states.filter(
        (state) => state.name == this.selectedState
      )[0].id;
    },
  },
  methods: {
    showAlert(message, type = null) {
      this.alert = {
        show: true,
        type: "warning",
        message: message,
      };
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("/");
    },
    async saveUpdate() {
      try {
        let filterUser = "firstname, lastname, birthday, phoneno";
        let userDetails = _.pick(this.user, filterUser.split(", "));
        userDetails.birthday = this.formatDate(userDetails.birthday);
        this.$store
          .dispatch("user/updateUser", userDetails)
          .then((res) => {
            if (res && res.message) {
              this.showAlert(res.message);
              return;
            } else {
              let shippingId = this.shippingDetails.id;
              let filterShipping =
                "address1, address2, zipcode, country, city, state";
              let shippingDetails = _.pick(
                this.shippingDetails,
                filterShipping.split(", ")
              );

              (shippingDetails.firstname = userDetails.firstname),
                (shippingDetails.lastname = userDetails.lastname);

              this.$store
                .dispatch("user/updateShippingDetails", {
                  id: shippingId,
                  data: shippingDetails,
                })
                .then((res) => {
                  if (res && res.message) {
                    this.showAlert(res.message);
                    return;
                  }
                  this.editInformation = false;
                  this.intakeForm = false;
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error.message);
        this.showAlert(error.message);
      }
    },
    async getStates() {
      const { data } = await LookUpService.getLookUp("state");
      if (data) {
        this.states = data;
        for (let index in data) {
          this.stateOptions[index] = data[index].name;
        }
      }

      // * Gets the user's pre-selected state.
      if (this.shippingDetails.state) {
        this.selectedState = this.states.filter(
          (state) => state.id == this.shippingDetails.state
        )[0].name;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("user/getUser");
    await this.$store.dispatch("user/getDefaultShipping");
    await this.$store.dispatch("user/getHealthQuestions");
    await this.getStates();

    // * Currently hardcoding the country data
    this.shippingDetails.country = "US";
    this.user.birthday = this.formatDate(this.user.birthday);
    this.user.phoneno = this.formatPhoneNumber(this.user.phoneno);
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 11px;
  color: #828282;
}

.form-control {
  padding-left: 0;
  color: #19191c;
  border-color: #828282;
}

@media (min-width: 760px) {
  .offset {
    margin-left: 200px;
  }
}
</style>
