var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "account-main my-5 my-md-6" },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _c("b-col", { staticClass: "col-auto" }, [
            _c("h3", { staticClass: "display-4 text-primary heading" }, [
              _vm._v("Your Information")
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        {
          staticClass: "mt-6",
          attrs: { "align-h": "center", "align-v": "center" }
        },
        [
          _c(
            "b-col",
            { staticClass: "mb-5 mx-4 mx-md-0 mb-md-0" },
            [
              _c(
                "b-card",
                { staticClass: "personal-info-card" },
                [
                  _c(
                    "b-row",
                    { staticClass: "pb-4", attrs: { "align-h": "between" } },
                    [
                      _c("b-col", { attrs: { cols: "auto" } }, [
                        _c("h2", [_vm._v("Personal Information")])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { width: "150px", color: "#a783ff" },
                              attrs: {
                                href: "/account-settings",
                                variant: "outline-secondary",
                                pill: ""
                              }
                            },
                            [_vm._v("EDIT")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-6", attrs: { "align-h": "end" } },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pb-3",
                          staticStyle: { "border-bottom": "1px solid #1a6a72" },
                          attrs: { cols: "12", md: "5" }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("First Name:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.firstname))])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Date of Birth:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.birthday))])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pb-3",
                          staticStyle: { "border-bottom": "1px solid #1a6a72" },
                          attrs: { cols: "12", md: "5" }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Last Name:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.lastname))])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Phone Number:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.phoneno))])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { attrs: { "align-h": "between" } },
                    [
                      _c("b-col", { attrs: { cols: "auto" } }, [
                        _c("h4", [_vm._v("Shipping Address")])
                      ]),
                      _c("b-col", { attrs: { cols: "auto" } })
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-6", attrs: { "align-h": "end" } },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pb-3",
                          staticStyle: { "border-bottom": "1px solid #1a6a72" },
                          attrs: { cols: "12", md: "5" }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Street Address:")]),
                              _c("b-col", [
                                _vm._v(_vm._s(_vm.shipping.address1))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("State:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.shipping.state))])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Zip Code:")]),
                              _c("b-col", [
                                _vm._v(_vm._s(_vm.shipping.zipcode))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pb-3",
                          staticStyle: { "border-bottom": "1px solid #1a6a72" },
                          attrs: { cols: "12", md: "5" }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Apartment/Suite:")]),
                              _c("b-col", [
                                _vm._v(
                                  _vm._s(
                                    _vm.shipping.address2
                                      ? _vm.shipping.address2
                                      : "N/A"
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("City:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.shipping.city))])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { attrs: { "align-h": "between" } },
                    [
                      _c("b-col", { attrs: { cols: "auto" } }, [
                        _c("h4", [_vm._v("Billing Address")])
                      ]),
                      _c("b-col", { attrs: { cols: "auto" } })
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-6", attrs: { "align-h": "end" } },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pb-3",
                          staticStyle: { "border-bottom": "1px solid #1a6a72" },
                          attrs: { cols: "12", md: "5" }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Street Address:")]),
                              _c("b-col", [
                                _vm._v(_vm._s(_vm.billing.address1))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("State:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.billing.state))])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Zip Code:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.billing.zipcode))])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pb-3",
                          staticStyle: { "border-bottom": "1px solid #1a6a72" },
                          attrs: { cols: "12", md: "5" }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Apartment/Suite:")]),
                              _c("b-col", [
                                _vm._v(
                                  _vm._s(
                                    _vm.billing.address2
                                      ? _vm.billing.address2
                                      : "N/A"
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("City:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.billing.city))])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { attrs: { "align-h": "between" } },
                    [
                      _c("b-col", { attrs: { cols: "auto" } }, [
                        _vm._v(" Billing Details "),
                        _c(
                          "small",
                          { staticClass: "text-muted text-uppercase" },
                          [
                            _c("b-icon", { attrs: { icon: "lock" } }),
                            _vm._v("Secure")
                          ],
                          1
                        )
                      ]),
                      _c("b-col", { attrs: { cols: "auto" } })
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-5", attrs: { "align-h": "end" } },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pb-3",
                          staticStyle: { "border-bottom": "1px solid #1a6a72" },
                          attrs: { cols: "12", md: "5" }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Card Number:")]),
                              _c("b-col", { staticClass: "fs-mask" }, [
                                _c("img", {
                                  staticStyle: { margin: "0 5px" },
                                  attrs: {
                                    width: "40px",
                                    src: _vm.brand,
                                    alt: ""
                                  }
                                }),
                                _vm._v(" " + _vm._s(_vm.lastFour))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Expiry:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.expiry))])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pb-3",
                          staticStyle: { "border-bottom": "1px solid #1a6a72" },
                          attrs: { cols: "12", md: "5" }
                        },
                        [
                          _c("b-row", [_c("b-col"), _c("b-col")], 1),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("CVV:")]),
                              _c("b-col", [_vm._v(_vm._s(_vm.cvv))])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        {
          staticClass: "mt-4",
          attrs: { "align-h": "center", "align-v": "center" }
        },
        [
          _c(
            "b-col",
            { staticClass: "mb-5 mx-4 mx-md-0 mb-md-0" },
            [
              _c(
                "b-card",
                { staticClass: "medical-info-card" },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-h": "between" } },
                    [
                      _c("b-col", { attrs: { cols: "auto" } }, [
                        _c("h2", [_vm._v("Medical History")])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-link",
                            { attrs: { href: "/medical-information" } },
                            [_c("u", [_vm._v("VIEW")])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { attrs: { "align-h": "end" } },
                    [
                      _c("b-col", { attrs: { cols: "12", md: "10" } }, [
                        _c("p", [
                          _vm._v(
                            " We made sure that all of our product recommendations are fit to your needs, we based our prescription from your answers from the onboarding process. "
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            " You can view your medical history and if wish to change please message your doctor. "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }