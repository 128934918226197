var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        {
          staticStyle: { height: "100vh" },
          attrs: { "align-v": "center", "align-h": "center" }
        },
        [
          _c(
            "b-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", sm: "12", md: "5", lg: "5", xl: "5" }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/Cancel.svg"), alt: "" }
              }),
              _c("p", { staticClass: "text-muted text-uppercase my-3" }, [
                _c("small", [_vm._v(_vm._s(this.$route.name) + " ERROR")])
              ]),
              _c("h1", [_vm._v("There’s no page here")]),
              _c("p", { staticClass: "text-muted font-weight-bold" }, [
                _vm._v(" Looks like you ended up here by accident? ")
              ]),
              _c("router-link", {
                attrs: { to: "/", custom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var navigate = ref.navigate
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-lg btn-primary mb-3",
                            attrs: { href: "javascript:void(0)" },
                            on: { click: navigate }
                          },
                          [_vm._v(" Return to your dashboard")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }