var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "non-rx" },
    [
      _c(
        "b-row",
        { staticClass: "p-3" },
        [
          _c("b-col", { staticClass: "mb-4", attrs: { cols: "12" } }, [
            _c(
              "h3",
              {
                staticClass: "bold-font",
                staticStyle: { "font-size": "24px" }
              },
              [_vm._v(" " + _vm._s(_vm.prescription.medicinename) + " ")]
            )
          ]),
          _c("b-col", { attrs: { cols: "12", md: "4" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("small", { staticClass: "link" }, [
                _vm._v("Treatment Details")
              ]),
              _vm.prescription.dosage && _vm.prescription.duration
                ? _c(
                    "p",
                    {
                      staticClass: "mb-4 bold",
                      staticStyle: { "letter-spacing": "0.01em" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.prescription.dosage) +
                          " / " +
                          _vm._s(_vm.prescription.frequency) +
                          " (" +
                          _vm._s(_vm.prescription.duration) +
                          ") "
                      )
                    ]
                  )
                : _c(
                    "p",
                    {
                      staticClass: "mb-4 bold",
                      staticStyle: { "letter-spacing": "0.01em" }
                    },
                    [_vm._v(" " + _vm._s(_vm.prescription.unit) + " ")]
                  ),
              _vm.showOrderDate
                ? _c("small", { staticClass: "link" }, [
                    _vm._v(" Next Order Date ")
                  ])
                : _vm._e(),
              _vm._l(_vm.filteredShipment, function(item) {
                return _c(
                  "p",
                  {
                    key: item.id,
                    staticClass: "mb-2 mt-2 bold",
                    staticStyle: { "letter-spacing": "0.01em" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.prescription.transactiontype != "one_time" &&
                            _vm.prescription.prescriptionstatus != "CANCELLED"
                            ? _vm.moment
                                .unix(item.shippingdate)
                                .format("MMM DD, YYYY")
                            : ""
                        ) +
                        " "
                    )
                  ]
                )
              }),
              _c("br"),
              _vm.prescription.prescriptionstatus != "SENT"
                ? _c("small", { staticClass: "link" }, [
                    _vm._v("Shipping Address")
                  ])
                : _c("small", { staticClass: "link" }, [
                    _vm._v("Prescription sent to")
                  ]),
              _vm.prescription.prescriptionstatus != "SENT"
                ? _c(
                    "p",
                    {
                      staticClass: "mb-4 mt-2 bold",
                      staticStyle: { "letter-spacing": "0.01em" }
                    },
                    [_vm._v(" " + _vm._s(_vm.prescription.address) + " ")]
                  )
                : _vm._e(),
              _vm.prescription.prescriptionstatus == "SENT"
                ? _c(
                    "p",
                    {
                      staticClass: "mb-4 mt-2 bold",
                      staticStyle: { "letter-spacing": "0.01em" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.prescription.sentto_pharmacy.name +
                              ", " +
                              _vm.prescription.sentto_pharmacy.address1 +
                              " " +
                              _vm.prescription.sentto_pharmacy.address2 +
                              " " +
                              _vm.prescription.sentto_pharmacy.city +
                              ", " +
                              _vm.prescription.sentto_pharmacy.state +
                              " " +
                              _vm.prescription.sentto_pharmacy.zipcode
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _vm.showPrescriptionStatus
                ? _c(
                    "b-list-group",
                    { attrs: { flush: "" } },
                    [
                      _vm.prescription.prescriptionstatus != "SENT"
                        ? _c(
                            "b-list-group-item",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center bold",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.show(_vm.reorderId)
                                }
                              }
                            },
                            [
                              _vm._v("Reorder treatment "),
                              _c("span", { staticClass: "next-arrow" })
                            ]
                          )
                        : _vm._e(),
                      _vm.showTrackShipment
                        ? _c(
                            "b-list-group-item",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center bold",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.show(_vm.trackId)
                                }
                              }
                            },
                            [
                              _vm._v("Track shipment "),
                              _c("span", { staticClass: "next-arrow" })
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            id: "getShipment",
            size: "md",
            centered: "",
            "hide-header": "",
            "hide-footer": ""
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo" })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c("h2", { staticClass: "mb-3 heading text-primary" }, [
                _vm._v("Get your shipment now?")
              ]),
              _c("p", { staticClass: "mb-3" }, [
                _vm._v(
                  " If you request to get your shipment now, we will update your shipping date frequency based on the date we sent out your order. "
                )
              ]),
              _c(
                "b-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            block: "",
                            disabled: _vm.processing,
                            variant: "outline-info"
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleModal("getShipment")
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.processing ? "Processing" : "Confirm")
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            block: "",
                            disabled: _vm.processing,
                            variant: "info"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$bvModal.hide("getShipment")
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            id: "skipShipment",
            size: "md",
            centered: "",
            "hide-header": "",
            "hide-footer": ""
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo" })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c("h2", { staticClass: "mb-3 heading text-primary" }, [
                _vm._v("Skip next shipment?")
              ]),
              _c("p", { staticClass: "mb-3" }, [
                _vm._v(
                  " If you skip your next shipment, your next order will process on "
                ),
                _c("b", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.prescription.prescriptionstatus != "CANCELLED"
                          ? _vm.moment
                              .unix(_vm.prescription.nextshipmentdate)
                              .format("MMM DD, YYYY")
                          : ""
                      ) +
                      " "
                  )
                ])
              ]),
              _c(
                "b-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            block: "",
                            disabled: _vm.processing,
                            variant: "outline-info"
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleModal("skipShipment")
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.processing ? "Processing" : "Confirm")
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            block: "",
                            disabled: _vm.processing,
                            variant: "info"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$bvModal.hide("skipShipment")
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            id: "shippingDate",
            size: "md",
            centered: "",
            "hide-header": "",
            "hide-footer": ""
          }
        },
        [
          _c("h2", { staticClass: "mb-3 text-center heading link" }, [
            _vm._v("Select a date")
          ]),
          _c(
            "div",
            {
              staticClass: "pb-4",
              staticStyle: { width: "310px", margin: "0 auto" }
            },
            [
              _c("flat-pickr", {
                staticClass: "form-control pl-0",
                attrs: { placeholder: "MM-DD-YYYY", config: _vm.config },
                model: {
                  value: _vm.shippingDate,
                  callback: function($$v) {
                    _vm.shippingDate = $$v
                  },
                  expression: "shippingDate"
                }
              })
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "text-center",
              staticStyle: { width: "310px", margin: "0 auto" },
              attrs: { "align-h": "center" }
            },
            [
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "", pill: "", variant: "outline-info" },
                      on: {
                        click: function($event) {
                          return _vm.toggleModal("shippingDate")
                        }
                      }
                    },
                    [_vm._v("Update")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "", pill: "", variant: "info" },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("shippingDate")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            id: _vm.reorderId,
            size: "md",
            centered: "",
            "hide-header": "",
            "hide-footer": ""
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo" })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c("h2", { staticClass: "mb-3 heading text-primary" }, [
                _vm._v("Reorder this prescription?")
              ]),
              _c("p", { staticClass: "mb-3" }, [
                _vm._v(
                  " If you wish to continue this treatment you can reorder this product and your care provider will review and approve if qualified. "
                )
              ]),
              _c(
                "b-row",
                {
                  staticClass: "text-center",
                  attrs: { "align-h": "center", "no-gutters": "" }
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            block: "",
                            variant: "outline-info"
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleModal("reorder")
                            }
                          }
                        },
                        [_vm._v("Confirm")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { pill: "", block: "", variant: "info" },
                          on: {
                            click: function($event) {
                              return _vm.$bvModal.hide(_vm.reorderId)
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            id: _vm.trackId,
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": ""
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-header intake-header",
              staticStyle: { padding: "0", border: "0" }
            },
            [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "6" } }, [
                        _c("h2", { staticClass: "mt-5 heading text-primary" }, [
                          _vm._v("Track your shipment")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "link" },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.hide(_vm.trackId)
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "x-circle-fill",
                                  variant: "primary"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.shipment, function(item) {
            return _c(
              "div",
              { key: item.id, staticClass: "modal-card mt-5" },
              [
                _c("h2", { staticClass: "mb-4 bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.prescription.medicinename) +
                      " - " +
                      _vm._s(
                        _vm.moment
                          .unix(item.shippingdate)
                          .format("MMM DD, YYYY")
                      ) +
                      " "
                  )
                ]),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        item.trackingno
                          ? [
                              _c(
                                "small",
                                {
                                  staticClass:
                                    "d-block text-muted d-letter-spacing"
                                },
                                [_vm._v("Tracking Number")]
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      "https://www.stamps.com/shipstatus/?confirmation=" +
                                      item.trackingno
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.trackingno) + " ")]
                              )
                            ]
                          : [
                              _c(
                                "small",
                                {
                                  staticClass:
                                    "d-block text-muted d-letter-spacing"
                                },
                                [_vm._v("Shipping Date")]
                              ),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.moment
                                      .unix(item.shippingdate)
                                      .format("MMM DD, YYYY")
                                  )
                                )
                              ])
                            ]
                      ],
                      2
                    ),
                    item.invoiceno
                      ? _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                          _c(
                            "small",
                            {
                              staticClass: "d-block text-muted d-letter-spacing"
                            },
                            [_vm._v("Invoice Number")]
                          ),
                          _c("p", [_vm._v(_vm._s(item.invoiceno))])
                        ])
                      : _vm._e(),
                    item.expecteddate
                      ? _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                          _c(
                            "small",
                            {
                              staticClass: "d-block text-muted d-letter-spacing"
                            },
                            [_vm._v("Expected Date")]
                          ),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.moment
                                  .unix(item.expecteddate)
                                  .format("MMM DD, YYYY")
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.shippedvia
                      ? _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                          _c(
                            "small",
                            {
                              staticClass: "d-block text-muted d-letter-spacing"
                            },
                            [_vm._v("Shipped via")]
                          ),
                          _c("p", [_vm._v(_vm._s(item.shippedvia))])
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            id: "cancelSubscription",
            size: "md",
            centered: "",
            "hide-header": "",
            "hide-footer": ""
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo" })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c("h2", { staticClass: "mb-3 heading text-primary" }, [
                _vm._v(" Are you sure you want to cancel your order? ")
              ]),
              _c("p", { staticClass: "mb-3" }, [
                _vm._v(
                  " If you cancel your subscription, your treatment will be stopped immediately. Canceling doesn't issue a refund. "
                )
              ]),
              _c(
                "b-row",
                {
                  staticClass: "text-center",
                  attrs: { "align-h": "center", "no-gutters": "" }
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            block: "",
                            disabled: _vm.processing,
                            variant: "outline-info"
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleModal("cancelSubscription")
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.processing ? "Processing" : "Confirm")
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            block: "",
                            disabled: _vm.processing,
                            variant: "info"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$bvModal.hide("cancelSubscription")
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }