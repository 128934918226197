import { render, staticRenderFns } from "./EligibilityTPB.vue?vue&type=template&id=bac05db8&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src158183496/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bac05db8')) {
      api.createRecord('bac05db8', component.options)
    } else {
      api.reload('bac05db8', component.options)
    }
    module.hot.accept("./EligibilityTPB.vue?vue&type=template&id=bac05db8&", function () {
      api.rerender('bac05db8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/onboarding/eligibility/split/EligibilityTPB.vue"
export default component.exports