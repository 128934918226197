var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "order-card",
      class: { selected: _vm.isSelected },
      on: { click: _vm.onPrescriptionClick }
    },
    [
      _c("div", { staticClass: "p-3 d-flex justify-content-between" }, [
        _c("div", [
          _c("p", { staticClass: "h2 mb-2" }, [
            _vm._v(
              " " +
                _vm._s(_vm.prescription.medicinename) +
                " | " +
                _vm._s(_vm.prescription.dosage) +
                " "
            )
          ]),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.prescription.unit))
          ])
        ]),
        _c("div", { staticClass: "h5", staticStyle: { color: "#a783ff" } }, [
          _vm._v("Order Processing")
        ])
      ]),
      _c("div", { staticClass: "p-3" }, [
        _vm._v("$" + _vm._s(_vm.prescription.amount.toFixed(2)))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }