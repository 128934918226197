<template>
  <div>
    <VerificationInfoA
      v-if="variant.slug == 'identity-verification'"
      @next="next"
    />
    <VerificationInfoB
      v-else
      @next="next"
      @show-why-need-dialog="showWhyNeedDialog"
    />
  </div>
</template>

<script>
import VerificationInfoA from "@/components/onboarding/verification/split/VerificationInfoA";
import VerificationInfoB from "@/components/onboarding/verification/split/VerificationInfoB";

export default {
  props: ["variant"],
  mounted() {
    console.log(this.variant.slug);
  },
  components: {
    VerificationInfoA,
    VerificationInfoB,
  },
  methods: {
    next() {
      this.$parent.$parent.$emit("next");
    },
    showWhyNeedDialog() {
      this.$parent.$parent.$emit("show-why-need-dialog");
    },
  },
};
</script>
