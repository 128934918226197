<template>
  <b-container class="referral-main my-5 my-md-6">
    <b-row align-h="center">
      <b-col class="col-auto">
        <h3 class="display-4 text-primary heading">Refer a Friend</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert variant="warning" :show="alert.show" dismissible>{{
          alert.message
        }}</b-alert>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center">
      <b-col md="6" class="mb-5 mx-4 mx-md-0 mb-md-0">
        <b-img
          fluid
          :src="require('@/assets/images/trio-photo.png')"
          alt="A photo of three women"
        />
      </b-col>
      <b-col class="px-5">
        <h3
          class="display-3 heading referral-text"
          style="font-weight: initial"
        >
          Your friends are our friends.
        </h3>
        <p class="sub-text">
          Loving your treatment? Share the news with your friends. We'll give
          them $50 off to support their well-being— and as a thank you, you'll
          get $25, too.
        </p>
        <div>
          <span class="text-muted"
            >Share your link and start sharing with friends:
          </span>
          <b-form-input v-model="referralLink" readonly />
          <CopyToClipboard :text="referralLink" @copy="showCopyToast">
            <b-button variant="info" class="mt-4 px-6" pill size="lg"
              >Start Sharing</b-button
            >
          </CopyToClipboard>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CopyToClipboard from "vue-copy-to-clipboard";
import { UsersService } from "@/services/users.service";

export default {
  components: {
    CopyToClipboard,
  },
  data() {
    return {
      referralLink: "",
      alert: {
        show: false,
        message: "",
      },
    };
  },
  async mounted() {
    window.analytics.page();
    const { data } = await UsersService.getReferralToken();
    this.referralLink = `${process.env.VUE_APP_SITE_URL}/referral?refcode=${data.referralcode}`;
    if (this.$route.params.refcode) {
      if (this.$route.params.refcode == data.referralcode) {
        this.showAlert("You can't use your own referral link.");
      } else {
        this.showAlert(
          "You've already created an account, and can't use the link."
        );
      }
    }
  },
  methods: {
    showAlert(message) {
      this.alert.show = true;
      this.alert.message = message;
    },
    showCopyToast() {
      this.$bvToast.toast("Copied to clipboard!", {
        noCloseButton: true,
        autoHideDelay: 1000,
        toaster: "b-toaster-bottom-center",
      });
    },
  },
};
</script>

<style>
.referral-text {
  line-height: 1.5;
}
</style>
