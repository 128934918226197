var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        size: _vm.size,
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "content-class": "dark-bg",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "no-stacking": ""
      },
      model: {
        value: _vm.modal,
        callback: function($$v) {
          _vm.modal = $$v
        },
        expression: "modal"
      }
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-6" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("div", { staticClass: "icon-logo dark" })
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-card" },
        [
          _c(
            "h2",
            {
              staticClass: "mb-3 heading color-inherit",
              staticStyle: { "font-size": "26px", "line-height": "32px" }
            },
            [_vm._v(" " + _vm._s(_vm.body.title) + " ")]
          ),
          _c("p", { staticClass: "mb-5" }, [
            _vm._v(" " + _vm._s(_vm.body.description) + " ")
          ]),
          _vm._l(_vm.body.answerlist, function(answer) {
            return _c(
              "b-button",
              {
                key: answer.id,
                attrs: { variant: "info", pill: "", block: "" },
                on: {
                  click: function($event) {
                    return _vm.submitAnswer(answer.id)
                  }
                }
              },
              [_vm._v(" " + _vm._s(answer.name) + " ")]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }