<template>
  <div class="d-block text-center">
    <b-button class="mt-4 mb-3" variant="info" pill block @click="$emit('next')"
      ><b-icon-lock></b-icon-lock>Continue</b-button
    >
    <a href="javascript:;" @click="toggleText" class="mx-auto"
      ><u>Why we need this information</u></a
    >
    <!-- <p class="sub-text mt-5 text-left" v-if="showText">
      A government-issued ID is required when purchasing any prescription
      medication, as the medical provider will need to verify your identity
      before they can determine if prescription medication is right for you.
      Your personal identifying information, medical history or ID will never be
      shared outside of our secure system.
    </p> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: false,
    };
  },
  methods: {
    toggleText() {
      // this.showText = !this.showText;
      this.$emit("show-why-need-dialog");
    },
  },
};
</script>
