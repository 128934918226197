<template>
  <div>
    <h2 class="question-title with-subtext">
      Ok great! You are eligible for treatment.
    </h2>
    <p class="sub-text group-mb">
      Please get started with your doctor visit by creating an account below.
      All the information you provide is legally required to provide medical
      care.
    </p>
    <b-row>
      <b-col cols="12" lg="6">
        <div role="group" class="group-mb">
          <label for="customerfn" class="input-label">First Name*</label>
          <input
            id="customerfn"
            name="customerfn"
            v-model="firstname"
            type="text"
            class="fs-mask form-control onboarding-input"
            :class="
              firstname.length > 0
                ? checkFirstNameValidity
                  ? 'is-valid'
                  : 'is-invalid'
                : ''
            "
            required
          />
          <b-form-invalid-feedback
            id="input-live-feedback"
            :state="checkFirstNameValidity"
          >
            First name must be alphanumeric
          </b-form-invalid-feedback>
        </div>
      </b-col>

      <b-col cols="12" lg="6">
        <div role="group" class="group-mb">
          <label for="customerln" class="input-label">Last Name*</label>
          <input
            id="customerln"
            name="customerln"
            v-model="lastname"
            type="text"
            class="fs-mask form-control onboarding-input"
            :class="
              lastname.length > 0
                ? checkLastNameValidity
                  ? 'is-valid'
                  : 'is-invalid'
                : ''
            "
            required
          />
          <b-form-invalid-feedback
            id="input-live-feedback"
            :state="checkLastNameValidity"
          >
            Last name must be alphanumeric
          </b-form-invalid-feedback>
        </div>
      </b-col>

      <b-col cols="12" lg="6">
        <div role="group" class="group-mb">
          <label for="birthday" class="input-label">Birthday*</label>
          <b-form-input
            id="birthday"
            class="fs-mask onboarding-input"
            v-model="birthdayDisplay"
            required
            readonly
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" lg="6">
        <div role="group" class="group-mb">
          <label for="state" class="input-label">State*</label>
          <b-form-input
            id="state"
            class="fs-mask onboarding-input"
            v-model="stateName"
            required
            readonly
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12">
        <div role="group" class="group-mb">
          <label for="phoneno" class="input-label">Phone Number*</label>
          <b-form-input
            id="phoneno"
            class="fs-mask onboarding-input hide-arrows"
            v-model="phoneno"
            type="tel"
            pattern="[0-9]*"
            @keyup.enter="submitAnswer"
            :formatter="formatPhoneNumber"
            required
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" v-if="$cookies.get('referralToken')">
        <div role="group" class="group-mb">
          <label for="refcode" class="input-label">Referral Code</label>
          <b-form-input
            id="refcode"
            class="fs-mask onboarding-input"
            v-model="refcode"
            readonly
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12">
        <b-form-checkbox
          class="mb-2 checkbox-label"
          :value="true"
          :unchecked-value="false"
          v-model="optforpromotions"
          >Yes, I'd like to occasionally receive Winona
          promotions</b-form-checkbox
        >
      </b-col>

      <b-col cols="12">
        <b-button
          pill
          block
          class="mb-3 mt-3"
          variant="info"
          @click="submitAnswer"
          :disabled="buttonDisabled"
        >
          Start my free visit
        </b-button>

        <!-- <small class="d-block"
          >Already have an account?
          <router-link to="/login" custom v-slot="{ navigate }"
            ><a href="javascript:void(0)" @click="navigate"
              >Sign In</a
            ></router-link
          >
        </small> -->
      </b-col>

      <b-col cols="12" class="mt-5">
        <small
          ><b-icon icon="lock-fill"></b-icon> Any information you submit is
          encrypted within our secure platform.</small
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { OnboardingService } from "@/services/onboarding.service";
import formValidation from "@/components/form/mixins";
import { AuthService } from "@/services/auth.service";
import { generateEventId } from "@/services/util";
import postscribe from "postscribe";
import VueCookies from "vue-cookies";
import Vue from "vue";

export default {
  mixins: [formValidation],
  props: [
    "eligibilityParams",
    "registrationParams",
    "stateName",
    "email",
    "id",
  ],
  data() {
    return {
      cpassword: "",
      firstname: "",
      lastname: "",
      buttonDisabled: false,
      phoneno: "",
      optforpromotions: true,
    };
  },
  watch: {
    firstname() {
      this.$emit("update-registration", "firstname", this.firstname);
    },
    lastname() {
      this.$emit("update-registration", "lastname", this.lastname);
    },
    phoneno() {
      this.$emit("update-registration", "phoneno", this.phoneno);
    },
  },
  computed: {
    refcode() {
      if ($cookies.get("referralToken")) {
        return $cookies.get("referralToken");
      } else {
        return "";
      }
    },
    checkFirstNameValidity() {
      return this.firstname == ""
        ? null
        : /^[\w\-\s]+$/.test(this.firstname)
        ? true
        : false;
    },
    checkLastNameValidity() {
      return this.lastname == ""
        ? null
        : /^[\w\-\s]+$/.test(this.lastname)
        ? true
        : false;
    },
    birthdayDisplay() {
      if (this.eligibilityParams.birthday) {
        const birthdayArray = this.eligibilityParams.birthday.split("-");
        return `${birthdayArray[1]}-${birthdayArray[2]}-${birthdayArray[0]}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    async fbqTrack(auth) {
      try {
        // FB Pixel Tracking
        fbq("init", "348219289912097", {
          em: this.email,
          fn: auth.firstname,
          ln: auth.lastname,
          ph: auth.phoneno,
          external_id: this.userId,
          extern_id: this.userId,
        });
        // fbq("track", "PageView");
        await OnboardingService.onboardingEvent("PageView");
        fbq(
          "trackCustom",
          "VisitStarted",
          {
            external_id: this.userId,
            extern_id: this.userId,
            ...(await this.$store.dispatch("getFBQParams")),
          },
          {
            eventID: auth.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async submitAnswer() {
      this.buttonDisabled = true;

      // Check discount code
      try {
        if ($cookies.get("promocode")) {
          const checkCode = await OnboardingService.checkCoupon(
            $cookies.get("promocode")
          );
        }
      } catch (err) {
        this.$emit("showAlert", err.message);
        this.buttonDisabled = false;
        return;
      }

      if (
        !this.firstname ||
        !this.lastname ||
        !this.phoneno ||
        this.phoneno.length < 12
      ) {
        this.$emit("showAlert", "Please fill in all fields.");
        this.buttonDisabled = false;
      } else if (
        !/^[\w\-\s]+$/.test(this.firstname) ||
        !/^[\w\-\s]+$/.test(this.lastname)
      ) {
        this.$emit(
          "showAlert",
          "First name and last name must be alphanumeric characters only."
        );
        this.buttonDisabled = false;
      } else {
        let data = {
          phoneno: this.phoneno,
          optforpromotions: this.optforpromotions,
        };

        if (this.firstname) {
          //data.firstname = this.firstname;
          Vue.set(data, "firstname", this.firstname);
        }
        if (this.lastname) {
          //data.lastname = this.lastname;
          Vue.set(data, "lastname", this.lastname);
        }

        // GTM?
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //   event: "Visit Started",
        //   customerID: this.id,
        //   state: this.eligibilityParams.state,
        // });

        for (let key in this.eligibilityParams) {
          //if (!data[key]) data[key] = this.eligibilityParams[key];
          if (!data[key]) Vue.set(data, key, this.eligibilityParams[key]);
        }

        if ("ovariansurgery" in data && !data.ovariansurgery) {
          delete data.ovariansurgery;
        }

        try {
          this.$emit("finish", data);
        } catch (err) {
          if (
            err.message == "An account with the given email already exists."
          ) {
            this.$emit(
              "showAlert",
              `An account with the email ${this.email} already exists. You may sign in from the link below or enter a new email address to create a new account.`
            );
          } else {
            if (err.status == 500) {
              this.$store.dispatch("sendErrorLog", {
                step: "Eligibility",
                error_log: {
                  message: err,
                },
              });
            }
            this.$emit("showAlert", err.message);
          }
          this.buttonDisabled = false;
        }
      }
    },
  },
  mounted() {
    if (this.registrationParams) {
      this.firstname = this.registrationParams.firstname;
      this.lastname = this.registrationParams.lastname;
      this.phoneno = this.registrationParams.phoneno;
    }

    if (document.getElementById("certified-logo").childNodes.length === 0) {
      postscribe(
        "#certified-logo",
        `<script src="https://static.legitscript.com/seals/8131176.js"><\/script>`
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hide-arrows {
  -moz-appearance: textfield;
}
</style>
