<template>
  <div class="single-answer">
    <h2 :class="['question-title', body.header ? 'with-subtext' : '']">
      {{ body.question }}
    </h2>
    <p class="sub-text group-mb" v-if="body.header">{{ body.header }}</p>
    <p class="sub-text group-mb" v-if="body.description">
      {{ body.description }}
    </p>
    <w-checkbox
      type="radio"
      v-model="answer"
      v-for="(list, i) in body.answerlist"
      :key="list.name + i"
      :id="list.name + body.id"
      :value="list.id"
      :label="list.name"
      :class="list.name == 'No Preference' ? 'no-option' : ''"
      :disabled="disabled"
      :description="list.description"
      :recommended="list.mostpopular || list.bestdeal"
      :recommended-text="
        list.mostpopular ? 'Most Popular' : list.bestdeal ? 'Best Deal' : ''
      "
      :treatment="list.tag == 'treatmentform'"
      :treatment-text="list.pricingstatement"
      :bold="list.tag == 'treatmentform' ? true : false"
      @change="changeAnswer(answer, list)"
    >
      <template v-if="list.image" v-slot:image>
        <div class="img-holder">
          <img :src="list.image" :alt="list.name" />
        </div>
      </template>
      <template v-if="list.discountedprice">
        <ul class="list-unstyled mb-0 mt-3">
          <li class="text-muted small-text">
            <s>{{ list.regularprice }}</s>
          </li>
          <li>
            <span class="mb-0 small-text bold-font">{{
              list.discountedprice
            }}</span>
          </li>
        </ul>
      </template>
      <template
        v-if="
          list.hasOwnProperty('regularprice') &&
          !list.hasOwnProperty('discountedprice')
        "
      >
        <span class="mb-0 mt-3">{{ list.regularprice }}</span>
      </template>
    </w-checkbox>
    <b-button
      v-if="this.showContinue"
      :disabled="disabled"
      @click="checkAnswer(answer)"
      pill
      block
      variant="info"
      >Continue</b-button
    >

    <ul class="mt-5" v-if="body.footerarray">
      <li
        class="sub-text group-mb mt-3"
        v-for="(item, i) in body.footerarray"
        :key="`fruit-${i}`"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    body: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      answer: null,
      showContinue: false,
    };
  },
  methods: {
    changeAnswer(answer, answerBody) {
      if (answerBody.hasOwnProperty("inline")) {
        this.showContinue = false;
      }

      if (!this.showContinue) {
        this.checkAnswer(answer, answerBody);
      }
    },
    checkAnswer(answer, answerBody) {
      if (answer == null) {
        this.$emit("alert", "Please select an answer below.");
      } else {
        this.$emit(
          "submitAnswer",
          this.body.id,
          this.answer,
          this.body,
          answerBody
        );
      }
    },
  },
  mounted() {
    if (this.body.hasOwnProperty("answer")) {
      this.answer = this.body.answer;
      let selectedAnswer = this.body.answerlist.filter(
        (answer) => answer.id == this.answer
      )[0];
      if (selectedAnswer.hasOwnProperty("inline")) {
        this.showContinue = false;
        this.$emit(
          "submitAnswer",
          this.body.id,
          this.answer,
          this.body,
          selectedAnswer
        );
      } else {
        this.showContinue = true;
      }
    } else {
      if (
        this.body.hasOwnProperty("preventautosubmit") &&
        this.body.preventautosubmit
      ) {
        this.showContinue = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.img-holder {
  margin-right: -20px;
}
</style>
