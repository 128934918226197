<template>
  <div>
    <div class="text-right">
      <a href="javascript:;" @click="$bvModal.hide('report-issue-form')">
        <b-icon icon="x" variant="link" font-scale="1.5"></b-icon>
      </a>
    </div>

    <div class="px-2 pt-2 pb-4">
      <div v-if="isFinished" class="mx-auto" style="max-width: 768px">
        <p class="h2 text-uppercase my-2" style="letter-spacing: 0.1rem">
          Thank You
        </p>
        <p>
          Your request has been received and our Patient Care team will contact
          you via email in 24 hours.
        </p>
        <b-button
          variant="info"
          pill
          block
          class="mt-4"
          @click="$bvModal.hide('report-issue-form')"
        >
          Close
        </b-button>
      </div>
      <div v-else class="mx-auto" style="max-width: 768px">
        <slot name="title"></slot>
        <p>
          Please share details about your issue with our Patient Care team.
          We’ll get back to you via email within 24 hours.
        </p>
        <b-alert
          v-model="alert.show"
          :variant="alert.type"
          dismissible
          @dismissed="alert.show = false"
        >
          {{ alert.message }}
        </b-alert>
        <b-form-textarea
          id="textarea"
          class="mx-auto"
          v-model.trim="message"
          rows="6"
          max-rows="6"
          style="
            overflow: auto;
            border: 1px solid #19191c;
            border-radius: 0.25rem;
          "
        ></b-form-textarea>
        <b-button
          variant="info"
          pill
          block
          class="mt-4"
          @click="onSendEmail"
          :disabled="isProcessing"
        >
          <b-spinner small v-if="isProcessing"></b-spinner>
          <span v-else>Continue</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ContactService } from "@/services/contact.service";

export default {
  props: {
    issue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      message: "",
      alert: {
        show: false,
        type: "warning",
        message: "",
      },
      isFinished: false,
      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    showAlert(message) {
      this.alert = {
        show: true,
        type: "warning",
        message: message,
      };
    },
    async onSendEmail() {
      try {
        if (!this.message) {
          this.showAlert("Kindly fill up all the fields");
          return;
        }
        this.isProcessing = true;
        await ContactService.reportIssue(this.user.id, {
          note: this.message,
          type: this.issue,
          issue: `${this.issue} Issue`,
        });
        this.message = "";
        this.isFinished = true;
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        this.showAlert(error?.message);
      }
    },
  },
};
</script>

<style></style>
