var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dynamic-array" },
    [
      _c("h2", { staticClass: "question-title" }, [
        _vm._v(_vm._s(_vm.body.question))
      ]),
      _c("b-form-input", {
        staticClass: "onboarding-input-lg",
        attrs: { type: "text", placeholder: "Input your allergy here" },
        model: {
          value: _vm.answers[0].allergy,
          callback: function($$v) {
            _vm.$set(_vm.answers[0], "allergy", $$v)
          },
          expression: "answers[0].allergy"
        }
      }),
      _vm.answers[0].allergy !== ""
        ? _c("b-form-textarea", {
            staticClass: "mt-3 onboarding-textarea",
            attrs: {
              rows: "4",
              placeholder: "What happens when you take the drug above?"
            },
            model: {
              value: _vm.answers[0].effect,
              callback: function($$v) {
                _vm.$set(_vm.answers[0], "effect", $$v)
              },
              expression: "answers[0].effect"
            }
          })
        : _vm._e(),
      _vm._l(_vm.answers, function(answer, index) {
        return _c(
          "div",
          { key: index, staticClass: "mb-3" },
          [
            index
              ? _c("b-icon", {
                  staticClass: "mb-3",
                  staticStyle: { float: "right", cursor: "pointer" },
                  attrs: { icon: "x-circle-fill" },
                  on: {
                    click: function($event) {
                      return _vm.$delete(_vm.answers, index)
                    }
                  }
                })
              : _vm._e(),
            index > 0
              ? _c("b-form-input", {
                  staticClass: "onboarding-input-lg",
                  attrs: {
                    type: "text",
                    placeholder: "Input your allergy here"
                  },
                  model: {
                    value: _vm.answers[index].allergy,
                    callback: function($$v) {
                      _vm.$set(_vm.answers[index], "allergy", $$v)
                    },
                    expression: "answers[index].allergy"
                  }
                })
              : _vm._e(),
            index > 0
              ? _c("b-form-textarea", {
                  staticClass: "mt-3 onboarding-textarea",
                  attrs: {
                    rows: "4",
                    placeholder: "What happens when you take the drug above?"
                  },
                  model: {
                    value: _vm.answers[index].effect,
                    callback: function($$v) {
                      _vm.$set(_vm.answers[index], "effect", $$v)
                    },
                    expression: "answers[index].effect"
                  }
                })
              : _vm._e()
          ],
          1
        )
      }),
      _c("u", [
        _vm.activateControls
          ? _c(
              "a",
              {
                staticClass: "text-info",
                attrs: { href: "javascript:void(0)" },
                on: { click: _vm.addAnswer }
              },
              [_vm._v("+ Add another allergy ")]
            )
          : _vm._e()
      ]),
      _vm.activateControls
        ? _c(
            "b-button",
            {
              staticClass: "mt-4",
              attrs: {
                pill: "",
                block: "",
                variant: "info",
                disabled: _vm.disabled
              },
              on: { click: _vm.checkAnswer }
            },
            [_vm._v("Continue")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }