var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-answer" },
    [
      _c(
        "h4",
        {
          class: [
            _vm.body.description ? "mb-3" : "",
            _vm.heading ? "question-title h2" : "label-text"
          ]
        },
        [_vm._v(" " + _vm._s(_vm.body.question) + " ")]
      ),
      _vm.body.description
        ? _c("p", { staticClass: "sub-text" }, [
            _vm._v(_vm._s(_vm.body.description))
          ])
        : _vm._e(),
      _vm.body.header
        ? _c("p", { staticClass: "sub-text" }, [
            _vm._v(_vm._s(_vm.body.header))
          ])
        : _vm._e(),
      _c("b-form-input", {
        class: {
          "onboarding-textarea": _vm.isAshComputed,
          "fs-mask": _vm.isSensitive
        },
        style: { height: _vm.textHeight },
        attrs: {
          id: "textarea",
          placeholder: _vm.body.placeholder,
          rows: "4",
          "max-rows": "6"
        },
        model: {
          value: _vm.answer,
          callback: function($$v) {
            _vm.answer = $$v
          },
          expression: "answer"
        }
      }),
      _c(
        "b-button",
        {
          staticClass: "mt-3",
          attrs: {
            variant: "info",
            pill: "",
            block: "",
            disabled: _vm.disabled
          },
          on: { click: _vm.checkAnswer }
        },
        [_vm._v("Continue")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }