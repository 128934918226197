<template>
  <b-row class="pl-3">
    <h2 class="question-title">What symptoms would you like to treat?</h2>
    <b-alert :show="alert.show" :variant="alert.type">{{
      alert.message
    }}</b-alert>
    <b-col cols="12" class="fs-exclude mb-2 pl-0 pr-0">
      <ul class="list-unstyled symptoms">
        <li v-for="(list, i) in symptoms" :key="i">
          <input
            type="checkbox"
            :id="'checkbox-' + i"
            v-model="selected"
            :value="list.id"
          />
          <label :for="'checkbox-' + i">
            <v-icon :name="list.id" size="35"></v-icon>
            <span>{{ list.name }}</span>
          </label>
        </li>
        <!-- <li>
          <input
            type="checkbox"
            id="checkbox-other"
            v-model="selected"
            value="others"
          />
          <label for="checkbox-other">
            <v-icon name="other" size="35"></v-icon>
            <span>Others</span>
          </label>
        </li> -->
      </ul>
    </b-col>
    <b-col cols="12" class="pl-0">
      <b-button
        pill
        variant="info"
        class="btn mb-3"
        :disabled="disabled"
        @click="submitSymptoms"
        >Continue</b-button
      >
    </b-col>
  </b-row>
</template>

<script>
import { LookUpService } from "@/services/lookup.service";
import { OnboardingService } from "@/services/onboarding.service";

export default {
  props: {
    selectedSymptoms: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    userid: {
      type: String,
      default: "",
    },
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    firstname: {
      type: String,
      default: "",
    },
    lastname: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // Alert
      alert: {
        show: false,
        type: "",
        message: "",
      },
      // Symptoms
      symptoms: [],
      selected: [],
      hasOthers: false,
    };
  },
  methods: {
    async populateSymptoms() {
      if (this.symptoms.length === 0) {
        const symptomData = await LookUpService.getLookUp("symptom");
        this.symptoms = symptomData.data;
      }
    },
    async populateSelectedSymptoms() {
      const { data } = await OnboardingService.getPatientOnboarding();
      if (data.hasOwnProperty("symptomid")) {
        this.selected = data.symptomid;
        // if(data.hasOwnProperty('othersymptom')) {
        //   this.selected.push('others')
        // }
      } else if ($cookies.get("winona-quiz-v2-answers")) {
        const quizanswers = $cookies.get("winona-quiz-v2-answers");
        // Remove "other" symptom to prevent showing the alternate question
        this.selected = quizanswers.selectedSymptoms.filter(
          (e) => e !== "other"
        );
      } else {
        this.selected = [];
      }
    },
    async submitSymptoms() {
      this.selected = this.selected.filter((item) => item);
      this.$emit("submitSymptoms", this.selected);
    },
  },
  async mounted() {
    this.populateSymptoms();
    this.populateSelectedSymptoms();

    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        customerId: this.userid,
        screen: "2.0-A",
        onboardingflow: this.onboardingflow,
        firstname: this.firstname,
        lastname: this.lastname,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
      },
      {
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );
  },
};
</script>
