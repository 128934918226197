var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "b-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loaded,
              expression: "loaded"
            }
          ],
          staticClass: "bg-primary px-4 py-3 text-secondary",
          attrs: { "no-body": "" }
        },
        [
          _c(
            "span",
            [
              _c(
                "b-row",
                { attrs: { "align-h": "between" } },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "text-center text-sm-left",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _vm._v(" Active Discount: "),
                      _c("br"),
                      _c("span", { staticClass: "heading text-secondary" }, [
                        _vm._v(" " + _vm._s(_vm.couponid) + " ")
                      ])
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-center text-sm-right",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _vm.couponData.percent_off
                        ? _c("span", [
                            _vm._v(
                              " Enjoy " +
                                _vm._s(_vm.couponData.percent_off) +
                                "% off "
                            )
                          ])
                        : _vm._e(),
                      _vm.couponData.applies_to
                        ? _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.discountedProduct.name) + " for "
                            )
                          ])
                        : _vm._e(),
                      _vm.couponData.duration == "once"
                        ? _c("span", [_vm._v(" your first treatment plan! ")])
                        : _vm._e(),
                      _c("br"),
                      _c("span", { staticClass: "text-small" }, [
                        _vm._v("Discount applied at checkout")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }