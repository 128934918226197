import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=9c21c416&scoped=true&"
import script from "./Chat.vue?vue&type=script&lang=js&"
export * from "./Chat.vue?vue&type=script&lang=js&"
import style0 from "./Chat.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Chat.vue?vue&type=style&index=1&id=9c21c416&scoped=true&lang=css&"
import style2 from "./Chat.vue?vue&type=style&index=2&id=9c21c416&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c21c416",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src158183496/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9c21c416')) {
      api.createRecord('9c21c416', component.options)
    } else {
      api.reload('9c21c416', component.options)
    }
    module.hot.accept("./Chat.vue?vue&type=template&id=9c21c416&scoped=true&", function () {
      api.rerender('9c21c416', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partials/Chat.vue"
export default component.exports