<template>
  <b-card class="non-rx">
    <b-row class="p-3">
      <b-col cols="12" class="mb-4">
        <h3 class="bold-font" style="font-size: 24px">
          {{ prescription.medicinename }}
        </h3>
      </b-col>
      <b-col cols="12" md="4"><!-- Spacer column --></b-col>
      <b-col cols="12" md="4">
        <small class="link">Treatment Details</small>
        <p
          v-if="prescription.dosage && prescription.duration"
          class="mb-4 bold"
          style="letter-spacing: 0.01em"
        >
          {{ prescription.dosage }} / {{ prescription.frequency }} ({{
            prescription.duration
          }})
        </p>
        <p v-else class="mb-4 bold" style="letter-spacing: 0.01em">
          {{ prescription.unit }}
        </p>

        <small v-if="showOrderDate" class="link">
          Next Order Date
        </small>
        <p
          v-for="item in filteredShipment"
          :key="item.id"
          class="mb-2 mt-2 bold"
          style="letter-spacing: 0.01em"
        >
          {{
            prescription.transactiontype != "one_time" &&
            prescription.prescriptionstatus != "CANCELLED"
              ? moment.unix(item.shippingdate).format("MMM DD, YYYY")
              : ""
          }}
        </p>
        <br />
        <!-- Shipping Address -->
        <small v-if="prescription.prescriptionstatus != 'SENT'" class="link"
          >Shipping Address</small
        >
        <small v-else class="link">Prescription sent to</small>

        <p
          v-if="prescription.prescriptionstatus != 'SENT'"
          class="mb-4 mt-2 bold"
          style="letter-spacing: 0.01em"
        >
          {{ prescription.address }}
        </p>
        <p
          v-if="prescription.prescriptionstatus == 'SENT'"
          class="mb-4 mt-2 bold"
          style="letter-spacing: 0.01em"
        >
          {{
            `${prescription.sentto_pharmacy.name}, ${prescription.sentto_pharmacy.address1} ${prescription.sentto_pharmacy.address2} ${prescription.sentto_pharmacy.city}, ${prescription.sentto_pharmacy.state} ${prescription.sentto_pharmacy.zipcode}`
          }}
        </p>
      </b-col>
      <b-col cols="12" md="4">
        <b-list-group flush v-if="showPrescriptionStatus">
          <b-list-group-item
            v-if="prescription.prescriptionstatus != 'SENT'"
            class="d-flex justify-content-between align-items-center bold"
            style="cursor: pointer"
            @click="$bvModal.show(reorderId)"
            >Reorder treatment <span class="next-arrow"></span
          ></b-list-group-item>
          <b-list-group-item
            v-if="showTrackShipment"
            class="d-flex justify-content-between align-items-center bold"
            style="cursor: pointer"
            @click="$bvModal.show(trackId)"
            >Track shipment <span class="next-arrow"></span
          ></b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>

    <!-- Modals -->
    <modal
      id="getShipment"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">Get your shipment now?</h2>
        <p class="mb-3">
          If you request to get your shipment now, we will update your shipping
          date frequency based on the date we sent out your order.
        </p>
        <b-row class="text-center">
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              :disabled="processing"
              @click="toggleModal('getShipment')"
              variant="outline-info"
              >{{ processing ? "Processing" : "Confirm" }}</b-button
            >
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              :disabled="processing"
              @click="$bvModal.hide('getShipment')"
              variant="info"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- skip shipment -->
    <modal
      id="skipShipment"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">Skip next shipment?</h2>
        <p class="mb-3">
          If you skip your next shipment, your next order will process on
          <b>
            {{
              prescription.prescriptionstatus != "CANCELLED"
                ? moment
                    .unix(prescription.nextshipmentdate)
                    .format("MMM DD, YYYY")
                : ""
            }}
          </b>
        </p>
        <b-row class="text-center">
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              :disabled="processing"
              @click="toggleModal('skipShipment')"
              variant="outline-info"
              >{{ processing ? "Processing" : "Confirm" }}</b-button
            >
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              :disabled="processing"
              @click="$bvModal.hide('skipShipment')"
              variant="info"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- shipping date -->
    <modal
      id="shippingDate"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <h2 class="mb-3 text-center heading link">Select a date</h2>
      <div style="width: 310px; margin: 0 auto" class="pb-4">
        <flat-pickr
          class="form-control pl-0"
          v-model="shippingDate"
          placeholder="MM-DD-YYYY"
          :config="config"
        ></flat-pickr>
      </div>
      <b-row
        class="text-center"
        align-h="center"
        style="width: 310px; margin: 0 auto"
      >
        <b-col cols="6" class="mb-3">
          <b-button
            block
            pill
            @click="toggleModal('shippingDate')"
            variant="outline-info"
            >Update</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button
            block
            pill
            @click="$bvModal.hide('shippingDate')"
            variant="info"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </modal>

    <!-- reorder -->
    <modal
      :id="reorderId"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>

      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">Reorder this prescription?</h2>
        <p class="mb-3">
          If you wish to continue this treatment you can reorder this product
          and your care provider will review and approve if qualified.
        </p>
        <b-row class="text-center" align-h="center" no-gutters>
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              @click="toggleModal('reorder')"
              variant="outline-info"
              >Confirm</b-button
            >
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              @click="$bvModal.hide(reorderId)"
              variant="info"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- track -->
    <modal :id="trackId" ref="modal" size="lg" centered hide-header hide-footer>
      <div class="modal-header intake-header" style="padding: 0; border: 0">
        <b-container fluid>
          <b-row>
            <b-col cols="6">
              <h2 class="mt-5 heading text-primary">Track your shipment</h2>
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button
                size="sm"
                variant="link"
                @click="$bvModal.hide(trackId)"
              >
                <b-icon icon="x-circle-fill" variant="primary"></b-icon>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- name of patient -->
      <div
        v-for="item in shipment"
        v-bind:key="item.id"
        class="modal-card mt-5"
      >
        <h2 class="mb-4 bold">
          {{ prescription.medicinename }} -
          {{ moment.unix(item.shippingdate).format("MMM DD, YYYY") }}
        </h2>
        <b-row>
          <b-col cols="12" md="6">
            <template v-if="item.trackingno">
              <small class="d-block text-muted d-letter-spacing"
                >Tracking Number</small
              >
              <!-- <p>{{ item.trackingno }}</p> -->
              <a
                target="_blank"
                v-bind:href="
                  `https://www.stamps.com/shipstatus/?confirmation=${item.trackingno}`
                "
              >
                {{ item.trackingno }}
              </a>
            </template>
            <template v-else>
              <small class="d-block text-muted d-letter-spacing"
                >Shipping Date</small
              >
              <p>{{ moment.unix(item.shippingdate).format("MMM DD, YYYY") }}</p>
            </template>
          </b-col>
          <!-- <b-col cols="12" md="6">
            <small class="d-block text-muted d-letter-spacing">Status</small>
            <p>
              {{
                item.trackingstatus
                  ? item.trackingstatus
                  : item.shippingstatus
                  ? item.shippingstatus
                  : ""
              }}
            </p>
          </b-col> -->
          <b-col v-if="item.invoiceno" cols="12" md="6">
            <small class="d-block text-muted d-letter-spacing"
              >Invoice Number</small
            >
            <p>{{ item.invoiceno }}</p>
          </b-col>

          <b-col v-if="item.expecteddate" cols="12" md="6">
            <small class="d-block text-muted d-letter-spacing"
              >Expected Date</small
            >
            <p>{{ moment.unix(item.expecteddate).format("MMM DD, YYYY") }}</p>
          </b-col>
          <b-col v-if="item.shippedvia" cols="12" md="6">
            <small class="d-block text-muted d-letter-spacing"
              >Shipped via</small
            >
            <p>{{ item.shippedvia }}</p>
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- cancel subscriptions -->
    <modal
      id="cancelSubscription"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>

      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">
          Are you sure you want to cancel your order?
        </h2>
        <p class="mb-3">
          If you cancel your subscription, your treatment will be stopped
          immediately. Canceling doesn't issue a refund.
        </p>
        <b-row class="text-center" align-h="center" no-gutters>
          <b-col cols="12" class="mb-3">
            <b-button
              pill
              block
              :disabled="processing"
              @click="toggleModal('cancelSubscription')"
              variant="outline-info"
              >{{ processing ? "Processing" : "Confirm" }}</b-button
            >
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              :disabled="processing"
              @click="$bvModal.hide('cancelSubscription')"
              variant="info"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
      </div>
    </modal>
  </b-card>
</template>

<script>
import { UsersService } from "@/services/users.service";
import { PrescriptionService } from "@/services/prescription.service";
import { TransactionService } from "@/services/transaction.service";

export default {
  props: {
    patientId: {
      type: String,
    },
    prescriptionId: {
      type: String,
    },
  },
  data() {
    return {
      prescription: {},
      shipment: [],
      processing: false,
      shippingDate: "",
      config: {
        minDate: "today",
        dateFormat: "m-d-Y",
        inline: true,
      },
    };
  },
  computed: {
    filteredShipment() {
      return this.shipment.filter((x) => x.shippingstatus === "PENDING");
    },
    showOrderDate() {
      // if(this.prescription.transactiontype !== 'one_time' && this.prescription.prescriptionstatus !== 'CANCELLED' &&
      //   this.shipment.filter((x) => {
      //     x.trackingstatus === 'IN-TRANSIT' || x.shippingstatus === 'PAID'
      //   }).length != this.shipment.length
      // ) {
      //   return true
      // } else {
      //   return false
      // }
      return false;
    },
    showTrackShipment() {
      return (
        this.prescription.prescriptionstatus === "ORDERED" ||
        this.prescription.prescriptionstatus === "CANCELLED"
      );
    },
    showPrescriptionStatus() {
      return this.prescription.prescriptionstatus != "SENT";
    },
    trackId() {
      return `track-${this.prescriptionId}`;
    },
    reorderId() {
      return `reorder-${this.prescriptionId}`;
    },
  },
  methods: {
    async getPrescription() {
      try {
        let res = await PrescriptionService.getPrescriptionorder(
          this.patientId,
          this.prescriptionId
        );
        if (res.data) {
          this.prescription = res.data;
        }
      } catch (err) {
        console.log(err.message);
      }
    },
    async getShipment() {
      const { data } = await UsersService.getShipment(this.prescriptionId);
      if (data) this.shipment = data;
    },
    // Modal methods
    async toggleModal(id) {
      this.$emit("showAlert", false);
      let res;
      try {
        this.processing = true;
        if (id === "getShipment") {
          res = await TransactionService.getNextShipment(this.prescriptionId);
          if (res && res.data) {
            // console.log("getShipment ", res.data);
          }
        } else if (id === "skipShipment") {
          res = await TransactionService.skipPrescriptionOrder(
            this.prescriptionId
          );
          if (res && res.data) {
            // console.log("skipShipment ", res.data);
          }
        } else if (id === "cancelSubscription") {
          res = await TransactionService.cancelPrescriptionOrder(
            this.prescriptionId
          );
          if (res && res.data) {
            // console.log("cancelSubscription ", res.data);
            window.location.reload;
          }
        } else if (id === "reorder") {
          res = await TransactionService.reorderPrescriptionOrder(
            this.prescriptionId
          );
          if (res && res.data) {
            // console.log("reorderPrescription ", res.data);
            this.$router.go();
          }
        }
      } catch (error) {
        if (error.message) {
          this.$emit("showAlert", true, error.message);
        }
      } finally {
        this.processing = false;
        await this.getShipment();
        this.$root.$emit("bv::hide::modal", id);
      }
    },
  },
  mounted() {
    this.getPrescription();
    this.getShipment();
  },
};
</script>
