var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showContent,
          expression: "showContent"
        }
      ],
      attrs: { fluid: "" }
    },
    [
      _c("OnboardingProgress", {
        attrs: { hide: _vm.hideBackButton, progress: _vm.progress },
        on: { back: _vm.prev }
      }),
      _c(
        "b-row",
        { staticClass: "onboarding-wrapper" },
        [
          _c("OnboardingHeader", {
            attrs: {
              hide: _vm.hideBackButton,
              step: "1",
              section: _vm.step == "email",
              title: _vm.title
            },
            on: { back: _vm.prev }
          }),
          _c(
            "b-col",
            {
              staticClass: "mt-6 pt-3 onboarding-content",
              attrs: { cols: "12", lg: "5", xl: "4", xxl: "4" }
            },
            [
              _c("DiscountBanner", {
                attrs: { show: _vm.coupon.show, couponid: _vm.coupon.couponid }
              }),
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                [
                  _vm._v(" " + _vm._s(_vm.alert.message) + " "),
                  _vm.alert.showEmail
                    ? _c(
                        "span",
                        [
                          _c("br"),
                          _c(
                            "router-link",
                            {
                              staticClass: "text-primary",
                              attrs: { to: "/login" }
                            },
                            [_c("u", [_vm._v("Click here to sign in.")])]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "b-row",
                { staticClass: "d-lg-none" },
                [
                  _c("b-col", [
                    _c(
                      "h3",
                      { staticClass: "bold d-letter-spacing onboarding-title" },
                      [_vm._v(" Account Creation ")]
                    )
                  ]),
                  _c("b-col", [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "\n              bold\n              d-letter-spacing\n              onboarding-title\n              text-muted text-right\n            "
                      },
                      [_vm._v(" Intake ")]
                    )
                  ])
                ],
                1
              ),
              _c("b-progress", {
                staticClass: "step-progress d-lg-none mb-5",
                attrs: {
                  value: _vm.progress[0].value,
                  max: _vm.progress[0].max,
                  variant: "purple",
                  height: "5px"
                }
              }),
              _vm.step == "state"
                ? _c("State", {
                    attrs: {
                      preAnswer: _vm.eligibilityParams.state,
                      onboardingflow: _vm.onboardingflow
                    },
                    on: {
                      setParams: _vm.setParams,
                      setStateName: _vm.setStateName,
                      setStep: _vm.setStep,
                      showLoader: _vm.showLoader,
                      showAlert: _vm.showAlert
                    }
                  })
                : _vm._e(),
              _vm.step == "email"
                ? _c("Email", {
                    attrs: {
                      onboardingflow: _vm.onboardingflow,
                      eligibilityParams: _vm.eligibilityParams
                    },
                    on: {
                      setStep: _vm.setStep,
                      showAlert: _vm.showAlert,
                      showEmailAlert: _vm.showEmailAlert,
                      setId: _vm.setId,
                      setEmail: _vm.setEmail
                    }
                  })
                : _vm._e(),
              _vm.step == "waitlist"
                ? _c("Waitlist", {
                    attrs: {
                      state: _vm.eligibilityParams.state,
                      onboardingflow: _vm.onboardingflow
                    },
                    on: { setStep: _vm.setStep, showAlert: _vm.showAlert }
                  })
                : _vm._e(),
              _vm.step == "thankyou"
                ? _c("Nothing", {
                    attrs: { body: _vm.thankyouBody },
                    on: { submitAnswer: _vm.exit }
                  })
                : _vm._e(),
              _vm.step == "birthday"
                ? _c("Birthday", {
                    attrs: {
                      preAnswer: _vm.eligibilityParams.birthday,
                      onboardingflow: _vm.onboardingflow,
                      externalid: _vm.userId
                    },
                    on: {
                      setParams: _vm.setParams,
                      setStep: _vm.setStep,
                      showLoader: _vm.showLoader,
                      showAlert: _vm.showAlert,
                      exit: _vm.exit
                    }
                  })
                : _vm._e(),
              _vm.step == "over60"
                ? _c("Nothing", {
                    attrs: { body: _vm.over60Body },
                    on: { submitAnswer: _vm.exit }
                  })
                : _vm._e(),
              _vm.step == "sex" && !_vm.registered
                ? _c("Sex", {
                    attrs: {
                      onboardingflow: _vm.onboardingflow,
                      preAnswer: _vm.eligibilityParams.gender,
                      age: _vm.age
                    },
                    on: {
                      setParams: _vm.setParams,
                      setStep: _vm.setStep,
                      showLoader: _vm.showLoader,
                      checkToken: _vm.checkAuthToken,
                      showAlert: _vm.showAlert
                    }
                  })
                : _vm._e(),
              _vm.step == "sex" && _vm.registered
                ? _c("Sex", {
                    attrs: {
                      preAnswer: _vm.eligibilityParams.gender,
                      onboardingflow: _vm.onboardingflow,
                      age: _vm.age,
                      eligibilityParams: _vm.eligibilityParams
                    },
                    on: {
                      setParams: _vm.setParams,
                      finish: _vm.finish,
                      setStep: _vm.setStep,
                      showLoader: _vm.showLoader,
                      checkToken: _vm.checkAuthToken,
                      showAlert: _vm.showAlert
                    }
                  })
                : _vm._e(),
              _vm.step == "surgery"
                ? _c("Surgery", {
                    on: {
                      setParams: _vm.setParams,
                      setStep: _vm.setStep,
                      showLoader: _vm.showLoader,
                      checkToken: _vm.checkAuthToken,
                      showAlert: _vm.showAlert
                    }
                  })
                : _vm._e(),
              _vm.step == "under35"
                ? _c("Nothing", {
                    attrs: { body: _vm.under35Body },
                    on: { submitAnswer: _vm.exit }
                  })
                : _vm._e(),
              _vm.step == "male"
                ? _c("Nothing", {
                    attrs: { body: _vm.maleBody },
                    on: { submitAnswer: _vm.exit }
                  })
                : _vm._e(),
              _vm.step == "registration"
                ? _c("Registration", {
                    ref: "Registration",
                    attrs: {
                      registrationParams: _vm.registrationParams,
                      eligibilityParams: _vm.eligibilityParams,
                      stateName: _vm.stateName,
                      id: _vm.userId,
                      email: _vm.email
                    },
                    on: {
                      showAlert: _vm.showAlert,
                      finish: _vm.finish,
                      "update-registration": _vm.updateRegistration
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "3", xl: "4", xxl: "4" } },
            [
              _c(
                "b-row",
                {
                  staticStyle: { height: "100%" },
                  attrs: { "align-v": "stretch" }
                },
                [
                  _c(
                    "b-col",
                    { attrs: { "align-self": "start", cols: "12" } },
                    [
                      _c("div", { staticClass: "d-none d-lg-block mt-6" }),
                      _c("div", { staticClass: "d-block d-lg-none mt-4" }),
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == "registration",
                            expression: "step == 'registration'"
                          }
                        ],
                        staticClass: "certified-logo text-center",
                        attrs: { id: "certified-logo" }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step == "email",
                  expression: "step == 'email'"
                }
              ],
              attrs: { cols: "12" }
            },
            [
              _c("Personalize", {
                attrs: {
                  baseline: {
                    id: "Account Creation TP Widget",
                    audience: { id: "CADKX4xBYMzH55uArnO7K" }
                  },
                  component: _vm.getRenderingComponent(false),
                  loadingComponent: _vm.getRenderingComponent(true),
                  holdout: 0.5
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }