import { ChatService } from "@/services/chat.service.js";

export default {
  namespaced: true,
  state: {
    unreadMessagesCount: 0,
  },
  mutations: {
    SET_UNREAD_COUNT(state, payload) {
      state.unreadMessagesCount = payload;
    },
  },
  getters: {},
  actions: {
    async getUnreadMessageCount({ commit, rootState }) {
      try {
        if (rootState?.user?.currentUser?.id) {
          const { data } = await ChatService.getUnreadMessageCount(
            rootState?.user?.currentUser?.id
          );
          commit("SET_UNREAD_COUNT", data);
          return data;
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  },
};
