var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eligibilityWaitlist" },
    [
      _c("h2", { staticClass: "mb-3 question-title" }, [
        _vm._v(" At the moment, we don't offer treatment in your state ")
      ]),
      _c("p", [
        _vm._v(
          " Our goal is to open up in your state soon. In the meantime, would you be interested in joining our waitlist? "
        )
      ]),
      _c("w-checkbox", {
        attrs: { type: "radio", label: "Yes", id: "yes", value: "true" },
        on: {
          change: function($event) {
            _vm.textbox = true
          }
        },
        model: {
          value: _vm.waitlist,
          callback: function($$v) {
            _vm.waitlist = $$v
          },
          expression: "waitlist"
        }
      }),
      _c("w-checkbox", {
        attrs: { type: "radio", label: "No", id: "no", value: "false" },
        on: { change: _vm.submitAnswer },
        model: {
          value: _vm.waitlist,
          callback: function($$v) {
            _vm.waitlist = $$v
          },
          expression: "waitlist"
        }
      }),
      _vm.textbox
        ? _c(
            "div",
            [
              _c("label", { attrs: { for: "email" } }, [
                _vm._v("What is your email address?")
              ]),
              _c("b-form-input", {
                staticClass: "fs-mask",
                attrs: { name: "email", placeholder: "Email" },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              }),
              _c(
                "b-button",
                {
                  staticClass: "px-5 mt-4",
                  attrs: { pill: "", variant: "info" },
                  on: { click: _vm.submitAnswer }
                },
                [_vm._v(" Continue ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }