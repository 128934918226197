<template>
  <div class="week-single">
    <b-col cols="3"></b-col>
    <!-- <b-col
      v-if="question.hasOwnProperty('type') && question.type == 'modal'"
      align-self="center"
    >
      <b-modal
        size="md"
        hide-header
        hide-footer
        centered
        content-class="dark-bg"
        no-close-on-backdrop
        no-close-on-esc
        v-model="showModal"
      >
        <b-row class="mb-6">
          <b-col cols="12">
            <div class="icon-logo dark"></div>
          </b-col>
        </b-row>
        <div class="modal-card">
          <h2
            class="mb-3 heading color-inherit"
            style="font-size: 26px; line-height: 32px"
          >
            {{ question.title }}
          </h2>
          <b-button
            variant="info"
            pill
            block
            v-for="(answer, i) in question.answerlist"
            :key="answer.name + i"
            @click="submitAnswer(question.id, answer.id, answer)"
            >{{ answer.name }}</b-button
          >
        </div>
      </b-modal>
    </b-col> -->

    <b-col v-if="mainScreenSteps.includes(question.id)">
      <div
        class="d-flex flex-column mx-auto"
        style="max-width: 768px"
        v-if="question.id === 3.51"
      >
        <h2 class="question-title-v2 mb-3 text-white">
          {{ question.title }}
        </h2>
        <div class="mt-3 mb-3">
          <div
            v-for="item in question.displayArray"
            :key="item.id"
            class="d-flex align-items-center mb-3"
          >
            <div
              style="
                width: 50px;
                height: 50px;
                background-color: #cdfcb1;
                border-radius: 999px;
              "
            ></div>
            <p class="sub-text text-white mb-0 ml-3">{{ item.name }}</p>
          </div>
        </div>
        <p class="sub-text group-mb mb-4 text-white">
          {{ question.question }}
        </p>
        <div class="cancel-membership">
          <b-button
            class="px-6"
            pill
            variant="info"
            size="lg"
            @click="
              submitAnswer(
                question.id,
                question.answerlist[0].id,
                question.answerlist[0]
              )
            "
          >
            {{ question.answerlist[0].name }}
          </b-button>
          <div class="mt-4 pt-1">
            <a
              href="javascript:void(0)"
              class="text-underline text-uppercase text-white"
              style="letter-spacing: 0.1rem"
            >
              Cancel membership
            </a>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column mx-auto"
        style="max-width: 768px"
        v-else-if="question.id === 3.71"
      >
        <h2 class="question-title-v2 mb-4 text-white">
          {{ question.question }}
        </h2>
        <div class="mb-2">
          <w-checkbox
            v-for="(item, i) in question.answerlist"
            :key="item.name + i"
            :id="item.name + question.id"
            :value="item.id"
            type="radio"
            :label="item.name"
            v-model="answer"
            :is-cancel="true"
            @change="submitAnswer(question.id, item.id, item)"
          ></w-checkbox>
          <div class="text-center">
            <b-button
              class="mt-4"
              v-if="question.hasOwnProperty('answer')"
              variant="info"
              size="lg"
              pill
              @click="
                submitAnswer(
                  question.id,
                  answer,
                  question.answerlist.find((o) => o.id == answer)
                )
              "
              style="width: 100%"
              >Continue</b-button
            >
          </div>
        </div>
        <div class="cancel-membership">
          <div class="mt-3 pt-1">
            <a
              href="javascript:void(0)"
              class="text-underline text-uppercase text-white"
              style="letter-spacing: 0.1rem"
            >
              Cancel membership
            </a>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column mx-auto" style="max-width: 768px" v-else>
        <h2 class="question-title-v2 mb-3 text-white">
          {{ question.title }}
        </h2>
        <p class="sub-text group-mb mb-4 text-white">
          {{ question.question }}
        </p>
        <div>
          <b-button
            class="px-6"
            pill
            variant="info"
            size="lg"
            @click="
              submitAnswer(
                question.id,
                question.answerlist[0].id,
                question.answerlist[0]
              )
            "
            v-if="question.answerlist && question.answerlist.length > 0"
          >
            {{ question.answerlist[0].name }}
          </b-button>
          <b-button
            class="px-6"
            pill
            variant="info"
            size="lg"
            v-else
            @click="submitAnswer(question.id, '')"
          >
            Complete
          </b-button>
          <div class="mt-4 pt-1" v-if="![12.1, 12.2].includes(question.id)">
            <a
              href="javascript:void(0)"
              class="text-underline text-uppercase text-white"
              style="letter-spacing: 0.1rem"
            >
              Cancel my subscription
            </a>
          </div>
        </div>
      </div>
    </b-col>
    <b-col
      v-else-if="
        [1, 2, 3.81, 4.1, 6.1, 7.1, 7.5, 8.1, 11.1, 11.2].includes(question.id)
      "
    >
      <div class="d-flex flex-column mx-auto" style="max-width: 768px">
        <h2 class="question-title-v2 mb-3">
          {{ question.title }}
        </h2>
        <p class="sub-text group-mb mb-4">
          {{ question.question }}
        </p>
        <div>
          <b-button
            class="px-6"
            pill
            variant="info"
            size="md"
            @click="
              submitAnswer(
                question.id,
                question.answerlist[0].id,
                question.answerlist[0]
              )
            "
          >
            {{ question.answerlist[0].name }}
          </b-button>
          <div class="mt-3 pt-1" v-if="question.answerlist.length > 1">
            <a
              href="javascript:void(0)"
              class="text-default text-underline"
              @click="
                submitAnswer(
                  question.id,
                  question.answerlist[1].id,
                  question.answerlist[1]
                )
              "
            >
              {{ question.answerlist[1].name }}
            </a>
          </div>
        </div>
      </div>
    </b-col>
    <b-col v-else-if="question.hasOwnProperty('displayArray')">
      <div class="d-flex flex-column mx-auto" style="max-width: 768px">
        <h2 class="question-title-v2 mb-3">{{ question.title }}</h2>
        <div class="accordion mb-3" role="tablist">
          <b-card
            no-body
            class="mb-0"
            style="border: none"
            v-for="(list, i) in question.displayArray"
            :key="i"
          >
            <b-card-header
              header-tag="header"
              class="p-1 mb-3"
              role="tab"
              style="border: none"
            >
              <div
                class="toggle-accordion bold d-letter-spacing"
                v-b-toggle="'accordion-' + i"
              >
                <div>
                  <span class="h1 mb-0" style="color: #2c2660">{{
                    list.title
                  }}</span>
                </div>
                <span class="when-opened text-underline" style="color: #a783ff">
                  <!-- <b-icon icon="chevron-up" /> -->
                  COLLAPSE
                </span>
                <span class="when-closed text-underline" style="color: #a783ff">
                  <!-- <b-icon icon="chevron-down" /> -->
                  EXPAND
                </span>
              </div>
            </b-card-header>
            <b-collapse
              :id="'accordion-' + i"
              accordion="my-accordion"
              role="tabpanel"
              :visible="i === 0"
            >
              <b-card-text class="pb-4 d-letter-spacing text-muted">{{
                list.name
              }}</b-card-text>
            </b-collapse>
          </b-card>
        </div>
        <div>
          <b-button
            class="px-6"
            pill
            variant="info"
            size="md"
            @click="
              submitAnswer(
                question.id,
                question.answerlist[0].id,
                question.answerlist[0]
              )
            "
          >
            {{ question.answerlist[0].name }}
          </b-button>
          <div class="mt-3 pt-1" v-if="question.answerlist.length > 1">
            <a
              href="javascript:void(0)"
              class="text-default text-underline"
              @click="
                submitAnswer(
                  question.id,
                  question.answerlist[1].id,
                  question.answerlist[1]
                )
              "
            >
              {{ question.answerlist[1].name }}
            </a>
          </div>
        </div>
      </div>
    </b-col>
    <b-col v-else align-self="center" class="d-flex flex-column mx-auto">
      <h1 class="question-title-v2">
        {{ question.question }}
      </h1>
      <div class="text-center">
        <!-- <b-button
          variant="outline-info"
          size="xl"
          v-for="(item, i) in question.answerlist"
          :key="item.name + i"
          :id="item.name + question.id"
          :value="item.id"
          :pressed="item.id == answer"
          class="mx-3 question-btn"
          :class="question.id == answer ? 'active' : ''"
          @click="submitAnswer(question.id, item.id, item)"
        >
          {{ item.name }}
        </b-button> -->

        <w-checkbox
          v-for="(item, i) in question.answerlist"
          :key="item.name + i"
          :id="item.name + question.id"
          :value="item.id"
          type="radio"
          :label="item.name"
          v-model="answer"
          @change="submitAnswer(question.id, item.id, item)"
        ></w-checkbox>
        <!-- <w-checkbox
            class="fs-exclude"
            v-model="sex"
            type="radio"
            value="Male"
            label="Male"
            id="sex-male"
            @change="submitAnswer"
          ></w-checkbox> -->
        <!-- <div class="my-4" v-if="answer === 'other'">
          <h3 class="text-left">
            Share more information on why you want to cancel
          </h3>
          <b-form-textarea
            id="textarea"
            class="onboarding-textarea-v2"
            style="width: 100%"
            v-model.trim="otherAnswer"
            rows="6"
            max-rows="6"
            placeholder="Write something"
          ></b-form-textarea>
        </div> -->
      </div>
      <div class="text-center">
        <b-button
          class="mt-4"
          v-if="question.hasOwnProperty('answer') && !composite"
          variant="info"
          size="lg"
          pill
          @click="
            submitAnswer(
              question.id,
              answer,
              question.answerlist.find((o) => o.id == answer)
            )
          "
          style="width: 100%"
          >Continue</b-button
        >
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default() {
        return {};
      },
    },
    composite: {
      type: Boolean,
      default: false,
    },
    mainScreenSteps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      answer: null,
      otherAnswer: "",
    };
  },
  watch: {
    question: {
      handler: function () {
        this.answer = null;
        if (
          this.question.hasOwnProperty("type") &&
          this.question.type == "modal"
        ) {
          this.showModal = true;
        }
        if (this.question.hasOwnProperty("answer")) {
          this.answer = this.question.answer;
        }
      },
      deep: true,
    },
  },
  methods: {
    submitAnswer(id, answer, answerBody) {
      // if (answer === "other" && !this.otherAnswer) return;
      console.log(id, answer, answerBody);
      this.showModal = false;
      this.answer = answer;
      this.$emit("submitAnswer", id, answer, answerBody);
    },
  },
  mounted() {
    if (this.question.hasOwnProperty("answer")) {
      this.answer = this.question.answer;
    }
  },
};
</script>

<style scoped>
.question-container {
  width: 40%;
}
@media (max-width: 1024px) {
  .question-container {
    width: 100%;
  }
}
.cancel-membership {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  align-items: center;
}
</style>
