<template>
  <b-container class="p-0" fluid v-show="!isFetchingOnboardingStatus">
    <top-bar :username="name" :profile-pic="profileUrl" :id="id" />
    <b-container
      fluid
      :class="this.$route.name == 'messages' ? 'p-0' : 'pl-0 pr-0'"
      style="overflow: hidden; margin-top: 67px"
    >
      <vue-page-transition>
        <router-view />
      </vue-page-transition>
    </b-container>
  </b-container>
</template>

<script>
import TopBar from "@/components/partials/Navbar";
import { OnboardingService } from "@/services/onboarding.service";
import LocalStorageService from "@/localStorage";
const localStorageService = LocalStorageService.getService();

export default {
  name: "Home",
  components: {
    TopBar,
    // Sidebar
  },
  data() {
    return {
      name: "",
      id: "",
      email: "",
      profileUrl: "",
      isFetchingOnboardingStatus: false,
      intervalFunction: null,
    };
  },
  watch: {
    $route(to, from) {
      if (from.name == "messages" && to.name != "messages") {
        window.Intercom("update", {
          hide_default_launcher: false,
        });
      }
    },
  },
  methods: {
    async getuser() {
      await this.$store
        .dispatch("user/getUser")
        .then((data) => {
          // if (data.onboardingstatus === "PENDING") {

          //   localStorageService.setItem("onboardingstep", data.onboardingstep);

          //   if (data.onboardingstep === 1) {
          //     this.pushRouteTo("/getting-started");
          //   } else if (data.onboardingstep >= 2 && data.onboardingstep < 3) {
          //     this.pushRouteTo("/health-questions");
          //   } else if (data.onboardingstep >= 3 && data.onboardingstep < 4) {
          //     this.pushRouteTo("/treatment-options");
          //   }else if (data.onboardingstep >= 4 && data.onboardingstep < 5) {
          //     this.pushRouteTo("/treatment-addons");
          //   }else if (data.onboardingstep >= 5 && data.onboardingstep < 6) {
          //     this.pushRouteTo("/identity-verification");
          //   }else if (data.onboardingstep >= 6) {
          //     this.pushRouteTo("/checkout");
          //   }else {
          //     this.pushRouteTo("/");
          //   }
          // }
          if (data.id) {
            this.id = data.id;
          }
          if (data.firstname && data.lastname) {
            this.name = data.firstname + " " + data.lastname;
          } else {
            this.name = data.email;
          }
          this.profileUrl = data.profileUrl;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    async fetchUnreadMessageCount() {
      await this.$store.dispatch("message/getUnreadMessageCount");
    },
    async getOnboardingStatus() {
      try {
        let { data } = await OnboardingService.getPatientOnboarding();
        if (
          data.hasOwnProperty("hasorder") &&
          data.hasorder &&
          data.onboardingstatus == "PENDING"
        ) {
          return;
        }
        if (
          data.hasOwnProperty("birthday") &&
          data.onboardingstatus == "PENDING" &&
          !data.hasOwnProperty("nonrxmedicationonly")
        ) {
          this.pushRouteTo("/");
        }
      } catch (err) {
        if (err.message == "Invalid token") {
          localStorageService.clearToken();
          this.$router.push("/login");
        }
      }
    },
    pushRouteTo(route) {
      // if sending path:
      if (this.$route.path != route) {
        this.$router.push(route);
      }
    },
  },
  async mounted() {
    if (!localStorageService.getItem("token") && this.$route.path != "/login") {
      return this.pushRouteTo(`/login?redirectTo=${this.$route.path}`);
    }

    if (window.localStorage.getItem("token")) {
      try {
        this.isFetchingOnboardingStatus = true;
        let { data } = await OnboardingService.getPatientOnboarding();

        // * Share a Sale handling
        if (data.hasOwnProperty("shareasaleclickid")) {
          if ($cookies.get("sas_m_awin")) {
            if (data.shareasaleclickid != $cookies.get("sas_m_awin")) {
              await OnboardingService.sendSASClickId(
                $cookies.get("sas_m_awin").clickId
              );
              $cookies.remove("sas_m_awin");
            }
          }
        } else {
          if ($cookies.get("sas_m_awin")) {
            await OnboardingService.sendSASClickId(
              $cookies.get("sas_m_awin").clickId
            );
            $cookies.remove("sas_m_awin");
          }
        }

        if (data.onboardingstatus === "PENDING") {
          return this.$router.push({ name: "onboarding" }).catch(() => {});
        }
        this.isFetchingOnboardingStatus = false;
      } catch (err) {
        if (err.message == "Invalid token") {
          localStorageService.clearToken();
          this.$router.push("/login");
        }
      }
    }

    await this.getuser();
    this.getOnboardingStatus();
    this.intervalFunction = setInterval(async () => {
      await this.fetchUnreadMessageCount();
    }, 300000);
    this.fetchUnreadMessageCount();
  },
};
</script>
