var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { cols: "12", lg: "4", xl: "4", xxl: "4" } },
    [
      _c(
        "b-row",
        {
          staticClass: "d-none d-lg-none d-lg-flex",
          style: {
            position: _vm.section ? "relative" : "fixed",
            height: _vm.section ? "100%" : "calc(100vh - 100px)"
          }
        },
        [
          _c(
            "b-col",
            { staticClass: "pl-5 ml-6", attrs: { cols: "12" } },
            [
              _c(
                "b-row",
                {
                  style: {
                    height: _vm.section ? "auto" : "calc(100vh - 230px)"
                  },
                  attrs: { "align-h": "center" }
                },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", "align-self": "start" } },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "onboarding-title d-letter-spacing mt-6"
                        },
                        [_vm._v(_vm._s(_vm.title))]
                      )
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-3",
                      attrs: { cols: "12", "align-self": "end" }
                    },
                    [
                      !_vm.section
                        ? [
                            _vm.step
                              ? _c("h6", {
                                  staticClass: "step d-letter-spacing"
                                })
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-navbar",
        {
          staticClass: "d-lg-none sidenav",
          staticStyle: { "z-index": "498" },
          attrs: { fixed: "top" }
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                {
                  staticStyle: { width: "100%" },
                  attrs: { "align-v": "center" }
                },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "3" } },
                    [
                      !_vm.hide
                        ? _c(
                            "b-button",
                            {
                              staticClass: "back-btn",
                              staticStyle: { "min-width": "inherit" },
                              attrs: { size: "lg", variant: "link" },
                              on: { click: _vm.backButton }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/small_back_arrow.svg")
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }