<template>
  <b-container fluid>
    <b-navbar toggleable="sm" fixed="top">
      <b-navbar-brand to="/" class="c-logo relative mb-0">
        <img src="@/assets/images/logo.svg" alt="">
      </b-navbar-brand>
    </b-navbar>
    <b-row 
      align-h="center"
      style="padding-top: 150px; padding-bottom: 100px; padding-left: 25px; padding-right: 25px">
      <b-col cols="12" lg="6" xl="6">
        <b-row>
          <b-col cols="12">
            <h1 class="heading">Success! Your visit has been sent to a doctor.</h1>
            <p>Thank your for your submission (order #0000000). Your visit is being reviewed by a doctor in the office of Dr. Lorem Ipsum.</p>
            
            <b-row align-v="center" class="mt-5 mb-6">
              <b-col cols="auto">
                <b-avatar src="https://placekitten.com/300/300" size="10rem"></b-avatar>
              </b-col>
              <b-col>
                <h2 class="mb-0 bold d-letter-spacing">Dr. Lorem Ipsum</h2>
                <p>Primary Care Physician</p>
              </b-col>
            </b-row>

            <h2 class="bold d-letter-spacing">Next steps</h2>
            <p>Your doctor will review your medical history and preferences. If approved, you will be prescribed a treatment and received your medication in 0-0 days.</p>

            <ul class="list-unstyled timeline">
              <li class="done"><b-icon icon="check-circle-fill" ></b-icon> submitted</li>
              <li class="in-progress"><b-icon icon="circle-fill" scale=".4" ></b-icon> Choosing Doctor</li>
              <li>Doctor Review</li>
              <li>Diagnosis and treatment</li>
            </ul>

            <b-button pill variant="primary" @click="done" class="mb-4">Go to Patient Dashboard</b-button>

            <h2 class="bold d-letter-spacing">Have a question?</h2>
            <p>Please go to our <router-link to="/support" custom v-slot="{ navigate }"><a href="javascript:void(0)" @click="navigate">Help Center</a></router-link> where you get answers to commonly asked reestions about billing, shipment status, etc.</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LocalStorageService from "@/localStorage";
import { OnboardingService } from "@/services/onboarding.service";
const localStorageService = LocalStorageService.getService();
export default {
  name: 'checkout-complete',
  methods: {
    done() {
      this.$router.push("/")
    },
  },
  async mounted() {
    if (!localStorageService.getItem("token")) {
      return this.$router.push("/login");
    }
    this.loadonBoarding()
  },
  methods: {
    async loadonBoarding() {
      if (!this.dataloaded) {
        const { data } = await OnboardingService.getPatientOnboarding();

        this.onboardingstep = data.onboardingstep;
        if (this.onboardingstep < 5) {
          if (this.onboardingstep >= 4) this.$router.push("/treatment-addons");
          else if (this.onboardingstep >= 3)
            this.$router.push("/treatment-options");
          else if (this.onboardingstep >= 2)
            this.$router.push("/health-questions");
          else if (this.onboardingstep === 1)
            this.$router.push("/getting-started");
          return;
        }

        this.step = data.onboardingstep;

        if (data.photoURL) {
          this.photoUrl = data.photoURL;
          this.step = 5.1;
        }
        if (data.identificationURL) {
          this.identificationURL = data.identificationURL;
          this.step = 5.2;
        }
        if (data.identificationbackURL) {
          this.identificationBackURL = data.identificationbackURL;
          this.step = 5.2;
        }

        if (this.step > 5.2) this.step = null;

        this.dataloaded = true;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.navbar.navbar-light {
  height: 100px;
  border: 0;
  background-color: $body-bg
}

$size: 35px;

.timeline {

  li {
    position: relative;
    display: flex;
    align-items: center;
    color: #bdbdbd;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 2rem;

    &:last-child {

      &:after {
        display: none;
      }
    }

    &.in-progress,
    &.done {
      color: #333333;

      .b-icon.bi {
        fill: $primary;
      }
    }

    &.done {
      &:after {
        border-color: $primary;
        border-left-style: solid;
      }
    }

    .b-icon.bi {
      font-size: $size;
      position: absolute
    }

    &:before {
      display: inline-block;
      content: '';
      width: $size;
      height: $size;
      margin-right: 1.5rem;
      border-radius: $size;
      background-color: #f2f2f2;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: calc(#{$size} / 2 - 1px);
      z-index: -1;
      border-left: 2px dashed #f2f2f2;
      height: 4rem;
      margin-top: 3rem;
    }
  }
}
</style>