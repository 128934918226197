var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pl-3 mx-auto", staticStyle: { "max-width": "768px" } },
    [
      _c("h2", { staticClass: "question-title" }, [
        _vm._v(_vm._s(_vm.body.question))
      ]),
      _c(
        "b-alert",
        { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
        [_vm._v(_vm._s(_vm.alert.message))]
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "fs-exclude mb-2 pl-0 pr-0", attrs: { cols: "12" } },
            [
              _c(
                "ul",
                {
                  staticClass: "list-unstyled symptoms justify-content-center"
                },
                [
                  _vm._l(_vm.symptoms, function(list, i) {
                    return _c("li", { key: i }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selected,
                            expression: "selected"
                          }
                        ],
                        attrs: { type: "checkbox", id: "checkbox-" + i },
                        domProps: {
                          value: list.id,
                          checked: Array.isArray(_vm.selected)
                            ? _vm._i(_vm.selected, list.id) > -1
                            : _vm.selected
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.selected,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = list.id,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.selected = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.selected = $$c
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        { attrs: { for: "checkbox-" + i } },
                        [
                          _c("v-icon", {
                            attrs: {
                              name: list.id,
                              size: ["acne"].includes(list.id) ? "50" : "35"
                            }
                          }),
                          _c("span", [_vm._v(_vm._s(list.name))])
                        ],
                        1
                      )
                    ])
                  }),
                  _vm.body.treatmentoption === "sideeffect"
                    ? _c("li", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selected,
                              expression: "selected"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "checkbox-" + _vm.symptoms.length + 1,
                            value: "others"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.selected)
                              ? _vm._i(_vm.selected, "others") > -1
                              : _vm.selected
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.selected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "others",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selected = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selected = $$c
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "checkbox-" + _vm.symptoms.length + 1
                            }
                          },
                          [
                            _c("v-icon", {
                              attrs: { name: "othersideeffect", size: "35" }
                            }),
                            _c("span", [_vm._v("Others")])
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            ]
          ),
          _vm.hasOthers && _vm.body.treatmentoption === "sideeffect"
            ? _c(
                "b-col",
                { staticClass: "mb-4 pl-0 pr-0", attrs: { cols: "12" } },
                [
                  _c("h3", [_vm._v("What are your added side effects?")]),
                  _c("b-form-textarea", {
                    staticClass: "onboarding-textarea-v2",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "textarea",
                      rows: "6",
                      "max-rows": "6",
                      placeholder: "Write something"
                    },
                    model: {
                      value: _vm.otherAnswer,
                      callback: function($$v) {
                        _vm.otherAnswer =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "otherAnswer"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { staticClass: "pl-0", attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn mb-3",
                  attrs: { pill: "", variant: "info", disabled: _vm.disabled },
                  on: { click: _vm.submitSymptoms }
                },
                [_vm._v("Continue")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }