var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { cols: "12" } },
    [
      _c(
        "b-row",
        {
          staticClass: "py-6",
          staticStyle: { height: "100%" },
          attrs: { "align-v": "stretch" }
        },
        [
          _c("b-col", { attrs: { cols: "12", "align-self": "start" } }, [
            _c("h1", { staticClass: "mb-2 heading text-center" }, [
              _vm._v("Hello, beautiful!")
            ]),
            _c("p", { staticClass: "label-text text-center" }, [
              _vm._v("Please log into your account")
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", "align-self": "center" } },
            [
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: "warning" } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
              _c(
                "div",
                { staticClass: "mb-4", attrs: { role: "group" } },
                [
                  _c(
                    "label",
                    { staticClass: "input-label", attrs: { for: "email" } },
                    [_vm._v("Email Address:")]
                  ),
                  _c("b-form-input", {
                    staticClass: "pl-0 fs-mask",
                    attrs: {
                      id: "email",
                      type: "email",
                      state: _vm.emailState,
                      autocomplete: "off",
                      required: ""
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "input-live-feedback" } },
                    [_vm._v(" The email must have '@email.com' ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4", attrs: { role: "group" } },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col input-label",
                        attrs: { for: "password" }
                      },
                      [_vm._v("Password:")]
                    )
                  ]),
                  _c("b-form-input", {
                    staticClass: "pl-0 fs-mask",
                    attrs: {
                      id: "password",
                      type: "password",
                      state: _vm.passwordState,
                      autocomplete: "off",
                      required: ""
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "input-live-feedback" } },
                    [_vm._v(" Min. 8 characters ")]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "text-center mt-6" }, [
                _c("small", { staticClass: "small-text" }, [
                  _vm._v(" Don't have an account yet? "),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("set-screen", "register")
                        }
                      }
                    },
                    [_vm._v("Register")]
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { "align-self": "end" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: _vm.loginDisabled,
                    block: "",
                    pill: "",
                    variant: "primary"
                  },
                  on: { click: _vm.login }
                },
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }