<template>
  <div class="idPhoto">
    <!-- ID modal -->
    <IdModal
      :show="showModal"
      @exit="hideModal()"
      header-class="id-modal"
      body-class="id-body-modal"
    >
      <template #header>
        <img :src="require('@/assets/images/id.png')" alt="" />
      </template>
      <template #body>
        <h3
          class="sub-text text-uppercase text-primary"
          style="letter-spacing: 0.16em"
        >
          Identity Verification
        </h3>

        <h2 class="heading h1 text-default mt-5 mb-2 initial-line-height">
          Why do you need a photo of my ID?
        </h2>
        <p class="sub-text">
          We use your ID photo to verify your identity, for example your name
          and date of birth. Telemedicine laws require us to verify this
          information before prescribing medication.
        </p>

        <h2 class="heading h1 text-default mt-4 mb-2 initial-line-height">
          Who sees this?
        </h2>
        <p class="sub-text">
          The photo of your ID will be stored securely and will only be shared
          with the patient support team and our identity verification platform.
        </p>

        <b-button pill block variant="info" @click="hideModal()" class="mt-5">
          I understand
        </b-button>
      </template>
    </IdModal>
    <div class="bg-overlay" @click="hideModal()"></div>

    <h2 class="question-title with-subtext">
      {{ isAndroid11Webview ? "Send us" : "Upload" }} a photo of your ID
    </h2>
    <p class="sub-text mb-4">
      In order to legally prescribe medications, we need a valid
      government-issued ID that matches your name and date of birth.
      <a style="color: #828282" class="text-underline" @click="openModal()">
        Why do you need this?
      </a>
    </p>
    <p class="sub-text mb-0">Acceptable Forms of ID</p>
    <b-row class="mb-3">
      <b-col cols="12" md="6" class="list-id">
        <ul>
          <li>Driver License</li>
          <li>Passport</li>
          <li>Identification Card</li>
        </ul>
      </b-col>
      <b-col cols="12" md="6">
        <ul>
          <li>Permanent Resident Card</li>
          <li>Consular Card</li>
        </ul>
      </b-col>
    </b-row>
    <div v-if="!isMobileOrTablet" class="fs-exclude">
      <!-- Start Front  -->
      <!-- isPhotoTaken || showId || identificationURL
                ? ''
                : 'url(' + require('@/assets/images/id.png') + ')' -->
      <div class="photo-holder fs-mask">
        <div
          v-if="(picture || idPreview) && uploadFileType !== 'application/pdf'"
          class="btn-crop"
          @click="openCropModal"
        >
          <b-icon icon="crop"></b-icon>
        </div>
        <div
          class="photo"
          :style="{
            backgroundImage:
              isPhotoTaken || showId || identificationURL
                ? ''
                : 'url(' + require('@/assets/images/id.png') + ')',
          }"
        >
          <img v-if="isPhotoTaken" :src="picture" class="fs-exclude" alt="" />
          <img
            v-else-if="showId"
            :src="idPreview"
            class="fs-exclude"
            style="object-fit: contain"
          />
          <img
            class="fs-exclude"
            :style="{
              width:
                identificationFileType === 'application/pdf' ? 'auto' : '100%',
            }"
            v-else-if="identificationURL"
            :src="identificationURL"
          />
        </div>
      </div>

      <b-row v-if="!isPhotoTaken && !showId && !identificationURL" class="mb3">
        <b-col cols="12" md="6">
          <b-button block pill variant="info" class="photo-btn">
            <input
              type="file"
              id="identification"
              ref="identification"
              accept="image/*, application/pdf"
              class="hide-file-input"
              v-on:change="idUpload()"
            />
            <label for="identification" class="mb-0">SELECT PHOTO</label>
          </b-button>
          <div class="mb-3 d-lg-none"></div>
        </b-col>
        <b-col cols="12" md="6">
          <b-button
            @click="showCamera"
            variant="outline-info"
            :disabled="saving"
            pill
            block
          >
            {{ isPhotoTaken ? "RETAKE" : "TAKE PHOTO" }}
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12" md="6">
          <b-button
            pill
            block
            variant="outline-info"
            :disabled="saving"
            class="photo-btn"
          >
            <input
              type="file"
              id="identification"
              ref="identification"
              accept="image/*, application/pdf"
              class="hide-file-input"
              v-on:change="idUpload()"
            />
            <label for="identification" class="mb-0">REUPLOAD</label>
          </b-button>
          <div class="mb-3 d-md-none"></div>
        </b-col>
        <b-col cols="12" class="d-block d-md-none py-2"></b-col>
        <b-col cols="12" md="6">
          <b-button
            @click="showCamera"
            variant="outline-info"
            :disabled="saving"
            pill
            block
          >
            {{ isPhotoTaken ? "Retake" : "TAKE PHOTO" }}
          </b-button>
          <div class="mb-3 d-md-none"></div>
        </b-col>
      </b-row>
      <!-- End Front  -->
      <b-button
        class="mt-3"
        v-if="isPhotoTaken || showId || identificationURL"
        pill
        variant="info"
        @click="submitAnswer({ isSkipped: false })"
        :disabled="saving"
        block
      >
        {{ saving ? "Saving..." : "CONFIRM" }}
      </b-button>

      <modal
        id="takePhoto"
        size="md"
        centered
        hide-header
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
      >
        <video
          ref="camera"
          width="100%"
          height="325"
          autoplay
          style="background-color: #000"
        ></video>
        <canvas
          class="photo-taken"
          ref="canvas"
          :width="325"
          :height="250"
        ></canvas>
        <b-row class="mt-3">
          <b-col cols="12" md="6">
            <b-button pill block @click="takePhoto" variant="info">
              Capture
            </b-button>
            <div class="mb-3 d-md-none"></div>
          </b-col>

          <b-col cols="12" md="6">
            <b-button
              pill
              block
              @click="hideCameraModal"
              variant="outline-info"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </modal>

      <b-modal
        class="fs-exclude"
        id="crop"
        size="md"
        centered
        hide-header
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
      >
        <vue-croppie
          ref="croppieRef"
          :enableOrientation="false"
          :enableResize="false"
          :boundary="{ width: 300, height: 200 }"
          :viewport="{ width: 300, height: 200 }"
        >
        </vue-croppie>
        <b-row class="mt-3">
          <b-col cols="12" md="6">
            <b-button pill block variant="info" @click="cropImage">
              Crop
            </b-button>
            <div class="mb-3 d-md-none"></div>
          </b-col>

          <b-col cols="12" md="6">
            <b-button pill block @click="closeCropModal" variant="outline-info">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div v-else>
      <MobileUpload
        class="fs-exclude"
        id-size
        @submitAnswer="setMobilePhoto"
        @showAlert="showAlert"
        @hideAlert="hideAlert"
        :isAndroid11Webview.sync="isAndroid11Webview"
        :existingPhoto="identificationURL"
        :existingPhotoFileType="identificationFileType"
        @skip="submitAnswer({ isSkipped: true })"
      />
    </div>
    <div class="text-center">
      <a
        v-if="identificationUploadStatus !== 'UPLOADED' && !isAndroid11Webview"
        href="javascript:void(0)"
        @click="showSkipDialog = true"
        class="text-default text-underline d-inline-block mt-4 py-3 mx-auto"
      >
        Having Trouble Uploading Photo?
      </a>
    </div>
    <b-modal
      id="trouble"
      v-model="showSkipDialog"
      hide-header
      hide-footer
      centered
      content-class="dark-bg"
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo dark"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <!-- <h2 class="mb-3 heading color-inherit" style="font-size: 26px; line-height: 32px">{{ body.title }}</h2> -->
        <p class="sub-text" style="color: inherit">
          You can bypass uploading your ID now but you will need to upload it
          later in order for your medical provider to verify your identity.
        </p>

        <b-row class="mb-3">
          <b-col cols="12" lg="12">
            <b-button
              variant="info"
              pill
              @click="closeModal"
              style="min-width: 100px; width: 100%"
            >
              Upload Now
            </b-button>
            <div class="separator"></div>
          </b-col>
          <b-col cols="12" lg="12">
            <b-button
              class="modal-cancel-btn"
              variant="outline-info"
              pill
              @click="submitAnswer({ isSkipped: true })"
              style="min-width: 100px; width: 100%"
            >
              Skip Uploading
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Services
import { isMobile } from "mobile-device-detect";
import { OnboardingService } from "@/services/onboarding.service";
import { generateEventId } from "@/services/util";
// Mixins
// import mixins from "@/components/form/mixins";
// Components
import MobileUpload from "@/components/partials/MobileUpload";
import IdModal from "@/components/partials/SideModal";

import postscribe from "postscribe";

import Tiff from "tiff.js";
import pdfIcon from "@/assets/images/onboarding/pdf-icon.png";

export default {
  // mixins: [mixins],
  props: {
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    analyticsId: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
  },
  components: {
    MobileUpload,
    IdModal,
  },
  computed: {
    isMobileOrTablet() {
      // mobile-device-detect API; isMobile = mobile or tablet
      return isMobile;
    },
    uploadFileType() {
      return this.identification?.type || "";
    },
  },
  data() {
    return {
      showModal: false,
      userid: null,
      currentonboardingstep: null,
      isPhotoTaken: false,
      showId: false,
      identificationURL: null,
      saving: false,
      userData: {},
      idPreview: "",
      picture: "",
      orientation: 0,

      email: "",
      firstname: "",
      lastname: "",
      phoneno: "",

      identification: null,
      identificationFileType: "",
      identificationUploadStatus: "",
      showSkipDialog: false,

      isAndroid11Webview: false,
    };
  },
  watch: {
    saving: {
      handler(val) {
        this.$store.commit("onboarding/SET_IS_IMAGE_UPLOAD_PROCESSING", val);
      },
      immediate: true,
    },
  },
  methods: {
    openModal() {
      this.elementTag("hidden", true);
    },
    hideModal() {
      this.elementTag(null, false);
    },
    elementTag(style, Boolean) {
      this.showModal = Boolean;
      const htmlTag = document.documentElement;
      htmlTag.style.overflow = style;
      // htmlTag.scrollTop = htmlTag.scrollHeight;
    },
    openCropModal() {
      this.$root.$emit("bv::show::modal", "crop");
      this.$nextTick(() => {
        this.$refs.croppieRef.bind({
          url: this.idPreview,
        });
      });
    },
    closeCropModal() {
      this.$root.$emit("bv::hide::modal", "crop");
    },
    cropImage() {
      let options = {
        format: "jpeg",
      };
      this.$refs.croppieRef.result(options, (output) => {
        this.idPreview = output;
        this.picture = output;
        this.identification = this.dataURLtoFile(output, "image");
        this.closeCropModal();
      });
    },
    closeModal() {
      this.$root.$emit("bv::hide::modal", "trouble");
    },
    setMobilePhoto(photo, orientation) {
      // const reader = new FileReader();
      this.picture = photo;
      this.idPreview = photo;
      if (orientation) this.orientation = orientation;
      this.submitAnswer({ isSkipped: false });
    },
    showAlert(msg) {
      this.$emit("showAlert", msg);
    },
    hideAlert() {
      this.$emit("hideAlert");
    },
    // camera functions
    showCamera() {
      this.$root.$emit("bv::show::modal", "takePhoto");
      this.createCameraElement();
    },
    createCameraElement() {
      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: "user",
        },
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          if (error.name == "NotAllowedError") {
            this.hideCameraModal();
            this.$emit(
              "showAlert",
              "Camera is not detected. Please turn on your camera and refresh your browser."
            );
            alert(
              "Camera is not detected. Please turn on your camera and refresh your browser."
            );
          }
        });
    },
    stopCameraStream() {
      if (this.$refs.camera?.srcObject) {
        let tracks = this.$refs.camera.srcObject.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
    },
    hideCameraModal() {
      this.stopCameraStream();
      this.$root.$emit("bv::hide::modal", "takePhoto");
    },
    takePhoto() {
      try {
        const canvas = this.$refs.canvas;
        const context = canvas.getContext("2d");
        context.drawImage(this.$refs.camera, 0, 0, 325, 250);
        this.picture = canvas.toDataURL("image/png");
        this.idPreview = canvas.toDataURL("image/png");
      } catch (err) {
        const id = this.userid ? this.userid : "Could not get customer ID";
        const email = this.email ? this.email : "Could not get customer email";
        this.$store.dispatch("sendErrorLog", {
          step: "Identity Verification - ID Take Photo",
          cus_id: id,
          email: email,
          error_log: err,
        });
        this.$emit("showAlert", err.message);
      }

      this.$nextTick(() => {
        this.$root.$emit("bv::hide::modal", "takePhoto");
        this.stopCameraStream();
        this.isPhotoTaken = true;
      });
    },
    idUpload() {
      try {
        this.hideAlert();

        let fileToUpload = this.$refs.identification.files[0];

        if (!fileToUpload) return;

        // Check filesize here
        if (fileToUpload.size > 25600 * 1024) {
          this.$emit("showAlert", "File too big (> 25MB)");
          return;
        }

        const isFileTypeSupported = /\.(jpe?g|png|pdf|tif?f)$/i.test(
          fileToUpload.name
        );

        if (!isFileTypeSupported) {
          throw "Please upload an image or a PDF file!";
        }

        // Set local file variable after file checks
        this.identification = fileToUpload;

        // Initialize a File Reader object
        let reader = new FileReader();

        if (this.identification.type === "image/tiff") {
          reader.addEventListener(
            "load",
            function () {
              this.isPhotoTaken = false;
              this.showId = true;
              this.picture = reader.result;

              let tiff = new Tiff({ buffer: reader.result });
              let canvas = tiff.toCanvas();
              if (canvas) {
                this.idPreview = canvas.toDataURL("image/png");
              }
            }.bind(this),
            false
          );
        } else {
          // Add an event listener to the reader that when the file
          // has been loaded, we flag the show preview as true and set the
          // image to be what was read from the reader
          reader.addEventListener(
            "load",
            function () {
              this.isPhotoTaken = false;
              this.showId = true;
              this.picture = reader.result;

              if (this.identification.type === "application/pdf") {
                this.idPreview = pdfIcon;
              } else {
                this.idPreview = reader.result;
              }
            }.bind(this),
            false
          );
        }

        // Check to see if the file is not empty.
        if (this.identification) {
          //  Ensure the file is an image file.
          if (/\.(jpe?g|png|pdf)$/i.test(this.identification.name)) {
            // Fire the readAsDataURL method which will read the file in and
            // upon completion fire a 'load' event which we will listen to and
            // display the image in the preview.
            reader.readAsDataURL(this.identification);
          } else if (/\.(tif?f)$/i.test(this.identification.name)) {
            reader.readAsArrayBuffer(this.identification);
          }
        }
      } catch (err) {
        console.log(err);
        const id = this.userid ? this.userid : "Could not get customer ID";
        const email = this.email ? this.email : "Could not get customer email";
        this.$store.dispatch("sendErrorLog", {
          step: "Identity Verification - ID Upload",
          cus_id: id,
          email: email,
          error_log: err,
        });
        this.showAlert(err);
      }
    },
    async fbqTrack(data) {
      try {
        // FB Pixel Tracking
        fbq("init", "348219289912097", {
          em: this.email,
          fn: this.firstname,
          ln: this.lastname,
          ph: this.phoneno,
          external_id: this.userid,
          extern_id: this.userid,
        });
        // fbq("track", "PageView");
        await OnboardingService.onboardingEvent("PageView");
        fbq(
          "trackCustom",
          "IDUploaded",
          {
            external_id: this.userid,
            extern_id: this.userid,
            state: this.state,
            ...(await this.$store.dispatch("getFBQParams")),
          },
          {
            eventID: data.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async submitAnswer({ isSkipped = false }) {
      try {
        this.saving = true;

        if (this.idPreview == "" && this.identificationURL != null) {
          // Segment
          window.analytics.identify(this.analyticsId, {
            iduploaded: this.identificationUploadStatus === "UPLOADED",
          });

          this.saving = false;
          this.$emit("setStep", "personalphoto");
          return;
        }

        let mform = new FormData();
        let idFront;

        if (isSkipped) {
          idFront = "SKIPPED";
        } else {
          if (this.isPhotoTaken === true && this.picture) {
            idFront = this.picture;
          } else if (this.isPhotoTaken === false && this.idPreview) {
            if (this.uploadFileType === "application/pdf") {
              idFront = this.picture;
            } else {
              idFront = this.idPreview;
            }
          }

          if (idFront) {
            if (!(idFront instanceof File)) {
              idFront = this.dataURLtoFile(idFront, "image");
            }
          }
        }

        mform.append("identification", idFront);
        if (this.orientation > 0)
          mform.append("rotationindex", this.orientation);
        let res;
        try {
          res = await OnboardingService.updatePatientOnboarding(5.2, mform, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        } catch (err) {
          console.log(err);
          this.$emit("showAlert", err);
          this.saving = false;
          return;
        }
        if (isSkipped) {
          // Segment
          window.analytics.identify(this.analyticsId, {
            iduploaded: false,
          });
          if (this.identificationUploadStatus !== "SKIPPED") {
            // ID Upload skipped analytics
            window.analytics.track(
              "ID Upload Skipped",
              {
                customerId: this.analyticsId,
                onboaringflow: this.onboardingflow,
                messageID: res.data.eventid,
                phoneno: this.phoneno,
                state: this.state,
                email: this.email,
                firstname: this.firstname,
                lastname: this.lastname,
                ...(await this.$store.dispatch("getDeviceDetailsForContext")),
                // ...(await this.$store.dispatch("getFBQParams")),
              },
              {
                context: await this.$store.dispatch("geDeviceDetails"),
              }
            );
            FS.event("ID Upload Skipped");
            // window.dataLayer = window.dataLayer || [];
            // window.dataLayer.push({
            //   event: "ID Upload Skipped",
            //   customerID: this.analyticsId,
            // });
          }
        } else {
          // Segment
          window.analytics.identify(this.analyticsId, {
            iduploaded: true,
          });
          if (this.identificationUploadStatus !== "UPLOADED") {
            // SEGMENT
            window.analytics.track(
              "ID Uploaded",
              {
                customerId: this.analyticsId,
                onboardingflow: this.onboardingflow,
                messageID: res.data.eventid,
                phoneno: this.phoneno,
                state: this.state,
                email: this.email,
                firstname: this.firstname,
                lastname: this.lastname,
                ...(await this.$store.dispatch("getDeviceDetailsForContext")),
                // ...(await this.$store.dispatch("getFBQParams")),
              },
              {
                context: await this.$store.dispatch("geDeviceDetails"),
              }
            );

            // WIN-14971
            window.Northbeam.fireCustomGoal("ID Uploaded", {});
            console.log("Northbeam: ID Uploaded");

            FS.event("ID Uploaded");
            // GTM: Mariwin
            // window.dataLayer = window.dataLayer || [];
            // window.dataLayer.push({
            //   event: "ID Uploaded",
            //   customerID: this.userid,
            // });
          }
        }
        if (res.data.eventid) {
          await this.fbqTrack(res.data);
        } else if (
          res.data.eventid &&
          process.env.VUE_APP_SERVER != "production"
        ) {
          console.log("FB TRACKING IDUploaded");
          console.log("FB TRACKING IDUploaded: " + res.data.eventid);
        }
        this.$emit("setStep", "personalphoto");
        this.saving = false;
      } catch (err) {
        if (err.status == 401) {
          this.$router.push("/login");
        } else {
          const id = this.userid ? this.userid : "Could not get customer ID";
          const email = this.email
            ? this.email
            : "Could not get customer email";
          this.$store.dispatch("sendErrorLog", {
            step: "Identity Verification - ID Submission",
            cus_id: id,
            email: email,
            error_log: err,
          });
          this.$emit("showAlert", err);
        }
      }
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    const { data } = await OnboardingService.getPatientOnboarding();
    this.userid = data.id;

    this.email = data.email;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.phoneno = data.phoneno;

    window.analytics.track(
      "Onboarding Screen Viewed",
      {
        customerId: this.analyticsId,
        screen: "4.2-A",
        firstname: this.firstname,
        lastname: this.lastname,
        onboardingflow: this.onboardingflow,
        ...(await this.$store.dispatch("getDeviceDetailsForContext")),
      },
      {
        context: await this.$store.dispatch("geDeviceDetails"),
      }
    );
    this.currentonboardingstep = data.onboardingstep;
    this.identificationUploadStatus = data.identificationupload;
    if (data.identificationURL) {
      this.identificationFileType = data.identificationFileType;
      if (this.identificationFileType === "application/pdf") {
        this.identificationURL = pdfIcon;
      } else {
        this.identificationURL = data.identificationURL;
      }
    }
    if (document.getElementById("certified-logo").childNodes.length === 0) {
      postscribe(
        "#certified-logo",
        `<script  src="https://static.legitscript.com/seals/8131176.js"><\/script>`
      );
    }
  },
};
</script>

<style lang="scss">
.id-modal {
  background-color: #f6f6f6;
  height: 50vh;

  @include media-breakpoint-down(xs) {
    height: 30vh;
  }

  img {
    height: 50%;
  }
}

.id-body-modal {
  overflow: auto;
  // height: 50vh !important;
  overflow-x: hidden;

  // @include media-breakpoint-down(xs) {
  //   height: 70vh !important;
  // }
}
</style>

<style lang="scss" scoped>
.separator {
  margin-bottom: 10px;
}
.modal-cancel-btn.btn-outline-info {
  border-color: #cdfcb1;
  background-color: transparent;
  color: #cdfcb1;

  &:active {
    background-color: transparent;
    border-color: #cdfcb1;
    box-shadow: 0 0 0 0.15rem rgba(#cdfcb1, 0.5);
  }

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba(#cdfcb1, 0.5);
  }
}

.notice {
  position: fixed;
  right: 120px;
  bottom: 222px;
  width: 263px;

  @include media-breakpoint-down(lg) {
    margin-top: 2rem;
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
  }
}

.photo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  background-color: #f2f2f2;
  position: relative;

  .btn-crop {
    padding: 0.75rem;
    background-color: white;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #a783ff;
  }

  @include media-breakpoint-up(xxl) {
    height: 250px * $scale-font;
    margin-bottom: 20px * $scale-font;
  }

  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-up(xxl) {
      width: 300px * $scale-font;
      height: 200px * $scale-font;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.photo-taken {
  position: absolute;
  left: -9999px;
}

.sub-text {
  a {
    cursor: pointer;
  }
}

.list-id {
  margin-bottom: -15px;
}
</style>
