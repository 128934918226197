var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.addonsReady
    ? _c(
        "div",
        { staticClass: "addons" },
        [
          _vm.nonrx
            ? _c("div", { staticClass: "mt-4 mb-5" }, [
                _c("h2", { staticClass: "question-title with-subtext" }, [
                  _vm._v(" Our non-Rx favorites ")
                ]),
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " These are some of our favorite non-Rx products, we have made everything that is just right for you. Select all products that you like! "
                  )
                ])
              ])
            : _c("h2", { staticClass: "mt-4 question-title" }, [
                _vm._v(
                  " Would you like to add any of these additional items to your cart? "
                )
              ]),
          _vm._l(_vm.body.data, function(item) {
            return _c("w-checkbox", {
              key: item.id,
              staticClass: "addons",
              attrs: {
                type: "checkbox",
                value: item,
                id: item.id,
                label: item.name,
                description: item.description,
                pricing: _vm.getPricing(item.pricing),
                "clamp-text": 2,
                recommended: _vm.recommendedItems.includes(item.id),
                bold: "",
                "align-v": "start"
              },
              scopedSlots: _vm._u(
                [
                  item.image
                    ? {
                        key: "image",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "img-holder" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    item.image == null
                                      ? require("@/assets/images/dashboard/articlebg.png")
                                      : item.image,
                                  alt: item.name
                                }
                              })
                            ])
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              ),
              model: {
                value: _vm.answers,
                callback: function($$v) {
                  _vm.answers = $$v
                },
                expression: "answers"
              }
            })
          }),
          _c(
            "b-button",
            {
              attrs: {
                variant: "info",
                pill: "",
                block: "",
                disabled: _vm.disabled
              },
              on: { click: _vm.submitAddons }
            },
            [_vm._v("Next")]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }