var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      !_vm.nonrx
        ? _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("h1", { staticClass: "question-title with-subtext" }, [
                _vm._v(" Success! Your visit has been sent to a doctor. ")
              ]),
              _c("p", { staticClass: "sub-text" }, [
                _vm._v(
                  " Okay great! Winona Physician is reviewing your completed medical questionnaire, and will reply to you shortly. "
                )
              ]),
              _c(
                "b-row",
                { staticClass: "mt-5 mb-5", attrs: { "align-v": "center" } },
                [
                  _c("b-col", { attrs: { cols: "auto" } }, [
                    _c("p", { staticClass: "sub-text" }, [
                      _vm._v(" What's Next? "),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            " You will be paired with a board-certified Winona Physician in your state "
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            " The Winona Physician will review your medical questionnaire and determine the treatment plan that is the best fit for you "
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "They will reach out to you through the patient portal to address any questions"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            " If they determine the right treatment plan for you, they will prescribe and process your treatment. "
                          )
                        ])
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-7",
                  attrs: { size: "lg", pill: "", variant: "info" },
                  on: { click: _vm.done }
                },
                [_vm._v("Go to Patient Dashboard")]
              ),
              _c("div", { staticClass: "mb-2" }),
              _c("h2", { staticClass: "label-header mb-3" }, [
                _vm._v("Have a question?")
              ]),
              _c("p", [
                _vm._v(" Please go to our "),
                _c(
                  "a",
                  {
                    staticClass: "text-info text-underline",
                    attrs: { href: this.$store.state.website + "/support" }
                  },
                  [_vm._v("Help Center")]
                ),
                _vm._v(
                  " where you get answers to commonly asked questions about billing, shipment status, etc. "
                )
              ])
            ],
            1
          )
        : _c(
            "b-col",
            [
              _c(
                "h1",
                {
                  staticClass: "heading",
                  staticStyle: { "line-height": "30px" }
                },
                [
                  _vm._v(
                    " Success! Your payment has been processed and your order is complete. "
                  )
                ]
              ),
              _c("p", [
                _vm._v(
                  "You may keep track of your order by following the link below."
                )
              ]),
              _c(
                "b-button",
                {
                  attrs: { pill: "", variant: "info" },
                  on: { click: _vm.done }
                },
                [_vm._v("View your Order")]
              )
            ],
            1
          ),
      _vm.isUpsellModalVisible
        ? _c("OneClickUpsell", {
            attrs: {
              isUpsellModalVisible: _vm.isUpsellModalVisible,
              "upsell-from": "Onboarding Upsell",
              couponid: _vm.couponid
            },
            on: {
              "update:isUpsellModalVisible": function($event) {
                _vm.isUpsellModalVisible = $event
              },
              "update:is-upsell-modal-visible": function($event) {
                _vm.isUpsellModalVisible = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }