var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "icons-item",
      class: { selected: _vm.isSelected },
      on: { click: _vm.onSelection }
    },
    [
      _c("v-icon", {
        attrs: {
          name: _vm.item.id,
          color: "#19191c",
          size: ["acne"].includes(_vm.item.id) ? "50" : "35"
        }
      }),
      _c("small", { staticClass: "d-inline-flex mt-2" }, [
        _vm._v(_vm._s(_vm.item.name))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }