var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.variant.slug == "identity-verification"
        ? _c("VerificationInfoA", { on: { next: _vm.next } })
        : _c("VerificationInfoB", {
            on: {
              next: _vm.next,
              "show-why-need-dialog": _vm.showWhyNeedDialog
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }