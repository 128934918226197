import { render, staticRenderFns } from "./InsuranceDocuments.vue?vue&type=template&id=01d29b60&scoped=true&"
import script from "./InsuranceDocuments.vue?vue&type=script&lang=js&"
export * from "./InsuranceDocuments.vue?vue&type=script&lang=js&"
import style0 from "./InsuranceDocuments.vue?vue&type=style&index=0&id=01d29b60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01d29b60",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src158183496/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01d29b60')) {
      api.createRecord('01d29b60', component.options)
    } else {
      api.reload('01d29b60', component.options)
    }
    module.hot.accept("./InsuranceDocuments.vue?vue&type=template&id=01d29b60&scoped=true&", function () {
      api.rerender('01d29b60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/account/InsuranceDocuments.vue"
export default component.exports