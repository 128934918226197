var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "question-title with-subtext" }, [
        _vm._v(" Ok great! You are eligible for treatment. ")
      ]),
      _c("p", { staticClass: "sub-text group-mb" }, [
        _vm._v(
          " Please get started with your doctor visit by creating an account below. All the information you provide is legally required to provide medical care. "
        )
      ]),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
            _c(
              "div",
              { staticClass: "group-mb", attrs: { role: "group" } },
              [
                _c(
                  "label",
                  { staticClass: "input-label", attrs: { for: "customerfn" } },
                  [_vm._v("First Name*")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.firstname,
                      expression: "firstname"
                    }
                  ],
                  staticClass: "fs-mask form-control onboarding-input",
                  class:
                    _vm.firstname.length > 0
                      ? _vm.checkFirstNameValidity
                        ? "is-valid"
                        : "is-invalid"
                      : "",
                  attrs: {
                    id: "customerfn",
                    name: "customerfn",
                    type: "text",
                    required: ""
                  },
                  domProps: { value: _vm.firstname },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.firstname = $event.target.value
                    }
                  }
                }),
                _c(
                  "b-form-invalid-feedback",
                  {
                    attrs: {
                      id: "input-live-feedback",
                      state: _vm.checkFirstNameValidity
                    }
                  },
                  [_vm._v(" First name must be alphanumeric ")]
                )
              ],
              1
            )
          ]),
          _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
            _c(
              "div",
              { staticClass: "group-mb", attrs: { role: "group" } },
              [
                _c(
                  "label",
                  { staticClass: "input-label", attrs: { for: "customerln" } },
                  [_vm._v("Last Name*")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lastname,
                      expression: "lastname"
                    }
                  ],
                  staticClass: "fs-mask form-control onboarding-input",
                  class:
                    _vm.lastname.length > 0
                      ? _vm.checkLastNameValidity
                        ? "is-valid"
                        : "is-invalid"
                      : "",
                  attrs: {
                    id: "customerln",
                    name: "customerln",
                    type: "text",
                    required: ""
                  },
                  domProps: { value: _vm.lastname },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.lastname = $event.target.value
                    }
                  }
                }),
                _c(
                  "b-form-invalid-feedback",
                  {
                    attrs: {
                      id: "input-live-feedback",
                      state: _vm.checkLastNameValidity
                    }
                  },
                  [_vm._v(" Last name must be alphanumeric ")]
                )
              ],
              1
            )
          ]),
          _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
            _c(
              "div",
              { staticClass: "group-mb", attrs: { role: "group" } },
              [
                _c(
                  "label",
                  { staticClass: "input-label", attrs: { for: "birthday" } },
                  [_vm._v("Birthday*")]
                ),
                _c("b-form-input", {
                  staticClass: "fs-mask onboarding-input",
                  attrs: { id: "birthday", required: "", readonly: "" },
                  model: {
                    value: _vm.birthdayDisplay,
                    callback: function($$v) {
                      _vm.birthdayDisplay = $$v
                    },
                    expression: "birthdayDisplay"
                  }
                })
              ],
              1
            )
          ]),
          _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
            _c(
              "div",
              { staticClass: "group-mb", attrs: { role: "group" } },
              [
                _c(
                  "label",
                  { staticClass: "input-label", attrs: { for: "state" } },
                  [_vm._v("State*")]
                ),
                _c("b-form-input", {
                  staticClass: "fs-mask onboarding-input",
                  attrs: { id: "state", required: "", readonly: "" },
                  model: {
                    value: _vm.stateName,
                    callback: function($$v) {
                      _vm.stateName = $$v
                    },
                    expression: "stateName"
                  }
                })
              ],
              1
            )
          ]),
          _c("b-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "group-mb", attrs: { role: "group" } },
              [
                _c(
                  "label",
                  { staticClass: "input-label", attrs: { for: "phoneno" } },
                  [_vm._v("Phone Number*")]
                ),
                _c("b-form-input", {
                  staticClass: "fs-mask onboarding-input hide-arrows",
                  attrs: {
                    id: "phoneno",
                    type: "tel",
                    pattern: "[0-9]*",
                    formatter: _vm.formatPhoneNumber,
                    required: ""
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submitAnswer($event)
                    }
                  },
                  model: {
                    value: _vm.phoneno,
                    callback: function($$v) {
                      _vm.phoneno = $$v
                    },
                    expression: "phoneno"
                  }
                })
              ],
              1
            )
          ]),
          _vm.$cookies.get("referralToken")
            ? _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "group-mb", attrs: { role: "group" } },
                  [
                    _c(
                      "label",
                      { staticClass: "input-label", attrs: { for: "refcode" } },
                      [_vm._v("Referral Code")]
                    ),
                    _c("b-form-input", {
                      staticClass: "fs-mask onboarding-input",
                      attrs: { id: "refcode", readonly: "" },
                      model: {
                        value: _vm.refcode,
                        callback: function($$v) {
                          _vm.refcode = $$v
                        },
                        expression: "refcode"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "mb-2 checkbox-label",
                  attrs: { value: true, "unchecked-value": false },
                  model: {
                    value: _vm.optforpromotions,
                    callback: function($$v) {
                      _vm.optforpromotions = $$v
                    },
                    expression: "optforpromotions"
                  }
                },
                [
                  _vm._v(
                    "Yes, I'd like to occasionally receive Winona promotions"
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "mb-3 mt-3",
                  attrs: {
                    pill: "",
                    block: "",
                    variant: "info",
                    disabled: _vm.buttonDisabled
                  },
                  on: { click: _vm.submitAnswer }
                },
                [_vm._v(" Start my free visit ")]
              )
            ],
            1
          ),
          _c("b-col", { staticClass: "mt-5", attrs: { cols: "12" } }, [
            _c(
              "small",
              [
                _c("b-icon", { attrs: { icon: "lock-fill" } }),
                _vm._v(
                  " Any information you submit is encrypted within our secure platform."
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }