var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { staticClass: "cart-login", attrs: { cols: "12" } },
    [
      _c(
        "b-row",
        {
          staticClass: "py-5",
          staticStyle: { height: "100%" },
          attrs: { "align-v": "stretch" }
        },
        [
          _c(
            "b-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", "align-self": "center" }
            },
            [
              _c("h1", { staticClass: "heading mb-4 text-center" }, [
                _vm._v(
                  " Success! Your payment has been processed and your order is complete. "
                )
              ]),
              _c(
                "b-button",
                {
                  staticStyle: { "min-width": "300px" },
                  attrs: { variant: "primary", pill: "" },
                  on: { click: _vm.proceedOrder }
                },
                [_vm._v("View your order")]
              )
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", "align-self": "end" }
            },
            [
              _c("p", { staticClass: "bold mb-2" }, [
                _vm._v("Have a question?")
              ]),
              _c("p", [
                _vm._v(" Please go to our "),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.window.href.location =
                          _vm.process.env.VUE_APP_WEBSITE_URL + "/support"
                      }
                    }
                  },
                  [_vm._v("Help Center")]
                ),
                _vm._v(
                  " where you get answers to commonly asked questions about billing, shipment status, etc. "
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }