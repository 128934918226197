var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "pl-3" },
    [
      _c("h2", { staticClass: "question-title" }, [
        _vm._v("What symptoms would you like to treat?")
      ]),
      _c(
        "b-alert",
        { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
        [_vm._v(_vm._s(_vm.alert.message))]
      ),
      _c(
        "b-col",
        { staticClass: "fs-exclude mb-2 pl-0 pr-0", attrs: { cols: "12" } },
        [
          _c(
            "ul",
            { staticClass: "list-unstyled symptoms" },
            _vm._l(_vm.symptoms, function(list, i) {
              return _c("li", { key: i }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selected,
                      expression: "selected"
                    }
                  ],
                  attrs: { type: "checkbox", id: "checkbox-" + i },
                  domProps: {
                    value: list.id,
                    checked: Array.isArray(_vm.selected)
                      ? _vm._i(_vm.selected, list.id) > -1
                      : _vm.selected
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.selected,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = list.id,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selected = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selected = $$c
                      }
                    }
                  }
                }),
                _c(
                  "label",
                  { attrs: { for: "checkbox-" + i } },
                  [
                    _c("v-icon", { attrs: { name: list.id, size: "35" } }),
                    _c("span", [_vm._v(_vm._s(list.name))])
                  ],
                  1
                )
              ])
            }),
            0
          )
        ]
      ),
      _c(
        "b-col",
        { staticClass: "pl-0", attrs: { cols: "12" } },
        [
          _c(
            "b-button",
            {
              staticClass: "btn mb-3",
              attrs: { pill: "", variant: "info", disabled: _vm.disabled },
              on: { click: _vm.submitSymptoms }
            },
            [_vm._v("Continue")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }