var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("OnboardingProgress", {
        attrs: { hide: _vm.hideBackButton, progress: _vm.progress },
        on: { back: _vm.prev }
      }),
      _c(
        "b-row",
        { staticClass: "onboarding-wrapper" },
        [
          _c("OnboardingHeader", {
            attrs: { hide: _vm.hideBackButton, step: "3", title: _vm.title },
            on: { back: _vm.prev }
          }),
          _c(
            "b-col",
            {
              staticClass: "mt-6 pt-3 onboarding-content",
              attrs: { cols: "12", lg: "5", xxl: "4" }
            },
            [
              _c(
                "b-row",
                { staticClass: "d-lg-none" },
                [
                  _c("b-col", [
                    _c(
                      "h3",
                      { staticClass: "bold d-letter-spacing onboarding-title" },
                      [_vm._v("Intake")]
                    )
                  ]),
                  _c("b-col", [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "\n              bold\n              d-letter-spacing\n              onboarding-title\n              text-muted text-right\n            "
                      },
                      [_vm._v(" Onboarding Complete ")]
                    )
                  ])
                ],
                1
              ),
              _c("b-progress", {
                staticClass: "step-progress d-lg-none mb-5",
                attrs: { value: 50, variant: "purple", height: "5px" }
              }),
              _vm.step == "selectAddons"
                ? _c("Addons", {
                    attrs: {
                      body: _vm.addonData,
                      productRevealData: _vm.productRevealData,
                      chosenAddons: _vm.onboardingData.hasOwnProperty(
                        "desiredaddon"
                      )
                        ? _vm.onboardingData.desiredaddon
                        : [],
                      nonrx: _vm.user.isNonRx,
                      disabled: _vm.buttonDisabled
                    },
                    on: { submitAddons: _vm.submitAddons }
                  })
                : _vm._e(),
              _vm.step == "selectPricing"
                ? _c("Pricing", {
                    attrs: {
                      body: _vm.selectedAddons,
                      disabled: _vm.buttonDisabled
                    },
                    on: { submitPricing: _vm.submitPricing }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", lg: "3", xxl: "4" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }