var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticStyle: { "background-color": "#FBFFF9" }, attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticStyle: { height: "100vh" }, attrs: { "align-v": "stretch" } },
        [
          _vm.screen == "login"
            ? _c("CartLogin", {
                attrs: {
                  redirect: _vm.loginWithRedirect.status,
                  redirectURL: _vm.loginWithRedirect.url
                },
                on: { "set-screen": _vm.setScreen }
              })
            : _vm._e(),
          _vm.screen == "forgotpassword"
            ? _c("CartForgotPassword", { on: { "set-screen": _vm.setScreen } })
            : _vm._e(),
          _vm.screen == "register"
            ? _c("CartRegistration", {
                attrs: { cartPath: _vm.cartPath },
                on: { "set-screen": _vm.setScreen }
              })
            : _vm._e(),
          _vm.screen == "account"
            ? _c("CartAccount", {
                attrs: { firstname: _vm.userData.firstname },
                on: { logout: _vm.logout }
              })
            : _vm._e(),
          _c("CartContent", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.screen == "cart",
                expression: "screen == 'cart'"
              }
            ],
            ref: "cartContent",
            attrs: { cartUpdate: _vm.cartUpdate },
            on: { "set-screen": _vm.setScreen }
          }),
          _vm.screen == "shipping"
            ? _c("CartShipping", { on: { "set-screen": _vm.setScreen } })
            : _vm._e(),
          _vm.screen == "billing"
            ? _c("CartBilling", { on: { "set-screen": _vm.setScreen } })
            : _vm._e(),
          _vm.screen == "checkout-complete"
            ? _c("CartComplete", { on: { "clear-cart": _vm.clearCart } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }