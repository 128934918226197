var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      _vm.seeMessage
        ? _c("chat", {
            attrs: {
              conversation: _vm.conversation,
              id: "chatList",
              userid: _vm.userid
            },
            on: {
              "&scroll": function($event) {
                return _vm.onScroll($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }