var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "notification", staticClass: "notification-box" },
    [
      _c("v-icon", {
        directives: [
          {
            name: "b-toggle",
            rawName: "v-b-toggle.sidebar-backdrop",
            modifiers: { "sidebar-backdrop": true }
          }
        ],
        class: [_vm.notify ? "active" : ""],
        attrs: { variant: "dark", name: "bell", size: "25" }
      }),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "sidebar-backdrop",
            "backdrop-variant": "dark",
            "bg-variant": "dark",
            backdrop: "",
            right: "",
            "no-header": "",
            "sidebar-class": "side-notification"
          },
          on: { change: _vm.clearNotifications }
        },
        [
          _c("div", { staticClass: "notification" }, [
            _c(
              "div",
              { staticClass: "notification-close" },
              [
                _c("b-icon", {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.sidebar-backdrop",
                      modifiers: { "sidebar-backdrop": true }
                    }
                  ],
                  staticStyle: { cursor: "pointer" },
                  attrs: { icon: "x-circle", variant: "secondary" }
                })
              ],
              1
            ),
            _c("div", { staticClass: "notification-header" }, [
              _c("h2", { staticClass: "mb-0 heading" }, [
                _vm._v("Notifications")
              ])
            ]),
            _c(
              "ul",
              {
                staticClass: "notification-body",
                on: {
                  "&scroll": function($event) {
                    return _vm.notificationScroll($event)
                  }
                }
              },
              _vm._l(_vm.notifications, function(notification, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    class: [
                      "notification-content",
                      notification.timestampread ? "" : "active"
                    ]
                  },
                  [
                    _c("a", { class: ["notification-content"] }, [
                      _c(
                        "div",
                        { staticClass: "notification-text" },
                        [
                          _c("h4", { staticClass: "mb-1" }, [
                            _vm._v(_vm._s(notification.title))
                          ]),
                          _c("p", { staticClass: "mb-2 small" }, [
                            _vm._v(" " + _vm._s(notification.description) + " ")
                          ]),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "6" } }, [
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.moment
                                        .unix(notification.timestampcreated)
                                        .format("MMMM DD, YYYY")
                                    )
                                  )
                                ])
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "6" }
                                },
                                [
                                  !notification.timestampread
                                    ? _c("b-icon", {
                                        attrs: {
                                          icon: "trash",
                                          variant: "secondary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.markAsRead(
                                              notification.id
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }