import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class ChatService extends BaseService {
  static async listConversation() {
    try {
      const response = await this.request({ auth: true }).get("/conversation");
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getConversation(conversationId) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversation/${conversationId}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async performMessageAction(conversationId, messageId, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/conversation/${conversationId}/message/${messageId}/action`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFollowUpQuestion(messageId, step) {
    try {
      const response = await this.request({ auth: true }).get(
        `/followup/${messageId}/${step}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPreviousFollowUpQuestion(messageId, step) {
    try {
      const response = await this.request({ auth: true }).get(
        `/followup/${messageId}/${step}/previous`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async answerFollowUpQuestion(messageId, step, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/followup/${messageId}/${step}`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async listConversationMember(conversationId) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversation/${conversationId}/member`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async listMessage(conversationId, nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversation/${conversationId}/message?nextpage=${nextpage}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async sendMessage(data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/conversation/${data.id}/message`,
        {
          message: data.message,
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async sendMessageWithAttachment(conversationid, data, config = {}) {
    try {
      const response = await this.request({ auth: true }).post(
        `/conversation/${conversationid}/messageattachment`,
        data,
        config
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getUnreadMessageCount(patientid) {
    try {
      const response = await this.request({ auth: true }, "v2").get(
        `/conversationMember/all/unreadmessagecount/${patientid}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
