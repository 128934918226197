var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticStyle: { border: "0" }, attrs: { "no-body": "" } },
    [
      _c(
        "b-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "b-col",
            { staticClass: "mr-1", attrs: { cols: "auto" } },
            [
              _c("b-form-checkbox", {
                staticClass: "product-reveal-checkbox",
                attrs: {
                  name: "product-id",
                  value: true,
                  "unchecked-value": false
                },
                model: {
                  value: _vm.isProductUpsellSelected,
                  callback: function($$v) {
                    _vm.isProductUpsellSelected = $$v
                  },
                  expression: "isProductUpsellSelected"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-row",
                {
                  staticStyle: {
                    position: "relative",
                    "background-color": "#f8f8f8"
                  },
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("b-col", { attrs: { cols: "12", lg: "4" } }, [
                    _c("div", { staticClass: "img-holder" }, [
                      _c("img", {
                        attrs: {
                          height: "100",
                          width: "200",
                          src: _vm.product.images[0]
                        }
                      })
                    ])
                  ]),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "px-4 py-4 d-flex flex-column justify-content-between",
                      attrs: { cols: "12", lg: "8" }
                    },
                    [
                      _c("div", [
                        _c(
                          "h3",
                          {
                            staticStyle: {
                              "letter-spacing": "0.01em",
                              "font-size": "18px"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.product.name) + " ")]
                        ),
                        _c("p", {
                          staticStyle: { "font-size": "14px" },
                          domProps: {
                            innerHTML: _vm._s(_vm.product.shortdescription.html)
                          }
                        })
                      ]),
                      _c(
                        "ul",
                        { staticClass: "list-unstyled list-inline mt-0 mb-0" },
                        _vm._l(_vm.product.pricing, function(price) {
                          return _c("li", { key: price.id }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedPricing,
                                  expression: "selectedPricing"
                                }
                              ],
                              attrs: {
                                id: price.id,
                                type: "radio",
                                name: price.id
                              },
                              domProps: {
                                value: price.id,
                                checked: _vm._q(_vm.selectedPricing, price.id)
                              },
                              on: {
                                change: function($event) {
                                  _vm.selectedPricing = price.id
                                }
                              }
                            }),
                            price.type === "one_time"
                              ? _c(
                                  "label",
                                  {
                                    staticClass: "subscription-label mb-0",
                                    attrs: { for: price.id }
                                  },
                                  [
                                    _vm._v(
                                      " $" +
                                        _vm._s(
                                          (price.unit_amount * 0.01).toFixed(2)
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _c(
                                  "label",
                                  {
                                    staticClass: "subscription-label mb-0",
                                    attrs: { for: price.id }
                                  },
                                  [
                                    _vm._v(
                                      " $" +
                                        _vm._s(
                                          (price.unit_amount * 0.01).toFixed(2)
                                        ) +
                                        "/month "
                                    )
                                  ]
                                )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }