var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "mt-4",
          attrs: { variant: "info", pill: "", block: "" },
          on: {
            click: function($event) {
              return _vm.$emit("next")
            }
          }
        },
        [_c("b-icon-lock"), _vm._v("Continue")],
        1
      ),
      _c("p", { staticClass: "sub-text mt-5" }, [
        _vm._v(
          " A government-issued ID is required when purchasing any prescription medication, as the medical provider will need to verify your identity before they can determine if prescription medication is right for you. Your personal identifying information, medical history or ID will never be shared outside of our secure system. "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }