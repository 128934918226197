<template>
  <div>
    <div>
      <div class="photo-holder fs-exclude">
        <div
          v-if="
            photo &&
            !existing &&
            photoFile.type !== 'application/pdf' &&
            !isAndroidFirefoxRestriction
          "
          class="btn-crop"
          @click="openCropModal"
        >
          <b-icon icon="crop"></b-icon>
        </div>
        <div
          :class="[
            'photo',
            idSize && isImageFileFormat && !isAndroidFirefoxRestriction
              ? 'id-size'
              : '',
          ]"
          :style="{
            backgroundImage: backgroundImage,
          }"
        >
          <img
            v-if="photo != ''"
            :src="photo"
            class="fs-mask"
            :style="{
              width:
                existingPhotoFileType === 'application/pdf' ? 'auto' : '100%',
            }"
          />
        </div>
      </div>
      <div v-if="isAndroid11WebView">
        <p class="sub-text">
          Please send your
          {{ backgroundType === "id" ? "ID document" : "photo" }} to our
          <a href="mailto:help@bywinona.com">email address</a> or via Intercom.
        </p>
        <b-button
          v-if="!dashboard"
          variant="info"
          pill
          block
          @click="skipUpload"
        >
          Continue
        </b-button>
      </div>
      <div v-else>
        <label
          :for="`${componentID}-upload`"
          class="btn btn-outline-info btn-pill mobile-upload-label"
          v-if="photo == '' || isAndroidWebView"
        >
          Select Photo
        </label>
        <b-button
          v-if="this.captureText === 'RETAKE' && !isAndroidWebView"
          class="mb-2"
          variant="outline-info"
          pill
          block
          @click="
            photo = '';
            photoFile = null;
          "
          :disabled="isImageUploadProcessing"
        >
          RETAKE
        </b-button>
        <label
          v-else-if="this.captureText !== 'RETAKE' && !isAndroidWebView"
          :for="`${componentID}-capture`"
          :class="{
            'btn-info': captureText == 'TAKE PHOTO',
            'btn-outline-info': captureText == 'RETAKE',
          }"
          class="btn btn-pill mobile-upload-label"
          :disabled="isImageUploadProcessing"
        >
          {{ captureText }}
        </label>
        <input
          type="file"
          :id="`${componentID}-capture`"
          accept="image/*"
          @change="(e) => setPictureMobile(e, { isPhotoTaken: true })"
          class="mobile-upload"
          name="mobile-upload"
          capture
        />
        <input
          type="file"
          :id="`${componentID}-upload`"
          accept="image/*, application/pdf"
          @change="(e) => setPictureMobile(e, { isPhotoTaken: false })"
          class="mobile-upload"
          name="mobile-upload"
        />
        <b-button
          v-if="!dashboard && this.captureText == 'RETAKE'"
          @click="submitAnswer"
          variant="info"
          pill
          block
          :disabled="isImageUploadProcessing"
        >
          <b-spinner small v-if="isImageUploadProcessing"></b-spinner>
          <span v-else>{{ submitText }}</span>
        </b-button>
      </div>
    </div>

    <b-modal
      class="fs-exclude"
      id="crop"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <vue-croppie
        ref="croppieRefMobile"
        :enableOrientation="false"
        :enableResize="false"
        :boundary="{ width: 300, height: backgroundType === 'id' ? 200 : 300 }"
        :viewport="{ width: 300, height: backgroundType === 'id' ? 200 : 300 }"
      >
      </vue-croppie>
      <b-row class="mt-3">
        <b-col cols="12" md="6">
          <b-button pill block variant="info" @click="cropImage">
            Crop
          </b-button>
          <div class="mb-3 d-md-none"></div>
        </b-col>

        <b-col cols="12" md="6">
          <b-button pill block @click="closeCropModal" variant="outline-info">
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { isAndroid, isFirefox, osVersion, osName } from "mobile-device-detect";
import piexif from "piexifjs";
import ImageCompressor from "js-image-compressor";
import Tiff from "tiff.js";
import pdfIcon from "@/assets/images/onboarding/pdf-icon.png";
import pictureIcon from "@/assets/images/onboarding/picture-icon.png";

export default {
  name: "MobileUpload",
  props: {
    idSize: {
      type: Boolean,
    },
    backgroundType: {
      type: String,
      default: "id",
    },
    existingPhoto: {
      type: String,
      default: "",
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
    existingPhotoFileType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      photo: "",
      photoFile: null,
      existing: false,
      orientation: 0,
      photoWidth: 0,
      photoHeight: 0,
    };
  },
  computed: {
    componentID() {
      return `mobile-upload-${this.backgroundType}`;
    },
    backgroundImage() {
      let imageToUse;
      switch (this.backgroundType) {
        case "id":
          imageToUse = `url(${require("@/assets/images/id.png")})`;
          break;
        case "personal":
          imageToUse = `url(${require("@/assets/images/photo.jpg")})`;
          break;
      }
      return this.photo == "" ? imageToUse : "";
    },
    captureText() {
      return this.photo == "" ? "TAKE PHOTO" : "RETAKE";
    },
    submitText() {
      if (this.photo == this.existingPhoto) {
        return "CONFIRM";
      } else {
        return this.isImageUploadProcessing ? "SUBMITTING" : "CONFIRM";
      }
    },
    isImageFileFormat() {
      return this.photoFile === null || this.photoFile?.type.includes("image/");
    },
    isImageUploadProcessing() {
      return this.$store.state.onboarding.isImageUploadProcessing;
    },
    isAndroidWebView() {
      if (isAndroid) {
        // Instagram WebView
        if (navigator.userAgent.match(/instagram/i)) {
          return true;
        }

        // Facebook WebView
        let ua = navigator.userAgent || navigator.vendor || window.opera;
        if (ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1) {
          return true;
        }

        return false;
      } else {
        return false;
      }
    },
    // If device is on Android using Firefox and photo is > 1.04 MB
    isAndroidFirefoxRestriction() {
      return (
        isAndroid &&
        isFirefox &&
        ((this.photoFile?.size || 0) > 1065 * 1024 ||
          this.photoWidth > 4000 ||
          this.photoHeight > 4000)
      );
    },
    isAndroid11WebView() {
      return (
        this.isAndroidWebView && osName === "Android" && osVersion === "11"
      );
    },
  },
  methods: {
    openCropModal() {
      this.$bvModal.show("crop");
      this.$nextTick(() => {
        this.$refs.croppieRefMobile.bind({
          url: this.photo,
        });
      });
    },
    closeCropModal() {
      this.$bvModal.hide("crop");
    },
    cropImage() {
      let options = {
        format: "jpeg",
      };
      this.$refs.croppieRefMobile.result(options, (output) => {
        this.photo = output;
        this.photoFile = this.dataURLtoFile(output, "image");
        this.closeCropModal();
      });
    },
    setPictureMobile(event, { isPhotoTaken }) {
      try {
        this.existing = false;
        this.$emit("hideAlert");

        let fileToUpload = event.target.files[0];

        if (!fileToUpload) return;

        if (fileToUpload.size > 25600 * 1024) {
          this.$emit("showAlert", "File too big (> 25MB)");
          return;
        }

        const isFileTypeSupported = /\.(jpe?g|png|pdf|tif?f)$/i.test(
          fileToUpload.name
        );

        if (!isFileTypeSupported) {
          throw "Please upload an image or a PDF file!";
        }

        // Set local file variable after file checks
        this.photoFile = fileToUpload;

        // Initialize a File Reader object
        let reader = new FileReader();

        if (this.photoFile.type === "image/tiff") {
          reader.addEventListener(
            "load",
            function () {
              this.photo = reader.result;
              let tiff = new Tiff({ buffer: reader.result });
              let canvas = tiff.toCanvas();
              if (canvas) {
                this.photo = canvas.toDataURL("image/png");
              }

              if (this.dashboard) {
                this.$emit("submitAnswerDashboard", {
                  photo: this.photo,
                  file: event.target.files[0],
                  orientation: this.orientation,
                  isPhotoTaken,
                });
              }
            }.bind(this),
            false
          );
          reader.readAsArrayBuffer(event.target.files[0]);
        } else {
          if (this.photoFile.type.includes("image")) {
            let options = {
              file: event.target.files[0],
              quality: 0.1,
              maxHeight: 240,
              maxWidth: 320,
              mimeType: "image/jpeg",
              success: function (result) {
                this.photo = result;
              },
              error: function (msg) {
                console.log(msg);
                throw "Please make sure the image you're uploading is valid.";
              },
            };
            new ImageCompressor(options);
          }
          reader.addEventListener(
            "load",
            function () {
              if (this.photoFile.type === "application/pdf") {
                this.photo = pdfIcon;
              } else {
                if (this.isAndroidFirefoxRestriction) {
                  this.photo = pictureIcon;

                  // Assign width and height only to firefox restrictions
                  let img = new Image();
                  img.onload = function (e) {
                    this.photoWidth = img.width;
                    this.photoHeight = img.height;
                  };
                  img.src = reader.result;
                } else {
                  this.photo = reader.result;
                }
              }

              if (this.photoFile.type == "image/jpeg") {
                let exif = piexif.load(reader.result);
                this.orientation = exif["0th"][piexif.ImageIFD.Orientation];
              }
              if (this.dashboard) {
                this.$emit("submitAnswerDashboard", {
                  photo: reader.result,
                  file: event.target.files[0],
                  orientation: this.orientation,
                  isPhotoTaken,
                });
              }
            }.bind(this),
            false
          );
          reader.readAsDataURL(event.target.files[0]);
        }
      } catch (err) {
        console.log(err);
        this.$emit("showAlert", err);
      }
    },
    submitAnswer() {
      if (this.photo == "") {
        this.$emit("showAlert", "Please submit a photo.");
        return;
      }
      this.$store.commit("onboarding/SET_IS_IMAGE_UPLOAD_PROCESSING", true);
      if (this.photo == this.existingPhoto) {
        this.$emit("skip");
      } else {
        this.$emit("submitAnswer", this.photoFile, this.orientation);
      }
    },
    skipUpload() {
      this.$emit("skip");
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
  watch: {
    existingPhoto() {
      if (this.existingPhoto) {
        this.photo = this.existingPhoto;
        this.existing = true;
      }
    },
  },
  async mounted() {
    let stream = null;
    if (!this.isAndroidWebView) {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
      } catch (err) {
        if (err.name == "NotAllowedError") {
          this.$emit(
            "showAlert",
            "Please enable your camera through permissions."
          );
        }
      }
    }

    this.$emit("update:isAndroid11Webview", this.isAndroid11WebView);
  },
  beforeDestroy() {
    this.photo = "";
    this.photoFile = "";
  },
};
</script>

<style lang="scss" scoped>
.id-size {
  width: 250px !important;
  height: 158px !important;
}

.mobile-upload-label {
  border-radius: 24px;
  display: block;
}

.mobile-upload {
  display: none;
  opacity: 0;
}

.photo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  background-color: #f2f2f2;
  position: relative;

  .btn-crop {
    padding: 0.75rem;
    background-color: white;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #a783ff;
  }

  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
