var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("small", { staticClass: "text-muted mb-3" }, [_vm._v("Face Photo")]),
      _c("MobileUpload", {
        key: "mobileUploadPersonal",
        attrs: {
          backgroundType: "personal",
          dashboard: true,
          existingPhoto: _vm.personalForComponent
        },
        on: {
          submitAnswerDashboard: function($event) {
            return _vm.setPhoto("personal", $event)
          }
        }
      }),
      _c("small", { staticClass: "text-muted mb-3" }, [_vm._v("ID Photo")]),
      _c("MobileUpload", {
        key: "mobileUploadId",
        attrs: {
          backgroundType: "id",
          dashboard: true,
          existingPhoto: _vm.idForComponent
        },
        on: {
          submitAnswerDashboard: function($event) {
            return _vm.setPhoto("id", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }