var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "pl-4 pr-4", attrs: { fluid: "" } },
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.intermediaryLoader && _vm.loaderType == 2
            ? _c("IntermediaryLoader", { attrs: { loaderType: "2" } })
            : _vm._e(),
          _vm.intermediaryLoader && _vm.loaderType == 3
            ? _c("IntermediaryLoader", { attrs: { loaderType: "3" } })
            : _vm._e(),
          _vm.intermediaryLoader && _vm.loaderType == 1
            ? _c("IntermediaryLoader", {
                attrs: {
                  loaderType: "1",
                  quote: _vm.intermediaryLoaderText,
                  image: require("@/assets/images/onboarding/loader.png")
                }
              })
            : _vm._e(),
          _vm.intermediaryLoader && _vm.loaderType == 4
            ? _c("IntermediaryLoader", {
                attrs: {
                  loaderType: "1",
                  quote: "With Winona, feeling better is a few steps away.",
                  image: require("@/assets/images/onboarding/loader1.png")
                }
              })
            : _vm._e(),
          _vm.intermediaryLoader &&
          _vm.loaderType == 5 &&
          _vm.messageType == "HIPAA"
            ? _c("IntermediaryLoader", {
                attrs: { loaderType: "5", darkBg: "" },
                on: { click: _vm.hideLoader },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "text",
                      fn: function() {
                        return [
                          _c("p", [
                            _vm._v(
                              " Welcome to Winona! We are excited to get to help you on your menopause journey. "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " Please start your brief online visit to confirm your interests, concerns, and goals. With this information, our medical team can send your personalized treatments directly from our Winona pharmacy to your home. "
                            )
                          ]),
                          _c("p", { staticClass: "loader-highlight" }, [
                            _vm._v(
                              " All of your personal information is properly stored and protected by our HIPAA compliant medical information storage system. "
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2064791976
                )
              })
            : _vm._e(),
          _vm.intermediaryLoader &&
          _vm.loaderType == 5 &&
          _vm.messageType == "doctorvisit"
            ? _c("IntermediaryLoader", {
                attrs: { loaderType: "5", darkBg: "" },
                on: { click: _vm.hideLoader },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "text",
                      fn: function() {
                        return [
                          _c("p", { staticClass: "loader-highlight" }, [
                            _vm._v(
                              " Your Winona doctor visit is free and includes unlimited follow-ups. Assuming there are not any questions or concerns from you or the doctor, and the doctor feels the treatments selected are safe, the prescriptions will be filled. You will have the opportunity to message your doctor directly from the patient portal after onboarding is completed. "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " Coming up, you will confirm your order, along with billing and shipping information. Your card will be authorized, but not charged, until treatment is confirmed by you and your Winona physician. "
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3416688756
                )
              })
            : _vm._e(),
          _vm.intermediaryLoader && _vm.loaderType == 6
            ? _c("IntermediaryLoader", {
                attrs: { loaderType: "6", neutralBg: "" }
              })
            : _vm._e(),
          _vm.intermediaryLoader &&
          _vm.loaderType == 5 &&
          _vm.messageType == "doctormatching"
            ? _c("IntermediaryLoader", {
                attrs: {
                  messageType: "doctormatching",
                  loaderType: "5",
                  sideText: "DOCTOR CONSULTATION",
                  userid: _vm.userid,
                  lightBg: ""
                },
                on: { click: _vm.hideLoader },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "text",
                      fn: function() {
                        return [
                          _c(
                            "b-row",
                            {
                              staticClass: "mt-5",
                              attrs: { "align-v": "center" }
                            },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("b-avatar", {
                                    attrs: {
                                      src: require("@/assets/images/onboarding/doctor.png"),
                                      size: "7rem"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("b-col", [
                                _c("h2", { staticClass: "mb-2 loader-text" }, [
                                  _vm._v("Dr. Michael Green, OB/GYN")
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass: "mb-0 loader-sub-text",
                                    staticStyle: { color: "#333333" }
                                  },
                                  [_vm._v(" CA License #: G80363 ")]
                                )
                              ])
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mt-5 text-default" }, [
                            _c(
                              "span",
                              { staticClass: "med-font d-block mb-3" },
                              [
                                _vm._v(
                                  "You've been matched with Dr. Michael Green, a board certified, licensed, OB/GYN in California with over 28 years of experience in women's healthcare."
                                )
                              ]
                            )
                          ]),
                          _c("p", { staticClass: "text-default" }, [
                            _vm._v(
                              " He will help you review your treatment options and choose your prescription if suitable — he'll be available for as many follow-ups as you need. "
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1864645595
                )
              })
            : _vm._e(),
          !_vm.intermediaryLoader
            ? _c(
                "b-navbar",
                {
                  staticClass: "d-flex",
                  staticStyle: { "box-shadow": "none", "z-index": "498" },
                  attrs: { toggleable: "sm", fixed: "top" }
                },
                [
                  _c(
                    "b-navbar-brand",
                    {
                      staticClass: "c-logo relative mb-2",
                      attrs: { to: "/website" }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/logo.svg"),
                          alt: ""
                        }
                      })
                    ]
                  ),
                  this.$route.path != "/eligibility"
                    ? _c(
                        "b-button",
                        {
                          staticClass: "logout ml-auto mr-3 pa-2",
                          attrs: { pill: "", variant: "light" },
                          on: {
                            click: function($event) {
                              _vm.showLogoutModal = true
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "mr-2",
                            attrs: {
                              src: require("@/assets/images/logout.svg")
                            }
                          }),
                          _vm._v("Logout")
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "vue-page-transition",
        [
          _c("router-view", {
            attrs: { title: _vm.title },
            on: {
              showLoader: _vm.showLoader,
              hideLoader: _vm.hideLoader,
              setOnboardingStep: _vm.setOnboardingStep,
              showExitModal: function($event) {
                _vm.showRedirectModal = true
              },
              setExiting: _vm.setExiting
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "hide-header": "",
            "hide-footer": "",
            centered: "",
            "content-class": "dark-bg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-stacking": ""
          },
          model: {
            value: _vm.showRedirectModal,
            callback: function($$v) {
              _vm.showRedirectModal = $$v
            },
            expression: "showRedirectModal"
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo dark" })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-3 heading color-inherit",
                  staticStyle: { "font-size": "26px", "line-height": "32px" }
                },
                [_vm._v(" Are you sure you want to quit the onboarding? ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-info", pill: "", block: "" },
                  on: {
                    click: function($event) {
                      _vm.window.location.href =
                        _vm.process.env.VUE_APP_SITE_URL
                    }
                  }
                },
                [_vm._v("Yes, I'm Sure")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "info", pill: "", block: "" },
                  on: {
                    click: function($event) {
                      _vm.showRedirectModal = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "hide-header": "",
            "hide-footer": "",
            centered: "",
            "content-class": "dark-bg",
            "no-close-on-backgdrop": "",
            "no-close-on-esc": "",
            "no-stacking": ""
          },
          model: {
            value: _vm.showLogoutModal,
            callback: function($$v) {
              _vm.showLogoutModal = $$v
            },
            expression: "showLogoutModal"
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo dark" })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-3 heading color-inherit",
                  staticStyle: { "font-size": "26px", "line-height": "32px" }
                },
                [_vm._v(" Are you sure you want to log out? ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-info", pill: "", block: "" },
                  on: { click: _vm.logout }
                },
                [_vm._v("Yes, I'm Sure")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "info", pill: "", block: "" },
                  on: {
                    click: function($event) {
                      _vm.showLogoutModal = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }