var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "d-flex",
      staticStyle: { "box-shadow": "none", "z-index": "498" },
      attrs: { toggleable: "sm", fixed: "top" }
    },
    [
      _c("b-navbar-brand", { staticClass: "c-logo relative mb-2" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/logo.svg"), alt: "" }
        })
      ]),
      _c(
        "b-button",
        {
          staticClass: "close-followup-modal ml-auto mr-3 pa-2",
          attrs: { pill: "", variant: "light" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c("img", {
            staticClass: "mr-2",
            attrs: { src: require("@/assets/images/logout.svg") }
          }),
          _vm._v("Close")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }