var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    {
      class: [
        _vm.name == "login"
          ? "login"
          : _vm.name == "signup"
          ? "signup"
          : _vm.name == "forgot-password"
          ? "forgot-password"
          : _vm.name == "new-password"
          ? "new-password"
          : _vm.name == "invitation-code"
          ? "invite-code"
          : ""
      ],
      attrs: { "align-v": "center", "align-h": "center" }
    },
    [
      _c(
        "b-col",
        {
          staticClass: "bg-form",
          attrs: { cols: "12", "align-self": "stretch" }
        },
        [
          _vm.name == "cart"
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.login()
                    }
                  }
                },
                [
                  _c(
                    "b-row",
                    {
                      staticStyle: { height: "85vh" },
                      attrs: { "align-v": "stretch" }
                    },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "h1",
                          { class: ["display-4 mb-3 heading", _vm.textCenter] },
                          [_vm._v(_vm._s(_vm.title))]
                        ),
                        _c("p", { class: ["text-muted", _vm.textCenter] }, [
                          _vm._v(_vm._s(_vm.subTitle))
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                show: _vm.loginAlert.show,
                                variant: _vm.loginAlert.type
                              }
                            },
                            [_vm._v(_vm._s(_vm.loginAlert.message))]
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-4", attrs: { role: "group" } },
                            [
                              _c("label", { attrs: { for: "email" } }, [
                                _vm._v("Email Address:")
                              ]),
                              _c("b-form-input", {
                                staticClass: "pl-0 fs-mask",
                                attrs: {
                                  id: "email",
                                  type: "email",
                                  state: _vm.emailState,
                                  autocomplete: "off",
                                  required: ""
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { id: "input-live-feedback" } },
                                [_vm._v(" The email must have '@email.com' ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-4", attrs: { role: "group" } },
                            [
                              _c("b-row", { attrs: { "align-v": "center" } }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col mb-0",
                                    attrs: { for: "password" }
                                  },
                                  [_vm._v("Password:")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-auto" },
                                  [
                                    _vm._t("password", [
                                      _c("router-link", {
                                        attrs: {
                                          tabindex: "-1",
                                          to: "/forgot-password",
                                          custom: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var navigate = ref.navigate
                                                return [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "form-text small d-letter-spacing mb-0 fs-mask",
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)"
                                                      },
                                                      on: { click: navigate }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Forgot password?"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          4260138573
                                        )
                                      })
                                    ])
                                  ],
                                  2
                                )
                              ]),
                              _c("b-form-input", {
                                staticClass: "pl-0 fs-mask",
                                attrs: {
                                  id: "password",
                                  type: "password",
                                  state: _vm.passwordState,
                                  autocomplete: "off",
                                  required: ""
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { id: "input-live-feedback" } },
                                [_vm._v(" Min. 8 characters ")]
                              )
                            ],
                            1
                          ),
                          _vm._t("register", [
                            _c(
                              "small",
                              { staticClass: "text-muted d-letter-spacing" },
                              [
                                _vm._v(" Don't have an account yet? "),
                                _c("router-link", {
                                  attrs: {
                                    to: "/eligibility",
                                    tabindex: "-1",
                                    custom: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var navigate = ref.navigate
                                          return [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void(0)"
                                                },
                                                on: { click: navigate }
                                              },
                                              [_vm._v("Get started")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2146134881
                                  )
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm._t("button", [
                            !_vm.dataloading
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      type: "submit",
                                      pill: "",
                                      block: "",
                                      variant: "primary",
                                      size: "lg"
                                    }
                                  },
                                  [_vm._v(" Continue ")]
                                )
                              : _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-3",
                                    attrs: {
                                      disabled: "",
                                      pill: "",
                                      block: "",
                                      variant: "primary",
                                      size: "lg"
                                    }
                                  },
                                  [_vm._v(" Logging in ")]
                                )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.name == "login"
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.login()
                    }
                  }
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "display-3 mb-3 heading initial-line-height"
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _c("p", { staticClass: "sub-text" }, [
                    _vm._v(_vm._s(_vm.subTitle))
                  ]),
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        show: _vm.loginAlert.show,
                        variant: _vm.loginAlert.type
                      }
                    },
                    [_vm._v(_vm._s(_vm.loginAlert.message))]
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4 mt-5", attrs: { role: "group" } },
                    [
                      _c(
                        "label",
                        { staticClass: "input-label", attrs: { for: "email" } },
                        [_vm._v("Email Address:*")]
                      ),
                      _c("b-form-input", {
                        staticClass: "pl-0 fs-mask",
                        attrs: {
                          id: "email",
                          type: "email",
                          state: _vm.emailState,
                          autocomplete: "off",
                          required: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [_vm._v(" The email must have '@email.com' ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col input-label",
                            attrs: { for: "password" }
                          },
                          [_vm._v("Password:*")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c("router-link", {
                              attrs: {
                                tabindex: "-1",
                                to: "/forgot-password",
                                custom: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var navigate = ref.navigate
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-primary form-text input-label",
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: { click: navigate }
                                          },
                                          [_vm._v(" Forgot password? ")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2790179606
                              )
                            })
                          ],
                          1
                        )
                      ]),
                      _c("b-form-input", {
                        staticClass: "pl-0 fs-mask",
                        attrs: {
                          id: "password",
                          type: "password",
                          state: _vm.passwordState,
                          autocomplete: "off",
                          required: ""
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [_vm._v(" Min. 8 characters ")]
                      )
                    ],
                    1
                  ),
                  !_vm.dataloading
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mb-3",
                          staticStyle: { "min-width": "250px" },
                          attrs: {
                            type: "submit",
                            pill: "",
                            variant: "info",
                            size: "lg"
                          }
                        },
                        [_vm._v(" Continue ")]
                      )
                    : _c(
                        "b-button",
                        {
                          staticClass: "mb-3",
                          staticStyle: { "min-width": "250px" },
                          attrs: {
                            pill: "",
                            disabled: "",
                            variant: "info",
                            size: "lg"
                          }
                        },
                        [_vm._v(" Logging in ")]
                      ),
                  _c(
                    "p",
                    { staticClass: "mb-0 sub-text" },
                    [
                      _vm._v(" Don't have an account? "),
                      _c("router-link", {
                        attrs: {
                          to: "/eligibility",
                          tabindex: "-1",
                          custom: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var navigate = ref.navigate
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-primary",
                                      attrs: { href: "javascript:void(0)" },
                                      on: { click: navigate }
                                    },
                                    [_vm._v(" Get Started ")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1601526205
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.name == "signup"
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.signUp()
                    }
                  }
                },
                [
                  _c("h1", { class: ["mb-3 heading", _vm.textCenter] }, [
                    _vm._v(_vm._s(_vm.title))
                  ]),
                  _c("p", { class: ["text-muted sub-text", _vm.textCenter] }, [
                    _vm._v(_vm._s(_vm.subTitle))
                  ]),
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        show: _vm.loginAlert.show,
                        variant: _vm.loginAlert.type
                      }
                    },
                    [_vm._v(_vm._s(_vm.loginAlert.message))]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c("b-col", { attrs: { cols: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "mb-4", attrs: { role: "group" } },
                          [
                            _c("label", { attrs: { for: "firstName" } }, [
                              _vm._v("First Name:")
                            ]),
                            _c("b-form-input", {
                              staticClass: "pl-0 fs-mask",
                              attrs: {
                                id: "firstName",
                                type: "text",
                                autocomplete: "off",
                                required: ""
                              },
                              model: {
                                value: _vm.firstName,
                                callback: function($$v) {
                                  _vm.firstName = $$v
                                },
                                expression: "firstName"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("b-col", { attrs: { cols: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "mb-4", attrs: { role: "group" } },
                          [
                            _c("label", { attrs: { for: "lastName" } }, [
                              _vm._v("Last Name:")
                            ]),
                            _c("b-form-input", {
                              staticClass: "pl-0 fs-mask",
                              attrs: {
                                id: "lastName",
                                type: "text",
                                autocomplete: "off",
                                required: ""
                              },
                              model: {
                                value: _vm.lastName,
                                callback: function($$v) {
                                  _vm.lastName = $$v
                                },
                                expression: "lastName"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c("label", { attrs: { for: "email" } }, [
                        _vm._v("Email Address:")
                      ]),
                      _c("b-form-input", {
                        staticClass: "pl-0 fs-mask",
                        attrs: {
                          id: "email",
                          type: "email",
                          state: _vm.emailState,
                          autocomplete: "off",
                          required: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [_vm._v(" The email must have '@email.com' ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c("label", { attrs: { for: "password" } }, [
                        _vm._v("Password:")
                      ]),
                      _c("b-form-input", {
                        staticClass: "pl-0 fs-mask",
                        attrs: {
                          id: "password",
                          type: "password",
                          state: _vm.passwordState,
                          required: "",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [_vm._v(" Min. 8 characters ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { id: "terms", name: "terms" },
                      model: {
                        value: _vm.iAgree,
                        callback: function($$v) {
                          _vm.iAgree = $$v
                        },
                        expression: "iAgree"
                      }
                    },
                    [
                      _vm._v(" I have read and agree to these "),
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.termLink },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showDialog(_vm.termLink)
                            }
                          }
                        },
                        [_vm._v("Terms & Conditions")]
                      ),
                      _vm._v(", "),
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.privacyLink },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showDialog(_vm.privacyLink)
                            }
                          }
                        },
                        [_vm._v("Privacy Policy")]
                      ),
                      _vm._v(", and "),
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.teleLink },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showDialog(_vm.teleLink)
                            }
                          }
                        },
                        [_vm._v("Telehealth Consent")]
                      )
                    ]
                  ),
                  _vm._t("login", [
                    _c(
                      "small",
                      { staticClass: "text-muted d-letter-spacing" },
                      [
                        _vm._v(" Already have an account "),
                        _c("router-link", {
                          attrs: { to: "/login", tabindex: "-1", custom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var navigate = ref.navigate
                                  return [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: { click: navigate }
                                      },
                                      [_vm._v("Log In")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1042951541
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  !_vm.dataloading
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mt-3 mb-3",
                          attrs: {
                            pill: "",
                            block: "",
                            type: "submit",
                            variant: "info",
                            size: "lg"
                          }
                        },
                        [_vm._v(" Register ")]
                      )
                    : _c(
                        "b-button",
                        {
                          staticClass: "mt-3 mb-3",
                          attrs: {
                            disabled: "",
                            pill: "",
                            block: "",
                            variant: "info",
                            size: "lg"
                          }
                        },
                        [_vm._v(" Processing ")]
                      )
                ],
                2
              )
            : _vm._e(),
          _vm.name == "forgot-password"
            ? _c(
                "b-form",
                {
                  staticClass: "mt-4",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.forgotPassword()
                    }
                  }
                },
                [
                  _vm.resetButton == true
                    ? _c(
                        "h1",
                        {
                          staticClass:
                            "display-3 mb-3 heading initial-line-height"
                        },
                        [_vm._v(" It’s on the way ")]
                      )
                    : _c(
                        "h1",
                        {
                          staticClass:
                            "display-3 mb-3 heading initial-line-height"
                        },
                        [_vm._v(" " + _vm._s(_vm.title) + " ")]
                      ),
                  _vm.resetButton == true
                    ? _c("p", { staticClass: "mb-6 sub-text" }, [
                        _vm._v(
                          " Please check your inbox and follow the instructions to reset your password. "
                        )
                      ])
                    : _c("p", { staticClass: "mb-6 sub-text" }, [
                        _vm._v(_vm._s(_vm.subTitle))
                      ]),
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        show: _vm.loginAlert.show,
                        variant: _vm.loginAlert.type
                      }
                    },
                    [_vm._v(_vm._s(_vm.loginAlert.message))]
                  ),
                  _vm.resetButton == false
                    ? _c(
                        "div",
                        { staticClass: "mb-4", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "email" }
                            },
                            [_vm._v("Email Address:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0 fs-mask",
                            attrs: {
                              id: "email",
                              type: "email",
                              state: _vm.emailState,
                              required: ""
                            },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-live-feedback" } },
                            [_vm._v(" The email must have '@email.com' ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.resetButton
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mb-3",
                          staticStyle: { "min-width": "250px" },
                          attrs: {
                            pill: "",
                            type: "submit",
                            variant: "info",
                            size: "lg"
                          }
                        },
                        [_vm._v("Send")]
                      )
                    : _vm._e(),
                  _vm.resetButton == true
                    ? _c("p", { staticClass: "sub-text" }, [
                        _vm._v(" Didn’t receive the link? "),
                        _c(
                          "a",
                          {
                            staticClass: "text-primary",
                            attrs: { href: "#", tabindex: "-1" },
                            on: {
                              click: function($event) {
                                _vm.resetButton = false
                              }
                            }
                          },
                          [_vm._v("Resend Link")]
                        )
                      ])
                    : _c(
                        "p",
                        { staticClass: "sub-text" },
                        [
                          _vm._t("goback", [
                            _vm._v(" Do you remember your password? "),
                            _c("router-link", {
                              attrs: {
                                to: "/login",
                                tabindex: "-1",
                                custom: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var navigate = ref.navigate
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-primary",
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: { click: navigate }
                                          },
                                          [_vm._v(" Try logging in ")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3165709457
                              )
                            })
                          ])
                        ],
                        2
                      )
                ],
                1
              )
            : _vm._e(),
          _vm.name == "new-password"
            ? _c(
                "b-form",
                {
                  staticClass: "mt-5",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.newPassword()
                    }
                  }
                },
                [
                  _vm.changeButton == true
                    ? [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "display-3 mb-3 heading initial-line-height"
                          },
                          [_vm._v(" Your password has been changed ")]
                        ),
                        _c("p", { staticClass: "sub-text" }, [
                          _vm._v(
                            " You can now use your new password to log in to your account. "
                          )
                        ])
                      ]
                    : [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "display-3 mb-3 heading initial-line-height"
                          },
                          [_vm._v(" " + _vm._s(_vm.title) + " ")]
                        ),
                        _c("p", { staticClass: "sub-text" }, [
                          _vm._v(_vm._s(_vm.subTitle))
                        ])
                      ],
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        show: _vm.loginAlert.show,
                        variant: _vm.loginAlert.type
                      }
                    },
                    [_vm._v(_vm._s(_vm.loginAlert.message))]
                  ),
                  _vm.changeButton == false
                    ? [
                        _c(
                          "div",
                          { staticClass: "mb-4", attrs: { role: "group" } },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "password" }
                              },
                              [_vm._v("New Password:")]
                            ),
                            _c("b-form-input", {
                              staticClass: "pl-0 fs-mask",
                              attrs: {
                                id: "password",
                                type: "password",
                                state: _vm.passwordState,
                                required: ""
                              },
                              model: {
                                value: _vm.password,
                                callback: function($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password"
                              }
                            }),
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "input-live-feedback" } },
                              [_vm._v(" Min. 8 characters ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4", attrs: { role: "group" } },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "cpassword" }
                              },
                              [_vm._v("Confirm Password:")]
                            ),
                            _c("b-form-input", {
                              staticClass: "pl-0 fs-mask",
                              attrs: {
                                id: "cpassword",
                                type: "password",
                                state: _vm.cpasswordState,
                                required: ""
                              },
                              model: {
                                value: _vm.cpassword,
                                callback: function($$v) {
                                  _vm.cpassword = $$v
                                },
                                expression: "cpassword"
                              }
                            }),
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "input-live-feedback" } },
                              [_vm._v(" Your password does not match ")]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.changeButton == true
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mb-3",
                          staticStyle: { "min-width": "250px" },
                          attrs: {
                            pill: "",
                            to: "/login",
                            variant: "info",
                            size: "lg"
                          }
                        },
                        [_vm._v("Login")]
                      )
                    : _c(
                        "b-button",
                        {
                          staticClass: "mb-3",
                          staticStyle: { "min-width": "250px" },
                          attrs: {
                            pill: "",
                            type: "submit",
                            variant: "info",
                            size: "lg"
                          }
                        },
                        [_vm._v("Change Password")]
                      ),
                  !_vm.changeButton
                    ? _c(
                        "p",
                        { staticClass: "sub-text" },
                        [
                          _vm._t("goback", [
                            _vm._v(" Do you remember your password? "),
                            _c("router-link", {
                              attrs: {
                                to: "/login",
                                tabindex: "-1",
                                custom: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var navigate = ref.navigate
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-primary",
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: { click: navigate }
                                          },
                                          [_vm._v("Try logging in")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3398943537
                              )
                            })
                          ])
                        ],
                        2
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm.name == "invitation-code"
            ? _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.invitationCode()
                    }
                  }
                },
                [
                  _c(
                    "h1",
                    { staticClass: "display-3 mb-3 initial-line-height" },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _c("p", { staticClass: "text-muted sub-text" }, [
                    _vm._v(_vm._s(_vm.subTitle))
                  ]),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c("label", { attrs: { for: "code" } }, [
                        _vm._v("Sign-up code:")
                      ]),
                      _c("b-form-input", {
                        staticClass: "pl-0",
                        attrs: { id: "code", type: "text", required: "" },
                        model: {
                          value: _vm.code,
                          callback: function($$v) {
                            _vm.code = $$v
                          },
                          expression: "code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        block: "",
                        type: "submit",
                        variant: "info",
                        size: "lg"
                      }
                    },
                    [_vm._v(" Continue ")]
                  ),
                  _c(
                    "small",
                    { staticClass: "text-muted" },
                    [
                      _vm._v(" Already have an account "),
                      _c("router-link", {
                        attrs: { to: "/login", tabindex: "-1", custom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var navigate = ref.navigate
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: { click: navigate }
                                    },
                                    [_vm._v("Log In")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1042951541
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }