// import auth from './auth';
// import step from './step';
import user from "./user";
import notification from "./notification";
import global from "./global";
import onboarding from "./onboarding";
import upsell from "./upsell";
import message from "./message";

export default {
  // auth,
  // step,
  user,
  notification,
  global,
  onboarding,
  upsell,
  message,
};
