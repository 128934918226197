var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "billing-input" },
    [
      _c(
        "div",
        { staticClass: "accordion", attrs: { role: "tablist" } },
        [
          _c(
            "b-card",
            { staticClass: "mb-2", attrs: { "no-body": "" } },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex payment-option",
                  attrs: {
                    "aria-expanded": _vm.isCreditOrDebitVisible
                      ? "true"
                      : "false",
                    "aria-controls": "accordion-1",
                    "header-tag": "header",
                    role: "tab"
                  },
                  on: {
                    click: function($event) {
                      _vm.isCreditOrDebitVisible = !_vm.isCreditOrDebitVisible
                    }
                  }
                },
                [
                  _c("div", [
                    _vm.isCreditOrDebitVisible
                      ? _c(
                          "span",
                          { staticClass: "when-opened" },
                          [
                            _c(
                              "b-avatar",
                              { attrs: { variant: "tertiary", size: "22px" } },
                              [
                                _c("b-icon", {
                                  staticStyle: { color: "#cdfcb1" },
                                  attrs: { icon: "check", "font-scale": "1.2" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "span",
                          { staticClass: "when-closed" },
                          [
                            _c(
                              "b-avatar",
                              {
                                staticStyle: { "background-color": "#e0e0e0" },
                                attrs: { icon: "chevron-up", size: "22px" }
                              },
                              [
                                _c("b-icon", {
                                  staticStyle: { color: "#e0e0e0" },
                                  attrs: {
                                    icon: "circle-fill",
                                    "font-scale": "1.2"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "payment-title",
                      staticStyle: { "flex-grow": "1" }
                    },
                    [_vm._v(" Credit or Debit Card ")]
                  ),
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/payment-methods/jcb.svg"),
                        width: "20px"
                      }
                    }),
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/payment-methods/visa.svg"),
                        width: "20px"
                      }
                    }),
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/payment-methods/mastercard.svg"),
                        width: "20px"
                      }
                    })
                  ])
                ]
              ),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "accordion-1",
                    accordion: "my-accordion",
                    role: "tabpanel"
                  },
                  model: {
                    value: _vm.isCreditOrDebitVisible,
                    callback: function($$v) {
                      _vm.isCreditOrDebitVisible = $$v
                    },
                    expression: "isCreditOrDebitVisible"
                  }
                },
                [
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("div", {
                              ref: "card",
                              staticClass: "form-control m-2"
                            })
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canMakePayment,
                  expression: "canMakePayment"
                }
              ],
              staticClass: "mb-4",
              attrs: { "no-body": "" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex payment-option",
                  attrs: {
                    "aria-expanded": _vm.isPayWithVisible ? "true" : "false",
                    "aria-controls": "accordion-2",
                    "header-tag": "header",
                    role: "tab"
                  },
                  on: {
                    click: function($event) {
                      _vm.isPayWithVisible = !_vm.isPayWithVisible
                    }
                  }
                },
                [
                  _c("div", [
                    _vm.isPayWithVisible
                      ? _c(
                          "span",
                          { staticClass: "when-opened" },
                          [
                            _c(
                              "b-avatar",
                              { attrs: { variant: "tertiary", size: "22px" } },
                              [
                                _c("b-icon", {
                                  staticStyle: { color: "#cdfcb1" },
                                  attrs: { icon: "check", "font-scale": "1.2" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "span",
                          { staticClass: "when-closed" },
                          [
                            _c(
                              "b-avatar",
                              {
                                staticStyle: { "background-color": "#e0e0e0" },
                                attrs: { icon: "chevron-up", size: "22px" }
                              },
                              [
                                _c("b-icon", {
                                  staticStyle: { color: "#e0e0e0" },
                                  attrs: {
                                    icon: "circle-fill",
                                    "font-scale": "1.2"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "payment-title",
                      staticStyle: { "flex-grow": "1" }
                    },
                    [_vm._v("Pay with")]
                  ),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/payment-methods/apple-pay.svg"),
                      width: "20px"
                    }
                  }),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/payment-methods/google-pay.svg"),
                      width: "20px"
                    }
                  })
                ]
              ),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "accordion-2",
                    accordion: "my-accordion",
                    role: "tabpanel"
                  },
                  model: {
                    value: _vm.isPayWithVisible,
                    callback: function($$v) {
                      _vm.isPayWithVisible = $$v
                    },
                    expression: "isPayWithVisible"
                  }
                },
                [
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("div", {
                              ref: "paymentRequestButton",
                              attrs: { id: "payment-request-button" }
                            })
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "mt-3 mb-3 checkbox-label",
                  attrs: { value: "true", "unchecked-value": "false" },
                  model: {
                    value: _vm.sameaddress,
                    callback: function($$v) {
                      _vm.sameaddress = $$v
                    },
                    expression: "sameaddress"
                  }
                },
                [
                  _vm._v(
                    " My billing information is the same as my shipping information "
                  )
                ]
              ),
              _vm.sameaddress != "true"
                ? _c("BillingAddress", {
                    ref: "addressComponent",
                    staticClass: "mt-5"
                  })
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "mt-5",
                  attrs: {
                    pill: "",
                    block: "",
                    variant: "info",
                    disabled: _vm.disabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.checkAnswers({ paymentmethodid: null })
                    }
                  }
                },
                [_vm._v("Submit")]
              ),
              _c("div", { staticClass: "checkout-agreement mt-3" }, [
                _c("p", [
                  _vm._v(
                    " By adding a payment method and clicking \"Submit,\" you're agreeing to opt into a subscription with Winona. However, you won't be charged unless your physician prescribes a treatment plan. If prescribed, your payment method will be charged at that time for the cost of your prescription(s). Your subscription will renew every month or every 3 months, depending on your preference. Please note you can cancel your subscription and stop receiving treatment at any time, by contacting the Patient Care Team. "
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-5 text-center", attrs: { cols: "12" } },
            [
              _c("b-icon", { attrs: { icon: "lock-fill" } }),
              _vm._v(" 256-BIT TLS SECURITY ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }