var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        backgroundColor:
          _vm.step === 2 ? "#d5f4ec" : _vm.step === 4 ? "#96B5AD" : "white"
      }
    },
    [
      _vm.step !== 4
        ? _c(
            "div",
            {
              staticClass: "mx-5 pt-5 position-relative",
              staticStyle: { "z-index": "2" }
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "link", to: "/account-settings" }
                },
                [
                  _c("span", {
                    staticClass: "next-arrow-purple",
                    staticStyle: { transform: "rotate(180deg)" }
                  }),
                  _vm._v(" Back to profile ")
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.step === 1
        ? _c(
            "b-container",
            { staticClass: "referral-main my-5 my-md-6 step-container" },
            [
              _c(
                "div",
                { staticClass: "step-one-content" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "col-auto" }, [
                        _c(
                          "h3",
                          {
                            staticClass: "display-4 text-primary heading",
                            staticStyle: { "line-height": "2rem" }
                          },
                          [
                            _vm._v(
                              " Sure you don’t want to just take a break? "
                            )
                          ]
                        ),
                        _c("h2", { staticClass: "mt-4" }, [
                          _vm._v("Sometimes you just need a break.")
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("b-form-group", {
                    staticClass: "my-5",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var takeABreak = ref.takeABreak
                            return [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "shipping-radio",
                                  attrs: {
                                    "aria-describedby": takeABreak,
                                    name: "take-a-break",
                                    value: "30"
                                  },
                                  model: {
                                    value: _vm.breakTime,
                                    callback: function($$v) {
                                      _vm.breakTime = $$v
                                    },
                                    expression: "breakTime"
                                  }
                                },
                                [_vm._v(" 30 days ")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "shipping-radio",
                                  attrs: {
                                    "aria-describedby": takeABreak,
                                    name: "take-a-break",
                                    value: "60"
                                  },
                                  model: {
                                    value: _vm.breakTime,
                                    callback: function($$v) {
                                      _vm.breakTime = $$v
                                    },
                                    expression: "breakTime"
                                  }
                                },
                                [_vm._v(" 60 days ")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "shipping-radio",
                                  attrs: {
                                    "aria-describedby": takeABreak,
                                    name: "take-a-break",
                                    value: "90"
                                  },
                                  model: {
                                    value: _vm.breakTime,
                                    callback: function($$v) {
                                      _vm.breakTime = $$v
                                    },
                                    expression: "breakTime"
                                  }
                                },
                                [_vm._v(" 90 days ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3365498568
                    )
                  }),
                  _c(
                    "b-row",
                    {
                      staticClass: "text-center",
                      attrs: { "align-h": "center", "no-gutters": "" }
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { "max-width": "300px" },
                              attrs: { pill: "", block: "", variant: "info" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push("/account-settings")
                                }
                              }
                            },
                            [_vm._v(" keep my subscription ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { "max-width": "300px" },
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-info",
                                disabled: _vm.isProcessing
                              },
                              on: { click: _vm.stopSubscription }
                            },
                            [_vm._v(" Pause subscription ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.data.availedstopcancel
                            ? _c(
                                "b-button",
                                {
                                  staticStyle: { "max-width": "300px" },
                                  attrs: {
                                    pill: "",
                                    block: "",
                                    variant: "link",
                                    disabled: _vm.isProcessing
                                  },
                                  on: { click: _vm.removeSubscription }
                                },
                                [_vm._v(" continue to cancel ")]
                              )
                            : _c(
                                "b-button",
                                {
                                  staticStyle: { "max-width": "300px" },
                                  attrs: {
                                    pill: "",
                                    block: "",
                                    variant: "link"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.step = 2
                                    }
                                  }
                                },
                                [_vm._v(" continue to cancel ")]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.step === 2
        ? _c("div", { staticClass: "upsell-container pt-6" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "step-two-content" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-alert",
                          {
                            attrs: { variant: "warning", show: _vm.alert.show }
                          },
                          [_vm._v(_vm._s(_vm.alert.message))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mb-4" },
                  [
                    _c("b-col", { staticClass: "col-auto" }, [
                      _c(
                        "h3",
                        {
                          staticClass: "display-4 text-primary heading",
                          staticStyle: { "line-height": "2.5rem" }
                        },
                        [
                          _vm._v(
                            " Hang on, keep your subscription and get 20% off your next shipment! "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "max-width": "300px" },
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "info",
                              disabled: _vm.isProcessing
                            },
                            on: { click: _vm.preventSubscription }
                          },
                          [_vm._v(" get 20% off ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "max-width": "300px" },
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "outline-info",
                              disabled: _vm.isProcessing
                            },
                            on: {
                              click: function($event) {
                                _vm.step = 1
                              }
                            }
                          },
                          [_vm._v(" Pause subscription ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: { "max-width": "300px" },
                            attrs: { pill: "", block: "", variant: "link" },
                            on: {
                              click: function($event) {
                                _vm.step = 3
                              }
                            }
                          },
                          [_vm._v(" continue to cancel ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.step === 3
        ? _c(
            "b-container",
            { staticClass: "referral-main my-5 my-md-6 step-container" },
            [
              _c(
                "div",
                { staticClass: "step-three-content" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "col-auto" }, [
                        _c(
                          "h3",
                          {
                            staticClass: "display-4 text-primary heading",
                            staticStyle: { "line-height": "2rem" }
                          },
                          [_vm._v(" We're sorry to see you go... ")]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                variant: "warning",
                                show: _vm.alert.show
                              }
                            },
                            [_vm._v(_vm._s(_vm.alert.message))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "mt-4",
                      attrs: { "align-h": "center", "align-v": "center" }
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-5 mx-4 mx-md-0 mb-md-0" },
                        [
                          _c(
                            "div",
                            { staticClass: "mb-4", attrs: { role: "group" } },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "d-letter-spacing",
                                  attrs: { for: "cancellationreason" }
                                },
                                [_vm._v(" Why are you cancelling? ")]
                              ),
                              _c("v-select", {
                                attrs: {
                                  "append-to-body": "",
                                  "calculate-position": _vm.withPopper,
                                  label: "text",
                                  options: _vm.cancellationReasons
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "open-indicator",
                                      fn: function(ref) {
                                        var attributes = ref.attributes
                                        return [
                                          _c(
                                            "b-icon",
                                            _vm._b(
                                              {
                                                attrs: { icon: "chevron-down" }
                                              },
                                              "b-icon",
                                              attributes,
                                              false
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  607575044
                                ),
                                model: {
                                  value: _vm.selectedReason,
                                  callback: function($$v) {
                                    _vm.selectedReason = $$v
                                  },
                                  expression: "selectedReason"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-4", attrs: { role: "group" } },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "d-letter-spacing",
                                  attrs: { for: "furtherexplanation" }
                                },
                                [_vm._v(" Further explanation ")]
                              ),
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "textarea",
                                  rows: "6",
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.furtherExplanation,
                                  callback: function($$v) {
                                    _vm.furtherExplanation = $$v
                                  },
                                  expression: "furtherExplanation"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-5" },
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticStyle: { "max-width": "300px" },
                                      attrs: {
                                        pill: "",
                                        block: "",
                                        variant: "outline-info",
                                        disabled: _vm.isProcessing
                                      },
                                      on: { click: _vm.removeSubscription }
                                    },
                                    [_vm._v(" Submit cancellation ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticStyle: { "max-width": "300px" },
                                      attrs: {
                                        pill: "",
                                        block: "",
                                        variant: "info",
                                        disabled: _vm.isProcessing
                                      },
                                      on: { click: _vm.preventSubscription }
                                    },
                                    [_vm._v(" get 20% off ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticStyle: { "max-width": "300px" },
                                      attrs: {
                                        pill: "",
                                        block: "",
                                        variant: "link",
                                        disabled: _vm.isProcessing
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.step = 1
                                        }
                                      }
                                    },
                                    [_vm._v(" Pause subscription ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.step === 4
        ? _c("div", { staticClass: "cancelled-container pt-6" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "step-four-content" },
              [
                _c(
                  "b-row",
                  { staticClass: "mb-4" },
                  [
                    _c("b-col", { staticClass: "col-auto" }, [
                      _c(
                        "h3",
                        {
                          staticClass: "display-4 heading",
                          staticStyle: {
                            "line-height": "2.5rem",
                            color: "#0c3523"
                          }
                        },
                        [
                          _vm._v(
                            " We have successfully canceled your account. We are sorry that Winona wasn't the right fit for you. "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              "max-width": "300px",
                              background: "#0c3523",
                              "border-color": "#0c3523"
                            },
                            attrs: { pill: "", block: "", variant: "info" },
                            on: {
                              click: function($event) {
                                return _vm.$router.push("/account-settings")
                              }
                            }
                          },
                          [_vm._v(" Back to profile ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "position-relative dhea-img-container" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/cancel-subscription/dhea.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "position-relative ep-img-container" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/cancel-subscription/ep-body-cream.jpeg"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }