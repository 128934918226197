var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    {
      staticClass: "d-none d-lg-none d-lg-flex py-4 progress-header mt-xxl-6",
      attrs: { "align-v": "center" }
    },
    [
      _c(
        "b-col",
        { staticClass: "pl-5", attrs: { cols: "1" } },
        [
          !_vm.hide
            ? _c(
                "b-button",
                {
                  staticClass: "back-btn",
                  staticStyle: { "min-width": "inherit" },
                  attrs: { size: "lg", variant: "link" },
                  on: { click: _vm.backButton }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/small_back_arrow.svg")
                    }
                  })
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "pr-7", attrs: { cols: "11" } },
        [
          _c(
            "b-row",
            _vm._l(_vm.progress, function(data, i) {
              return _c(
                "b-col",
                {
                  key: data + i,
                  class: data.value !== 0 ? "" : "progress-disable",
                  attrs: { cols: "12", md: "4" }
                },
                [
                  _c("b-progress", {
                    staticClass: "mb-1 step-progress",
                    attrs: {
                      value: data.value,
                      max: data.max,
                      variant: "purple",
                      height: "8px"
                    }
                  }),
                  _c("span", [_vm._v(" " + _vm._s(data.title) + " ")])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }