var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-block" },
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("div", { staticClass: "d-none d-lg-block" }, [
          _c(
            "div",
            {
              staticClass: "trustpilot-widget",
              staticStyle: {
                "padding-top": "1rem",
                "padding-bottom": "1rem",
                width: "100%",
                height: "200px"
              },
              attrs: {
                id: "trustbox",
                "data-locale": "en-US",
                "data-template-id": "53aa8912dec7e10d38f59f36",
                "data-businessunit-id": "60a3fe65d3a8ed0001a01007",
                "data-style-height": "140px",
                "data-style-width": "100%",
                "data-theme": "light",
                "data-stars": "4,5",
                "data-review-languages": "en"
              }
            },
            [
              _c(
                "a",
                {
                  staticStyle: {
                    display: "block",
                    width: "100%",
                    "text-align": "center"
                  },
                  attrs: {
                    href: "https://www.trustpilot.com/review/bywinona.com",
                    target: "_blank",
                    rel: "noopener"
                  }
                },
                [
                  _c("img", {
                    staticStyle: { height: "30px", margin: "0 auto" },
                    attrs: {
                      alt: "trustpilot",
                      src: require("@/assets/images/onboarding/trustpilot-logo.png")
                    }
                  })
                ]
              )
            ]
          )
        ]),
        _c("div", { staticClass: "d-block d-lg-none" }, [
          _c(
            "div",
            {
              staticClass: "trustpilot-widget",
              attrs: {
                id: "trustbox",
                "data-locale": "en-US",
                "data-template-id": "539ad0ffdec7e10e686debd7",
                "data-businessunit-id": "60a3fe65d3a8ed0001a01007",
                "data-style-height": "350px",
                "data-style-width": "100%",
                "data-theme": "light",
                "data-stars": "4,5",
                "data-review-languages": "en"
              }
            },
            [
              _c(
                "a",
                {
                  staticStyle: {
                    display: "block",
                    width: "100%",
                    "text-align": "center"
                  },
                  attrs: {
                    href: "https://www.trustpilot.com/review/bywinona.com",
                    target: "_blank",
                    rel: "noopener"
                  }
                },
                [
                  _c("img", {
                    staticStyle: {
                      height: "40px",
                      margin: "0 auto",
                      display: "block"
                    },
                    attrs: {
                      alt: "trustpilot",
                      src: require("@/assets/images/onboarding/trustpilot-horizontal.png")
                    }
                  })
                ]
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }